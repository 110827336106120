import grey from '@material-ui/core/colors/grey';

const grey600 = grey[600];

const styles = theme => ({
            navigation: {
                fontSize: 15,
                fontWeight: 300,
                color: grey600,
                paddingBottom: 15,
                display: 'block',
                displayPrint: "none!important",
                '@media print': {
                    display: 'none',
                },
            },
            wrapper: {
                display: 'flex',
                justifyContent: 'space-between', // Distributes space between the items
                alignItems: 'center', // Aligns items vertically
            },
            title: {
                fontSize: 24,
                fontWeight: 300,
                marginBottom: 20
            },
            paper: {
                padding: 30
            },
            clear: {
                clear: 'both'
            },
            appBar: {
                position: 'fixed',
                top: 0,
                overflow: 'hidden',
                maxHeight: 57,
                '@media print': {
                    display: 'none',
                },
            },
            saveButton: {
                backgroundColor: "rgb(148,217,148)"
            },
            deleteButton: {
                backgroundColor: "rgb(212,99,62)",
                displayPrint: "none!important"
            },
            editButton: {
                backgroundColor: "rgb(214,211,68)",
                displayPrint: "none!important"
            },
            neutralButton: {
                backgroundColor: "rgb(182,182,177)"
            },
            editFormButton: {
                display: "block",
                float: "right",
                marginTop: 100,
                marginRight: 50,
                backgroundColor: "rgb(126,139,129)"
            },
            addFormButton: {
                display: "block",
                float: "right",
                marginTop: 100,
                marginRight: 50,
                marginBottom: 10,
                backgroundColor: "rgb(148,217,148)"
            },
            addFormStickyButton: {
                display: "block",
                position: "fixed",
                bottom: "25px",
                right: "95px",
                backgroundColor: "rgb(148,217,148)",
                '@media print': {
                    display: 'none',
                },
            },
            printFormStickyButton: {
                display: "block",
                position: "fixed",
                bottom: "25px",
                right: "20px",
                backgroundColor: "rgb(90,85,85)",
                '@media print': {
                    display: 'none',
                },
            },

            addFormStickyButtonDisabled: {
                display: "block",
                position: "fixed",
                bottom: "25px",
                right: "95px",
                backgroundColor: "rgb(149,155,158)",
            },

            changeFormStickyButtonDisabled: {
                // display: "block",
                // position: "fixed",
                // bottom: "25px",
                // right: "2px",
                // backgroundColor: "rgb(149,155,158)",
                backgroundColor: "rgb(148,217,148)",
            },

            root: {
                flexGrow: 1,
            },
            verticalTextCenter: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: "1.25rem"
            },
            numeratedItemGrid: {
                backgroundColor: "rgb(251,212,180)",
                width: 80,
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000"

            },
            centeredItemGrid: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            numeratedFreeItemGrid: {
                backgroundColor: "rgb(251,212,180)",
                textAlign: "center",
            },
            coloredItemGrid: {
                backgroundColor: "rgb(251,212,180)",
                borderTop: "2px solid black",
                borderLeft: "2px solid black",
                borderRight: "2px solid black",
            },
            coloredItemGrid1: {
                backgroundColor: "rgb(255,231,231)",
                border: "2px solid black",
            },
            heightControlColoredItemGrid: {
                backgroundColor: "rgb(251,212,180)",
                borderTop: "2px solid black",
                borderLeft: "2px solid black",
                borderRight: "2px solid black",
                minHeight: 65
            },
            lessHeightControlColoredItemGrid: {
                backgroundColor: "rgb(251,212,180)",
                borderTop: "2px solid black",
                borderLeft: "2px solid black",
                borderRight: "2px solid black",
                minHeight: 32
            },
            heightControlBlueItemGrid: {
                backgroundColor: "rgb(180,223,251)",
                borderTop: "2px solid black",
                borderLeft: "2px solid black",
                borderRight: "2px solid black",
                minHeight: 65
            },
            purpleColoredItemGrid: {
                backgroundColor: "rgb(229,223,236)",
                border: "1px solid black",
            },
            printHiddenItemGrid: {
                border: "1px solid black",
                '@media print': {
                    display: 'none',
                },
            },
            blueColoredItemGrid: {
                backgroundColor: "rgb(180,223,251)",
                border: "1px solid black",
            },
            heightPurpleControlColoredItemGrid: {
                backgroundColor: "rgb(229,223,236)",
                border: "1px solid black",
                minHeight: 66

            },
            lessHeightPurpleControlColoredItemGrid: {
                backgroundColor: "rgb(229,223,236)",
                border: "1px solid black",
                minHeight: 80

            },
            container: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            mainContainer: {
                padding: 20
            },
            divider: {
                height: 35
            },
            paddedLeftContainer: {
                paddingLeft: 25
            },
            paddedRightContainer: {
                paddingRight: 25
            },
            paddedBottomContainer: {
                marginBottom: 55
            },
            paddedFullWidthInput: {
                margin: 10,
                width: "calc(100% - 20px)",
                backgroundColor: 'lightgray',
            },

            controlPaddedFullWidthInput: {
                width: "100%",
                '& textarea': {
                    display: 'flex!important',
                    textWrap: "wrap"
                },
                '& div': {},
                backgroundColor: 'lightgray',
            },

            lessPaddedFullWidthInput: {
                margin: 2,
                width: "calc(100% - 4px)",
                backgroundColor: "lightgray"
            },
            lessPaddedFullWidthInputNoPrint: {
                margin: 2,
                width: "calc(100% - 4px)",
                backgroundColor: "lightgray",
                '@media print': {
                    display: 'none',
                },
            },
            paddedInput: {
                margin: 10, backgroundColor: "lightgray"

            },
            noOutline: {
                "&:focus": {
                    outline: "none"

                }
            },
            input: {
                "&:focus": {
                    outline: "none!important"

                }
            },

            paddedRadio: {
                marginLeft: 10,
                width: "calc(100% - 10px)"
            },
            textField: {
                marginLeft: theme.spacing.unit,
                marginRight: theme.spacing.unit,
                width: 100
            },
            loginTextField: {
                marginLeft: theme.spacing.unit,
                marginRight: theme.spacing.unit,
                width: "90%"
            },
            searchTextField: {
                // margin: '8px',
                width: '220px',
                whiteSpace: 'nowrap', // ensures the placeholder is on one line
                overflow: 'hidden',   // hides overflow text
                textOverflow: 'ellipsis'
            },
            filterContainer: {
                display: 'flex',
                alignItems: 'center',
            },
            marginLeftContainer: {
                marginLeft: 25
            },
            button: {
                margin: theme.spacing.unit,
                '@media print': {
                    display: 'none',
                },
            },
            row: {
                '&:nth-of-Status(odd)': {
                    backgroundColor: theme.palette.background.default,
                },
            },
            thinButton: {
                margin: theme.spacing.unit,
                height: 20,
                '@media print': {
                    display: 'none',
                },
            },
            noPrint: {
                margin: 0,
                '@media print': {
                    display: 'none',
                    border: '0!important',
                },
            },
            grid: {
                container: {
                    marginBottom: 50,
                }
            },
            typography: {
                marginLeft: theme.spacing.unit,
            },
            typographyLineHeight: {
                marginLeft: 10,
                lineHeight: 2.6
            },
            compactTypographyLineHeightPrint: {
                marginLeft: 10,
                marginRight: 10,
                lineHeight: 1.6,
                '@media print': {
                    fontSize: '1.25rem'
                },

            },
            typographyLineHeightPrint: {
                marginLeft: 10,
                marginRight: 10,
                lineHeight: 1.6,
                '@media print': {
                    fontSize: '1.70rem'
                },

            },
            compactTypographyPrint: {
                lineHeight: 1.6,
                fontSize: '1.25rem',
                width: "calc(100% - 4px)",
                display: 'none',
                '@media print': {
                    display: 'block',
                },
            },
            typographyPrint: {
                lineHeight: 1.6,
                fontSize: '1.70rem',
                width: "calc(100% - 4px)",
                display: 'none',
                '@media print': {
                    display: 'block',
                },
            },
            paddedTypography: {
                marginLeft: 10,
                fontSize: "1.25rem"
            },
            centeredText: {
                textAlign: "center"
            },
            kontrolorItemGrid: {
                backgroundColor: "rgb(180,223,251)",
                flex: 1
            },
            orangeColoredItemGrid: {
                flex: 1,
                backgroundColor: "rgb(251,212,180)"
            },
            paddedGrid: {
                paddingTop: 15,
                paddingBottom: 15
            },
            divSignature: {
                borderBottom: "1px solid #000",
                marginTop: 30
            },
            '@global': {
                '@media print': {
                    '@page': {
                        margin: '4mm',
                        size: 'A4 landscape', // Default to landscape
                    },
                    body: {
                        zoom: '0.5',
                    },
                    'body.portrait': {
                        size: 'A4 portrait',
                        zoom: '0.5',
                    },
                },
            },
        }
    )
;

export default styles;
