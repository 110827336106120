import React from 'react';
import ApplicationList from "../pages/application/ApplicationList";
import EditApplication from "../pages/application/EditApplication";
import CreateApplication from "../pages/application/CreateApplication";
import App from "../App";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PouRastitelnoList from "../pages/plan/rastitelno/PouRastitelnoList";
import EditPouRastitelno from "../pages/plan/rastitelno/EditPouRastitelno";
import CreatePouRastitelno from "../pages/plan/rastitelno/CreatePouRastitelno";
import PouStocarstvoList from "../pages/plan/stocarstvo/PouStocarstvoList";
import CreatePouStocarstvo from "../pages/plan/stocarstvo/CreatePouStocarstvo";
import EditPouStocarstvo from "../pages/plan/stocarstvo/EditPouStocarstvo";
import PrivateRoute from "./privateRoute";
import LoginScreen from "../pages/LoginScreen";
import {getLoggedIn, getUser} from "../redux/user/reducer";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../pages/styles";
import PouPcelarstvoList from "../pages/plan/pcelarstvo/PouPcelarstvoList";
import EditPouPcelarstvo from "../pages/plan/pcelarstvo/EditPouPcelarstvo";
import CreatePouPcelarstvo from "../pages/plan/pcelarstvo/CreatePouPcelarstvo";
import UserList from "../pages/user/UserList";
import CreateUser from "../pages/user/CreateUser";
import EditUser from "../pages/user/EditUser";
import EditOwnUser from "../pages/user/ЕditOwnUser";
import LookupList from "../pages/lookup/LookupList";
import RegisterUser from "../pages/RegisterUser";
import ForgotPassword from "../pages/ForgotPassword";
import SpecRastitelnoList from "../pages/specification/rastitelno/SpecRastitelnoList";
import CreateSpecRastitelno from "../pages/specification/rastitelno/CreateSpecRastitelno";
import EditSpecRastitelno from "../pages/specification/rastitelno/EditSpecRastitelno";
import SpecPcelarstvoList from "../pages/specification/pcelarstvo/SpecPcelarstvoList";
import CreateSpecPcelarstvo from "../pages/specification/pcelarstvo/CreateSpecPcelarstvo";
import EditSpecPcelarstvo from "../pages/specification/pcelarstvo/EditSpecPcelarstvo";
import SpecStocarstvoList from "../pages/specification/stocarstvo/SpecStocarstvoList";
import CreateSpecStocarstvo from "../pages/specification/stocarstvo/CreateSpecStocarstvo";
import EditSpecStocarstvo from "../pages/specification/stocarstvo/EditSpecStocarstvo";

class Index extends React.Component {
    render() {
        const authed = this.props.loggedIn
        return (
            <Router>
                <App>
                    <Switch>
                        <Route exact path="/login" component={LoginScreen}/>
                        <Route exact path="/RegisterUser" component={RegisterUser}/>
                        <Route exact path="/ForgotPassword" component={ForgotPassword}/>

                        <PrivateRoute authed={authed} exact path="/" component={ApplicationList}/>
                        <PrivateRoute authed={authed} exact path="/ApplicationList" component={ApplicationList}/>
                        <PrivateRoute authed={authed} exact path="/CreateApplication" component={CreateApplication}/>
                        <PrivateRoute authed={authed} exact path="/Application/:id" component={EditApplication}/>

                        <PrivateRoute authed={authed} exact path="/SpecRastitelnoList" component={SpecRastitelnoList}/>
                        <PrivateRoute authed={authed} exact path="/CreateSpecRastitelno"
                                      component={CreateSpecRastitelno}/>
                        <PrivateRoute authed={authed} exact path="/SpecRastitelno/:id" component={EditSpecRastitelno}/>

                        <PrivateRoute authed={authed} exact path="/SpecStocarstvoList" component={SpecStocarstvoList}/>
                        <PrivateRoute authed={authed} exact path="/CreateSpecStocarstvo"
                                      component={CreateSpecStocarstvo}/>
                        <PrivateRoute authed={authed} exact path="/SpecStocarstvo/:id" component={EditSpecStocarstvo}/>

                        <PrivateRoute authed={authed} exact path="/SpecPcelarstvoList" component={SpecPcelarstvoList}/>
                        <PrivateRoute authed={authed} exact path="/CreateSpecPcelarstvo"
                                      component={CreateSpecPcelarstvo}/>
                        <PrivateRoute authed={authed} exact path="/SpecPcelarstvo/:id" component={EditSpecPcelarstvo}/>


                        <PrivateRoute authed={authed} exact path="/PouRastitelnoList" component={PouRastitelnoList}/>
                        <PrivateRoute authed={authed} exact path="/PouStocarstvoList" component={PouStocarstvoList}/>
                        <PrivateRoute authed={authed} exact path="/PouPcelarstvoList" component={PouPcelarstvoList}/>
                        <PrivateRoute authed={authed} exact path="/CreatePouRastitelno"
                                      component={CreatePouRastitelno}/>
                        <PrivateRoute authed={authed} exact path="/PouRastitelno/:id" component={EditPouRastitelno}/>
                        <PrivateRoute authed={authed} exact path="/CreatePouStocarstvo"
                                      component={CreatePouStocarstvo}/>
                        <PrivateRoute authed={authed} exact path="/PouStocarstvo/:id" component={EditPouStocarstvo}/>
                        <PrivateRoute authed={authed} exact path="/CreatePouPcelarstvo"
                                      component={CreatePouPcelarstvo}/>
                        <PrivateRoute authed={authed} exact path="/PouPcelarstvo/:id" component={EditPouPcelarstvo}/>

                        <PrivateRoute authed={authed} exact path="/UserList" component={UserList}/>
                        <PrivateRoute authed={authed} exact path="/CreateUser" component={CreateUser}/>
                        <PrivateRoute authed={authed} exact path="/User/:id" component={EditUser}/>
                        <PrivateRoute authed={authed} exact path="/EditOwnUser" component={EditOwnUser}/>

                        <PrivateRoute authed={authed} exact path="/LookupList" component={LookupList}/>
                        <Route component={LoginScreen}/>
                        <Route component={RegisterUser}/>
                        <Route component={ForgotPassword}/>
                    </Switch>
                </App>
            </Router>
        );
    }
}

const mapStateToProps = state => ({
    user: getUser(state),
    loggedIn: getLoggedIn(state)
})
export default withStyles(globalStyles)(
    connect(mapStateToProps, null)(Index)
)
