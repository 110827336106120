import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {bindActionCreators, compose} from "redux";
import PouStocarstvo from "./PouStocarstvo";
import {getPouStocarstvo} from "../../../redux/pouStocarstvo/reducer";
import {fetchPouStocarstvoById, updatePouStocarstvoRequest} from "../../../redux/pouStocarstvo/fetch";
import {
    fetchPouStocarstvoControlById,
    updatePouStocarstvoControlRequest
} from "../../../redux/pouStocarstvoControl/fetch";
import Notification from "../../../common/Notification";
import {cleanJsonBody, cleanJsonControl} from "../../../redux/helpers";
import {getPouStocarstvoControl} from "../../../redux/pouStocarstvoControl/reducer";
import {uploadFileRequest} from "../../../redux/fileSystem/fetch";
import {getUploadedFile} from "../../../redux/fileSystem/reducer";
import {getUser} from "../../../redux/user/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";


export class EditPouStocarstvo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.updatePouStocarstvo = this.updatePouStocarstvo.bind(this);
        this.uploadFileStocarstvo = this.uploadFileStocarstvo.bind(this);
        this.redirectToPouStocarstvoList = this.redirectToPouStocarstvoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchPouStocarstvoById, fetchPouStocarstvoControlById, fetchLookups} = this.props;
        fetchPouStocarstvoById(this.props.match.params.id);
        fetchPouStocarstvoControlById(this.props.match.params.id);
        fetchLookups();
    }

    updatePouStocarstvo(values) {
        /*  Create and call updatePouStocarstvo action */
        const id = values.id
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)), true);
        let newValuesControl = cleanJsonControl(JSON.parse(JSON.stringify(values)));
        this.props.updatePouStocarstvoRequest(newValues, id)
        this.props.updatePouStocarstvoControlRequest(newValuesControl, id)
        this.showNotification("Успешно зачувано!");
    }

    redirectToPouStocarstvoList() {
        this.props.dispatch(reset('PouStocarstvo'));
        this.props.history.push(`/PouStocarstvoList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    uploadFileStocarstvo(file_upload_id) {
        const myInput = document.getElementById(file_upload_id);
        return uploadFileRequest(myInput).then(res => {
            this.showNotification("Успешно прикачен фајл!");
            return res
        })
    }

    render() {
        const {notify, message} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Card>
                    <CardContent className={classes.noPrint}>
                        <h1>Измени ПОУ сточарство</h1>
                    </CardContent>
                    {this.props.id && this.props.pouStocarstvo &&
                        this.props.pouStocarstvoControl &&
                        this.props.pouStocarstvoControl.controlId === this.props.pouStocarstvo.id &&
                        <PouStocarstvo
                        pouStocarstvo={this.props.pouStocarstvo.pouStocarstvo}
                        pouStocarstvoControl={this.props.pouStocarstvoControl.pouStocarstvoControl}
                        pouStocarstvoId={this.props.pouStocarstvo.pouStocarstvo.id}
                        uploadedFileId={this.props.fileSystem ? this.props.fileSystem.fileSystem : null}
                        uploadFile={this.uploadFileStocarstvo}
                        onSubmit={this.updatePouStocarstvo}
                        onCancel={this.redirectToPouStocarstvoList}
                        editMode
                        user={this.props.user}
                        lookupData={this.props.lookupData}
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={false}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        pouStocarstvo: getPouStocarstvo(state),
        pouStocarstvoControl: getPouStocarstvoControl(state),
        fileSystem: getUploadedFile(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPouStocarstvoById,
        updatePouStocarstvoRequest,
        fetchPouStocarstvoControlById,
        updatePouStocarstvoControlRequest,
        fetchLookups
    }, dispatch)
}


EditPouStocarstvo.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditPouStocarstvo.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(EditPouStocarstvo);
