import React from 'react';
import PropTypes from 'prop-types';
import Application from './Application';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Notification from '../../common/Notification';
import {bindActionCreators, compose} from "redux";
import {fetchApplicationById, updateApplicationRequest} from "../../redux/application/fetch";
import {getApplication} from "../../redux/application/reducer";
import {cleanJsonBody} from "../../redux/helpers";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../styles";


export class EditApplication extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.updateApplication = this.updateApplication.bind(this);
        this.redirectToApplicationList = this.redirectToApplicationList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchApplicationById} = this.props;
        fetchApplicationById(this.props.match.params.id);
    }


    updateApplication(values) {
        /*  Create and call updateApplication action */
        const id = values.id
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)));
        this.props.updateApplicationRequest(newValues, id)
        this.showNotification("Успешно зачувано");

    }

    redirectToApplicationList() {
        this.props.dispatch(reset('Application'));
        this.props.history.push(`/ApplicationList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message} = this.state;
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardContent className={classes.noPrint}>
                        <h1>Измени Апликација</h1>
                    </CardContent>
                    {this.props.id && this.props.application &&
                    <Application
                        application={this.props.application.application}
                        applicationId={this.props.application.application.id}
                        onSubmit={this.updateApplication}
                        onCancel={this.redirectToApplicationList}
                        editMode
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={false}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        application: getApplication(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchApplicationById, updateApplicationRequest}, dispatch)
}


EditApplication.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditApplication.contextTypes = {
    router: PropTypes.object
};

export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(EditApplication);
