export const zemjisteData =
    [
        {
            id: "1", ime: "ниви"
        },
        {
            id: "2", ime: "пасишта"
        },
        {
            id: "3", ime: "ливади"
        },
        {
            id: "4", ime: "друго"
        }
    ]