import React from 'react';
import propTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add'
import format from 'string-format';
import {Fab} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {connect} from "react-redux";
import PageBase from "../../PageBase";
import Notification from "../../../common/Notification";
import ConfirmDelete from "../../../common/ConfirmDelete";
import globalStyles from "../../styles";
import {getSpecNum, getSpecPcelarstvo, getSpecPcelarstvoList} from "../../../redux/specPcelarstvo/reducer";
import {
    deleteSpecPcelarstvoRequest,
    duplicateSpecPcelarstvoRequest,
    fetchSpecPcelarstvoList
} from "../../../redux/specPcelarstvo/fetch";
import {bindActionCreators} from "redux";


const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18
    },
    body: {
        fontSize: 16,
    },

}))(TableCell);

const CustomTableSmallCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18,
        width: 20
    },
    body: {
        fontSize: 16,
        width: 20
    },

}))(TableCell);

const styles = theme => ({
    row: {
        '&:nth-of-Status(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        margin: theme.spacing.unit,
    }
});


export class SpecPcelarstvoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteRecord: false,
            id: '',
            pgNo: 1,
            pageSize: 10,
            notify: false,
            message: '',
            error: false
        }
        this.deleteSpecPcelarstvo = this.deleteSpecPcelarstvo.bind(this);
        this.deleteAfterConfirmation = this.deleteAfterConfirmation.bind(this);
        this.editSpecPcelarstvo = this.editSpecPcelarstvo.bind(this);
        this.addSpecPcelarstvo = this.addSpecPcelarstvo.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.addLeadingZeroes = this.addLeadingZeroes.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }

    formatDate(inputDateTime) {
        const date = new Date(inputDateTime);
         // Use 'en-GB' locale for "dd.mm.yyyy" format
        return date.toLocaleDateString('en-GB');
    }

    sortTable = (column) => {
        const { specPcelarstvoList, sortDirection } = this.state;

        // Determine the sort direction
        const direction = sortDirection === 'desc' ? -1 : 1;

        // Sort the array based on the selected column
        const sortedList = specPcelarstvoList.sort((a, b) => {
            if (column === 'createdAt') {
                // Sort by date for the 'createdAt' column
                const dateA = new Date(a[column].split('.').reverse().join('-'));
                const dateB = new Date(b[column].split('.').reverse().join('-'));

                if (dateA < dateB) return -direction;
                if (dateA > dateB) return direction;
            } else {
                // Sort alphabetically for other columns
                if (a[column] < b[column]) return -direction;
                if (a[column] > b[column]) return direction;
            }

            return 0;
        });

        this.setState({
            specPcelarstvoList: sortedList,
            sortBy: column,
            sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
        });
    };

    componentWillMount() {
        const {fetchSpecPcelarstvoList} = this.props;
        fetchSpecPcelarstvoList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.specNum !== nextProps.specNum || this.state.specPcelarstvoList !== nextProps.specPcelarstvoList) {
            this.setState({ specPcelarstvoList: nextProps.specPcelarstvoList }, () => {
                this.sortTable('broj_spec');
            });
        }
    }

    deleteSpecPcelarstvo(id) {
        this.setState({deleteRecord: true, id: id});
    }

    editSpecPcelarstvo(id) {
        this.props.history.push(`/SpecPcelarstvo/` + id);
    }

    duplicateSpecPcelarstvo(id) {
        this.props.duplicateSpecPcelarstvoRequest(id)
        this.showNotification("Успешно креиран дупликат");
        setTimeout(() => this.redirectToSpecPcelarstvoList(), 1000);
    }

    redirectToSpecPcelarstvoList() {
        window.location.reload()
    }

    deleteAfterConfirmation(deleteConfirmed) {
        this.setState({deleteRecord: false});
        if (deleteConfirmed) {
            /* let selectedid =  this.state.id
              Create and call deleteSpecPcelarstvo action */
            const {deleteSpecPcelarstvoRequest} = this.props
            deleteSpecPcelarstvoRequest(this.state.id)
            this.showNotification("Успешно избришано");
        }
        this.setState({id: ''});
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    addSpecPcelarstvo() {
        this.props.history.push(`/CreateSpecPcelarstvo`);
    }

    addLeadingZeroes(num) {
        const number = num ? num : 0
        const numberString = number.toString()
        const desiredLength = 4;

        if (numberString.length >= desiredLength) {
            // If the number already has 4 or more digits, return it as is
            return numberString;
        } else {
            // Add leading zeroes to make it a 4-digit number
            const numberOfZeroesToAdd = desiredLength - numberString.length;
            const leadingZeroes = '0'.repeat(numberOfZeroesToAdd);
            return leadingZeroes + numberString;
        }
    }
    render() {
        const {classes, specPcelarstvoList} = this.props;
        const {notify, message, error} = this.state;
        return (
            <PageBase title="Листа спецификации пчеларство">
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add" className={classes.button}
                    onClick={this.addSpecPcelarstvo}
                >
                    <AddIcon/>
                    Додади спецификација
                </Fab>
                <Table>
                    <TableHead>
                        {specPcelarstvoList &&
                        <TableRow>
                            <CustomTableCell onClick={() => this.sortTable('broj_spec')}>Број на сертификат</CustomTableCell>
                            <CustomTableCell onClick={() => this.sortTable('createdAt')}>Датум на креирање</CustomTableCell>
                            <CustomTableCell onClick={() => this.sortTable('pouName')}>ПОУ</CustomTableCell>
                            <CustomTableCell>Дупликат</CustomTableCell>
                            <CustomTableSmallCell>Измени</CustomTableSmallCell>
                            <CustomTableSmallCell>Избриши</CustomTableSmallCell>
                        </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {specPcelarstvoList && specPcelarstvoList.map(SpecPcelarstvo => {
                            return (
                                <TableRow key={SpecPcelarstvo.Broj} className={classes.row}>
                                    <CustomTableCell>{this.addLeadingZeroes(SpecPcelarstvo.broj_spec)}</CustomTableCell>
                                    <CustomTableCell>{this.formatDate(SpecPcelarstvo.createdAt)}</CustomTableCell>
                                    <CustomTableCell>{SpecPcelarstvo.pouName}</CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="duplicate"
                                                onClick={() => this.duplicateSpecPcelarstvo(SpecPcelarstvo.id)}>
                                            <FileCopyIcon/>
                                        </Button>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="edit"
                                                onClick={() => this.editSpecPcelarstvo(SpecPcelarstvo.id)}>
                                            <EditIcon/>
                                        </Button>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="delete"
                                                onClick={() => this.deleteSpecPcelarstvo(SpecPcelarstvo.id)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <ConfirmDelete resourceHeader="Избриши спецификација?"
                               resourceOpis={format("Дали сте сигурни дека сакате да ја ибришете спецификацијата'{}'?", this.state.id)}
                               onModalClose={this.deleteAfterConfirmation}
                               openDeleteDialog={this.state.deleteRecord}/>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </PageBase>
        );
    }
}

SpecPcelarstvoList.propTypes = {
    classes: propTypes.object.isRequired,
};

const mapStateToProps = state => ({
    specPcelarstvoList: getSpecPcelarstvoList(state),
    specPcelarstvo: getSpecPcelarstvo(state),
    specNum: getSpecNum(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchSpecPcelarstvoList,
        deleteSpecPcelarstvoRequest,
        duplicateSpecPcelarstvoRequest
    }, dispatch)
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(SpecPcelarstvoList))