export const zamenaVosokData =
    [
        {
            id: "true", ime: "Да"
        },
        {
            id: "false", ime: "Не"
        },
        {
            id: "delumno", ime: "Делумно"
        }
    ]