import {
    CLEAR_POU_PCELARSTVO_CONTROL,
    CREATE_NEW_POU_PCELARSTVO_CONTROL,
    CREATE_POU_PCELARSTVO_CONTROL,
    DELETE_POU_PCELARSTVO_CONTROL,
    FETCH_POU_PCELARSTVO_CONTROL,
    UPDATE_POU_PCELARSTVO_CONTROL
} from "../actionTypes";

export const fetchPouPcelarstvoControlByIdAction = (payload) => ({
    type: FETCH_POU_PCELARSTVO_CONTROL,
    payload: {
        pouPcelarstvoControl: payload,
    },
})
export const createNewPouPcelarstvoControlAction = (payload) => ({
    type: CREATE_NEW_POU_PCELARSTVO_CONTROL,
    payload: {
        pouPcelarstvoControl: payload,
    },
})

export const createPouPcelarstvoControlAction = (payload) => ({
    type: CREATE_POU_PCELARSTVO_CONTROL,
    payload: {
        pouPcelarstvoControl: payload,
    },
})
export const updatePouPcelarstvoControlAction = (payload) => ({
    type: UPDATE_POU_PCELARSTVO_CONTROL,
    payload: {
        pouPcelarstvoControl: payload,
    },
})
export const clearPouPcelarstvoControlAction = () => ({
    type: CLEAR_POU_PCELARSTVO_CONTROL,
    payload: {
        pouPcelarstvoControl: null,
    },
})
export const deletePouPcelarstvoControlAction = () => ({
    type: DELETE_POU_PCELARSTVO_CONTROL,
    payload: {
        pouPcelarstvoControl: null,
    },
})