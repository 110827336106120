import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../pages/styles";
import Button from "@material-ui/core/Button";
import {downloadFileRequest} from "../redux/fileSystem/fetch";

class UploadInput extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {valueIn: this.props.uploadInput};
    }

    setValue(val) {
        this.setState({valueIn: val});
    }

    render() {
        const {
            classes,
            uploadLabel,
            downloadLabel,
            onChangeAction,
            onDeleteAction,
            fileUploadId,
            fileId,
            disabledButtons
        } = this.props

        return (
            <div>
                <input
                    className={classes.input}
                    style={{display: 'none'}}
                    id={fileUploadId}
                    onChange={onChangeAction}
                    multiple
                    type="file"
                />
                <label htmlFor={fileUploadId}>
                    <Button variant="raised"
                            component="span"
                            className={classes.button}
                            disabled={disabledButtons === undefined ? false : disabledButtons}>
                        {uploadLabel}
                    </Button>
                </label>
                {fileId &&
                <Button variant="contained"
                        component="span" onClick={() => downloadFileRequest(fileId)} className={classes.button}>
                    {downloadLabel}
                </Button>
                }
                {fileId &&
                <Button variant="contained"
                        component="span" onClick={onDeleteAction} className={classes.button}
                        disabled={disabledButtons === undefined ? false : disabledButtons}>
                    Избриши
                </Button>
                }
            </div>
        )
    }
}

export default withStyles(globalStyles)(UploadInput);