export const newSpecRastitelnoData =
    {
        "creatorId": "",
        "createdAt": "",
        "broj_sertifikat": "",
        "pouId": "",
        "pouName": "",
        "kapaciteti": [
            {
                "parcela": "",
                "povrsina": "",
                "organsko": "",
                "preod": ""
            }
        ],
        "proizvodstvo": [
            {
                "kultura": "",
                "povrsina": "",
                "status": ""
            }
        ]
    }