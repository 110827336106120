import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {bindActionCreators, compose} from "redux";
import PouPcelarstvo from "./PouPcelarstvo";
import {getPouPcelarstvo} from "../../../redux/pouPcelarstvo/reducer";
import {fetchPouPcelarstvoById, updatePouPcelarstvoRequest} from "../../../redux/pouPcelarstvo/fetch";
import {
    fetchPouPcelarstvoControlById,
    updatePouPcelarstvoControlRequest
} from "../../../redux/pouPcelarstvoControl/fetch";
import Notification from "../../../common/Notification";
import {cleanJsonBody, cleanJsonControl} from "../../../redux/helpers";
import {getPouPcelarstvoControl} from "../../../redux/pouPcelarstvoControl/reducer";
import {getUploadedFile} from "../../../redux/fileSystem/reducer";
import {uploadFileRequest} from "../../../redux/fileSystem/fetch";
import {getUser} from "../../../redux/user/reducer";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";


export class EditPouPcelarstvo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.updatePouPcelarstvo = this.updatePouPcelarstvo.bind(this);
        this.uploadFilePcelarstvo = this.uploadFilePcelarstvo.bind(this);
        this.redirectToPouPcelarstvoList = this.redirectToPouPcelarstvoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchPouPcelarstvoById, fetchPouPcelarstvoControlById, fetchLookups} = this.props;
        fetchPouPcelarstvoById(this.props.match.params.id);
        fetchPouPcelarstvoControlById(this.props.match.params.id);
        fetchLookups();
    }

    updatePouPcelarstvo(values) {
        /*  Create and call updatePouPcelarstvo action */
        const id = values.id
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)), true);
        let newValuesControl = cleanJsonControl(JSON.parse(JSON.stringify(values)));
        this.props.updatePouPcelarstvoRequest(newValues, id)
        this.props.updatePouPcelarstvoControlRequest(newValuesControl, id)
        this.showNotification("Успешно зачувано!");
    }

    redirectToPouPcelarstvoList() {
        this.props.dispatch(reset('PouPcelarstvo'));
        this.props.history.push(`/PouPcelarstvoList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    uploadFilePcelarstvo(file_upload_id) {
        const myInput = document.getElementById(file_upload_id);
        return uploadFileRequest(myInput).then(res => {
            this.showNotification("Успешно прикачен фајл!");
            return res
        })
    }

    render() {
        const {notify, message} = this.state;
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardContent className={classes.noPrint}>
                        <h1>Измени ПОУ пчеларство</h1>
                    </CardContent>
                    {this.props.id && this.props.pouPcelarstvo &&
                        this.props.pouPcelarstvoControl &&
                        this.props.pouPcelarstvoControl.controlId === this.props.pouPcelarstvo.id &&
                    <PouPcelarstvo
                        pouPcelarstvo={this.props.pouPcelarstvo.pouPcelarstvo}
                        pouPcelarstvoControl={this.props.pouPcelarstvoControl.pouPcelarstvoControl}
                        pouPcelarstvoId={this.props.pouPcelarstvo.pouPcelarstvo.id}
                        uploadedFileId={this.props.fileSystem ? this.props.fileSystem.fileSystem : null}
                        uploadFile={this.uploadFilePcelarstvo}
                        onSubmit={this.updatePouPcelarstvo}
                        onCancel={this.redirectToPouPcelarstvoList}
                        editMode
                        user={this.props.user}
                        lookupData={this.props.lookupData}
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={false}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        pouPcelarstvo: getPouPcelarstvo(state),
        pouPcelarstvoControl: getPouPcelarstvoControl(state),
        fileSystem: getUploadedFile(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPouPcelarstvoById,
        updatePouPcelarstvoRequest,
        fetchPouPcelarstvoControlById,
        updatePouPcelarstvoControlRequest,
        fetchLookups
    }, dispatch)
}


EditPouPcelarstvo.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditPouPcelarstvo.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(EditPouPcelarstvo);
