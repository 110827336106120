import {
    createLookupAction,
    createNewLookupAction,
    deleteLookupAction,
    fetchLookupByIdAction,
    fetchLookupByTypeAction,
    fetchLookupsAction,
    updateLookupAction
} from "./actions";
import {apiURL, authHeader, requestOptions} from "../helpers";
import {newLookupData} from "./newLookupData";


export function fetchLookups() {
    return dispatch => {
        fetch(`${apiURL}/lookup/all`, requestOptions())
            .then(res => res.json())
            .then(res => {

                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchLookupsAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchLookupById(lookupId) {
    return dispatch => {
        fetch(`${apiURL}/lookup/${lookupId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchLookupByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchLookupByType(lookupType) {
    return dispatch => {
        fetch(`${apiURL}/lookup/all/${lookupType}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchLookupByTypeAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewLookup() {
    const newLookup = newLookupData
    return dispatch => {
        dispatch(createNewLookupAction(newLookup))
        return newLookup
    }
}

export function updateLookupRequest(lookup, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...lookup})
    };

    return dispatch => {
        fetch(`${apiURL}/lookup/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updateLookupAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function createLookupRequest(lookup) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...lookup})
    };

    return dispatch => {
        fetch(`${apiURL}/lookup/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(createLookupAction(res)).then(dispatch(fetchLookupByType("")))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deleteLookupRequest(id, type) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/lookup/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deleteLookupAction()).then(dispatch(fetchLookupByType("")))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}