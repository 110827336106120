import {
    CREATE_NEW_SPEC_RASTITELNO,
    CREATE_SPEC_RASTITELNO,
    DELETE_SPEC_RASTITELNO,
    FAILED_CREATE_SPEC_RASTITELNO,
    FETCH_ALL_SPEC_RASTITELNO,
    FETCH_SPEC_RASTITELNO,
    UPDATE_SPEC_RASTITELNO
} from "../actionTypes";

const initialState = {
    specRastitelnoList: [],
    specRastitelno: null,
    error: false
};

export function specRastitelnoReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_SPEC_RASTITELNO: {
            const specNum = action.payload.specRastitelnoList
            return {
                ...state,
                specRastitelno: null,
                specRastitelnoList: specNum,
                specNum: specNum ? specNum.length : 0,
                error: false
            };
        }
        case FETCH_SPEC_RASTITELNO: {
            return {
                ...state,
                specRastitelno: action.payload
            };
        }
        case UPDATE_SPEC_RASTITELNO: {
            return {
                ...state,
                specRastitelno: action.payload
            };
        }
        case CREATE_NEW_SPEC_RASTITELNO: {
            return {
                ...state,
                specRastitelno: action.payload
            };
        }
        case CREATE_SPEC_RASTITELNO: {
            return {
                ...state,
                specRastitelno: action.payload
            };
        }
        case FAILED_CREATE_SPEC_RASTITELNO: {
            return {
                ...state,
                specRastitelno: action.payload,
                error: true
            };
        }
        case DELETE_SPEC_RASTITELNO: {
            return {
                ...state,
                specRastitelno: action.payload
            };
        }
        default:
            return state;
    }
}

export const getSpecRastitelnoList = state => state.specRastitelno.specRastitelnoList;
export const getSpecNum = state => state.specRastitelno.specNum;
export const getSpecRastitelno = state => state.specRastitelno.specRastitelno;