import React from 'react';
import PropTypes from 'prop-types';
import globalStyles from "../styles";
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import {Grid} from "@material-ui/core";
import CustomLabeledInput from "../../components/customLabeledInput";
import * as ALL from "../../common/staticLabels";
import {authorities} from "../../mockData/authorities";
import Button from "@material-ui/core/Button";
import Notification from "../../common/Notification";


export class User extends React.Component {

    constructor(props) {
        super(props);
        this.checkCanSave = this.checkCanSave.bind(this)
        this.requiredFieldsMessage = this.requiredFieldsMessage.bind(this)
        this.showNotification = this.showNotification.bind(this)

        this.state = {
            navDrawerOpen: false, ...this.props.selectedUser,
            authorities: this.normaliseAuthorities(this.props.selectedUser.authorities),
            notify: false,
            message: '',
            error: false
        };
    }

    normaliseAuthorities(authList) {
        const normAuth = [];
        if (authList) {
            authList.forEach(auth => normAuth.push(auth.authority));
        }
        return normAuth;
    }

    checkCanSave() {
        if (this.state.createMode) {
            return (!!this.state.username && !!this.state.fullName && !!this.state.password && !!this.state.rePassword)
        } else {
            return (!!this.state.fullName)
        }
    }

    requiredFieldsMessage() {
        this.showNotification("Полињата се задолжителни!");
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleChange(name, event) {
        event.persist()
        this.handleObj(name, event.target.value)
    };

    handleObj(name, value) {
        let object = JSON.parse(JSON.stringify(value))
        this.setState({[name]: object})

    };

    multiselectHandleChange(name, event) {
        event.persist()
        const val = event.target.value
        const arr = this.state[name]
        const index = this.state[name].indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.push(val)
        }
        this.setState({[name]: arr})
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {classes, createMode, editMode, registerMode} = this.props;
        const {notify, message, error} = this.state;
        const canSave = this.checkCanSave()

        return (
            (this.state.id !== undefined || this.props.createMode || this.props.registerMode) &&
            <div>
                <Grid container className={classes.container}>
                    <CustomLabeledInput labelInput={ALL.USERNAME}
                                        inputType={"text"}
                                        size={12}
                                        multilineInput={true}
                                        disabled={!createMode && !registerMode}
                                        valueInput={this.state.username}
                                        onChangeAction={(event) => this.handleChange('username', event)}
                    />

                    <CustomLabeledInput labelInput={ALL.FULLNAME}
                                        inputType={"text"}
                                        size={12}
                                        multilineInput={true}
                                        valueInput={this.state.fullName}
                                        onChangeAction={(event) => this.handleChange('fullName', event)}
                    />
                    {(createMode || registerMode || editMode) &&
                    <CustomLabeledInput labelInput={ALL.PASSWORD}
                                        inputType={"password"}
                                        size={12}
                                        multilineInput={true}
                                        valueInput={this.state.password}
                                        onChangeAction={(event) => this.handleChange('password', event)}
                    />
                    }
                    {(createMode || registerMode) &&
                    <CustomLabeledInput labelInput={ALL.CONFIRM_PASSWORD}
                                        inputType={"password"}
                                        size={12}
                                        multilineInput={true}
                                        valueInput={this.state.rePassword}
                                        onChangeAction={(event) => this.handleChange('rePassword', event)}
                    />
                    }
                    {(createMode || editMode) &&
                    <Grid container xs={12}>
                        <Grid container xs={12} justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.AUTHORITIES}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput size={12}
                                                fullWidthCheckbox={true}
                                                inputType={"checkbox"}
                                                vertical={true}
                                                valueInput={this.state.authorities}
                                                options={authorities}
                                                onChangeAction={(event) => this.multiselectHandleChange('authorities', event)}/>
                        </Grid>
                    </Grid>
                    }
                </Grid>

                {
                    (createMode || editMode || registerMode) && !canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.requiredFieldsMessage()}>
                        {createMode ? "Креирај корисник" : registerMode ? "Регистрирај се" : "Зачувај корисник"}
                    </Button>
                }
                {
                    (createMode || editMode || registerMode) && canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.props.onSubmit(this.state)}>
                        {createMode ? "Креирај корисник" : registerMode ? "Регистрирај се" : "Зачувај корисник"}
                    </Button>
                }
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}


User.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    }
;

User = reduxForm(
    {
        form: 'User',
        enableReinitialize: true,
        hasRole: true,
    }
)(User)

export default withStyles(globalStyles)(User);