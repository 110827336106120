import {
    createNewPouPcelarstvoAction,
    createPouPcelarstvoAction,
    deletePouPcelarstvoAction,
    duplicatePouPcelarstvoAction,
    fetchPouPcelarstvoByIdAction,
    fetchPouPcelarstvoListAction,
    updatePouPcelarstvoAction,
} from "./actions";
import {apiURL, authHeader, cleanJsonBody, requestOptions} from "../helpers";
import {newPouPcelarstvoData} from "./newPouPcelarstvoData";
import {newPouPcelarstvoControlData} from "../pouPcelarstvoControl/newPouPcelarstvoControlData";
import {createPouPcelarstvoControlRequest} from "../pouPcelarstvoControl/fetch";
import {clearPouPcelarstvoControlAction} from "../pouPcelarstvoControl/actions";

export function fetchPouPcelarstvoList() {
    return dispatch => {
        fetch(`${apiURL}/pouPcelarstvo/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                let i = 0;
                res.map(item => {
                    i = i + 1;
                    item.ime = i + ") " + item.ime_farma + " - " + item.sopstvenik + " - " + item.header_datum_popol;
                    item.id = item.id;
                    return item;
                })
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchPouPcelarstvoListAction(res));
                dispatch(clearPouPcelarstvoControlAction());

                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchPouPcelarstvoById(pouPcelarstvoId) {
    return dispatch => {
        fetch(`${apiURL}/pouPcelarstvo/${pouPcelarstvoId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again

                dispatch(fetchPouPcelarstvoByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewPouPcelarstvo() {
    const newPouPcelarstvo = newPouPcelarstvoData
    return dispatch => {
        dispatch(createNewPouPcelarstvoAction(newPouPcelarstvo))
        return newPouPcelarstvo
    }
}

export function updatePouPcelarstvoRequest(pouPcelarstvo, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...pouPcelarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/pouPcelarstvo/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updatePouPcelarstvoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function duplicatePouPcelarstvoRequest(pouPcelarstvoId) {

    return dispatch => {
        fetch(`${apiURL}/pouPcelarstvo/${pouPcelarstvoId}`, requestOptions())
            .then(res => res.json()).then(res => {
            let newValues = cleanJsonBody(JSON.parse(JSON.stringify(res)), true);
            const postRequestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...newValues})

            };
            fetch(`${apiURL}/pouPcelarstvo/`, postRequestOptions)
                .then(res => res.json())
                .then(res => {
                    if (!res.id) {
                        throw(res.error);
                    } else {
                        fetch(`${apiURL}/kontrolorPcelarstvo/${pouPcelarstvoId}`, requestOptions())
                            .then(res => res.json())
                            .then(conRes => {
                                if (conRes.error) {
                                    throw(conRes.error);
                                } else {
                                    const pouPcelarstvoControl = conRes;
                                    pouPcelarstvoControl.controlId = res.id;
                                    const postRequestOptions = {
                                        method: 'POST',
                                        headers: authHeader(),
                                        body: JSON.stringify({...pouPcelarstvoControl})
                                    };
                                    fetch(`${apiURL}/kontrolorPcelarstvo/`, postRequestOptions)
                                        .then(res => res.json())
                                        .then(res => {
                                            if (res.error) {
                                                throw(res.error);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }
                            })
                    }
                    dispatch(duplicatePouPcelarstvoAction(res));
                    return res;
                })
                .catch(error => {
                    console.log(error)
                })
        })

    }
}

export function createPouPcelarstvoRequest(pouPcelarstvo) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...pouPcelarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/pouPcelarstvo/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    throw(res.error);
                } else {
                    const pouPcelarstvoControl = newPouPcelarstvoControlData;
                    pouPcelarstvoControl.controlId = res.id;
                    dispatch(createPouPcelarstvoControlRequest(pouPcelarstvoControl));
                }
                dispatch(createPouPcelarstvoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deletePouPcelarstvoRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/pouPcelarstvo/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deletePouPcelarstvoAction()).then(dispatch(fetchPouPcelarstvoList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}