import React from 'react';
import PropTypes from 'prop-types';
import {Fab, TableHead} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import globalStyles from "../styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import ConfirmDelete from "../../common/ConfirmDelete";
import Notification from "../../common/Notification";
import format from 'string-format';
import PageBase from '../PageBase';
import {getSelectedUser, getUserList, getUserNum} from "../../redux/user/reducer";
import {bindActionCreators} from "redux";
import {deleteUserRequest, fetchUserList} from "../../redux/user/fetch";

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18
    },
    body: {
        fontSize: 16,
    },

}))(TableCell);

const CustomTableSmallCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18,
        width: 20
    },
    body: {
        fontSize: 16,
        width: 20
    },

}))(TableCell);

const styles = theme => ({
    row: {
        '&:nth-of-Status(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        margin: theme.spacing.unit,
    }
});

export class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteRecord: false,
            id: '',
            pgNo: 1,
            pageSize: 10,
            notify: false,
            message: '',
            error: false
        }
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteUserAfterConfirmation = this.deleteUserAfterConfirmation.bind(this);
    }

    componentWillMount() {
        const {fetchUserList} = this.props;
        fetchUserList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.userNum !== nextProps.userNum) {
            this.setState({userList: nextProps.userList})
        }
    }


    addUser() {
        this.props.history.push(`/CreateUser`)
    }

    editUser(id) {
        this.props.history.push(`/User/` + id);
    }

    deleteUser(id) {
        this.setState({deleteRecord: true, id: id});
    }

    deleteUserAfterConfirmation(deleteConfirmed) {
        this.setState({deleteRecord: false});
        if (deleteConfirmed) {
            const {deleteUserRequest} = this.props
            deleteUserRequest(this.state.id)
            this.showNotification("Успешно избришано!");
        }
        this.setState({id: ''});
    }

    redirectToUserList() {
        window.location.reload()
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {classes, userList} = this.props;
        const {notify, message, error} = this.state;

        return (
            <PageBase title="Листа корисници">
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add" className={classes.button}
                    onClick={this.addUser}
                >
                    <AddIcon/>
                    Додади корисник
                </Fab>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Име и презиме</CustomTableCell>
                            <CustomTableSmallCell>Измени</CustomTableSmallCell>
                            <CustomTableSmallCell>Избриши</CustomTableSmallCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList && userList.map(User => {
                            return (
                                <TableRow key={User.id} className={classes.row}>
                                    <CustomTableCell>{User.fullName}</CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="edit"
                                                onClick={() => this.editUser(User.id)}>
                                            <EditIcon/>
                                        </Button>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="delete"
                                                onClick={() => this.deleteUser(User.id)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <ConfirmDelete resourceHeader="Избриши корисник?"
                               resourceOpis={format("Дали сте сигурни дека сакате да го ибришете корисникот'{}'?", this.state.id)}
                               onModalClose={this.deleteUserAfterConfirmation}
                               openDeleteDialog={this.state.deleteRecord}/>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </PageBase>
        )
    }
}

UserList.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    userList: getUserList(state),
    selectedUser: getSelectedUser(state),
    userNum: getUserNum(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchUserList, deleteUserRequest}, dispatch)
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(UserList)
)