import {combineReducers} from 'redux';
import {reducer as reducerForm} from 'redux-form';
import {applicationReducer} from "./application/reducer";
import {userReducer} from "./user/reducer";
import {pouRastitelnoReducer} from "./pouRastitelno/reducer";
import {pouStocarstvoReducer} from "./pouStocarstvo/reducer";
import {pouStocarstvoControlReducer} from "./pouStocarstvoControl/reducer";
import {pouRastitelnoControlReducer} from "./pouRastitelnoControl/reducer";
import {pouPcelarstvoReducer} from "./pouPcelarstvo/reducer";
import {pouPcelarstvoControlReducer} from "./pouPcelarstvoControl/reducer";
import {fileSystemReducer} from "./fileSystem/reducer";
import {lookupReducer} from "./lookup/reducer";
import {specRastitelnoReducer} from "./specRastitelno/reducer";
import {specStocarstvoReducer} from "./specStocarstvo/reducer";
import {specPcelarstvoReducer} from "./specPcelarstvo/reducer";

const rootReducer = combineReducers({
    form: reducerForm,
    login: userReducer,
    lookup: lookupReducer,
    application: applicationReducer,
    pouRastitelno: pouRastitelnoReducer,
    pouStocarstvo: pouStocarstvoReducer,
    pouPcelarstvo: pouPcelarstvoReducer,
    pouStocarstvoControl: pouStocarstvoControlReducer,
    pouRastitelnoControl: pouRastitelnoControlReducer,
    pouPcelarstvoControl: pouPcelarstvoControlReducer,
    specRastitelno: specRastitelnoReducer,
    specStocarstvo: specStocarstvoReducer,
    specPcelarstvo: specPcelarstvoReducer,
    fileSystem: fileSystemReducer
})
export default rootReducer;