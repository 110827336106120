import {
    CREATE_NEW_POU_RASTITELNO,
    CREATE_POU_RASTITELNO,
    DELETE_POU_RASTITELNO,
    FETCH_ALL_POU_RASTITELNO,
    FETCH_POU_RASTITELNO,
    UPDATE_POU_RASTITELNO
} from "../actionTypes";

const initialState = {
    pouRastitelnoList: [],
    pouRastitelno: null
};

export function pouRastitelnoReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_POU_RASTITELNO: {
            const pouNum = action.payload.pouRastitelnoList
            return {
                ...state,
                pouRastitelno: null,
                pouRastitelnoList: pouNum,
                pouNum: pouNum ? pouNum.length : 0
            };
        }
        case FETCH_POU_RASTITELNO: {
            return {
                ...state,
                pouRastitelno: action.payload
            };
        }
        case UPDATE_POU_RASTITELNO: {
            return {
                ...state,
                pouRastitelno: action.payload
            };
        }
        case CREATE_NEW_POU_RASTITELNO: {
            return {
                ...state,
                pouRastitelno: action.payload
            };
        }
        case CREATE_POU_RASTITELNO: {
            return {
                ...state,
                pouRastitelno: action.payload
            };
        }
        case DELETE_POU_RASTITELNO: {
            return {
                ...state,
                pouRastitelno: action.payload
            };
        }
        default:
            return state;
    }
}

export const getPouRastitelnoList = state => state.pouRastitelno.pouRastitelnoList;
export const getPouNum = state => state.pouRastitelno.pouNum;
export const getPouRastitelno = state => state.pouRastitelno.pouRastitelno;