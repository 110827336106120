export const TITLE = "Апликација за органска сертификација согласно законот за органско производство на Северна Македонија"

export const SPECIFICATION_TITLE_MK = "Спецификација на сертификат бр. "

export const SPECIFICATION_TITLE_ENG = "Specification for certificate № "

export const SPECIFICATION_DESC_MK = "за еднаквост со одредбите на Законот за органско земјоделско производство на Р.Македонија врз база на извршената контрола од страна на ПРО-ЦЕРТ следните површини и производи се сертифицирани"

export const SPECIFICATION_DESC_ENG = "of equivalence with the provisions of the Macedonian law on organic production based on the conducted inspection by PRO CERT the following products and areas have been certified"

export const SPECIFICATION_FOOTER = "Оваа Спецификација е валидна само во комбинација со назначениот сертикат / This Annex is valid only in combination with the certificate indicated above"

export const SPECIFICATION_FOOTER_1 = "Количините се наведени според достапните податоците за време на извршената контрола и истите можат да варираат / Stated quantities are stated according to available data during the control and are subject to changeability"

export const SPECIFICATION_FOOTER_2 = "Операторите се должни сите значајни промени во количините да ги пријават кај сертификаторот / Organic operators have to report to the certifier any significant changes in quantities"

export const IME_KOMPANIJA = "Име и законска форма на компанијата";

export const KONTAKT_LICE = "Контакт лице за сертификација";

export const TELEFON = "Телефон/мобилен/фиксен";

export const MESTO = "Место";

export const OPSTINA = "Општина";

export const ULICABROJ = "Улица и број";

export const PRAVEN_STATUS = "Правен статус";

export const EMAIL = "Е-маил";

export const RASTITELNO_PROIZVODSTVO = "Растително производство";

export const LOKACIJA_FARMA = "Локација на фармата";

export const VKUPNO_HEKTARI = "Вкупно хектари";

export const GLAVNA_KULTURA = "Главна култура/вид";

export const KULTURA_ROTACIJA = "Култура во ротација";

export const STOCARSKO_PROIZVODSTVO = "Сточарско производство";

export const VID_ZIVOTNI = "Вид животни";

export const BROJ_ZIVOTNI = "Број на животни";

export const PRERABOTKA = "Преработка";

export const VID_PRERABOTKA = "Вид на преработка";

export const LOKACIJA_PRERABOTUVACKI_KAPACITET = "Локација на преработувачкиот капацитет";

export const BROJ_PRERABOTENI_PROIZVODI = "Број на преработени производи";

export const SUROVINA = "Суровина";

export const FINALEN_PROIZVOD = "Финален производ";

export const SAMONIKNATI_VIDOVI_TITLE = "Собирање самоникнати видови";

export const REGION_SOBIRANJE = "Регион на собирање";

export const POVRSINA_SOBIRANJE = "Површина на собирање";

export const BROJ_SOBIRACI = "Број на собирачи";

export const BROJ_OTKUPNI_PUNKTOVI = "Број на откупни пунктови";

export const SAMONIKNATI_VIDOVI = "Самоникнати видови кои се собираат";

export const KOLICINI_SERTIFICIRANJE = "Количини кои се сертифицираат";

export const PCELARSTVO = "Пчеларство";

export const LOKACIJA_PCELARNIK = "Локација на пчеларник";

export const BROJ_PCELNI_SEMEJSTVA = "Број на пчелни семејства";

export const MIGRATORNI_LOKACII = "Миграторни локации";

export const TRGOVIJA = "Трговија";

export const VID_RASTENIE = "Вид растение / type of plant";

export const KOLICINA_SVEZ_PROIZVOD = "Количина свеж производ / quantities (t)";

export const DEL_KOJ_SE_SOBIRA = "Дел кој се собира / Collected part";

export const SPECIFIKACIJA_STATUS = "Статус / Status";

export const ADD = "Додади";

export const REMOVE = "Избриши";

export const HEKTARI = "Хектари";

export const PARCELA = "Парцела / Field"

export const POVRSHINA = "Површина / Area (HA)"

export const ORGANSKO = "Органско / Organic"

export const VO_PREOD = "Во преод / In conversion"

export const IZBRISHI = "X"

export const KULTURA = "Култура / Crop"

export const PROIZVODSTVO = "Производство / Produce"

export const RASTITELNO_PROIZVODNI_KAPACITETI = "Производни капацитети растително / Production capacity crops"

export const STOCARSTVO_PROIZVODNI_KAPACITETI = "Производни капацитети сточарство / Production capacity animal husbandry"

export const PCELARSTVO_PROIZVODNI_KAPACITETI = "Производни капацитети пчеларство / Production capacity beekeeping"

export const VID_OPERACIJA = "Вид операција која се сертифицира(може да се одбере повеќе од една опција):"

export const VID_OPERACIJA_DRUGO = "или друго:"

export const STANDARD_SERTIFICIRA = "Стандард по кој се сертифицира"

export const ZAKON_ZEMJODELSTVO_RM = "Законот за органско земјоделско производство на РМ"

export const ISTORIJAT_SERTIFIKACIJA = "Историјат за сертификација"

export const TIP_KOPIJA = "Дали имате копија од стандардот(и) според кој барате сертификација?"

export const PREGOVARACI_VKLUCENI = "Дали во некој од процесите кои се предмет на сертификација се вклучени подоговарачи?"

export const PREGOVARACI_VKLUCENI_DESC = "Во кој процес се вклучени подоговарачи?"

export const ISTORIJAT_SERTIFIKACIJA_TITLE = "Која од следнава ваша изјава е точна?"

export const IZJAVA_1 = "Прв пат аплицираме за сертификација според наведениот стандард во точка 4"

export const IZJAVA_2_DESC = "Сертифициран сум од страна на "
export const IZJAVA_2_CHECK = "но размислувам да го променам телото"

export const IZJAVA_3_DESC = "Мојот сертификат издаден од страна на "
export const IZJAVA_3_CHECK = "истече"

export const IZJAVA_4_DESC = "Мојот сертификат издаден од страна на "
export const IZJAVA_4_CHECK = "беше суспендиран или повлечен"

export const IZJAVA_5 = "Со оваа апликација потврдувам дека сум доволно информиран за правилата за контрола и сертификација на органско производство и располагам со капацитети истите да ги применувам во случај на склучување на договор за сертификација."

export const KONTROLA1 = "Барањето за сертификација е јасно дефинирано "
export const KONTROLA2 = "Дали постојат нечаснотии во апликацијата кои треба да се разјаснат?"
export const KONTROLA3 = "ПРО-ЦЕРТ е во можност да изврши сертификација и апликацијата е прифатена:"
export const KONTROLA4 = "ПРО-ЦЕРТ не е во можност да ја изврши побараната контрола и сертификација и Апликацијата се одбива. Клиентот е известен."
export const KONTROLA5 = "Причина за одбивање на апликацијата"


export const DATA = "Дата"
export const PECAT_POTPIS = "Печат и потпис:"
export const PECAT_ILI_POTPIS = "Потпис /и /или печат:"


export const POU_PROCERT = "ПРО-ЦЕРТ"
export const POU_FORM = "4.3.4 форм"
export const POU_HEADER_SUBTITLE = "План за органско управување за растително производство"
export const POU_HEADER_STOC_SUBTITLE = "План за органско управување за сточарско производство"
export const POU_HEADER_BROJ_DOKUMENT = "Бр. на документ: 3.1.2"
export const POU_HEADER_VERSION = "в.2 : 10.05.2023"
export const POU_HEADER_PREV_VERSION = "в.1 : 04.03.2022"
export const POU_HEADER_KOMPANIJA = "Компанија"
export const POU_HEADER_ODGOVOREN_KOMPANIJA = "Одговорен на компанијата"
export const POU_HEADER_KONTROLOR = "Контролор"
export const POU_HEADER_DATUM_POPOL = "Датум на пополнување"
export const POU_HEADER_DATUM_KONTROLA = "Датум на контрола"
export const POU = "План за органско управување (ПОУ)"
export const POU_DESC = "Планот за Органско управување е основен документ за сертификација. Во овој план операторот опишува како го организира производството и обезбедува исполнување на барањата на ЗОЗП. Операторот ги осознава соодветностите со стандардот и дефинира корективни активности. Од оваа причина бараме целосно да го пополните формуларот. Контролорот на ПРО-ЦЕРТ ќе ја оцени имплементацијата на она што го опишувате. За детали околу стандардот ве молиме проверете во односниот стандард за кого сакате да се сертифицирате. За ИНФОРМАЦИИ ОКОЛУ толкувањата на стандардот од страна на ПРО-ЦЕРТ ве молиме погледнете ги нашите политики."
export const POU_ADRESA = "Адреса на фармата"
export const POU_IME_NA_FARMATA = "Име на фармата"
export const POU_SOPSTVENIK = "Сопственик"
export const POU_ULICA_BROJ_FAX = "Улица, број, поштенски фах"
export const POU_GRAD_KOD = "Град, поштенски код"
export const POU_ODGOVORNO_LICE = "Одговорно лице (ако е различно)"
export const POU_ZEMJA = "Земја"
export const POU_TELEFON = "Телефон"
export const POU_FAKS = "Факс"
export const POU_MOBILEN_TELEFON = "Мобилен телефон"
export const POU_PARTNERI = "Ваши партнери во органското управување"
export const POU_PRERABOTUVAC_R = "Преработувач: Дали вашите производи после бербата се преработуваат( пакуваат, чистат, смрзнуваат, сушат итн) од страна на независна единица?"
export const POU_PRERABOTUVAC_S = "Преработувач: дали вашите производи ce преработуваат (кланица, пакување, смрзнување итн.) од независна единица?"
export const POU_PRERABOTUVAC_DA = "Ако е \"Да\" наведете го името, целосната адреса, телефон, факс, е-маил на вашиот преработувач или наведете ако сè уште не знаете, кој ќе ги преработи вашите производи"
export const POU_IZVOZNIK = "Извозник: Ве молиме наведете ја целосната адреса на вашиот извозник или наведете ако сеуште не знаете кој ќе биде ваш извозник или дека не планирате воопшто да ги извезувате вашите производи"
export const POU_VKLUCENI_PODOGOVARACI = "Дали се вклучени подоговарачи во вашите активности? (на пр.Механизација и сл.)?"
export const POU_IMATEL = "Имател: сертификатот треба да гласи на име на:"
export const POU_OPSEG_SERTIFIKACIJA = "Опсег на сертификација"
export const POU_POBARANA_OVAA_GOD = "Побарана оваа година"
export const POU_POBARANA_PRV_PAT = "Побарана прв пат"
export const POU_SERT_GODINA = "Не е прв пат, сертифицирано од ...(година)"
export const POU_PRETH_SERT = "Предходно сертифицирана од страна на ..."
export const POU_ZAKON_ORGAN_PROIZ = "Закон за органско производство на РМ"
export const POU_KOPIJA = "Дали имате копија од стандардот според кој барате сертификација?"
export const POU_KOPIJA_STO = "Дали сте запознаени со правилата за органско производство според кои барате сертификација?"
export const POU_RAZMER_PROSTUDIRANJE = "Oбјаснете, до кој степен ги разбирате правилата за органско производство."
export const POU_NE_RAZBRANO = "Во случај да немате доволно познавања, што ќе превземете?"
export const POU_OSPORENA_ORGANSKA_SERTIFIKACIJA = "Дали некогаш органската сертификација ви била оспорена била суспендирана или одземена?"
export const POU_OSPORENA_ORGANSKA_SERTIFIKACIJA_DA = "Ако \"Да\" ве молиме опишете кога, од кого, зошто и штo од тогаш се променило."
export const POU_POVRSINI_KULTURI_ZA_SERTIFIKACIJA = "За кои површини и култури барате сертификација оваа година?"
export const POU_MIN_KOR = "Минатогодишни корективни активности "
export const POU_MIN_KOR_AKT = "Наведете ги корективните активности мината година и објаснете како се имплементирани."
export const POU_KOREKT_AKT = "Корективни активности"
export const POU_IMPLEMENTACIJA = "Имплементација"
export const POU_ANIMALNI_SERT = "За што барате сертификација оваа година?"
export const POU_PROIZVOD_ZIVOTNI = "Производ/животни"
export const POU_BR_ZIVOTNI_ORG = "Број на животни - органско"
export const POU_BR_ZIVOTNI_VOPREOD = "Број на животни - во преод"
export const POU_KG_PROIZVOD = "Килограми производ - органско"
export const POU_KG_PROIZVOD_PREOD = "Килограми производ - во преод"
export const POU_BR_ZIVOTNI_ORG_VTORA = "Број на животни - органско (во преод втора година)"
export const POU_KG_PROIZVOD_VTORA = "Килограми производ - органско(во преод втора година)"
export const POU_BR_ZIVOTNI_ORG_PRVA = "Број на животни - органско"
export const POU_KG_PROIZVOD_PRVA = "Килограми производ - органско"
export const POU_UPRAVUVANJE_OKOLINA = "Управување со околината"
export const POU_PODATOCI_ZA_OPKRUZUVANJETO = "Приближни податоци за опкружувањето на Вашата фарма"
export const POU_NADMORSKA_VISINA = "Надморска висина (m)"
export const POU_GODISNI_VRNEZI = "Годишни врнежи (mm)"
export const POU_POCVI = "Почви"
export const POU_MM = "mm"
export const POU_OPIS_ORG_KONV = "Општ опис на органскиот и конвенционален систем на сточарско производство во вашето опкружување"
export const POU_ORG_HEKTARI = "Хектари со органски статус"
export const POU_ORG = "Oрганско"
export const POU_BR_ZIVOTNI_ORG_STATUS = "Број на животни со органски статус"
export const POU_KONV = "Ковенционално"
export const POU_OPST_KARAK = "Општи карактеристики"
export const POU_ISHRANA = "Исхрана"
export const POU_SMEST = "Сместување"
export const POU_STOC_PRAK = "Сточарски практики"
export const POU_VET_TRET = "Ветеринарни третмани"
export const POU_PRINOS = "Принос (кг месо/грло)"
export const POU_EROZJA = "Дали почвената ерозија е проблем на вашата фарма?"
export const POU_ZAGADUVANJE = "Дали загадувањето на водата преку несоодветно ѓубриво или складирање на шталско ѓубре или преку прекумерна апликација на ѓубриво, е (потенцијален) проблем на вашата фарма?"
export const POU_SPALUVANJE_OSTATOCI = "Дали спалувате растителни остатоци или други органски материјали на вашата фарма?"
export const POU_SUMSKI_POVRSINI = "Дали вашата фарма содржи шумски површини, жбунови, мочуришта или други еколошки вредни површини, кои не се користат за култивирање или испаша?"
export const POU_OPIS = "Опис"
export const POU_NEORGANSKI_SMET = "Дали неорганскиот смет се собира и одложува на точно дефинирано место, каде што нема опасност од влијание врз водата, културите, луѓето или животните?"
export const POU_OPIS_FARMA = "Опис на фарма"
export const POU_POVRSINI_KULTURI = "Површини и култури (ве молиме наведете ги сите површини, вклучувајќи и ливади, угар, напуштени парцели и конвенционални култури!)"
export const POU_PARCELA_BROJ_NAZIV = "Парцела број или назив"
export const POU_POVRSHINA = "Површина (ха)"
export const POU_KONVENC_INPUTI = "Последна употреба на конвенц.инпути (месец/година)"
export const POU_KONVENC = "Конвенционално?*"
export const POU_POSADENI_KULTURI = "Посадени култури"
export const POU_GJUBRIVO = "Употреба на ѓубриво"
export const POU_PESTICIDI = "Последна употреба на конвенционални инпути"
export const POU_KONVEN_PROIZVODSTVO = "Дали е тоа конвенционално производство?"
export const POU_3_GOD = "Пред 3 год."
export const POU_2_GOD = "Пред 2 год."
export const POU_1_GOD = "Пред 1 год."
export const POU_OVAA_GODINA = "Оваа год."
export const POU_MAPA = "Ве молиме приложете мапа/или нацртајте ги сите ваши парцели. Во Анекс Бр."
export const POU_IMA_STOCARSTVO = "Дали имате сточарство на вашата фарма?"
export const POU_VID = "Вид"
export const POU_BROJ = "Број"
export const POU_PREOD = "Преод"
export const POU_BR_ZIVOTNI_PREOD = "Број на животни во преоден период"
export const POU_PERIOD_PREOD = "Период на преод за нови парцели: Ве молиме означете овде, ако не е релевантно, бидејќи сите парцели го поминаа периодот на преод."
export const POU_PERIOD_PREOD_NE = "Ако не, ве молиме пополнете во следново:"
export const POU_PRIZNAVANJE_UPRAVUVANJE = "Дали барате признавање на управувањето со земјата пред да потпишете договор со ПРО-ЦЕРТ?"
export const POU_PRIZNAVANJE_UPRAVUVANJE_DA = "Ако \"Да\", каков доказ имате за органското управување во минатото?"
export const POU_PARALELNO_MESANO_PROIZ_R = "Паралелно и мешано производство, одделување на култури со различен статус"
export const POU_PARALELNO_MESANO_PROIZ = "Дали имате паралелно производство во ваква смисла за било која култура?"
export const POU_PARALELNO_MESANO_PROIZ_DA = "Да, за следниве култури и површни:"
export const POU_SEMINJA_SADEN_M = "Семиња и саден материјал"
export const POU_KULTURA = "Култура"
export const POU_STATUS = "Статус"
export const POU_SEMINJA = "Семиња/саден материјал"
export const POU_SOPST_ORG_SEM = "Сопствени органски"
export const POU_KUP_ORG_SEM = "Купени органски"
export const POU_KONVENC_SEM_TRET = "Конвенционални нетретирани"
export const POU_KONVENC_SEM_NETRET = "Конвенционални третирани"
export const POU_PLODORED = "Плодоред"
export const POU_PLODORED_OPIS = "За едногодишните култури, органското земјоделие побарува примена на плодоред, за да се зачува почвената плодност и превентива на културите од болести и штетници."
export const POU_PLODORED_NE_PRIMENLIVO = "Не применливо, имаме само повеќегодишни култури"
export const POU_PLODORED_OPISANO = "Плодоред како што е опишано во 9.1, нема што повеќе да се објаснува за тоа"
export const POU_LEGUMENOZI_POKRIVNI_K = "Легуминози и покривни култури"
export const POU_LEGUMENOZI_OPIS = "Ве молиме наведете ги легуминозните видови кои се употребуваат на вашата фарма и процентот од вкупната површина или од плодоредот покриен со легуминози."
export const POU_GJUBRENJE = "Ѓубрење"
export const POU_GJUBRENJE_OPIS = "Ве молиме опишете го вашиот систем на ѓубрење(вид ѓубриво, количини)и како сте сигурни дека се користат само дозволени ѓубрива."
export const POU_POTEKLO = "Потекло"
export const POU_SOPSTVENO = "Сопствено"
export const POU_KUPENO = "Купено"
export const POU_KORISTENA_KOLICINA = "Количина (т, кг, л) користени на хектар/годишно"
export const POU_KONTROLA_BOLESTI_STETNICI = "Контрола на болести и штетници"
export const POU_POU_KONTROLA_BOLESTI_STETNICI_OPIS = "Пред употреба на било каква препарати за контрола на болест или штетник, органските фармери треба да превенираат болести и штетници,преку адекватен плодоред, подржува природни непријатели и да користи отпорни видови и вариетети. Како ова го имплементирате на вашата фарма? Ве молиме наведете некои конкретни примери."
export const POU_IMPLEMENTACIJA_PREVENTIVNI_MERKI = "По имплементација на погоре споменатите превентивни мерки, ако е неопходно можат да се користат производи наведени во Листа на производи за заштита на растенија пропишани со ЗОЗП на РМ."
export const POU_STETNIK_BOLEST = "Штетник или болест"
export const POU_UPOTREBEN_PROIZVOD = "Употребен производ"
export const POU_DOZA = "Доза (на пр. Литри/ха)"
export const POU_AKTIVNA_MATERIJA = "Активна материја"
export const POU_NEMA_UPOT_PREP = "НЕМА УПОТРЕБЕНИ ПРЕПАРАТИ"
export const POU_PESTICIDI_SOSEDNI_FARMI = "Нанесување пестициди од соседни фарми"
export const POU_GRANICI_NANESUVANJE_PESTICIDI = "Ве молиме наведете ги сите граници (маргини) на вашето поле каде што има потенцијален ризик од нанесување пестициди од конвенционални полиња."
export const POU_POVRSINA = "Површина"
export const POU_GRANICI = "Граници (Север, Запад...)"
export const POU_SOSEDNA_KONVEC_K = "Соседна конвенционална култура"
export const POU_INTENZITET_PRSKANJE = "Интензитет на прскање"
export const POU_OPREMA_PRSKANJE = "Опрема за прскање"
export const POU_RASTOJANIE = "Растојание (метри)"
export const POU_VEGETACIJA_G = "Вегетација на границите "
export const POU_RIZIK = "Ризик од нанесување"
export const POU_REZIDUI_PEST = "Дали имате испитување за резидуи од пестициди?"
export const POU_REZIDUI_PEST_DA = "Ако Да, ве молиме опишете ја фреквенцијата и процедурата на тестирањето."
export const POU_VODA_ZAGADUVANJE = "Водата за наводнување како извор на загадување"
export const POU_VODA_ZAGADUVANJE_IZVOR = "Дали водата за наводнување е извор на микробиолошко или хемиско загадување?"
export const POU_VODA_KVALITET = "Во спротивно опишете го потеклото и квалитетот на водата за наводнување."
export const POU_BERBA_PRINOSI = "Берба и приноси"
export const POU_PRINOS_MINATA_GODINA = "Принос од минатата година"
export const POU_OCEKUVAN_PRINOS = "Очекуван принос од оваа година"
export const POU_PERIOD_BERBA = "Период на берба (месец)"
export const POU_VKUPNO_BRUTO = "Вкупно бруто"
export const POU_BRUTO_HA = "Бруто за ха"
export const POU_SUMA_PROIZ_SERT = "Приноси од производи. Ве молиме наведете ги количините од една година за сите производи пријавени за сертификација.Бараме од вас да ги наведете овие бројки иако не сте имале досега продажба како органско, само за да биде познато дали вашиот систем за водење евиденција обезбедува точен увид во текот на производите."
export const POU_ZALIHA_MIN_GOD = "На залиха од минатата година"
export const POU_VKUPNO_NETO = "Вкупен нето принос"
export const POU_PRODADENO_ORG = "Продадено како органско"
export const POU_PODADENO_ORG_PREOD = "Продадено како во органско во преод"
export const POU_PRODADENO_KONVENV = "Продадено како конвенционално"
export const POU_MOM_ZALIHA = "Моментално на залиха"
export const POU_BERBENO_RAKUVANJE = "Пост бербено ракување"
export const POU_BERB_MANIPULIRANJA = "Не релевантно: Нема пост бербени манипулирања или складирање"
export const POU_POSEBEN_IZVESTAJ = "Не релевантно: Погледај посебен извештај за преработка"
export const POU_BERB_RAK_POINAKU = "Ако е поинаку, ве молиме опишете ги во детали сите постапки низ кои се подложуваат вашите производи по бербата."
export const POU_POSTAPKA = "Постапка"
export const POU_IZVEDUVA = "Каде се изведува?"
export const POU_ODGOVOREN = "Кој е одговорен?"
export const POU_ODDELUVANJE_NESERT = "Како е обезбедено оделување од несертифицирани производи?"
export const POU_VKLUCENI_PODDOGOVARACI = "Дали поддоговарачи се вклучени во некој дел од транспорт, пост берени манипулации или складирање?"
export const POU_DOZVOLENI_NAVEDENI_SUP = "Само супстанции наведени во Листа на средства за чистење и дезинфекција според ЗОЗП на РМ, се дозволени за заштита на складираните производи од складишни штетници.Ве молиме објаснете што правите за да спречите или контролирате штетници за време на постбербеното ракување и складирање"
export const POU_NEMA_SKLAD_PRERAB = "Не применливо, нема складирање или преработување."
export const POU_ETIKETIRANJE = "Етикетирање"
export const POU_PRIMEROK_ETIKETA = "Етикетите мора да содржат информации за видот на производ, количина, производител, органска состојба, име на сертификациско тело и број на шаржа. Дали имате изготвено етикета за вашиот производ/производи?"
export const POU_PRIMEROK_ETIKETA_DA = "Ве молиме приложете примерок од вашата етикета која планирате да ја употребите или веке ја употребувате!"
export const POU_PRIMEROK_ETIKETA_1 = "Ве молиме приложете примерок од етикетите кои се применуваат за означување на производите"
export const POU_PRIMEROK_ETIKETA_PCELARSTVO = "Етикетите мора да содржат информации за видот на производ, количина, производител, органска состојба (услов), име на сертификациско тело и број на шаржа. Ве молиме приложете примерок од вашата етикета која планирате да ја употребите или веке ја употребувате! ****Поле за прикачување на анекси."
export const POU_PRAVILA_LOGO_ZNAK = "Во случај да го користите или планирате да го користите националното лого за органско производство или логото на сертификациското тело, ве молиме наведете дали сте запознаени со правилата за користење на истите и објаснете како тоа ќе го сторите."
export const POU_ZAPISI_SLEDLIVOST = "Записи и следливост"
export const POU_DOBRI_ZAPISI = "Органските производители мора да чуваат добри записи. Дали чувате и имате ажурирани записи?"
export const POU_DNEV_FARMA = "Дневник на фарма, во кој се опишани основните активности на фармата за секоја парцела (употреба на ѓубрива,садење, заштита од болести штетници, берба)"
export const POU_FAKTURI_NABAVKI = "Фактури за сите набавки на семе, ѓубрива, пестициди"
export const POU_DNEV_PAKUVANJE_PRERABOTKA = "Дневник за пакување и/или преработка"
export const POU_EVIDEN_PRODAZBI = "Водење евиденција за сите продажби на земјоделските производи"
export const POU_DRUGI = "Други"
export const POU_CUVANJE_ZAPISI = "Дали чувате записи од сите добиени жалби и поплаки? "
export const POU_ANEKSI = "Aнекси:"
export const POU_IZJAVA_KLIENT = "Изјава на клиентот"
export const POU_IZJAVA_SOGLASNOST = "Со потпишувањето на овој документ со своерачен потпис клиентот изјавува и дава согласност за следното:"
export const POU_IZJAVA_1 = "1.\tСогласно барањето на член 2 од Правилникот за начинот и постапката за вршење на стручна контрола во огранското земјоделско производство одговорното лице наведено во документот изјавува дека податоците наведени во овој документ и сите доставени прилози се усогласени и одговараат на фактичката состојба во единицата, објектите и активностите кои се предмет на контрола и сертификација. "
export const POU_IZJAVA_2 = "2.\tКонтролните - сертификациски тела во случаите кога е тоа потребно можат слободно да разменуваат информации за единицата, објектите и активностите кои се предмет на контрола и сертификација."
export const POU_IZJAVA_3 = "3.\tКонтролното - сертификациско тело да ги користи поддоговорените лабораториски услуги на сите лаборатории со кои има склучено договор."
export const POU_DOP_NESOODVETNOSTI = "Дополнителни несоодветности идентификувани од контролорот"
export const POU_POP_KONTROLOR = "Да се пополни од контролорот"
export const POU_BR = "Бр."
export const POU_POP_FARMA = "Да се пополни од фармата или компанијата"
export const POU_NESOODVETNOSTI = "Несоодветности"
export const POU_KOREKT_AKT_DEF = "Корективни активности дефинирани од фарма или компанија"
export const POU_ROK = "Рок"
export const POU_KOREKT_AKT_ADEK = " Дали корективните активности се адекватни?"
export const POU_ROK_ADEK = "Дали рокот е адекватен?"
export const POU_SERTIF_POSLE_KOREK = "Сертификат само после корекцијата?"
export const POU_ZAKLUCOK_KONTROLOR = "Заклучоци на контролорот"
export const POU_STIKLIRANJE = "ШТИКЛИРАЊЕ"
export const POU_ZAKLUCOK_KONTROLOR_1 = "Нема несоодветност. Ја препорачувам компанијата за сертификација."
export const POU_ZAKLUCOK_KONTROLOR_2 = "Несоодветности, кои ќе се корегираат по издавање на сертификат a можат да влијаат на промена на статус"
export const POU_ZAKLUCOK_KONTROLOR_3 = "Погоре споменатите несоодветности треба да се корегираат пред издавање на сертификат."
export const POU_ZAKLUCOK_KONTROLOR_4 = "Треба да се изврши нова контрола, за да се потврдат имплементациите на потребните корективни активности. Сертификатот ќе се издаде само после тоа."
export const POU_ZAKLUCOK_KONTROLOR_5 = "Операторот засега не е за сертифицирање."
export const POU_DATA_POTPIS_F_K = "Дата и потпис на одговорен на фарма/компанија"
export const POU_DATA_POTPIS_KONT = "Дата и потпис на контролор"
export const POU_ZEMJISTE = "Земјиште"
export const POU_PREOD_HEKTARI = "Хектари во преод"
export const POU_3_GOD_PREOD = "3-та година во преод "
export const POU_2_GOD_PREOD = "2-ра година во преод "
export const POU_1_GOD_PREOD = "1-ва година во преод "
export const POU_VK_POVRSINA = "Вкупна површина на фармата"
export const POU_POVR_PASISTA = "Површина под пасишта"
export const POU_DR_HRANA = "Друга храна"
export const POU_SKICA_OBJ = "Ве молиме приложете скица на сите објекти на фармата, вклучувајќи штали, амбари, простории за складирање, итн."
export const POU_NAVEDI_ZIVOTNI = "Ве молиме наведете ги сите ваши животни, вклучително и животните кои не планирате да ги конвертирате во органско. Ве молиме користете ја оваа колона и за животни за кои не побарувате сертификација."
export const POU_VIDOVI = "Вид/категорија"
export const POU_KONVE = "*Конвенционално"
export const POU_KONVE_HEKTARI = "Хектари со конвенционален статус"
export const POU_KONVE_BROJ_ZIVOTNI = "Број на конвенционални животни"
export const POU_BR_ZIV = "Број на животни"
export const POU_POTEKLO_PREOD = "Потекло на животни, преод"
export const POU_VO_EDINICA_OD = "Во вашата единица се од... (дата) "
export const POU_PREOD_OD = "Преодот почнува(дата)"
export const POU_PREOD_DO = "Преодот завршува(дата)"
export const POU_DOZ_NABAVKA = "Се дозволува набавка на животни од конвенционални единици само во исклучителни слуции."
export const POU_DOZ_NABAVKA2 = "Како осигурувате дека ова ќе го применувате во пракса?"
export const POU_PARALELNO_ODG = "Не се дозволува паралелно производство-одгледување (конвенционално и органски) на животни од ист вид на иста фарма. Животни од друг вид можат да бидат одгледувани на конвенционален начин, ако имате јасно одвоени капацитети."
export const POU_NADM_NESOODV = "Во случај да увидете несоодветности околу преодот, паралелно производство и одделување, ве молиме опишете како ова ќе го надминете."
export const POU_POINAKVA_SITU = "Поинаква ситуација (опишете)."
export const POU_OBROCI = "Ве молиме опишете ги во детали оброците на хранливи материи за вашите органски животни или животни во преод, според жвотински видови, раса (специфицирајте пол, возраст и цел, правејќи разлика на пр. помеѓу телиња, јунци и крави)"
export const POU_ADAPT_ISH = "Ве молиме опишете го вашиот систем на хранење на фармата."
export const POU_HRAN_MAT = "Хранливи материи во Зимски или сушен период (напишете количини)- ПО РАСА - ГОВЕДА"
export const POU_DOZV_HRAN_MAT = "Се дозволува користење одреден процент хранливи материи во преод, додека конвенционално хранење е дозволено само за свињи и живина и само во многу ограничени количини."
export const POU_KONV_PREOD_HRAN_MAT = "Наведете го потеклото на храната која се користи на фармата. Ставете во прилог документи од набавка на храна (доколку е применливо)."
export const POU_MLADI_CICACI = "Дали и колкав период младите животни цицаат од мајките?"
export const POU_TREVOPASNI = "Дали животните одат на испаша?"
export const POU_KABASTA_H = "Каква и колку кабаста храна користите по животно?"
export const POU_NE_DOZV_H = "Уреа, хормони, антибиотици, не се дозволени во исхраната на органските животни"
export const POU_ADITIVI = "Дали користите адитиви?"
export const POU_ADITIVI_KORISTI = "Ако ДА, ве молиме наведете ги сите адитиви кои ги користите."
export const POU_NESOG_H = "Во случај да откриете несогласувања во хранењето, ве молиме опишете што ќе кореграте."
export const POU_BOLESTI = "Превенција и третман на болести и штетници"
export const POU_PREV_BOLESTI = "Ве молиме опишете го вашиот систем за превенција од болести."
export const POU_BOL_TRETMAN = "Во случај ако се појави болест, предност се дава на природни, хомеопатски третмани или слични методи."
export const POU_UTVRDUVANJE_KARENCA = "Ве молиме опишете го како ја утврдувате каренцата, по користење на лекарства."
export const POU_ANTIB_PREV = "Превентивна употреба на антибиотици, кокцидиостатици или други паразитициди, хормони итн. не се дозволени. Дали користите вакви препарати превентивно?"
export const POU_SANITARNA_PRAKTIKA = "Ве молиме објаснете го вашиот систем за одржување на хигиена во објектите за животните."
export const POU_PREVENTIVA = "Ве молиме објаснете како ги превенирате и третирате паразитите на животните и како осигурувате исполнување на погоре споменатите правила."
export const POU_PODOBR_PREVENTIVA = "Во случај да констатирате несоодветности во однос на превентивата и третманот на болести и штетници кај животните, ве молиме опишете како во иднина ќе ја подобрите."
export const POU_PRAKTIKI = "Сточарски практики и услови на сместување"
export const POU_RAZMNOZH = "Ве молиме опишете го системот на размножување на животните."
export const POU_OPERACII = "Ве молиме опишете дали применувате поткратување на опашки, сечење заби, поткратување на клунови или рогови, или слични практики."
export const POU_KASTRACIJA = "Ве молиме опишете, дали, како и на која возраст се кастрираат животните на фармата."
export const POU_VRZUVANJE_ZIVOTNI = "Ве молиме објаснете дали животните на фармата се врзани, се чуваат во боксови или во кафези."
export const POU_USLOVI_SHTALI = "Шталите треба да пружат доволно простор и надворешни површини за слободно движење според потребите на секој вид и секоја возраст. Ве молиме опишете како сте сигурни дека објектите и површините за слободно движење на животните овозможуваат доволно движење, соодветно свеж воздух, сончева светлина, за да може животните природно да се однесуваат и да се чува нивното здравје."
export const POU_PODOVI_SHTALI = "Ве молиме опишете какви се подовите во објектите за сместување на животните."
export const POU_NESOODVETNOSTI_PODOBRUVANJE_USLOVI = "Во случај да уочите несоодветности во однос на вашите сточарски практики и услови на сместување, ве молиме објаснете како ќе ја подобрите ситуацијата."
export const POU_UPRAVUVANJE_GJUBRE_ISPASHA = "Управување со ѓубрето и прекумерна испаша "
export const POU_UPRAVUVANJE_GJUBRE_ISPASHA_OPIS_1 = "Органските стандарди ги обврзуваат сточарските единици да не ја загадуваат околината, особено со прекумерно чување или несоодветно манипулирање со ѓубривото. "
    + "Законот за органско "
export const POU_UPRAVUVANJE_GJUBRE_ISPASHA_OPIS_2 = "● Правилник за правила и постапки во раст.производтсво: Максимум 170 кгN/ха од ѓубриво, што одговара на пр. на 2 молзни крави или 14 свињи"
export const POU_UPRAVUVANJE_GJUBRE_ISPASHA_OPIS_3 = "Фармите треба да имаат соодветен капацитети за чување ѓубриво и урина од животните, на начин кој недозволува загадување на површинските и подземни водите."
export const POU_KG_ZIVA_MERA = "Колку кг жива мера имате по хектар на вашето стопанство?"
export const POU_KG_AZOT = "Колку кг азот по хектар приближно се содржи ѓубривото и во урината добиена од вашите животни?"
export const POU_ZAGADUVANJE_VODA = "Дали загадувањето на водата преку несоодветно складирање на ѓубрето е (потенцијален) проблем на вашата фарма?"
export const POU_PREKUMERNA_ISPASHA = "Дали постои опасност од прекумерно испасување на достапната паша за вашите животни?"
export const POU_POPRAVANJE_UPRAVUVANJE_GJUBRE = "Дали постои опасност од загадување на водите преку ѓубриво или урината?"
export const POU_PRINOSI = "Приноси"
export const POU_PROIZVOD = "Производ"
export const POU_PRINOSI_MINATA_GODINA = "Приноси од минатата година (назначи за која единица!)"
export const POU_PRINOSI_OVAA_GODINA = "Приноси оваа година година (очекувани - назначи за која единица!)"
export const POU_KOLICINA = "Количина"
export const POU_VKUPNO_BRUTO_PRINOSI = "Вкупно бруто* / "
export const POU_VKUPNO_NETO_PRINOSI = "Вкупно нето** / "
export const POU_BRUTO_PO_ZIVOTNO = "Бруто* по животно / "
export const POU_KOLICINA_PO_ZIVOTNO = "Количина по животно"
export const POU_PRINOS_KOMENTAR = "Во случај ако приносот (очекуван) од оваа година е значително повисок или понизок од минатата година ве молиме коментирајте."
export const POU_POSTBERBENO_RAKUVANJE = "Постбербено ракување"
export const POU_POSTAPUVANJE_PROD_ZIVOTNI = "Постапување со производи од животните"
export const POU_POSTBERBENO_RAKUVANJE_OPIS = "Ве молиме опишете ги во детали сите постапки низ кои поминуваат производите добиени од животните."
export const POU_POSTAPKA_CEKOR = "Постапка/Чекор"
export const POU_PODDOGOVARACI_POSTBERBENO = "Дали поддоговарачи се вклучени во некој дел од транспорт, молзење, друг процес или складирање?"
export const POU_PODDOGOVARACI_POSTBERBENO_PCELARSTVO = "Дали поддоговарачи се вклучени во некој дел од транспорт, екстракција на мед,друг процес или складирање?"
export const POU_PODDOGOVARACI_POSTBERBENO_OPIS = "Ако ДА, ве молиме наведете ги комплетните имиња, адреси и опишете како обезбедувате органски интегритет во единицата на поддоговарачот."
export const POU_IZBEGNUVANJE_ZABRANETI_SUPSTANCI = "Што превземате за да избегнете контакт на органските производи со забранети или други загадувачки супстанции (на пр. Складишни инсектициди, фумигација, заштитни средства за дрвја, несоодветна амбалажа, горива и др.) за време на манипулација на молзење, друг процес со производите, складирања и транспорт?"
export const POU_IZBEGNUVANJE_ZABRANETI_SUPSTANCI_PCELARSTVO = "Дали постои опасност од загадување на производите со загадувачки супстанции за време на ракување, транспорт или складирање?"
export const POU_IZBEGNUVANJE_MESHANJE_PROIZVODI = "Доколку на фармата има производи со различен статус, како превенирате мешање на производите?"
export const POU_IZBEGNUVANJE_STRES = "Што превземате за намалување на стресот на животните за време на транспорт?"
export const POU_KOREKTIVNI_MERKI_OPIS = "Во случај на ризик од загадување или мешање или транспортот на животните не е соодветно организиран, ве молиме опишете ги корективните активности кои ќе ги имплементирате."
export const POU_KOREKTIVNI_MERKI_OPIS_PCELARSTVO = "Во случај на ризик од загадување, ве молиме опишете ги корективните активности кои ќе ги имплементирате."
export const POU_ORGANSKI_PROIZVODI_AMBALAZI = "Освен за првиот транспорт на производи, млеко при молзење или друг процес, до првото складирање или преработка, органските производи треба да се чуваат и транспортираат во затворени и соодветно-обележена амбалажи."
export const POU_ORGANSKI_PROIZVODI_AMBALAZI_PCELARSTVO = "Освен за првиот транспорт на производи, до првото складирање или преработка, органските производи треба да се чуваат и транспортираат во затворени и соодветно-обележена амбалажи."
export const POU_ETIKETIRANJE_PCELARSTVO = "Ве молиме опишете го етикетирањето во различните чекори од постбербените манипулации:"
export const POU_ETIKETA_KOREKCIJA = "Во случај да не ги етикетирате адекватно вашите производи или вашите етикети не ги содржат сите потребни податоци, ве молиме објаснете како ова ќе го корегирате."
export const POU_AZURIRANI_ZAPISI_OPIS = "Органските производители мора да чуваат добри записи. Дали чувате и имате ажурирани записи?"
export const POU_AZURIRANI_ZAPISI = "Дали имате и чувате ажурирани записи?"
export const POU_DNEVNIK_STOCARSTVO = "Дневник на штала, записи за раѓање, набавка, умирање, продажба на животни, ветеринарни третмани итн"
export const POU_DNEVNIK_SHTALA = "Дневник на штала"
export const POU_FAKTURI_STOCARSTVO = "Фактури за сите набавки на храна, ветеринарни медикаменти, животни и др."
export const POU_DNEVNIK_PAKUVANJE = "Дневник на пакување и/или преработка"
export const POU_EVIDENCIJA_PRODAZBA = "Евиденција за продажба на сточарските производи"
export const POU_EVIDENCIJA_PRODAZBA_PCELARSTVO = "Евиденција за продажба"
export const POU_ZAPISI_ZALBI_POPLAKI = "Дали чувате записи од сите добиени жалби и поплаки?"
export const POU_ZAPISI_ZALBI_POPLAKI_NE = "Ако НЕ, ве молиме да отпочнете со евидентирање и чување на записи од жалби и поплаки."
export const POU_ZAPISI_ZALBI_POPLAKI_DA = "Ако ДА, ве молиме наведете ги главните информации за примените жалби и поплаки и превземените корективни активности и припремете копии од записите бидејќи ќе ви бидат побарани во текот на контролата."
export const POU_PRIMENI_ZALBI = "Примени жалби"
export const POU_PREVZEMENI_KOREKTIVNI_AKT = "Превземени корективни активности"
export const POU_SLEDLIVOST = "Замислете дека недозволени субстанции се пронајдени во производ кој сте го продале. До која точка низ записите може да се вратите наназад и да го утврдите потеклото на истите?"
export const POU_SLEDLIVOST_PODOBRUVANJE = "Во случај кога недостасуваат записи и следливост, што планирате за да ова го подобрите?"
export const POU_ZADOLZITELNI_ANEKSI = "Задолжителни анекси"
export const POU_ANEKS = "Анекс"
export const POU_ANEKS_ZADOLZITELNO = "Задолжително во следните случаи"
export const POU_ANEKS_1 = "Потпишан поддоговарач"
export const POU_ANEKS_ZADOLZITELNO_1 = "Ако фармата не е корисник на сертификатот ако е вклучен друг поддоговарач"
export const POU_ANEKS_2 = "Оригинал/факсирана/скенирана последна страна од ПОУ, потпишана од оператор и контролор"
export const POU_ANEKS_ZADOLZITELNO_2 = "Во случај кога да треба да се форматира или кога сите корективни акции не се наоѓаат на последната страна, сите страници каде што се сумирањата на корективните активности треба да бидат потпишани од операторот"
export const POU_ANEKS_3 = "Мапа на фарма"
export const POU_ANEKS_ZADOLZITELNO_3 = "Првата година или кога има измени"
export const POU_ANEKS_4 = "Примерок од етикета, сметка или фактура"
export const POU_ANEKS_ZADOLZITELNO_4 = "Секоја фарма која продава директно било какви производи како органски. Во случај на поддоговарани фарми каде што корисник на сертификат е преработувач или трговец, не е потребно покажување на етикети, сметки или фактури."
export const POU_ANEKS_5 = "Скицирање на објектите на фармата"
export const POU_ANEKS_ZADOLZITELNO_5 = "Во случај на големи фарми, каде што има објекти за складирање и/или преработка или пакување."
export const POU_ANEKS_6 = "Запис за примерок"
export const POU_ANEKS_ZADOLZITELNO_6 = "Ако се зема примерок"
export const POU_ANEKS_7 = "Ветеринарна потврда за бројна состојба на животните"
export const POU_ANEKS_ZADOLZITELNO_7 = ""
export const POU_DRUGI_ANEKSI = "Други анекси"
export const POU_SUMIRANJE_NESOODVETNOSTI = " Сумирање на несоодветности идентификувани од самата фарма"
export const POU_SUMIRANJE_NESOODVETNOSTI_OPIS = "Ве молиме сумирајте ги несоодветностите кои сте ги уочиле на вашата фарма и корективните активности кои планирате да ги имплементирате."
export const POU_BROJ_POU = "Број од ПОУ*"
export const POU_NESOODVETNOST = "Не соодветност"
export const POU_KOREKTIVNA_AKTIVNOST = "Корективна активност"
export const POU_PREDLOZENA_KOREKTIVNA_AKTIVNOST = "Предложена корективна активност"
export const POU_OZNACUVANJE_POU = "*Ве молиме упатете на означувањето во Планот за Органско Управување."
export const POU_VEKJE_SE_IMPLEMENTIRA = "Дали веќе се имплементира?"
export const POU_ROK_IMPLEMENTACIJA = "Рок"
export const POU_SERTIFIKATPOSLE_KOREKCIJA = "Дали сертификатот да се издаде само после имплементацијата на корективните активности?"
export const POU_NESOODVETNOSTI_IDENTIFIKUVANI = "Дали несоодветностите се точно идентификувани?"
export const POU_ZIVOTNI = "Животни"
export const POU_SM_DEN = "СМ/ден"
export const POU_KG_SM = "кг/СМ"
export const POU_HRANLIVI_MAT = "Хранливи материи"
export const POU_ZAPISI_KOL_DEN = "Записи за добиени количини на ден"
export const POU_POTEKLO_PRAVILA = "Има воспоставено јасни правила за преод на животни, кои се чуваат или се набавени од конвенционални единици."
export const POU_POTEKLO_ZIVOTNI = "Ве молиме наведете ги оние животински видови за кои барате сертификација и нивното потекло."
export const POU_POTEKLO_ZIV = "Потекло на животните"


export const SPEC_PROIZ_KAPACITET_PRERABOTKA = "Производни капацитети за преработка / processing capacity"
export const SPEC_PROIZVOD = "Производ / Product"
export const SPEC_SOSTAV = "Состав/ingredients"
export const SPEC_VALIDNOST_1 = "Оваа спецификација е валидна само во комбинација со назначениот сертикат / This Annex is valid only in combination with the certificate indicated above"
export const SPEC_VALIDNOST_2 = "Операторите се должни сите значајни промени во количините да ги пријават кај сертификаторот / Organic operators have to report to the certifier any significant changes in quantities"
export const SPEC_VALIDNOST_3 = "Количините се наведени според достапните податоците за време на извршената контрола и истите можат да варираат / Stated quantities are stated according to available data during the control and are subject to changeability"
export const SPEC_PROIZ_KAPACITET_PCELARSTVO = "Производни капацитети пчеларство / Production capacity beekeeping"
export const SPEC_KOLICINA = "Количина / Quantity"
export const SPEC_LOKACIJA_PCELARNIK = "Локација на пчеларник / Beehiveslocation"
export const SPEC_BROJ_PCELNI_SEMEJSTVA = "Број пчелни семејства / numberofbeehives"
export const POU_KONTROLA_UPATSTVO = " *Потполно? Дали информациите дадени од компанијата се потполни и точни? Соодветно? Дали опишаната процедура или ситуација е соодветствува со стандардот(ите)? Имплементирано? Дали опишаната процедура се имплементира во пракса?\n" +
    "*Kористете ги следниве картенки: Да - y; Делумно – p ; НЕ - n; НеПрименливо - na; Не-проверено - nc; Не сигурно - ?"
export const POU_PLAN_KONTROLA = "План на контрола"
export const POU_AKTIVNOST = "Активност"
export const POU_ZAEDNO_SO = " Заедно со ...(одговорни во компанијата)"
export const POU_POSEBNI_INCIDENTI = " Посебни инциденти"
export const POU_NAODI = "Наоди за време контролата, кои бараат брзо реагирање од страна на сертификаторот?"
export const POU_AZURIRANJE = "Ажурирање на ПОУ: ПОУ се ажурира во случај на повторна сертификација: Дали операторот спроведува комплетно и точно ажурирање на ПОУ пред контролата?"
export const POU_PRIMEROCI = "Дали се земени примероци?"
export const POU_PRIMEROCI_DA = "Ако ДА, наведете датата и број на записник"

export const POU_FORM_PCELARSTVO = "4.3.4 форм"
export const POU_FORM_STOCARSTVO = "4.3.2 форм"
export const POU_FORM_RASTITELNO = "4.3.1 форм"
export const POU_HEADER_SUBTITLE_PCELARSTVO = "План за органско управување за пчеларско производство"
export const POU_HEADER_VERSION_PCELARSTVO = "в.2 : 10.05.2023"
export const POU_HEADER_PREV_VERSION_PCELARSTVO = "в.1 : 04.03.2022"
export const PCELARSKO_PROIZVODSTVO = "Пчеларско производство";
export const POU_PRERABOTUVAC_PCELARSTVO = "Преработувач: дали вашите пчеларски производи (мед и друго) се преработуваат ( центрифугирање, филтрирање, пакување и тн.) во независна единица?"
export const POU_PCELARNIK_ORG = "Пчеларник - органско"
export const POU_PCELNI_SEMEJSTVA_ORG = "Број на пчелни семејства - органско"
export const POU_KOLICINA_ORG = "Вид и количина на органски производ"
export const POU_PCELARNIK_PREOD = "Пчеларник - во преод"
export const POU_PCELNI_SEMEJSTVA_PREOD = "Број на пчелни семејства - во преод"
export const POU_KOLICINA_PREOD = "Вид и количина на производ во преод"
export const POU_PCELARSTVO_SERT = "За кои производи барате сертификација оваа година?"
export const POU_ANEKS_FOTO = "Фото од фарма"
export const POU_ANEKS_POTVRDA = "Ветеринарна потврда "
export const POU_ANEKS_CENTRIFUGA = "Центрифуга за цедење мед."
export const POU_ANEKS_OPREMA = "Опрема за пчеларство"
export const POU_ANEKS_EVIDENCIJA = "Евиденција"
export const POU_LOKACIJA = "Локација"
export const POU_LOKACIJA_OPIS1 = "Органските пчелни семејства треба да се сместени на локација која ги овозможува следните услови:"
export const POU_LOKACIJA_OPIS2 = "•\tДоволно нектар и полен\n" +
    "•\tДоволно извори за чиста вода\n" +
    "•\tВо радиус приближно од 3 км околу пчеларникот, мора да доминира природна вегетација и екстензивнo производствo или органски култивирани растенија. Ова се однесува ососбено за култури кои се посетувани од пчелите.\n"
export const POU_PRESELUVANJE = "Дали ги преселувате вашите пчелни семејства? Ако Да, пополнете и во 6.2 и 6.3. Ве молиме продолжете ја табелата или користете додатна во случај ако има повеќе од една селидба!"
export const POU_GLAVNA_LOKACIJA = "Локација на пчеларник"
export const POU_LOKACIJA_PERIOD = "Оваа локација се користи од-до (месец)"
export const POU_NAD_VISINA = "Отприлика, надморска висина"
export const POU_POVRSINA_VEGETACIJA = "Површини кои се користат и вегетација на радиус од 3 км"
export const POU_IZVOR_NEKTAR = "Извор на нектар или/и медлика"
export const POU_RIZIK_ZAGADUVANJE = "Ризик од загадување"
export const POU_VODA_RASTOJANIE = "Вода за пчелите достапна е на растојание од: (м)"
export const POU_VKUPNA_POVRSINA = "% на вкупна површина"
export const POU_HIGIENSKI_KVALITET = "Ве молиме објаснете го хигиенскиот квалитет на водата"
export const POU_LOKACIJA_MIGRACIJA = "Локација на миграцијата. Ве молиме опишете ја околината"
export const POU_MAPA_PCELARSTVO = "Мапа, кадешто фиксните и миграторните локации се прикажани, се наоѓа во Анекс бр."
export const POU_NESOODVETNI_LOKACII = "Во случај ако вашите пчели се сместени на несоодветна фиксна и/или миграторна локација, ве молиме опишете како ќе ја поправите оваа ситуација."
export const POU_POTEKLO_PCELI_PREOD = "Потекло на пчелите, преод "
export const POU_POTEKLO_PCELI_PREOD_OPIS_1 = "Пчелите во органското пчеларство мора да се од органско потекло, вклучително:"
export const POU_POTEKLO_PCELI_PREOD_OPIS_2 = "•\tДелење на сопствените семејства\n" +
    "•\tНабавување или роење од сертифицирани органски пчелари\n"
export const POU_POTEKLO_PCELI_PREOD_OPIS_3 = "Во случај да сте на самиот почеток со органското пчеларство, вашите пчели мора да поминат една година период на преод. Истото важи ако набавите пчелни семејства од конвенционални пчелари.Набавка од конвенционални пчелари е дозволена само ако органски семејства не се достапни."
export const POU_POTEKLO_OPIS = "Ве молиме опишете го потеклото на вашите семејства"
export const POU_PREOD_DETALI = "Детали за преод на вашите семејства (*молиме наведете месец и година)"
export const POU_IDENTIFIKACIJA = "Идентификација на пчеларник"
export const POU_POSLEDNA_NABAVKA = "Последна набавка на конвенционални пчели*"
export const POU_ORG_POTEKLO_NESOODVETNOST = "Во случај да сите ваши семејства не се со органско потекло или немаат поминато низ потребниот преод од една година, опишете како ќе се осигурите дека во иднина оваа несоодветност нема да се повтори."
export const POU_ZAMENA_VOSOK = "Дали го заменувате целиот ваш восок за време на периодот на преодот."
export const POU_ZAMENA_VOSOK_NE = "Не, само: (%) "
export const POU_ZAMENA_VOSOK_DA = "Ако Да или Делумно, како го правите тоа?"
export const POU_ZAMENA_VOSOK_DOC = "Дали замената на восокот соодветно е документирана?"
export const POU_MESANJE_VOSOK = "Како е организиран вашиот затворен циклус на восокот за да се избегне мешање со конвенционален восок?"

export const POU_VOSOK_NESOGLASNOST = "Во случај вашиот циклус на замена на восок да не е затворен, што ќе направете за да ја надминете оваа несогласност?"
export const POU_MATERIJAL = "Материјал"
export const POU_MATERIJAL_OPIS = "Воглавно природни материјали треба да се користат за кошниците и други пчеларски материјали."
export const POU_MATERIJAL_KOSNICI = "Од каков материјал се направени вашите кошници?"
export const POU_KOSNICI_BOENJE = "Каков тип на боење користите за кошниците?"
export const POU_RAMKI_MATERIJAL = "Од што се направени рамките?"
export const POU_CISTENJE = "Што користите за чистење и одржување на кошниците, рамките и восокот?"
export const POU_MATERIJALI_PODOBRUVANJE = "Во случај ако некои од вашите материјали не се соодветни со предходно кажаното, како ќе ја подобрите оваа ситуација."
export const POU_MED_ZA_PCELI_OPIS = "Пчелите треба да се обезбедени со доволно природна храна за нормален опстанок. Само во случај на екстремни услови(студено, сушно) при што опстанокот на пчелите е загрозен, дозволено е вештачка прихрана со органски мед/шеќер."
export const POU_MED_ZA_PCELI = "Колку од собраниот мед оставате на пчелите за нивна исхрана?"
export const POU_SEZONA_BEZ_CVETANJE = "Колку долго трае сушната или ладната сезона без цветање, во вашиот реон?"
export const POU_ISHRANA_PCELARSTVO = "Што вие користите за исхрана?"
export const POU_ISHRANA_PODOBRUVANJE = "Во случај да не е комплетно соодветен вашиот систем со побарувањата за исхрана споменати погоре, ве молиме објаснете како ќе го подобрите."
export const POU_UPRAVUVANJE_PCELI = "Управување со пчелните семејства"
export const POU_UBIVAJE_PCELI_OPIS = "Пчелите не треба да се убиваат како метод за собирање мед, екстремитетите на матицата не треба да се отсекуваат како метод за спречување на роење.Трутовите може да се елиминараат само како метода за превентива од Varoa, но не од други причини.Употребата на хемиски репеленти не е дозволена за време на прибирање или управување со семејствата."
export const POU_UBIVAJE_PCELI = "Дали убивате пчели како метод при собирање мед?"
export const POU_SECENJE_KRILA = "Дали практикувате отсекување на крила или нозе, како метод за спречување на роење или предизвикување тивка замена на старите матици?"
export const POU_UBIVANJE_TRUTOVI = "Дали ги убивате трутовите од други причини освен од превенција од Varoa?"
export const POU_UPOTREBA_REPELENTI = "Кои материи ги користите за потчадување на пчелните семејства при преглед?"
export const POU_KOLONII_PODOBRUVANJE = "Во случај ако вашата практика за управување со колониите не е соодветна со точките споменати погоре, како ќе ја поправите оваа ситуација?"
export const POU_PREVENTIVA_OPIS = "Болестите и штетниците на пчелните семејства мора превентивно да се спречуваат со користење на автохтони пчели, \n" +
    "со соодветно чистење и дезинфекција, промена на матицата, оставање доволно количини храна, уништување на инфицирани материјали и др. \n" +
    "Ако и покрај наведените мерки, болести или штетници ги нападнат колониите, тие треба веднаш да се третираат.\n" +
    "Природната и хомеопатската медицина треба да се преферира. Ако користите алопатски ветеринарни лекарства или пестициди, \n" +
    "односниaтa кошница мора да се изолира од другите и треба да помине едногодишен период на преод. За тоа време восокот треба \n" +
    "повторно да се замени, според тоа како што е објаснето во точка 8."
export const POU_TESTIRANJE_VOSOK = "Дали восокот или медот ги тестирате за резидуи од антибиотици или пестициди?"
export const POU_TESTIRANJE_VOSOK_DA = "Ако Да, објаснете ја процедурата и фреквенцијата."
export const POU_TESTIRANJE_VOSOK_REZULTATI = "Резултатите од тестот се приложени во Анекс Бр."
export const POU_BR_KOLONII = "Број на набавени колонии"
export const POU_ADITIVI_DA = "Да,во просек околу кг/годишно за секоја колонија"
export const POU_ADITIVI_DRUGO = "Други забелешки"
export const POU_PREVENTIVA_OPIS_P = "Опишете ги во детали мерките кои ги користите за превентива од болести и штетници на вашите семејства."
export const POU_BOLESTI_OPIS_P = "Наведете ги болестите и штетниците кои ги напаѓаат вашите колонии, наведувајки колку нивното влијание е штетно."
export const POU_BOLESTI_SUPSTANCI = "Кои супстанции ги користите во случај на напад на болести или штетници?"
export const POU_BOLESTI_PREVENCIJA_P = "Во случај ако превентивата за болести и штетници, не е соодветна со побарувањата објаснети погоре, ве молиме опишете што ќе сторите за да ја поправите ситуацијата."
export const POU_IZOLACIJA_KOLONII = "Како осигурувате дека третираните колонии со конвенционални препарати се изолирани од останатите и се подложени на едногодишен период на преод?"
export const POU_IZOLACIJA_KOLONII_OPIS = "Во спротивно, објаснете како."
export const POU_PRINNOSI_SOBIRANJE = "Приноси и собирање производи"
export const POU_BRUTO_PO_SEMEJSTVO = "Бруто* по семејство /"
export const POU_POSTBERBENO_RAKUVANJE_P = "Сите органски стандарди ставаат особен акцент на пост бербеното одвојување помеѓу сертифицирани и несертифицирани производи"
export const POU_POSTBERBENO_RAKUVANJE_OPIS_P = "Опишете ги детално сите чекори низ кои поминуваат производите во постбербените активности."
export const POU_ETIKETIRANJE_OPIS = "Освен за првиот транспорт од собирањето, до првото складирање или преработка, органските производи треба да се чуваат и транспортираат во затворени и соодветно-обележена амбалажи. Етикетите мора да содржат информации за видот на производ, количина, производител, органска состојба, име на сертификациско тело и број на шаржа."
export const POU_PCELARSKI_DNEVNICI = "Пчеларски дневници, опишување на сите активности"
export const POU_ZAPISI_TRETMANI = "Записи за ветеринарни третмани, вклучувајќи информација за аплицирани препарати, дози и (ако е применливо) изолација на третираните семејства"
export const POU_ZAPISI_SEMEJSTVA = "Записи за собрани количини, по дата"
export const POU_FAKTURI_P = "Фактури од набавки на восок, пчели, храна и ветеринарни производи"
export const LOOKUP_PREBARAJ = "Пребарај шифрарник"
export const LOOKUP_DODADI = "Додади шифрарник"
export const SEARCH = "Пребарај"
export const USERNAME = "Корисничко име (E-адреса)"
export const FULLNAME = "Име и презиме"
export const AUTHORITIES = "Улоги"
export const PASSWORD = "Лозинка"
export const CURRENT_PASSWORD = "Моментална лозинка *"
export const NEW_PASSWORD = "Нова лозинка *"
export const CONFIRM_PASSWORD = "Потврди лозинка *"
export const LOKACIJA_ODGLEDUVALISTE = "Локација на одгледувалиште / Location of holding"
export const REGISTRACISKI_BR_ODGLEDUVALISTE = "Регистрациски број на одгледувалиште / RBO-number"
export const SPEC_VID_ZIVOTNO = "Вид животно / Livestock"
export const SPEC_BROJ_ZIVOTNI = "Број животни / Number of animals"
export const SPEC_KOLICINI = "Количини, број / Quantities, number"
export const SPEC_IZBOR_POU = "Изберете ПОУ за кое ќе креирате спецификација"
export const POU_VID_PROIZVOD = "Вид производ"
export const POU_KOLICINA_PROIZVOD = "Количина на производ"
export const POU_STATUS_PROIZVOD = "Статус на производ"
export const POU_BROJ_KOLICINA = "Број / Количина (kg)"
export const POU_HEADER_VERSION_STOCARSTVO = "в.2 : верзија 10.05.2023"
export const POU_HEADER_PREV_VERSION_STOCARSTVO = "в.1 : верзија 04.03.2022"
export const POU_HEADER_IZGOTVIL = "Изготвил: Љубица Тимова"
export const POU_HEADER_PROVERIL_POTVRDIL = "Проверил/Потврдил: Горан Колев"
export const SISTEMSKA_GRESKA = "Системска грешка!"
export const POGRRESNI_PODATOCI_LOGIN = "Погрешни податоци. Обидете се повторно!"
export const IZRABOTIL = "Изработил:"
export const PROCERT_EN = "ПРО-ЦЕРТ / PRO-CERT"
