import {
    createApplicationAction,
    createNewApplicationAction,
    deleteApplicationAction,
    fetchApplicationByIdAction,
    fetchApplicationsAction,
    updateApplicationAction
} from "./actions";
import {apiURL, authHeader, requestOptions} from "../helpers";
import {newApplicationData} from "./newApplicationData";


export function fetchApplications() {
    return dispatch => {
        fetch(`${apiURL}/aplikacija/all`, requestOptions())
            .then(res => res.json())
            .then(res => {

                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchApplicationsAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchApplicationById(applicationId) {
    return dispatch => {
        fetch(`${apiURL}/aplikacija/${applicationId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchApplicationByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewApplication() {
    const newApplication = newApplicationData
    return dispatch => {
        dispatch(createNewApplicationAction(newApplication))
        return newApplication
    }
}

export function updateApplicationRequest(application, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...application})
    };

    return dispatch => {
        fetch(`${apiURL}/aplikacija/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updateApplicationAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function createApplicationRequest(application) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...application})
    };

    return dispatch => {
        fetch(`${apiURL}/aplikacija/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(createApplicationAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deleteApplicationRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/aplikacija/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deleteApplicationAction()).then(dispatch(fetchApplications()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}