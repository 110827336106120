import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import * as ALL from '../../../common/staticLabels'
import {Grid, Typography} from '@material-ui/core';
import globalStyles from "../../styles";
import GridTable from "../../../components/gridTable";
import CustomLabeledInput from "../../../components/customLabeledInput";
import {yesno} from "../../../mockData/ddDaNeData";
import {zakluchok} from "../../../mockData/zakluchokLookup";
import ControlInput from "../../../components/controlInput";
import {korektivniAktivnostiData} from "../../../mockData/ddKorektivniAktivnostiData";
import {daNePonekogasData} from "../../../mockData/ddDaNePonekogasData";
import {daNeDelumnoData} from "../../../mockData/ddDaNeDelumnoData";
import {postberbenoRakuvanjeData} from "../../../mockData/postberbenoRakuvanjeData";
import Button from "@material-ui/core/Button";

import {zemjisteData} from "../../../mockData/ddZemjisteData";
import {yesnomaybe} from "../../../mockData/ddDaNePraznoData";
import Notification from "../../../common/Notification";
import UploadInput from "../../../components/uploadInput";

class PouStocarstvo extends Component {

    constructor(props) {
        super(props);
        this.addKultura = this.addKultura.bind(this)
        this.updateObject = this.updateObject.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.checkCanSave = this.checkCanSave.bind(this)
        this.requiredFieldsMessage = this.requiredFieldsMessage.bind(this)
        this.showNotification = this.showNotification.bind(this)
        this.uploadFileAndSetState = this.uploadFileAndSetState.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.hasRole = this.hasRole.bind(this)
        // this.state = {navDrawerOpen: false, ...stocarstvoData, ...kontrolorData};
        this.state = {
            navDrawerOpen: false, ...this.props.pouStocarstvo, ...this.props.pouStocarstvoControl,
            notify: false,
            message: '',
            error: false
        };

    }

    checkCanSave() {
        return (!!this.state.mobilen || !!this.state.telefon) && !!this.state.ime_farma && !!this.state.sopstvenik
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.pouStocarstvo.id && this.props.pouStocarstvo.id !== nextProps.pouStocarstvo.id) ||
            (this.props.pouStocarstvoControl && this.props.pouStocarstvoControl.controlId && this.props.pouStocarstvoControl.controlId !== nextProps.pouStocarstvoControl.controlId)) {
            window.location.reload();
        }
    }

    addKultura() {
        this.setState({
            navDrawerOpen: !this.state.navDrawerOpen
        });
    }

    handleChange(name, event) {
        event.persist()
        this.handleObj(name, event.target.value)
    };

    handleObj(name, value) {
        let object = JSON.parse(JSON.stringify(value))
        this.setState({[name]: object})

    };

    updateObject(obj, keys, value) {
        let key = keys.shift();
        if (keys.length > 0) {

            let tmp = this.updateObject(obj[key], keys, value);
            return {...obj, [key]: tmp};
        } else {
            return {...obj, [key]: value};
        }
    }

    multiselectHandleChange(name, event) {
        event.persist()
        const val = event.target.value
        const arr = this.state[name]
        const index = this.state[name].indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.push(val)
        }
        this.setState({[name]: arr})
    };

    calculateHeaderSize(fraction, unit) {
        return 100 / fraction * unit + "%"
    }

    requiredFieldsMessage() {
        this.showNotification("Полињата во точка 1 се задолжителни");
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    uploadFileAndSetState(fileUploadId) {
        this.props.uploadFile(fileUploadId).then(res => {
            this.handleObj("fileUploadId", res)
            this.setState({[fileUploadId]: res})
        })

    }

    deleteFile(fileUploadId) {
        this.setState({[fileUploadId]: ""})
        this.showNotification("Успешно избришан фајл");
    }

    hasRole(role) {
        var userAuthorities = this.props.user && this.props.user.user && this.props.user.user.authorities;
        var modifierUserAuthorities = this.props.pouStocarstvo && this.props.pouStocarstvo.modifierUser && this.props.pouStocarstvo.modifierUser.authorities;
        if (role === "CONTROL_ADMIN" && !this.props.pouStocarstvoControl) {
            return false;
        }
        if (modifierUserAuthorities) {
            if (userAuthorities && userAuthorities.length < 2 && userAuthorities.length < modifierUserAuthorities.length) {
                return false;
            }
        }

        return userAuthorities && userAuthorities.some(function (u) {
            return u.authority === role;
        }) || false;
    }

    render() {
        const {classes, createMode, editMode} = this.props;
        const {notify, message, error} = this.state;
        const hasRoleControlAdmin = this.hasRole("CONTROL_ADMIN");
        const hasRoleClient = this.hasRole("CLIENT");
        const canSave = this.checkCanSave()

        const planKontrolaTemplate = {
            "data": "",
            "aktivnost": "",
            "zaedno_so": "",
            "posebni_incidenti": ""
        }
        const korektivniAktivnostiTemplate = {
            "aktivnost": "",
            "implementacija": ""
        }
        const animalniProizvodiTemplate = {
            "zivotni": "",
            "broj_kolicina": "",
            "status": ""
        }
        const opisFarmaTemplate = {
            "zemjiste": "",
            "hektari_organsko": "",
            "hektari_preod": "",
            "hektari_konvencionalno": ""
        }
        const brZivotniVidTemplate = {
            "vid": "",
            "br_organsko": "",
            "br_preod": "",
            "br_konvencionalno": ""
        }
        const potekloZivotniPreodTemplate = {
            "vid": "",
            "br_zivotni": "",
            "broj": "",
            "lokacija": ""
        }
        const prinosiTemplate = {
            "proizvod": "",
            "vkupno_bruto_lani": "",
            "bruto_po_zivotno_lani": "",
            "vkupno_bruto_godinava": "",
            "bruto_po_zivotno_godinava": ""
        }
        const postberbenoRakuvanjeOpisTemplate = {
            "postapka": "",
            "kade_izveduvanje": "",
            "odgovoren": ""
        }
        const primeniZalbiTemplate = {
            "zalba": "",
            "korektivni_aktivnosti": ""
        }
        const aneksiTemplate = {
            "broj": "",
            "aneks": ""
        }
        const nesoodvetnostiFarmaTemplate =
            {
                "nesoodvetnost": "",
                "korektivna_aktivnost": "",
                "rok_implementacija": "",
                "tocno_identifikuvani": "",
                "adekvatni": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }

        const dopolnitelniNesoodvetnostiTemplate =
            {
                "korektivni_aktivnosti_definicija": "",
                "rok": "",
                "broj": "",
                "nesoodvetnosti": "",
                "adekvateni_korektivni_aktivnosti": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }

        let trpe = ""
        let size = ""
        trpe = 100 / 5 + "%"
        size = (100 / 5) * 2 + "%"
        return (

            (this.state.id !== undefined || this.props.createMode) && this.props.lookupData !== undefined &&
            <div>
                <Grid container style={{border: "1px solid black"}} xs={12}>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center',
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_PROCERT}</Typography></Grid>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center',
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_FORM_STOCARSTVO}</Typography></Grid>
                    <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={3}>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_PREV_VERSION_STOCARSTVO}</Typography></Grid>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_VERSION_STOCARSTVO}</Typography></Grid>
                    </Grid>
                    <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={3}>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_IZGOTVIL}</Typography></Grid>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_PROVERIL_POTVRDIL}</Typography></Grid>
                    </Grid>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center'
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_HEADER_STOC_SUBTITLE}</Typography></Grid>
                </Grid>
                <Grid container style={{border: "1px solid black"}} xs={12}>
                    <CustomLabeledInput labelInput={ALL.POU_HEADER_KOMPANIJA}
                                        inputType={"text"}
                                        size={3}
                                        vertical={true}
                                        valueInput={this.state.header_kompanija}
                                        onChangeAction={(event) => this.handleChange('header_kompanija', event)}
                    />
                    <CustomLabeledInput labelInput={ALL.POU_HEADER_DATUM_POPOL}
                                        inputType={"text"}
                                        size={2}
                                        vertical={true}
                                        valueInput={this.state.header_datum_popol}
                                        onChangeAction={(event) => this.handleChange('header_datum_popol', event)}
                    />
                    <CustomLabeledInput labelInput={ALL.POU_HEADER_ODGOVOREN_KOMPANIJA}
                                        inputType={"text"}
                                        size={2}
                                        vertical={true}
                                        valueInput={this.state.header_odgovoren_kompanija}
                                        onChangeAction={(event) => this.handleChange('header_odgovoren_kompanija', event)}
                    />
                    <CustomLabeledInput labelInput={ALL.POU_HEADER_KONTROLOR}
                                        inputType={"text"}
                                        size={3}
                                        vertical={true}
                                        valueInput={this.state.header_kontrolor}
                                        onChangeAction={(event) => this.handleChange('header_kontrolor', event)}
                    />
                    <CustomLabeledInput labelInput={ALL.POU_HEADER_DATUM_KONTROLA}
                                        inputType={"text"}
                                        size={2}
                                        vertical={true}
                                        valueInput={this.state.header_datum_kontrola}
                                        onChangeAction={(event) => this.handleChange('header_datum_kontrola', event)}
                    />
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{ALL.POU}</Typography>
                        <Typography variant="h7">{ALL.STOCARSKO_PROIZVODSTVO}</Typography>
                        <Grid container xs={12} className={classes.coloredItemGrid1}>
                            <Grid item xs={12}><Typography>{ALL.POU_DESC}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid xs={12} className={classes.heightControlBlueItemGrid}>
                                    <Typography variant="h5">{ALL.POU_PLAN_KONTROLA}</Typography>
                                </Grid>
                                <GridTable obj={this.state.plan_kontrola}
                                           objLen={this.state.plan_kontrola.length}
                                           labeli={[ALL.DATA, ALL.POU_AKTIVNOST, ALL.POU_ZAEDNO_SO, ALL.POU_POSEBNI_INCIDENTI]}
                                           values={["data", "aktivnost", "zaedno_so", "posebni_incidenti"]}
                                           types={["textarea", "textarea", "textarea", "textarea"]}
                                           valueName={"plan_kontrola"}
                                           ddData={[null, null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           size={12}
                                           disabled={[!hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                           disabledButtons={!hasRoleControlAdmin}
                                           template={planKontrolaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NAODI}
                                                inputType={"text"}
                                                disabled={!hasRoleControlAdmin}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.naodi_kontrola}
                                                onChangeAction={(event) => this.handleChange('naodi_kontrola', event)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRIMEROCI}
                                                inputType={"radio"}
                                                disabled={!hasRoleControlAdmin}
                                                valueInput={this.state.zemeni_primeroci}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                onChangeAction={(event) => this.handleChange('zemeni_primeroci', event)}/>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.state.zemeni_primeroci === "true" &&
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRIMEROCI_DA}
                                                    inputType={"text"}
                                                    disabled={!hasRoleControlAdmin}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.zemeni_primeroci_da}
                                                    onChangeAction={(event) => this.handleChange('zemeni_primeroci_da', event)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <br/>
                <br/>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={11} className={classes.orangeColoredItemGrid}>
                        <CustomLabeledInput labelInput={ALL.POU_KONTROLA_UPATSTVO}
                                            size={12}
                                            firstElementSize={12}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">1</Typography></Grid>
                    </Grid>
                    <Grid container xs={11} className={classes.orangeColoredItemGrid}>
                        <CustomLabeledInput labelInput={ALL.POU_ADRESA}
                                            size={12}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <CustomLabeledInput labelInput={ALL.POU_IME_NA_FARMATA}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.ime_farma}
                                            onChangeAction={(event) => this.handleChange('ime_farma', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_SOPSTVENIK}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.sopstvenik}
                                            onChangeAction={(event) => this.handleChange('sopstvenik', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_ODGOVORNO_LICE}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.odgovorno_lice}
                                            size={12}
                                            multilineInput={true}
                                            onChangeAction={(event) => this.handleChange('odgovorno_lice', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_ULICA_BROJ_FAX}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.ulica_broj_fax}
                                            size={12}
                                            multilineInput={true}
                                            onChangeAction={(event) => this.handleChange('ulica_broj_fax', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_GRAD_KOD}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.grad_postenski_kod}
                                            onChangeAction={(event) => this.handleChange('grad_postenski_kod', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_TELEFON}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.telefon}
                                            onChangeAction={(event) => this.handleChange('telefon', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_MOBILEN_TELEFON}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.mobilen}
                                            onChangeAction={(event) => this.handleChange('mobilen', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.EMAIL}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.mail}
                                            onChangeAction={(event) => this.handleChange('mail', event)}
                        />
                    </Grid>

                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_1', event)}
                                          controlInput={this.state["control_1"]}

                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PARTNERI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IMATEL}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.imatel}
                                                onChangeAction={(event) => this.handleChange('imatel', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_2_1', event)}
                                          controlInput={this.state["control_2_1"]}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_OPSEG_SERTIFIKACIJA}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <CustomLabeledInput labelInput={ALL.POU_POBARANA_PRV_PAT}
                                            inputType={"radio"}
                                            disabled={!hasRoleClient}
                                            options={yesno}
                                            vertical={true}
                                            size={4}
                                            valueInput={this.state.zakon_organsko_prv_pat}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_prv_pat', event)}/>
                        <CustomLabeledInput labelInput={ALL.POU_SERT_GODINA}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            size={4}
                                            vertical={true}
                                            placeholderInput={" "}
                                            multilineInput={true}
                                            valueInput={this.state.zakon_organsko_nePrvaPat_sertificirano}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_nePrvaPat_sertificirano', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_PRETH_SERT}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            size={4}
                                            vertical={true}
                                            placeholderInput={" "}
                                            multilineInput={true}
                                            valueInput={this.state.zakon_organsko_prethodno_sertificirano}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_prethodno_sertificirano', event)}
                        />
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_3_1', event)}
                                          controlInput={this.state["control_3_1"]}
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KOPIJA_STO}
                                                size={12}
                                                inputType={"checkbox"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                valueInput={this.state.tip_kopija}
                                                options={this.props.lookupData.tip_kopija}
                                                onChangeAction={(event) => this.multiselectHandleChange('tip_kopija', event)}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_3_2', event)}
                                          controlInput={this.state["control_3_2"]}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_RAZMER_PROSTUDIRANJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.objasnuvanja_standard}
                                                onChangeAction={(event) => this.handleChange('objasnuvanja_standard', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_3_3', event)}
                                          controlInput={this.state["control_3_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NE_RAZBRANO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.promena_standard}
                                                onChangeAction={(event) => this.handleChange('promena_standard', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_3_4', event)}
                                          controlInput={this.state["control_3_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_OSPORENA_ORGANSKA_SERTIFIKACIJA}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.sertifikacija_osporena}
                                                onChangeAction={(event) => this.handleChange('sertifikacija_osporena', event)}
                            />
                        </Grid>
                        {
                            this.state.sertifikacija_osporena === "true" &&
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_OSPORENA_ORGANSKA_SERTIFIKACIJA_DA}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.sertifikacija_osporena_opis}
                                                    onChangeAction={(event) => this.handleChange('sertifikacija_osporena_opis', event)}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_3_5', event)}
                                          controlInput={this.state["control_3_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_MIN_KOR_AKT}
                                                size={12}
                                                vertical={true}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.minatogodishni_korektivni_aktivnosti}
                                                options={korektivniAktivnostiData}
                                                onChangeAction={(event) => this.handleChange('minatogodishni_korektivni_aktivnosti', event)}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_4', event)}
                                          controlInput={this.state["control_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <GridTable obj={this.state.korektivni_aktivnosti}
                                       labeli={[ALL.POU_KOREKT_AKT, ALL.POU_IMPLEMENTACIJA]}
                                       values={["aktivnost", "implementacija"]}
                                       types={["textarea", "textarea"]}
                                       disabled={[!hasRoleClient, !hasRoleClient]}
                                       disabledButtons={!hasRoleClient}
                                       valueName={"korektivni_aktivnosti"}
                                       size={12}
                                       ddData={[null, null]}
                                       template={korektivniAktivnostiTemplate}
                                       onChangeAction={(name, value) => this.handleObj(name, value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_4_1', event)}
                                          controlInput={this.state["control_4_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid xs={12} className={classes.heightControlColoredItemGrid}>
                                    <Typography variant="h5">{ALL.POU_ANIMALNI_SERT}</Typography>
                                </Grid>
                                <GridTable obj={this.state.animalni_proizvodi_sertifikacija}
                                           labeli={[ALL.POU_PROIZVOD_ZIVOTNI, ALL.POU_BROJ_KOLICINA, ALL.POU_STATUS]}
                                           values={["zivotni", "broj_kolicina", "status"]}
                                           types={["dropdown", "text", "dropdown"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[this.props.lookupData.zivotno, null, this.props.lookupData.document_status]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           size={12}
                                           valueName={"animalni_proizvodi_sertifikacija"}
                                           template={animalniProizvodiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_5', event)}
                                          controlInput={this.state["control_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PODATOCI_ZA_OPKRUZUVANJETO}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NADMORSKA_VISINA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={" "}
                                                valueInput={this.state.nadmorska_visina_farma}
                                                onChangeAction={(event) => this.handleChange('nadmorska_visina_farma', event)}
                            />
                        </Grid>

                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_GODISNI_VRNEZI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={" "}
                                                valueInput={this.state.godishni_vrnezi_farma}
                                                onChangeAction={(event) => this.handleChange('godishni_vrnezi_farma', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_6', event)}
                                          controlInput={this.state["control_6"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_OPIS_FARMA}
                                                firstElementSize={12}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.opis_farma}
                                           labeli={[ALL.POU_ZEMJISTE, ALL.POU_ORG_HEKTARI, ALL.POU_PREOD_HEKTARI, ALL.POU_KONVE_HEKTARI]}
                                           values={["zemjiste", "hektari_organsko", "hektari_preod", "hektari_konvencionalno"]}
                                           types={["dropdown", "text", "text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[zemjisteData, null, null, null, null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"opis_farma"}
                                           size={12}
                                           template={opisFarmaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_7_1', event)}
                                          controlInput={this.state["control_7_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SKICA_OBJ}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.skica_objekti}
                                                onChangeAction={(event) => this.handleChange('skica_objekti', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'
                              style={{border: "1px solid black", display: 'flex', justifyContent: 'center'}}>
                            <UploadInput uploadFile={() => this.props.uploadFile()}
                                         fileUploadId={"skica_upload_id"}
                                         fileId={this.state.skica_upload_id}
                                         onDeleteAction={() => this.deleteFile("skica_upload_id")}
                                         onChangeAction={() => this.uploadFileAndSetState("skica_upload_id")}
                                         uploadLabel={"Додади мапа!"} downloadLabel={"Превземи мапа!"}/>
                        </Grid>
                    </Grid>

                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_7_2', event)}
                                          controlInput={this.state["control_7_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_NAVEDI_ZIVOTNI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.br_zivotni_vid}
                                           labeli={[ALL.POU_VIDOVI, ALL.POU_BR_ZIVOTNI_ORG_STATUS, ALL.POU_BR_ZIVOTNI_PREOD, ALL.POU_KONVE_BROJ_ZIVOTNI]}
                                           values={["vid", "br_organsko", "br_preod", "br_konvencionalno"]}
                                           types={["dropdown", "text", "text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[this.props.lookupData.zivotno, null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"br_zivotni_vid"}
                                           size={12}
                                           template={brZivotniVidTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_8', event)}
                                          controlInput={this.state["control_8"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">9</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POTEKLO_PREOD}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">9.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput
                                labelInput={ALL.POU_POTEKLO_PRAVILA + '\n' + ALL.POU_POTEKLO_ZIVOTNI}
                                size={12}
                                firstElementSize={12}
                            />
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable obj={this.state.poteklo_zivotni_preod}
                                               labeli={[ALL.POU_VID, ALL.POU_BR_ZIV, ALL.POU_POTEKLO_ZIV, ALL.POU_LOKACIJA]}
                                               values={["vid", "br_zivotni", "broj", "lokacija"]}
                                               types={["dropdown", "textarea", "textarea", "textarea"]}
                                               disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                               disabledButtons={!hasRoleClient}
                                               ddData={[this.props.lookupData.zivotno, null, null, null]}
                                               columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                               valueName={"poteklo_zivotni_preod"}
                                               size={12}
                                               template={potekloZivotniPreodTemplate}
                                               onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_9_1', event)}
                                          controlInput={this.state["control_9_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ISHRANA}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ADAPT_ISH}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.ishrana_opis}
                                                onChangeAction={(event) => this.handleChange('ishrana_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_10_1', event)}
                                          controlInput={this.state["control_10_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_DOZV_HRAN_MAT}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KONV_PREOD_HRAN_MAT}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.hranlivi_materii_opis}
                                                onChangeAction={(event) => this.handleChange('hranlivi_materii_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_10_2', event)}
                                          controlInput={this.state["control_10_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_MLADI_CICACI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.mladi_cicaci_opis}
                                                onChangeAction={(event) => this.handleChange('mladi_cicaci_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_10_3', event)}
                                          controlInput={this.state["control_10_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_TREVOPASNI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.trevopasni_period}
                                                onChangeAction={(event) => this.handleChange('trevopasni_period', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_10_4', event)}
                                          controlInput={this.state["control_10_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KABASTA_H}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.kabasta_hrana_obezbeduvanje}
                                                onChangeAction={(event) => this.handleChange('kabasta_hrana_obezbeduvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_10_5', event)}
                                          controlInput={this.state["control_10_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.6</Typography></Grid>
                    </Grid>

                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ADITIVI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.aditivi}
                                                onChangeAction={(event) => this.handleChange('aditivi', event)}
                            />
                        </Grid>
                        {
                            this.state.aditivi === "true" &&

                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_ADITIVI_KORISTI}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.aditivi_opis}
                                                    onChangeAction={(event) => this.handleChange('aditivi_opis', event)}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_10_6', event)}
                                          controlInput={this.state["control_10_6"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/*12*/
                }
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_BOLESTI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PREV_BOLESTI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sistem_prevencija_bolesti}
                                                onChangeAction={(event) => this.handleChange('sistem_prevencija_bolesti', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_11_1', event)}
                                          controlInput={this.state["control_11_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_UTVRDUVANJE_KARENCA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.alopatski_lekarstva_praksa}
                                                onChangeAction={(event) => this.handleChange('alopatski_lekarstva_praksa', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_11_2', event)}
                                          controlInput={this.state["control_11_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ANTIB_PREV}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={daNePonekogasData}
                                                valueInput={this.state.preparati_preventirvno}
                                                onChangeAction={(event) => this.handleChange('preparati_preventirvno', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_11_3', event)}
                                          controlInput={this.state["control_11_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SANITARNA_PRAKTIKA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sistem_higiena_opis}
                                                onChangeAction={(event) => this.handleChange('sistem_higiena_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_11_4', event)}
                                          controlInput={this.state["control_11_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PRAKTIKI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_RAZMNOZH}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sistem_razmnozuvanje}
                                                onChangeAction={(event) => this.handleChange('sistem_razmnozuvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_12_1', event)}
                                          controlInput={this.state["control_12_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_OPERACII}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.operacii_zivotni_praktiki}
                                                onChangeAction={(event) => this.handleChange('operacii_zivotni_praktiki', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_12_2', event)}
                                          controlInput={this.state["control_12_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KASTRACIJA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.kastracija}
                                                onChangeAction={(event) => this.handleChange('kastracija', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_12_3', event)}
                                          controlInput={this.state["control_12_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_VRZUVANJE_ZIVOTNI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.vrzuvanje_zivotni}
                                                onChangeAction={(event) => this.handleChange('vrzuvanje_zivotni', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_12_4', event)}
                                          controlInput={this.state["control_12_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_USLOVI_SHTALI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.uslovi_shtali}
                                                onChangeAction={(event) => this.handleChange('uslovi_shtali', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_12_5', event)}
                                          controlInput={this.state["control_12_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12.6</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PODOVI_SHTALI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.podovi_shtali}
                                                onChangeAction={(event) => this.handleChange('podovi_shtali', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_12_6', event)}
                                          controlInput={this.state["control_12_6"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_UPRAVUVANJE_GJUBRE_ISPASHA}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KG_ZIVA_MERA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.kg_ziva_mera}
                                                onChangeAction={(event) => this.handleChange('kg_ziva_mera', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_13_1', event)}
                                          controlInput={this.state["control_13_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PREKUMERNA_ISPASHA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.prekumerna_ispasha}
                                                onChangeAction={(event) => this.handleChange('prekumerna_ispasha', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_13_2', event)}
                                          controlInput={this.state["control_13_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_POPRAVANJE_UPRAVUVANJE_GJUBRE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.popravanje_upravuvanje_gjubre}
                                                onChangeAction={(event) => this.handleChange('popravanje_upravuvanje_gjubre', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_13_3', event)}
                                          controlInput={this.state["control_13_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">14</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PRINOSI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid style={{width: trpe}} className={classes.purpleColoredItemGrid}>
                                </Grid>
                                <Grid style={{width: size}} className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_PRINOSI_MINATA_GODINA} </Typography>
                                </Grid>
                                <Grid style={{width: size}} className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_PRINOSI_OVAA_GODINA} </Typography>
                                </Grid>
                                <GridTable obj={this.state.prinosi}
                                           labeli={[ALL.POU_PROIZVOD, ALL.POU_KOLICINA, ALL.POU_KOLICINA_PO_ZIVOTNO, ALL.POU_KOLICINA, ALL.POU_KOLICINA_PO_ZIVOTNO]}
                                           values={["proizvod", "vkupno_bruto_lani", "bruto_po_zivotno_lani", "vkupno_bruto_godinava", "bruto_po_zivotno_godinava"]}
                                           types={["text", "text", "text", "text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"prinosi"}
                                           size={12}
                                           template={prinosiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_14', event)}
                                          controlInput={this.state["control_14"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POSTAPUVANJE_PROD_ZIVOTNI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.postberbeno_rakuvanje}
                                                options={postberbenoRakuvanjeData}
                                                size={12}
                                                vertical={true}
                                                onChangeAction={(event) => this.handleChange('postberbeno_rakuvanje', event)}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_15_1', event)}
                                          controlInput={this.state["control_15_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_POSTBERBENO_RAKUVANJE_OPIS}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.postberbeno_rakuvanje_opis}
                                           labeli={[ALL.POU_POSTAPKA_CEKOR, ALL.POU_IZVEDUVA, ALL.POU_ODGOVOREN]}
                                           values={["postapka", "kade_izveduvanje", "odgovoren"]}
                                           types={["textarea", "textarea", "textarea"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"postberbeno_rakuvanje_opis"}
                                           size={12}
                                           template={postberbenoRakuvanjeOpisTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_15_2', event)}
                                          controlInput={this.state["control_15_2"]}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PODDOGOVARACI_POSTBERBENO}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.poddogovaraci}
                                                onChangeAction={(event) => this.handleChange('poddogovaraci', event)}
                            />
                        </Grid>
                        {
                            this.state.poddogovaraci === "true" &&
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PODDOGOVARACI_POSTBERBENO_OPIS}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.poddogovaraci_opis}
                                                    onChangeAction={(event) => this.handleChange('poddogovaraci_opis', event)}
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_15_3', event)}
                                          controlInput={this.state["control_15_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZBEGNUVANJE_ZABRANETI_SUPSTANCI_PCELARSTVO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izbegnuvanje_zabraneti_supstanci}
                                                onChangeAction={(event) => this.handleChange('izbegnuvanje_zabraneti_supstanci', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_15_4', event)}
                                          controlInput={this.state["control_15_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZBEGNUVANJE_MESHANJE_PROIZVODI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izbegnuvanje_meshanje_proizvodi}
                                                onChangeAction={(event) => this.handleChange('izbegnuvanje_meshanje_proizvodi', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_15_5', event)}
                                          controlInput={this.state["control_15_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.6</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZBEGNUVANJE_STRES}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izbegnuvanje_stres}
                                                onChangeAction={(event) => this.handleChange('izbegnuvanje_stres', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_15_6', event)}
                                          controlInput={this.state["control_15_6"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">16</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ETIKETIRANJE}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">16.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRIMEROK_ETIKETA_1}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.etiketa}
                                                onChangeAction={(event) => this.handleChange('etiketa', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_16_1', event)}
                                          controlInput={this.state["control_16_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">16.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRAVILA_LOGO_ZNAK}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.logo_znak_opis}
                                                onChangeAction={(event) => this.handleChange('logo_znak_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_16_2', event)}
                                          controlInput={this.state["control_16_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">17</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_SLEDLIVOST}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">17.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_AZURIRANI_ZAPISI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.azurirani_zapisi}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('azurirani_zapisi', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_DNEVNIK_STOCARSTVO}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.dnevnik}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('dnevnik', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_KOL_DEN}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.zapisi_kolicini_den}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('zapisi_kolicini_den', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_FAKTURI_STOCARSTVO}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.fakturi}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('fakturi', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_DNEVNIK_PAKUVANJE}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.dnevnik_pakuvanje}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('dnevnik_pakuvanje', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_EVIDENCIJA_PRODAZBA}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.evidencija_prodazba}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('evidencija_prodazba', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_DRUGI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={" "}

                                                multilineInput={true}
                                                valueInput={this.state.drugi}
                                                onChangeAction={(event) => this.handleChange('drugi', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_17_1', event)}
                                          controlInput={this.state["control_17_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">17.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_ZALBI_POPLAKI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.zapisi_zalbi_poplaki}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                onChangeAction={(event) => this.handleChange('zapisi_zalbi_poplaki', event)}/>
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput
                                labelInput={ALL.POU_ZAPISI_ZALBI_POPLAKI_NE + "\n" + ALL.POU_ZAPISI_ZALBI_POPLAKI_DA}
                                size={12}
                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.primeni_zalbi}
                                           labeli={[ALL.POU_PRIMENI_ZALBI, ALL.POU_PREVZEMENI_KOREKTIVNI_AKT]}
                                           values={["zalba", "korektivni_aktivnosti"]}
                                           types={["text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"primeni_zalbi"}
                                           size={12}
                                           cellWidth={12}
                                           template={primeniZalbiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_17_2', event)}
                                          controlInput={this.state["control_17_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">17.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SLEDLIVOST}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sledlivost}
                                                onChangeAction={(event) => this.handleChange('sledlivost', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={hasRoleControlAdmin}
                                          onChangeAction={(event) => this.handleChange('control_17_3', event)}
                                          controlInput={this.state["control_17_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>

                <Typography variant="h5">{ALL.POU_ZADOLZITELNI_ANEKSI}</Typography>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container className={classes.orangeColoredItemGrid}>*/}
                    {/*        <CustomLabeledInput labelInput={ALL.POU_BROJ}*/}
                    {/*                            size={12}*/}
                    {/*                            firstElementSize={12}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_1}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_1}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput labelInput="1"*/}
                    {/*                            size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_4}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_4}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput labelInput="2"*/}
                    {/*                            size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_5}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_5}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_6}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_6}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    {/*<Grid container xs={1}>*/}
                    {/*    <Grid container>*/}
                    {/*        <CustomLabeledInput size={12}/>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_7}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_7}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <Typography variant="h5">{ALL.POU_DRUGI_ANEKSI}</Typography>
                <Grid container className={classes.container}>
                    <GridTable obj={this.state.aneksi}
                               labeli={[ALL.POU_BROJ, ALL.POU_ANEKS]}
                               values={["broj", "aneks"]}
                               types={["text", "upload"]}
                               disabled={[!hasRoleClient, !hasRoleClient]}
                               disabledButtons={!hasRoleClient}
                               ddData={[null, null]}
                               uploadFile={(name) => this.props.uploadFile(name)}
                               columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                               valueName={"aneksi"}
                               size={11}
                               template={aneksiTemplate}
                               onChangeAction={(name, value) => this.handleObj(name, value)}
                    />
                </Grid>
                <br/>
                <Typography variant="h5">{ALL.POU_IZJAVA_KLIENT}</Typography>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_SOGLASNOST}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_1}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <br/>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                            <Grid item xs={12}><Typography variant="h5">18</Typography></Grid>
                        </Grid>
                        <Grid container xs={11} className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput
                                labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI_OPIS}
                                size={12}
                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid style={{width: this.calculateHeaderSize(7, 4.6)}}
                                      className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"} variant="h6"> {ALL.POU_POP_FARMA} </Typography>
                                </Grid>
                                <Grid style={{width: this.calculateHeaderSize(7, 2.4)}}
                                      className={classes.blueColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                </Grid>
                                <GridTable obj={this.state.nesoodvetnosti_farma}
                                           labeli={[ALL.POU_NESOODVETNOST, ALL.POU_PREDLOZENA_KOREKTIVNA_AKTIVNOST,
                                               ALL.POU_ROK_IMPLEMENTACIJA,
                                               ALL.POU_NESOODVETNOSTI_IDENTIFIKUVANI, ALL.POU_ROK_ADEK,
                                               ALL.POU_KOREKT_AKT_ADEK, ALL.POU_SERTIF_POSLE_KOREK]}
                                           controlIndexes={[]}
                                           colWidth={[1.9, 1.9, 0.8, 0.6, 0.6, 0.6, 0.6]}
                                           values={["nesoodvetnost", "korektivna_aktivnost", "rok_implementacija",
                                               "tocno_identifikuvani", "adekvatni", "adekvaten_rok", "sertifikat_posle_korekcija"]}
                                           types={["textarea", "textarea", "textarea", "dropdown", "dropdown", "dropdown", "dropdown"]}
                                           ddData={[null, null, null, yesnomaybe, yesnomaybe, yesnomaybe, yesnomaybe]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"nesoodvetnosti_farma"}
                                           size={12}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                           template={nesoodvetnostiFarmaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>

                    <Grid container xs={12}>
                        <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                            <Grid item xs={12}><Typography variant="h5">19</Typography></Grid>
                        </Grid>
                        <Grid container xs={11} justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_DOP_NESOODVETNOSTI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>

                                <Grid style={{width: this.calculateHeaderSize(7, 2.4)}}
                                      className={classes.blueColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                </Grid>
                                <Grid style={{width: this.calculateHeaderSize(7, 2.8)}}
                                      className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"} variant="h6"> {ALL.POU_POP_FARMA} </Typography>
                                </Grid>
                                <Grid style={{width: this.calculateHeaderSize(7, 1.8)}}
                                      className={classes.blueColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                </Grid>
                                <GridTable obj={this.state.dopolnitelni_nesoodvetnosti}
                                           labeli={[ALL.POU_BROJ_POU, ALL.POU_NESOODVETNOST, ALL.POU_KOREKT_AKT_DEF,
                                               ALL.POU_ROK, ALL.POU_KOREKT_AKT_ADEK,
                                               ALL.POU_ROK_ADEK, ALL.POU_SERTIF_POSLE_KOREK]}
                                           controlIndexes={[]}
                                           colWidth={[0.5, 1.9, 1.9, 0.9, 0.6, 0.6, 0.6]}
                                           values={["broj", "nesoodvetnosti", "korektivni_aktivnosti_definicija", "rok",
                                               "adekvateni_korektivni_aktivnosti", "adekvaten_rok", "sertifikat_posle_korekcija"]}
                                           types={["textarea", "textarea", "textarea", "textarea", "dropdown", "dropdown", "dropdown"]}
                                           ddData={[null, null, null, null, yesnomaybe, yesnomaybe, yesnomaybe]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"dopolnitelni_nesoodvetnosti"}
                                           size={12}
                                           disabled={[!hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleClient, !hasRoleClient, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                           template={dopolnitelniNesoodvetnostiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>

                    <Grid container xs={12}>
                        <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                            <Grid item xs={12}><Typography variant="h5">20</Typography></Grid>
                        </Grid>
                        <Grid container xs={11} justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ZAKLUCOK_KONTROLOR}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput size={12}
                                                inputType={"checkbox"}
                                                vertical={true}
                                                fullWidthCheckbox={true}
                                                valueInput={this.state.zaklucok}
                                                options={zakluchok}
                                                disabled={!hasRoleControlAdmin}
                                                onChangeAction={(event) => this.multiselectHandleChange('zaklucok', event)}/>
                        </Grid>
                    </Grid>
                </Grid>

                <br/>
                <br/>
                <br/>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText}
                                    variant="h5">{ALL.POU_DATA_POTPIS_F_K}</Typography>
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText}
                                    variant="h5">{ALL.POU_DATA_POTPIS_KONT}</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <CustomLabeledInput inputType={"text"}
                                            disabled={!hasRoleClient}
                                            size={12}
                                            placeholderInput={" "}
                                            valueInput={this.state.data_farma}
                                            onChangeAction={(event) => this.handleChange('data_farma', event)}
                        />
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <CustomLabeledInput inputType={"text"}
                                            size={12}
                                            disabled={!hasRoleControlAdmin}
                                            placeholderInput={" "}
                                            valueInput={this.state.data_kontrolor}
                                            onChangeAction={(event) => this.handleChange('data_kontrolor', event)}
                        />
                    </Grid>
                </Grid>
                <div style={{height: "80px"}}/>
                {/*{this.props.editMode &&*/
                }
                {/*< Button style={globalStyles.neutralButton} variant="contained" size={"medium"}*/
                }
                {/*         aria-label="update"*/
                }
                {/*         onClick={() => this.props.onSubmit(this.state)}>*/
                }
                {/*    Зачувај ПОУ сточарство*/
                }
                {/*    <EditIcon/>*/
                }
                {/*</Button>*/
                }
                {/*}*/
                }
                {
                    (createMode || editMode) && !canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.requiredFieldsMessage()}>
                        {createMode ? "Креирај ПОУ сточарство" : "Зачувај ПОУ сточарство"}
                    </Button>
                }
                {
                    (createMode || editMode) && canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.props.onSubmit(this.state)}>
                        {createMode ? "Креирај ПОУ сточарство" : "Зачувај ПОУ сточарство"}
                    </Button>
                }
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        )
            ;
    }
}


PouStocarstvo.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    }
;

PouStocarstvo = reduxForm(
    {
        form: 'PouStocarstvo',
        enableReinitialize: true,
        hasRole: true,
    }
)(PouStocarstvo)

export default withStyles(globalStyles)(PouStocarstvo);