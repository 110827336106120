export const ddLookupTypesData =
    [
        {
            id: "document_status", ime: "статус"
        },
        {
            id: "kultura", ime: "култура"
        },
        {
            id: "praven_status", ime: "правен статус"
        },
        {
            id: "samoniknat_vid", ime: "самоникнат вид"
        },
        {
            id: "surovina", ime: "суровина"
        },
        {
            id: "tip_kopija", ime: "тип на копија"
        },
        {
            id: "trgovija", ime: "трговија"
        },
        {
            id: "operacija", ime: "операција"
        },
        {
            id: "zivotno", ime: "животно"
        }
    ]