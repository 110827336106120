import React from 'react';
import propTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Notification from '../../../common/Notification';
import Button from '@material-ui/core/Button';
import ConfirmDelete from '../../../common/ConfirmDelete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import format from 'string-format';
import PageBase from '../../PageBase';
import globalStyles from "../../styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import {
    deletePouStocarstvoRequest,
    duplicatePouStocarstvoRequest,
    fetchPouStocarstvoList
} from "../../../redux/pouStocarstvo/fetch";
import {getPouNum, getPouStocarstvo, getPouStocarstvoList} from "../../../redux/pouStocarstvo/reducer";
import {Fab, TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18
    },
    body: {
        fontSize: 16,
    },

}))(TableCell);

const CustomTableSmallCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18,
        width: 20
    },
    body: {
        fontSize: 16,
        width: 20
    },

}))(TableCell);

export class PouStocarstvoList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteRecord: false,
            id: '',
            pgNo: 1,
            pageSize: 10,
            notify: false,
            message: '',
            error: false,
            yearFilter: null,
            years: [],
            imeFarmaFilter: ''
        }
        this.deletePouStocarstvo = this.deletePouStocarstvo.bind(this);
        this.deleteAfterConfirmation = this.deleteAfterConfirmation.bind(this);
        this.editPouStocarstvo = this.editPouStocarstvo.bind(this);
        this.addPouStocarstvo = this.addPouStocarstvo.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.handleYearFilterChange = this.handleYearFilterChange.bind(this);
        this.handleImeFarmaFilterChange = this.handleImeFarmaFilterChange.bind(this);
    }

    handleYearFilterChange(year) {
        this.setState({yearFilter: year});
    }

    handleImeFarmaFilterChange(event) {
        this.setState({imeFarmaFilter: event.target.value});
    }

    sortTable = (column) => {
        const {pouStocarstvoList, sortDirection} = this.state;

        if (pouStocarstvoList === undefined || !pouStocarstvoList)
            return 0;
        // Determine the sort direction
        const direction = sortDirection === 'desc' ? -1 : 1;
        // Sort the array based on the selected column
        const sortedList = pouStocarstvoList.sort((a, b) => {
            if (column === 'createdAt' || column === 'header_datum_popol' || column === 'header_datum_kontrola') {
                // Sort by date for the 'createdAt' column
                const dateA = new Date(a[column].split('.').reverse().join('-'));
                const dateB = new Date(b[column].split('.').reverse().join('-'));

                if (dateA < dateB) return -direction;
                if (dateA > dateB) return direction;
            } else {
                // Sort alphabetically for other columns
                if (a[column] < b[column]) return -direction;
                if (a[column] > b[column]) return direction;
            }

            return 0;
        });

        this.setState({
            pouStocarstvoList: sortedList,
            sortBy: column,
            sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
        });
    };

    componentWillMount() {
        const {fetchPouStocarstvoList} = this.props;
        fetchPouStocarstvoList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.pouNum !== nextProps.pouNum || this.state.pouStocarstvoList !== nextProps.pouStocarstvoList) {
            this.setState({pouStocarstvoList: nextProps.pouStocarstvoList}, () => {
                this.sortTable('ime_farma');
                // Extract years and set state
                const years = nextProps.pouStocarstvoList.map(item => new Date(item.header_datum_popol).getFullYear())
                    .filter(year => !isNaN(year));
                const uniqueYears = [...new Set(years)];
                this.setState({years: uniqueYears});
            });
        }
    }

    deletePouStocarstvo(id) {
        this.setState({deleteRecord: true, id: id});
    }

    editPouStocarstvo(id) {
        this.props.history.push(`/PouStocarstvo/` + id);
    }

    duplicatePouStocarstvo(id) {
        this.props.duplicatePouStocarstvoRequest(id)
        this.showNotification("Успешно креиран дупликат");
        setTimeout(() => this.redirectToPouStocarstvoList(), 1000);
    }

    savePouStocarstvo(values) {
        /*  Create and call updatePouStocarstvo action */
        // let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)), true);
        // this.props.createPouStocarstvoRequest(newValues)
        // this.showNotification("Успешно креиран дупликат");
    }

    redirectToPouStocarstvoList() {
        window.location.reload()
    }


    deleteAfterConfirmation(deleteConfirmed) {
        this.setState({deleteRecord: false});
        if (deleteConfirmed) {
            /* let selectedid =  this.state.id
              Create and call deletePouStocarstvo action */
            const {deletePouStocarstvoRequest} = this.props
            deletePouStocarstvoRequest(this.state.id)
            this.showNotification("Успешно избришано!");
        }
        this.setState({id: ''});
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    addPouStocarstvo() {
        this.props.history.push(`/CreatePouStocarstvo`);
    }

    render() {
        const {classes, pouStocarstvoList} = this.props;
        const {notify, message, error, yearFilter, years, imeFarmaFilter} = this.state;

        const filteredList = pouStocarstvoList ? pouStocarstvoList.filter(item => {
            const yearMatches = yearFilter ? new Date(item.header_datum_popol).getFullYear() === yearFilter : true;
            const imeFarmaMatches = item.ime_farma && item.ime_farma.toLowerCase().includes(imeFarmaFilter.toLowerCase());
            return yearMatches && imeFarmaMatches;
        }) : [];

        return (
            <PageBase title="Листа ПОУ сточарство">
                <div className={classes.wrapper}>
                    <div className={classes.fabWrapper}>
                        <Fab
                            variant="extended"
                            color="primary"
                            aria-label="add" className={classes.button}
                            onClick={this.addPouStocarstvo}
                        >
                            <AddIcon/>
                            Додади ПОУ сточарство
                        </Fab>
                    </div>
                    <div className={classes.filterContainer}>
                        <TextField
                            label="Пребарај по име на фарма"
                            value={imeFarmaFilter}
                            onChange={this.handleImeFarmaFilterChange}
                            className={classes.button + ' ' + classes.searchTextField}
                        />
                        {years.map(year => (
                            <Button
                                key={year}
                                variant={year === yearFilter ? "contained" : "outlined"}
                                onClick={() => this.handleYearFilterChange(year)}
                                className={classes.button}
                            >
                                {year}
                            </Button>
                        ))}
                        <Button
                            variant={yearFilter === null ? "contained" : "outlined"}
                            onClick={() => this.handleYearFilterChange(null)}
                            className={classes.button}
                        >
                            Сите
                        </Button>
                    </div>
                </div>
                <Table>
                    <TableHead>
                        {pouStocarstvoList &&
                            <TableRow>
                                <CustomTableCell onClick={() => this.sortTable('ime_farma')}>Име на
                                    фармата</CustomTableCell>
                                <CustomTableCell onClick={() => this.sortTable('header_datum_popol')}>Датум на
                                    пополнување</CustomTableCell>
                                <CustomTableCell
                                    onClick={() => this.sortTable('sopstvenik')}>Сопственик</CustomTableCell>
                                <CustomTableCell onClick={() => this.sortTable('header_datum_kontrola')}>Датум на
                                    контрола</CustomTableCell>
                                <CustomTableCell>Дупликат</CustomTableCell>
                                <CustomTableSmallCell>Измени</CustomTableSmallCell>
                                <CustomTableSmallCell>Избриши</CustomTableSmallCell>
                            </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {pouStocarstvoList && filteredList && filteredList.map(PouStocarstvo => {
                            return (
                                <TableRow key={PouStocarstvo.Broj} className={classes.row}>
                                    <CustomTableCell>{PouStocarstvo.ime_farma}</CustomTableCell>
                                    <CustomTableCell>{PouStocarstvo.header_datum_popol}</CustomTableCell>
                                    <CustomTableCell>{PouStocarstvo.sopstvenik}</CustomTableCell>
                                    <CustomTableCell>{PouStocarstvo.header_datum_kontrola}</CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="duplicate"
                                                onClick={() => this.duplicatePouStocarstvo(PouStocarstvo.id)}>
                                            <FileCopyIcon/>
                                        </Button>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="edit"
                                                onClick={() => this.editPouStocarstvo(PouStocarstvo.id)}>
                                            <EditIcon/>
                                        </Button>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                aria-label="delete"
                                                onClick={() => this.deletePouStocarstvo(PouStocarstvo.id)}>
                                            <DeleteIcon/>
                                        </Button>
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <ConfirmDelete resourceHeader="Избриши ПОУ?"
                               resourceOpis={format("Дали сте сигурни дека сакате да го ибришете ПОУ-то'{}'?", this.state.id)}
                               onModalClose={this.deleteAfterConfirmation}
                               openDeleteDialog={this.state.deleteRecord}/>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </PageBase>
        );
    }
}

PouStocarstvoList.propTypes = {
    classes: propTypes.object.isRequired,
};

const mapStateToProps = state => ({
    pouStocarstvoList: getPouStocarstvoList(state),
    pouStocarstvo: getPouStocarstvo(state),
    pouNum: getPouNum(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPouStocarstvoList,
        deletePouStocarstvoRequest,
        duplicatePouStocarstvoRequest
    }, dispatch)
}

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(PouStocarstvoList)
)