export const zivotniData =
    [
        {
            id: "1", ime: "овци"
        },
        {
            id: "2", ime: "кози"
        },
        {
            id: "3", ime: "говеда"
        },
        {
            id: "4", ime: "свињи"
        },
        {
            id: "5", ime: "кокошки"
        },
        {
            id: "6", ime: "гуски"
        },
        {
            id: "7", ime: "патки"
        },
        {
            id: "8", ime: "мисирки"
        },
        {
            id: "9", ime: "друга живина"
        },
    ]