export const trgovijaLookup =
    [
        {
            id: "1", ime: "Извоз за ЕУ"
        },
        {
            id: "2", ime: "Извоз за други земји"
        },
        {
            id: "3", ime: "Увоз од ЕУ"
        },
        {
            id: "4", ime: "Увоз од други земји"
        },
        {
            id: "5", ime: "Трговија со домашни производи"
        }
    ]