export const opstiniData =
    [
        {id: "1", ime: "Арачиново"},
        {id: "2", ime: "Берово"},
        {id: "3", ime: "Битола"},
        {id: "4", ime: "Богданци"},
        {id: "5", ime: "Боговиње"},
        {id: "6", ime: "Босилово"},
        {id: "7", ime: "Брвеница"},
        {id: "8", ime: "Валандово"},
        {id: "9", ime: "Василево"},
        {id: "10", ime: "Вевчани"},
        {id: "11", ime: "Велес"},
        {id: "12", ime: "Виница"},
        {id: "13", ime: "Вранештица"},
        {id: "14", ime: "Врапчиште"},
        {id: "15", ime: "Гевгелија"},
        {id: "16", ime: "Гостивар"},
        {id: "17", ime: "Градско"},
        {id: "18", ime: "Дебар"},
        {id: "19", ime: "Дебарца"},
        {id: "20", ime: "Делчево"},
        {id: "21", ime: "Демир Капија"},
        {id: "22", ime: "Демир Хисар"},
        {id: "23", ime: "Дојран"},
        {id: "24", ime: "Долнени"},
        {id: "25", ime: "Другово"},
        {id: "26", ime: "Желино"},
        {id: "27", ime: "Зајас"},
        {id: "28", ime: "Зелениково"},
        {id: "29", ime: "Зрновци"},
        {id: "30", ime: "Илинден"},
        {id: "31", ime: "Јегуновце"},
        {id: "32", ime: "Кавадарци "},
        {id: "33", ime: "Карбинци"},
        {id: "34", ime: "Кичево"},
        {id: "35", ime: "Конче"},
        {id: "36", ime: "Кочани"},
        {id: "37", ime: "Кратово"},
        {id: "38", ime: "Крива Паланка"},
        {id: "39", ime: "Кривогаштани"},
        {id: "40", ime: "Крушево"},
        {id: "41", ime: "Куманово"},
        {id: "42", ime: "Липково"},
        {id: "43", ime: "Лозово"},
        {id: "44", ime: "Маврово и Ростуше"},
        {id: "45", ime: "Македонски Брод"},
        {id: "46", ime: "Македонска Каменица"},
        {id: "47", ime: "Могила"},
        {id: "48", ime: "Неготино"},
        {id: "49", ime: "Новаци"},
        {id: "50", ime: "Ново Село"},
        {id: "51", ime: "Осломеј"},
        {id: "52", ime: "Охрид"},
        {id: "53", ime: "Петровец"},
        {id: "54", ime: "Пехчево"},
        {id: "55", ime: "Пласница"},
        {id: "56", ime: "Прилеп"},
        {id: "57", ime: "Пробиштип"},
        {id: "58", ime: "Радовиш"},
        {id: "59", ime: "Ранковце"},
        {id: "60", ime: "Ресен"},
        {id: "61", ime: "Росоман"},
        {id: "62", ime: "Старо Нагоричане"},
        {id: "63", ime: "Свети Николе"},
        {id: "64", ime: "Сопиште"},
        {id: "65", ime: "Струга"},
        {id: "66", ime: "Струмица"},
        {id: "67", ime: "Студеничани"},
        {id: "68", ime: "Теарце"},
        {id: "69", ime: "Тетово"},
        {id: "70", ime: "Центар Жупа"},
        {id: "71", ime: "Чашка"},
        {id: "72", ime: "Чешиново и Облешево"},
        {id: "73", ime: "Чучер Сандево"},
        {id: "74", ime: "Штип"},
        {id: "75", ime: "Аеродром"},
        {id: "76", ime: "Бутел"},
        {id: "77", ime: "Гази Баба"},
        {id: "78", ime: "Ѓорче Петров"},
        {id: "79", ime: "Карпош"},
        {id: "80", ime: "Кисела Вода"},
        {id: "81", ime: "Сарај"},
        {id: "82", ime: "Центар"},
        {id: "83", ime: "Чаир"},
        {id: "84", ime: "Шуто Оризари"},
        {id: "85", ime: "Град Скопје"}
    ]