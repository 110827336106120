import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import * as ALL from '../../common/staticLabels'
import {Grid, Input, Typography} from '@material-ui/core';
import GridTable from "../../components/gridTable";
import CustomLabeledInput from "../../components/customLabeledInput";
import {kulturaData} from "../../mockData/ddKulturaData";
import {opstiniData} from "../../mockData/ddOpstiniData";
import {pravenStatus} from "../../mockData/ddPravenStatusData";
import {yesno} from "../../mockData/ddDaNeData";
import {trgovijaLookup} from "../../mockData/trgovijaLookup";
import {tipKopija} from "../../mockData/tipKopijaLookup";
import {vidOperacija} from "../../mockData/vidOperacijaLookup";
import CustomLabelCheckboxInput from "../../components/customLabelCheckboxInput";
import {zivotniData} from "../../mockData/ddZivotniData";
import {samoniknatiData} from "../../mockData/ddSamoniknatiData";
import {prerabotkaData} from "../../mockData/ddPrerabotkaData";
import {izjava1Lookup} from "../../mockData/izjava1Lookup";
import {izjava2Lookup} from "../../mockData/izjava2Lookup";
import {izjava3Lookup} from "../../mockData/izjava3Lookup";
import {izjava5Lookup} from "../../mockData/izjava5Lookup";
import {izjava4Lookup} from "../../mockData/izjava4Lookup";
import {prazenChecbox} from "../../mockData/prazenCheckboxLookup";
import globalStyles from "../styles";
import Button from "@material-ui/core/Button";

class Application extends Component {

    constructor(props) {
        super(props);
        this.addKultura = this.addKultura.bind(this)
        this.updateObject = this.updateObject.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.vidOperacijaFlag = this.vidOperacijaFlag.bind(this)
        this.hasRole = this.hasRole.bind(this)
        this.state = {navDrawerOpen: false, ...this.props.application};
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.application.id && this.props.application.id !== nextProps.application.id) {
            window.location.reload();
        }
    }

    addKultura() {
        this.setState({
            navDrawerOpen: !this.state.navDrawerOpen
        });
    }

    handleAdd(type, template) {
        let list = this.state[type]
        list.push(template)
        this.setState({[type]: list});
    }

    handleRemove(type) {
        let list = this.state[type]
        if (list.length > 1) {
            list.pop()
        }
        this.setState({[type]: list});
    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value})
    };

    handleDDChange(name, event, value) {
        event.persist()
        this.setState({[name]: value})
    };

    multiselectHandleChange(name, event) {
        event.persist()
        const val = event.target.value
        const arr = this.state[name]
        const index = this.state[name].indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.push(val)
        }
        this.setState({[name]: arr})
    };

    handleObj(name, value) {
        this.setState({[name]: value})
    };

    updateObject(obj, keys, value) {
        let key = keys.shift();
        if (keys.length > 0) {

            let tmp = this.updateObject(obj[key], keys, value);
            return {...obj, [key]: tmp};
        } else {
            return {...obj, [key]: value};
        }
    }

    vidOperacijaFlag(vid) {
        const {vid_operacija} = this.state
        return vid_operacija ? vid_operacija.includes(vid) : false
    }

    hasRole(role) {
        if (this.props.user && this.props.user.user.authorities) {
            return this.props.user.user.authorities.some(u => u.authority === role)
        }
        return false;
    }

    render() {
        const {classes, createMode, editMode} = this.props;
        const hasRoleControlAdmin = this.hasRole("CONTROL_ADMIN");

        const glavnaKulturaTemplate = {
            "kultura": "",
            "hektari": ""
        }
        const kulturaRotacijaTemplate = {
            "kultura": "",
            "hektari": ""
        }
        const prerabotkaTemplate = {
            "surovina": "",
            "finalen_proizvod": ""
        }
        const samoniknatiVidoviTemplate = {
            "samoniknat_vid": "",
            "kolicini": ""
        }
        const zivotniTemplate = {
            "vid": "",
            "broj": ""
        }

        return (
            (this.state.id !== undefined || this.props.createMode) &&
            <div>
                <Grid container style={{border: "1px solid black"}} xs={12}>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center',
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_PROCERT}</Typography></Grid>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center',
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_HEADER_BROJ_DOKUMENT}</Typography></Grid>
                    <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={4}>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_PREV_VERSION}</Typography></Grid>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_VERSION}</Typography></Grid>
                    </Grid>
                    <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={4}>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_IZGOTVIL}</Typography></Grid>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_PROVERIL_POTVRDIL}</Typography></Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container spacing={16} className={classes.container}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{ALL.TITLE}</Typography>
                    </Grid>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">1</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.IME_KOMPANIJA}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.ime_kompanija}
                                                onChangeAction={(event) => this.handleChange('ime_kompanija', event)}/>

                            <CustomLabeledInput labelInput={ALL.KONTAKT_LICE}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.kontakt_lice}
                                                onChangeAction={(event) => this.handleChange('kontakt_lice', event)}/>

                            <CustomLabeledInput labelInput={ALL.ULICABROJ}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.ulica_broj}
                                                onChangeAction={(event) => this.handleChange('ulica_broj', event)}/>

                            <CustomLabeledInput labelInput={ALL.OPSTINA}
                                                inputType={"dropdown"}
                                                options={opstiniData}
                                                valueInput={this.state.opstina}
                                                onChangeAction={(event, newValue) => this.handleDDChange('opstina', event, newValue)}/>

                            <CustomLabeledInput labelInput={ALL.MESTO}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.mesto}
                                                onChangeAction={(event) => this.handleChange('mesto', event)}/>

                            <CustomLabeledInput labelInput={ALL.TELEFON}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.telefon}
                                                onChangeAction={(event) => this.handleChange('telefon', event)}/>

                            <CustomLabeledInput labelInput={ALL.PRAVEN_STATUS}
                                                inputType={"radio"}
                                                valueInput={this.state.praven_status}
                                                options={pravenStatus}
                                                onChangeAction={(event) => this.handleChange('praven_status', event)}/>

                            <CustomLabeledInput labelInput={ALL.EMAIL}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.email}
                                                onChangeAction={(event) => this.handleChange('email', event)}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.VID_OPERACIJA}</Typography>
                        </Grid>
                        <Grid container spacing={2} justify='space-around'>
                            <CustomLabeledInput
                                customClassName={classes.paddedLeftContainer}
                                inputType={"checkbox"}
                                valueInput={this.state.vid_operacija}
                                options={vidOperacija}
                                size={12}
                                onChangeAction={(event) => this.multiselectHandleChange('vid_operacija', event)}/>


                            <CustomLabeledInput labelInput={ALL.VID_OPERACIJA_DRUGO}
                                                inputType={"text"}
                                                multilineInput={true}
                                                placeholderInput={"друго"}
                                                valueInput={this.state.vid_operacija_drugo}
                                                onChangeAction={(event) => this.handleChange('vid_operacija_drugo', event)}/>
                            <Grid item xs={6} style={{border: "1px solid black"}}/>
                        </Grid>
                    </Grid>
                </Grid>
                {this.vidOperacijaFlag("Растително производство") &&
                <Grid container spacing={16} className={classes.container}
                >
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.RASTITELNO_PROIZVODSTVO}</Typography>
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.LOKACIJA_FARMA}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.rst_lokacija_farma}
                                                onChangeAction={(event) => this.handleChange('rst_lokacija_farma', event)}/>

                            <CustomLabeledInput labelInput={ALL.VKUPNO_HEKTARI}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.rst_vk_hektari}
                                                onChangeAction={(event) => this.handleChange('rst_vk_hektari', event)}/>

                            <GridTable obj={this.state.glavna_kultura}
                                       labeli={[ALL.GLAVNA_KULTURA, ALL.HEKTARI]}
                                       values={["kultura", "hektari"]}
                                       valueName={"glavna_kultura"}
                                       types={["dropdown", "text"]}
                                       ddData={[kulturaData, null]}
                                       template={glavnaKulturaTemplate}
                                       onChangeAction={(name, value) => this.handleObj(name, value)}
                            />

                            {/*<GridTable obj={this.state.kultura_rotacija}*/}
                            {/*           labeli={[ALL.KULTURA_ROTACIJA, ALL.HEKTARI]}*/}
                            {/*           values={["kultura", "hektari"]}*/}
                            {/*           valueName={"kultura_rotacija"}*/}
                            {/*           types={["dropdown", "text"]}*/}
                            {/*           ddData={[kulturaData, null]}*/}
                            {/*           template={kulturaRotacijaTemplate)}*/}
                            {/*           onChangeAction={(name, value) => this.handleObj(name, value)}*/}
                            {/*/>*/}
                        </Grid>
                    </Grid>
                </Grid>
                }
                {this.vidOperacijaFlag("Преработка") &&
                <Grid container spacing={16} className={classes.container} hidden={true}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.PRERABOTKA}</Typography>
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid container xs={6}>
                                <CustomLabeledInput labelInput={ALL.VID_PRERABOTKA}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.vid_prerabotka}
                                                    onChangeAction={(event) => this.handleChange('vid_prerabotka', event)}/>
                                <CustomLabeledInput labelInput={ALL.LOKACIJA_PRERABOTUVACKI_KAPACITET}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.lokacija_prerabotuvackiot_obj}
                                                    onChangeAction={(event) => this.handleChange('lokacija_prerabotuvackiot_obj', event)}/>
                                <CustomLabeledInput labelInput={ALL.BROJ_PRERABOTENI_PROIZVODI}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.broj_preraboteni_proizvodi}
                                                    onChangeAction={(event) => this.handleChange('broj_preraboteni_proizvodi', event)}/>
                            </Grid>
                            <Grid container xs={6}>
                                <GridTable obj={this.state.lista_prerabotka}
                                           labeli={[ALL.SUROVINA, ALL.FINALEN_PROIZVOD]}
                                           values={["surovina", "finalen_proizvod"]}
                                           valueName={"lista_prerabotka"}
                                           types={["dropdown", "textarea"]}
                                           size={12}
                                           ddData={[prerabotkaData, null]}
                                           template={prerabotkaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />

                            </Grid>
                            <Grid item xs={6} style={{border: "1px solid black"}}/>

                        </Grid>
                    </Grid>
                </Grid>
                }
                {this.vidOperacijaFlag("Собирање самоникнати видови") &&
                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.SAMONIKNATI_VIDOVI_TITLE}</Typography>
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid container xs={6}>


                                <CustomLabeledInput labelInput={ALL.REGION_SOBIRANJE}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.region_sobiranje}
                                                    onChangeAction={(event) => this.handleChange('region_sobiranje', event)}/>

                                <CustomLabeledInput labelInput={ALL.POVRSINA_SOBIRANJE}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.povrsina_sobiranje}
                                                    onChangeAction={(event) => this.handleChange('povrsina_sobiranje', event)}/>
                                <CustomLabeledInput labelInput={ALL.BROJ_SOBIRACI}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.broj_sobiraci}
                                                    onChangeAction={(event) => this.handleChange('broj_sobiraci', event)}/>

                                <CustomLabeledInput labelInput={ALL.BROJ_OTKUPNI_PUNKTOVI}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.broj_otkupniPunktovi}
                                                    onChangeAction={(event) => this.handleChange('broj_otkupniPunktovi', event)}/>
                            </Grid>
                            <Grid container xs={6}>
                                <GridTable obj={this.state.samoniknati_vidovi}
                                           labeli={[ALL.SAMONIKNATI_VIDOVI, ALL.KOLICINI_SERTIFICIRANJE]}
                                           values={["samoniknat_vid", "kolicini"]}
                                           valueName={"samoniknati_vidovi"}
                                           types={["dropdown", "text"]}
                                           size={12}
                                           ddData={[samoniknatiData, null]}
                                           template={samoniknatiVidoviTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                            <Grid item xs={6} style={{border: "1px solid black"}}/>
                        </Grid>
                    </Grid>
                </Grid>
                }
                {this.vidOperacijaFlag("Сточарско производство") &&
                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.STOCARSKO_PROIZVODSTVO}</Typography>
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid container xs={6}>
                                <CustomLabeledInput labelInput={ALL.LOKACIJA_FARMA}
                                                    inputType={"text"}
                                                    multilineInput={true}
                                                    size={12}
                                                    valueInput={this.state.sto_lokacija_farma}
                                                    onChangeAction={(event) => this.handleChange('sto_lokacija_farma', event)}/>
                            </Grid>
                            <Grid container xs={6}>
                                <GridTable obj={this.state.zivotni}
                                           labeli={[ALL.VID_ZIVOTNI, ALL.BROJ_ZIVOTNI]}
                                           values={["vid", "broj"]}
                                           valueName={"zivotni"}
                                           size={12}
                                           types={["dropdown", "textarea"]}
                                           ddData={[zivotniData, null]}
                                           template={zivotniTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
                {this.vidOperacijaFlag("Пчеларство") &&
                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.PCELARSTVO}</Typography>
                        </Grid>
                        <Grid container justify='space-around'>

                            <CustomLabeledInput labelInput={ALL.LOKACIJA_PCELARNIK + " 1"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.lokacija_pcelarnik1}
                                                onChangeAction={(event) => this.handleChange('lokacija_pcelarnik1', event)}/>
                            <CustomLabeledInput labelInput={ALL.BROJ_PCELNI_SEMEJSTVA + " 1"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.broj_pcelniSemejstva1}
                                                onChangeAction={(event) => this.handleChange('broj_pcelniSemejstva1', event)}/>

                            <CustomLabeledInput labelInput={ALL.LOKACIJA_PCELARNIK + " 2"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.lokacija_pcelarnik2}
                                                onChangeAction={(event) => this.handleChange('lokacija_pcelarnik2', event)}/>
                            <CustomLabeledInput labelInput={ALL.BROJ_PCELNI_SEMEJSTVA + " 2"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.broj_pcelniSemejstva2}
                                                onChangeAction={(event) => this.handleChange('broj_pcelniSemejstva2', event)}/>

                            <CustomLabeledInput labelInput={ALL.LOKACIJA_PCELARNIK + " 3"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.lokacija_pcelarnik3}
                                                onChangeAction={(event) => this.handleChange('lokacija_pcelarnik3', event)}/>
                            <CustomLabeledInput labelInput={ALL.BROJ_PCELNI_SEMEJSTVA + " 3"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.broj_pcelniSemejstva3}
                                                onChangeAction={(event) => this.handleChange('broj_pcelniSemejstva3', event)}/>

                            <CustomLabeledInput labelInput={ALL.LOKACIJA_PCELARNIK + " 4"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.lokacija_pcelarnik4}
                                                onChangeAction={(event) => this.handleChange('lokacija_pcelarnik4', event)}/>
                            <CustomLabeledInput labelInput={ALL.BROJ_PCELNI_SEMEJSTVA + " 4"}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.broj_pcelniSemejstva4}
                                                onChangeAction={(event) => this.handleChange('broj_pcelniSemejstva4', event)}/>

                            <CustomLabeledInput labelInput={ALL.MIGRATORNI_LOKACII}
                                                inputType={"radio"}
                                                valueInput={this.state.migratorni_lokacii}
                                                options={yesno}
                                                onChangeAction={(event) => this.handleChange('migratorni_lokacii', event)}/>
                            <Grid item xs={6} style={{border: "1px solid black"}}/>
                        </Grid>
                    </Grid>
                </Grid>
                }
                {this.vidOperacijaFlag("Трговија") &&
                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.6</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.TRGOVIJA}</Typography>
                        </Grid>
                        <Grid container justify='space-around'>

                            <Grid container justify='space-around'>
                                <CustomLabeledInput
                                    customClassName={classes.paddedLeftContainer}
                                    inputType={"checkbox"}
                                    valueInput={this.state.trgovija}
                                    options={trgovijaLookup}
                                    size={12}
                                    onChangeAction={(event) => this.multiselectHandleChange('trgovija', event)}
                                />
                                <Grid item xs={6} style={{border: "1px solid black"}}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.STANDARD_SERTIFICIRA}</Typography>
                        </Grid>
                        <Grid container spacing={2} justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.ZAKON_ZEMJODELSTVO_RM}
                                                size={12}
                                                inputType={"checkbox"}
                                                valueInput={this.state.zakon_zem_rm}
                                                options={prazenChecbox}
                                                onChangeAction={(event) => this.multiselectHandleChange('zakon_zem_rm', event)}/>


                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={16} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">4</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid xs={12} className={classes.coloredItemGrid}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.ISTORIJAT_SERTIFIKACIJA}</Typography>
                        </Grid>
                        <Grid container spacing={2} justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.TIP_KOPIJA}
                                                size={12}
                                                inputType={"checkbox"}
                                                valueInput={this.state.tip_kopija}
                                                options={tipKopija}
                                                onChangeAction={(event) => this.multiselectHandleChange('tip_kopija', event)}/>

                            <CustomLabeledInput labelInput={ALL.PREGOVARACI_VKLUCENI}
                                                size={12}
                                                firstElementSize={8}
                                                inputType={"radio"}
                                                valueInput={this.state.pregovaraci_vkluceni}
                                                options={yesno}
                                                onChangeAction={(event) => this.handleChange('pregovaraci_vkluceni', event)}/>

                            <CustomLabeledInput labelInput={ALL.PREGOVARACI_VKLUCENI_DESC}
                                                size={12}
                                                inputType={"text"}
                                                multilineInput={true}
                                                valueInput={this.state.pregovaraci_vkluceni_desc}
                                                options={yesno}
                                                disabled={this.state.pregovaraci_vkluceni !== "true"}
                                                onChangeAction={(event) => this.handleChange('pregovaraci_vkluceni_desc', event)}/>
                        </Grid>
                        <Grid item xs={12} style={{border: "1px solid black"}}>
                            <Typography className={classes.typographyLineHeight}
                                        variant="h5">{ALL.ISTORIJAT_SERTIFIKACIJA_TITLE}</Typography>
                        </Grid>
                        <Grid container spacing={2} justify='space-around'>
                            <CustomLabelCheckboxInput
                                labelCheck={ALL.IZJAVA_1}
                                valueCheck={this.state.izjava1}
                                changeActionCheck={(event) => this.multiselectHandleChange('izjava1', event)}
                                options={izjava1Lookup}
                            />

                            <CustomLabelCheckboxInput
                                labelDesc={ALL.IZJAVA_2_DESC}
                                labelCheck={ALL.IZJAVA_2_CHECK}
                                valueDesc={this.state.izjava2_desc}
                                valueCheck={this.state.izjava2_check}
                                changeActionDesc={(event) => this.handleChange('izjava2_desc', event)}
                                changeActionCheck={(event) => this.multiselectHandleChange('izjava2_check', event)}
                                options={izjava2Lookup}
                            />
                            <CustomLabelCheckboxInput
                                labelDesc={ALL.IZJAVA_3_DESC}
                                labelCheck={ALL.IZJAVA_3_CHECK}
                                valueDesc={this.state.izjava3_desc}
                                valueCheck={this.state.izjava3_check}
                                changeActionDesc={(event) => this.handleChange('izjava3_desc', event)}
                                changeActionCheck={(event) => this.multiselectHandleChange('izjava3_check', event)}
                                options={izjava3Lookup}
                            />
                            <CustomLabelCheckboxInput
                                labelDesc={ALL.IZJAVA_4_DESC}
                                labelCheck={ALL.IZJAVA_4_CHECK}
                                valueDesc={this.state.izjava4_desc}
                                valueCheck={this.state.izjava4_check}
                                changeActionDesc={(event) => this.handleChange('izjava4_desc', event)}
                                changeActionCheck={(event) => this.multiselectHandleChange('izjava4_check', event)}
                                options={izjava4Lookup}
                            />
                            <CustomLabelCheckboxInput
                                labelCheck={ALL.IZJAVA_5}
                                valueCheck={this.state.izjava5}
                                changeActionCheck={(event) => this.multiselectHandleChange('izjava5', event)}
                                options={izjava5Lookup}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <br/>
                <br/>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText} variant="h5">{ALL.DATA}</Typography>
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText}
                                    variant="h5">{ALL.PECAT_ILI_POTPIS}</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Input
                            className={classes.paddedFullWidthInput}
                            value={this.state.datum}
                            onChange={event => this.handleChange("datum", event)}
                            margin={"none"}

                        />
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Input
                            className={classes.paddedFullWidthInput}
                            onChange={event => this.handleChange("potpis", event)}
                            margin={"none"}
                        />
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <div hidden={!this.props.hasRole}>
                    <Grid container spacing={4} className={classes.container}>
                        <CustomLabeledInput labelInput={ALL.KONTROLA1}
                                            firstElementSize={10}
                                            inputType={"radio"}
                                            valueInput={this.state.kontrola1}
                                            options={yesno}
                                            size={12}
                                            disabled={!hasRoleControlAdmin}
                                            onChangeAction={(event) => this.handleChange('kontrola1', event)}/>
                        <CustomLabeledInput labelInput={ALL.KONTROLA2}
                                            firstElementSize={10}
                                            inputType={"radio"}
                                            valueInput={this.state.kontrola2}
                                            options={yesno}
                                            size={12}
                                            disabled={!hasRoleControlAdmin}
                                            onChangeAction={(event) => this.handleChange('kontrola2', event)}/>
                        <CustomLabeledInput labelInput={ALL.KONTROLA3}
                                            firstElementSize={10}
                                            inputType={"radio"}
                                            valueInput={this.state.kontrola3}
                                            options={yesno}
                                            size={12}
                                            disabled={!hasRoleControlAdmin}
                                            onChangeAction={(event) => this.handleChange('kontrola3', event)}/>
                        <CustomLabeledInput labelInput={ALL.KONTROLA4}
                                            firstElementSize={10}
                                            inputType={"radio"}
                                            valueInput={this.state.kontrola4}
                                            options={yesno}
                                            size={12}
                                            disabled={!hasRoleControlAdmin}
                                            onChangeAction={(event) => this.handleChange('kontrola4', event)}/>
                        <CustomLabeledInput labelInput={ALL.KONTROLA5}
                                            firstElementSize={10}
                                            inputType={"text"}
                                            multilineInput={true}
                                            valueInput={this.state.kontrola5_desc}
                                            size={12}
                                            disabled={!hasRoleControlAdmin}
                                            onChangeAction={(event) => this.handleChange('kontrola5_desc', event)}/>
                    </Grid>
                </div>
                <br/>
                <br/>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText} variant="h5">{ALL.DATA}</Typography>
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText} variant="h5">{ALL.PECAT_POTPIS}</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Input
                            disabled={!hasRoleControlAdmin}
                            className={classes.paddedFullWidthInput}
                            value={this.state.datum_kontrola}
                            onChange={event => this.handleChange("datum_kontrola", event)}
                            margin={"none"}
                        />
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Input
                            disabled={!hasRoleControlAdmin}
                            className={classes.paddedFullWidthInput}
                            onChange={event => this.handleChange("potpis_kontrola", event)}
                            margin={"none"}/>
                    </Grid>
                </Grid>
                {/*{editMode &&*/}
                {/*< Button*/}
                {/*    classes={{*/}
                {/*        root: classes.addFormButton, // class name, e.g. `classes-nesting-root-x`*/}
                {/*        label: classes.label, // class name, e.g. `classes-nesting-label-x`*/}
                {/*    }}*/}
                {/*    variant="contained" size={"large"}*/}
                {/*    aria-label="create">*/}
                {/*    Издади сертификат*/}
                {/*</Button>*/}
                {/*}*/}
                {/*{editMode &&*/}
                {/*< Button*/}
                {/*    classes={{*/}
                {/*        root: classes.addFormButton, // class name, e.g. `classes-nesting-root-x`*/}
                {/*        label: classes.label, // class name, e.g. `classes-nesting-label-x`*/}
                {/*    }}*/}
                {/*    variant="contained" size={"large"}*/}
                {/*    aria-label="create">*/}
                {/*    Генерирај профактура*/}
                {/*</Button>*/}
                {/*}*/}
                {/*{editMode &&*/}
                {/*< Button*/}
                {/*    classes={{*/}
                {/*        root: classes.addFormButton, // class name, e.g. `classes-nesting-root-x`*/}
                {/*        label: classes.label, // class name, e.g. `classes-nesting-label-x`*/}
                {/*    }}*/}
                {/*    variant="contained" size={"large"}*/}
                {/*    aria-label="create">*/}
                {/*    Генерирај понуда*/}
                {/*</Button>*/}
                {/*}*/}
                {(createMode || editMode) &&
                < Button
                    classes={{
                        root: classes.addFormButton, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    variant="contained" size={"large"}
                    aria-label="create"
                    onClick={() => this.props.onSubmit(this.state)}>
                    {createMode ? "Креирај Апликација" : "Зачувај Апликација"}
                </Button>
                }
            </div>

        );
    }
}


Application.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    }
;

Application = reduxForm(
    {
        form: 'Application',
        enableReinitialize: true,
        hasRole: true,
    }
)(Application)


export default withStyles(globalStyles)(Application)
