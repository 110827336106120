import {
    CREATE_APPLICATION,
    CREATE_NEW_APPLICATION,
    DELETE_APPLICATION,
    FETCH_ALL_APPLICATIONS,
    FETCH_APPLICATION,
    UPDATE_APPLICATION
} from "../actionTypes";


export const fetchApplicationsAction = (payload) => ({
    type: FETCH_ALL_APPLICATIONS,
    payload: {
        applications: payload,
    },
})

export const fetchApplicationByIdAction = (payload) => ({
    type: FETCH_APPLICATION,
    payload: {
        application: payload,
    },
})

export const createNewApplicationAction = (payload) => ({
    type: CREATE_NEW_APPLICATION,
    payload: {
        application: payload,
    },
})

export const createApplicationAction = (payload) => ({
    type: CREATE_APPLICATION,
    payload: {
        application: payload,
    },
})
export const updateApplicationAction = (payload) => ({
    type: UPDATE_APPLICATION,
    payload: {
        application: payload,
    },
})
export const deleteApplicationAction = () => ({
    type: DELETE_APPLICATION,
    payload: {
        application: null,
        appNum: 0
    },
})

