import {
    CREATE_NEW_SPEC_STOCARSTVO,
    CREATE_SPEC_STOCARSTVO,
    DELETE_SPEC_STOCARSTVO,
    FAILED_CREATE_SPEC_STOCARSTVO,
    FETCH_ALL_SPEC_STOCARSTVO,
    FETCH_SPEC_STOCARSTVO,
    UPDATE_SPEC_STOCARSTVO
} from "../actionTypes";

const initialState = {
    specStocarstvoList: [],
    specStocarstvo: null,
    error: false
};

export function specStocarstvoReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_SPEC_STOCARSTVO: {
            const specNum = action.payload.specStocarstvoList
            return {
                ...state,
                specStocarstvo: null,
                specStocarstvoList: specNum,
                specNum: specNum ? specNum.length : 0
            };
        }
        case FETCH_SPEC_STOCARSTVO: {
            return {
                ...state,
                specStocarstvo: action.payload
            };
        }
        case UPDATE_SPEC_STOCARSTVO: {
            return {
                ...state,
                specStocarstvo: action.payload
            };
        }
        case CREATE_NEW_SPEC_STOCARSTVO: {
            return {
                ...state,
                specStocarstvo: action.payload
            };
        }
        case CREATE_SPEC_STOCARSTVO: {
            return {
                ...state,
                specStocarstvo: action.payload
            };
        }
        case FAILED_CREATE_SPEC_STOCARSTVO: {
            return {
                ...state,
                specStocarstvo: action.payload,
                error: true
            };
        }
        case DELETE_SPEC_STOCARSTVO: {
            return {
                ...state,
                specStocarstvo: action.payload
            };
        }
        default:
            return state;
    }
}

export const getSpecStocarstvoList = state => state.specStocarstvo.specStocarstvoList;
export const getSpecNum = state => state.specStocarstvo.specNum;
export const getSpecStocarstvo = state => state.specStocarstvo.specStocarstvo;