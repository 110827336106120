import {
    CREATE_LOOKUP,
    CREATE_NEW_LOOKUP,
    DELETE_LOOKUP,
    FETCH_ALL_LOOKUPS,
    FETCH_LOOKUP,
    FETCH_LOOKUP_BY_TYPE,
    UPDATE_LOOKUP
} from "../actionTypes";


export const fetchLookupsAction = (payload) => ({
    type: FETCH_ALL_LOOKUPS,
    payload: {
        lookups: payload,
    },
})

export const fetchLookupByIdAction = (payload) => ({
    type: FETCH_LOOKUP,
    payload: {
        lookup: payload,
    },
})

export const fetchLookupByTypeAction = (payload) => ({
    type: FETCH_LOOKUP_BY_TYPE,
    payload: {
        lookup: payload,
    },
})

export const createNewLookupAction = (payload) => ({
    type: CREATE_NEW_LOOKUP,
    payload: {
        lookup: payload,
    },
})

export const createLookupAction = (payload) => ({
    type: CREATE_LOOKUP,
    payload: {
        lookup: payload,
    },
})
export const updateLookupAction = (payload) => ({
    type: UPDATE_LOOKUP,
    payload: {
        lookup: payload,
    },
})
export const deleteLookupAction = () => ({
    type: DELETE_LOOKUP,
    payload: {
        lookup: null,
        appNum: 0
    },
})

