import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import * as ALL from '../../../common/staticLabels'
import {Grid, Typography} from '@material-ui/core';
import globalStyles from "../../styles";
import GridTable from "../../../components/gridTable";
import CustomLabeledInput from "../../../components/customLabeledInput";
import {yesno} from "../../../mockData/ddDaNeData";
import ControlInput from "../../../components/controlInput";
import {korektivniAktivnostiData} from "../../../mockData/ddKorektivniAktivnostiData";
import {zamenaVosokData} from "../../../mockData/ddZamenaVosokData";
import {daNeDelumnoData} from "../../../mockData/ddDaNeDelumnoData";
import {izolacijaKoloniiLookup} from "../../../mockData/izolacijaKoloniiLookup";
import {prerabotuvacData} from "../../../mockData/ddPrerabotuvacData";
import {yesnomaybe} from "../../../mockData/ddDaNePraznoData";
import Notification from "../../../common/Notification";
import Button from "@material-ui/core/Button";
import {zakluchok} from "../../../mockData/zakluchokLookup";
import UploadInput from "../../../components/uploadInput";

class PouPcelarstvo extends Component {

    constructor(props) {
        super(props);
        this.addKultura = this.addKultura.bind(this)
        this.updateObject = this.updateObject.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.checkCanSave = this.checkCanSave.bind(this)
        this.requiredFieldsMessage = this.requiredFieldsMessage.bind(this)
        this.showNotification = this.showNotification.bind(this)
        this.uploadFileAndSetState = this.uploadFileAndSetState.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.hasRole = this.hasRole.bind(this)
        this.state = {
            navDrawerOpen: false, ...this.props.pouPcelarstvo, ...this.props.pouPcelarstvoControl,
            notify: false,
            message: '',
            error: false
        };
    }

    checkCanSave() {
        return (!!this.state.mobilen || !!this.state.telefon) && !!this.state.ime_farma && !!this.state.sopstvenik
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.pouPcelarstvo.id && this.props.pouPcelarstvo.id !== nextProps.pouPcelarstvo.id) ||
            (this.props.pouPcelarstvoControl && this.props.pouPcelarstvoControl.controlId && this.props.pouPcelarstvoControl.controlId !== nextProps.pouPcelarstvoControl.controlId)) {
            window.location.reload();
        }
    }

    addKultura() {
        this.setState({
            navDrawerOpen: !this.state.navDrawerOpen
        });
    }

    handleChange(name, event) {
        event.persist()
        this.handleObj(name, event.target.value)
    };

    handleObj(name, value) {
        let object = JSON.parse(JSON.stringify(value))
        this.setState({[name]: object})
    };

    updateObject(obj, keys, value) {
        let key = keys.shift();
        if (keys.length > 0) {

            let tmp = this.updateObject(obj[key], keys, value);
            return {...obj, [key]: tmp};
        } else {
            return {...obj, [key]: value};
        }
    }

    multiselectHandleChange(name, event) {
        event.persist()
        const val = event.target.value
        const arr = this.state[name]
        const index = this.state[name].indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.push(val)
        }
        this.setState({[name]: arr})
    };

    calculateHeaderSize(fraction, unit) {
        return 100 / fraction * unit + "%"
    }

    requiredFieldsMessage() {
        this.showNotification("Полињата во точка 1 се задолжителни");
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    uploadFileAndSetState(fileUploadId) {
        this.props.uploadFile(fileUploadId).then(res => {
            this.setState({[fileUploadId]: res})
        })
    }

    deleteFile(fileUploadId) {
        this.setState({[fileUploadId]: ""})
        this.showNotification("Успешно избришан фајл");
    }

    hasRole(role) {
        var userAuthorities = this.props.user && this.props.user.user && this.props.user.user.authorities;
        var modifierUserAuthorities = this.props.pouPcelarstvo && this.props.pouPcelarstvo.modifierUser && this.props.pouPcelarstvo.modifierUser.authorities;
        if (role === "CONTROL_ADMIN" && !this.props.pouPcelarstvoControl) {
            return false;
        }
        if (modifierUserAuthorities) {
            if (userAuthorities && userAuthorities.length < 2 && userAuthorities.length < modifierUserAuthorities.length) {
                return false;
            }
        }

        return userAuthorities && userAuthorities.some(function (u) {
            return u.authority === role;
        }) || false;
    }

    render() {
        const {classes, createMode, editMode} = this.props;
        const {notify, message, error} = this.state;
        const hasRoleControlAdmin = this.hasRole("CONTROL_ADMIN");
        const hasRoleClient = this.hasRole("CLIENT");
        const canSave = this.checkCanSave()

        const planKontrolaTemplate = {
            "data": "",
            "aktivnost": "",
            "zaedno_so": "",
            "posebni_incidenti": ""
        }
        const korektivniAktivnostiTemplate = {
            "aktivnost": "",
            "implementacija": ""
        }
        const animalniProizvodiTemplate = {
            "vid_proizvod": "",
            "kolicina_proizvod": "",
            "status_proizvod": ""
        }
        const glavnaLokacijaTemplate = {
            "lokacija": "",
            "br_semejstva": "",
            "status": ""
        }
        const glavnaLokacijaPovrsiniTemplate = {
            "povrsina_vegetacija": "",
            "vkupna_povrsina": "",
            "izvor_nektar_medlika": "",
            "rizik_zagaduvanje": ""
        }
        const preodSemejstvaTemplate = {
            "identifikacija": "",
            "zapocnuva": "",
            "zavrsuva": "",
            "posledna_nabavka": "",
            "br_nabaveni_kolonii": ""
        }
        const prinosiTemplate = {
            "proizvod": "",
            "vkupno_bruto_lani": "",
            "vkupno_neto_lani": "",
            "bruto_po_semejstvo": "",
            "vkupno_bruto_godinava": "",
            "vkupno_neto_godinava": "",
            "bruto_po_semejstvo_godinava": ""
        }
        const postberbenoRakuvanjeOpisTemplate = {
            "postapka": "",
            "kade_izveduvanje": "",
            "odgovoren": ""
        }
        const primeniZalbiTemplate = {
            "zalba": "",
            "korektivni_aktivnosti": ""
        }
        const aneksiTemplate = {
            "broj": "",
            "aneks": ""
        }
        const nesoodvetnostiFarmaTemplate =
            {
                "nesoodvetnost": "",
                "korektivna_aktivnost": "",
                "rok_implementacija": "",
                "tocno_identifikuvani": "",
                "adekvatni": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }

        const dopolnitelniNesoodvetnostiTemplate =
            {
                "korektivni_aktivnosti_definicija": "",
                "rok": "",
                "broj": "",
                "nesoodvetnosti": "",
                "adekvateni_korektivni_aktivnosti": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }

        let trpe = ""
        let size = ""
        trpe = 100 / 5 + "%"
        size = (100 / 5) * 2 + "%"

        return (
            (this.state.id !== undefined || this.props.createMode) && this.props.lookupData !== undefined &&
            <div>
                <Grid container style={{border: "1px solid black"}} xs={12}>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center',
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_PROCERT}</Typography></Grid>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center',
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_FORM_PCELARSTVO}</Typography></Grid>
                    <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={3}>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_PREV_VERSION_PCELARSTVO}</Typography></Grid>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_VERSION_PCELARSTVO}</Typography></Grid>
                    </Grid>
                    <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={3}>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_IZGOTVIL}</Typography></Grid>
                        <Grid item xs={12} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                            height: "50%"
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_PROVERIL_POTVRDIL}</Typography></Grid>
                    </Grid>
                    <Grid item xs={2} style={{
                        border: "1px solid black",
                        display: 'flex',
                        justifyContent: 'center'
                    }}><Typography
                        className={classes.verticalTextCenter}>{ALL.POU_HEADER_SUBTITLE_PCELARSTVO}</Typography></Grid>
                </Grid>
                <Grid container style={{border: "1px solid black"}} xs={12}>

                    <Grid container item xs={12}>
                        <CustomLabeledInput labelInput={ALL.POU_HEADER_KOMPANIJA}
                                            inputType={"text"}
                                            size={3}
                                            vertical={true}
                                            valueInput={this.state.header_kompanija}
                                            onChangeAction={(event) => this.handleChange('header_kompanija', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_HEADER_DATUM_POPOL}
                                            inputType={"text"}
                                            size={2}
                                            vertical={true}
                                            valueInput={this.state.header_datum_popol}
                                            onChangeAction={(event) => this.handleChange('header_datum_popol', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_HEADER_ODGOVOREN_KOMPANIJA}
                                            inputType={"text"}
                                            size={2}
                                            vertical={true}
                                            valueInput={this.state.header_odgovoren_kompanija}
                                            onChangeAction={(event) => this.handleChange('header_odgovoren_kompanija', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_HEADER_KONTROLOR}
                                            inputType={"text"}
                                            size={3}
                                            vertical={true}
                                            valueInput={this.state.header_kontrolor}
                                            onChangeAction={(event) => this.handleChange('header_kontrolor', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_HEADER_DATUM_KONTROLA}
                                            inputType={"text"}
                                            size={2}
                                            vertical={true}
                                            valueInput={this.state.header_datum_kontrola}
                                            onChangeAction={(event) => this.handleChange('header_datum_kontrola', event)}
                        />
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{ALL.POU}</Typography>
                        <Typography variant="h7">{ALL.PCELARSKO_PROIZVODSTVO}</Typography>
                        <Grid container xs={12} className={classes.coloredItemGrid1}>
                            <Grid item xs={12}><Typography>{ALL.POU_DESC}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid xs={12} className={classes.heightControlBlueItemGrid}>
                                    <Typography variant="h5">{ALL.POU_PLAN_KONTROLA}</Typography>
                                </Grid>
                                <GridTable obj={this.state.plan_kontrola}
                                           objLen={this.state.plan_kontrola.length}
                                           labeli={[ALL.DATA, ALL.POU_AKTIVNOST, ALL.POU_ZAEDNO_SO, ALL.POU_POSEBNI_INCIDENTI]}
                                           values={["data", "aktivnost", "zaedno_so", "posebni_incidenti"]}
                                           types={["textarea", "textarea", "textarea", "textarea"]}
                                           ddData={[null, null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           size={12}
                                           disabled={[!hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                           disabledButtons={!hasRoleControlAdmin}
                                           template={planKontrolaTemplate}
                                           valueName={"plan_kontrola"}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NAODI}
                                                inputType={"text"}
                                                size={12}
                                                disabled={!hasRoleControlAdmin}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.naodi_kontrola}
                                                onChangeAction={(event) => this.handleChange('naodi_kontrola', event)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_AZURIRANJE}
                                                inputType={"radio"}
                                                disabled={!hasRoleControlAdmin}
                                                valueInput={this.state.azuriranje_pou}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                onChangeAction={(event) => this.handleChange('azuriranje_pou', event)}/>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    this.state.azuriranje_pou === "true" &&
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRIMEROCI}
                                                    inputType={"text"}
                                                    disabled={!hasRoleControlAdmin}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.zemeni_primeroci_kontrola}
                                                    onChangeAction={(event) => this.handleChange('zemeni_primeroci_kontrola', event)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <br/>
                <br/>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={11} className={classes.orangeColoredItemGrid}>
                        <CustomLabeledInput labelInput={ALL.POU_KONTROLA_UPATSTVO}
                                            size={12}
                                            firstElementSize={12}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">1</Typography></Grid>
                    </Grid>
                    <Grid container xs={11} className={classes.orangeColoredItemGrid}>
                        <CustomLabeledInput labelInput={ALL.POU_ADRESA}
                                            size={12}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <CustomLabeledInput labelInput={ALL.POU_IME_NA_FARMATA}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.ime_farma}
                                            requiredInput={true}
                                            multilineInput={true}
                                            onChangeAction={(event) => this.handleChange('ime_farma', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_SOPSTVENIK}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.sopstvenik}
                                            onChangeAction={(event) => this.handleChange('sopstvenik', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_ODGOVORNO_LICE}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.odgovorno_lice}
                                            size={12}
                                            multilineInput={true}
                                            onChangeAction={(event) => this.handleChange('odgovorno_lice', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_ULICA_BROJ_FAX}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.ulica_broj_fax}
                                            size={12}
                                            multilineInput={true}
                                            onChangeAction={(event) => this.handleChange('ulica_broj_fax', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_GRAD_KOD}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.grad_postenski_kod}
                                            onChangeAction={(event) => this.handleChange('grad_postenski_kod', event)}
                        />

                        <CustomLabeledInput labelInput={ALL.POU_ZEMJA}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.zemja}
                                            onChangeAction={(event) => this.handleChange('zemja', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_TELEFON}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.telefon}
                                            onChangeAction={(event) => this.handleChange('telefon', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_MOBILEN_TELEFON}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.mobilen}
                                            onChangeAction={(event) => this.handleChange('mobilen', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_FAKS}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            valueInput={this.state.faks}
                                            onChangeAction={(event) => this.handleChange('faks', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.EMAIL}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            multilineInput={true}
                                            valueInput={this.state.mail}
                                            onChangeAction={(event) => this.handleChange('mail', event)}
                        />
                    </Grid>

                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_1', event)}
                                          controlInput={this.state["control_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PARTNERI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRERABOTUVAC_PCELARSTVO}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                options={prerabotuvacData}
                                                valueInput={this.state.prerabotuvac}
                                                onChangeAction={(event) => this.handleChange('prerabotuvac', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_2_1', event)}
                                          controlInput={this.state["control_2_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.prerabotuvac.match("true") &&
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRERABOTUVAC_DA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.prerabotuvac_opis}
                                                onChangeAction={(event) => this.handleChange('prerabotuvac_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_2_2', event)}
                                          controlInput={this.state["control_2_2"]}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                }
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZVOZNIK}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izvoznik}
                                                onChangeAction={(event) => this.handleChange('izvoznik', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_2_3', event)}
                                          controlInput={this.state["control_2_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">2.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IMATEL}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.imatel}
                                                onChangeAction={(event) => this.handleChange('imatel', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_2_4', event)}
                                          controlInput={this.state["control_2_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_OPSEG_SERTIFIKACIJA}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <CustomLabeledInput labelInput={ALL.POU_POBARANA_OVAA_GOD}
                                            inputType={"radio"}
                                            disabled={!hasRoleClient}
                                            options={yesno}
                                            size={3}
                                            vertical={true}
                                            valueInput={this.state.zakon_organsko_ovaa_godina}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_ovaa_godina', event)}/>
                        <CustomLabeledInput labelInput={ALL.POU_POBARANA_PRV_PAT}
                                            inputType={"radio"}
                                            disabled={!hasRoleClient}
                                            options={yesno}
                                            vertical={true}
                                            size={3}
                                            valueInput={this.state.zakon_organsko_prv_pat}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_prv_pat', event)}/>
                        <CustomLabeledInput labelInput={ALL.POU_SERT_GODINA}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            size={3}
                                            vertical={true}
                                            placeholderInput={" "}
                                            multilineInput={true}
                                            valueInput={this.state.zakon_organsko_nePrvaPat_sertificirano}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_nePrvaPat_sertificirano', event)}
                        />
                        <CustomLabeledInput labelInput={ALL.POU_PRETH_SERT}
                                            inputType={"text"}
                                            disabled={!hasRoleClient}
                                            size={3}
                                            vertical={true}
                                            placeholderInput={" "}
                                            multilineInput={true}
                                            valueInput={this.state.zakon_organsko_prethodno_sertificirano}
                                            onChangeAction={(event) => this.handleChange('zakon_organsko_prethodno_sertificirano', event)}
                        />
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_3_1', event)}
                                          controlInput={this.state["control_3_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KOPIJA}
                                                size={12}
                                                inputType={"checkbox"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                valueInput={this.state.tip_kopija}
                                                options={this.props.lookupData.tip_kopija}
                                                onChangeAction={(event) => this.multiselectHandleChange('tip_kopija', event)}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_3_2', event)}
                                          controlInput={this.state["control_3_2"]}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_RAZMER_PROSTUDIRANJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.objasnuvanja_standard}
                                                onChangeAction={(event) => this.handleChange('objasnuvanja_standard', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_3_3', event)}
                                          controlInput={this.state["control_3_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NE_RAZBRANO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.promena_standard}
                                                onChangeAction={(event) => this.handleChange('promena_standard', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_3_4', event)}
                                          controlInput={this.state["control_3_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">3.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_OSPORENA_ORGANSKA_SERTIFIKACIJA}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.sertifikacija_osporena}
                                                onChangeAction={(event) => this.handleChange('sertifikacija_osporena', event)}
                            />
                        </Grid>
                        {this.state.sertifikacija_osporena.match("true") &&
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_OSPORENA_ORGANSKA_SERTIFIKACIJA_DA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sertifikacija_osporena_opis}
                                                onChangeAction={(event) => this.handleChange('sertifikacija_osporena_opis', event)}
                            />
                        </Grid>
                        }
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_3_5', event)}
                                          controlInput={this.state["control_3_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_MIN_KOR_AKT}
                                                size={12}
                                                vertical={true}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.minatogodishni_korektivni_aktivnosti}
                                                options={korektivniAktivnostiData}
                                                onChangeAction={(event) => this.handleChange('minatogodishni_korektivni_aktivnosti', event)}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_4', event)}
                                          controlInput={this.state["control_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <GridTable obj={this.state.korektivni_aktivnosti}
                                       labeli={[ALL.POU_KOREKT_AKT, ALL.POU_IMPLEMENTACIJA]}
                                       disabled={[!hasRoleClient, !hasRoleClient]}
                                       disabledButtons={!hasRoleClient}
                                       values={["aktivnost", "implementacija"]}
                                       types={["textarea", "textarea"]}
                                       size={12}
                                       ddData={[null, null]}
                                       valueName={"korektivni_aktivnosti"}
                                       template={korektivniAktivnostiTemplate}
                                       onChangeAction={(name, value) => this.handleObj(name, value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_4_1', event)}
                                          controlInput={this.state["control_4_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid xs={12} className={classes.heightControlColoredItemGrid}>
                                    <Typography variant="h5">{ALL.POU_PCELARSTVO_SERT}</Typography>
                                </Grid>
                                <GridTable obj={this.state.proizvodi_sertifikacija}
                                           labeli={[ALL.POU_VID_PROIZVOD, ALL.POU_KOLICINA_PROIZVOD, ALL.POU_STATUS_PROIZVOD]}
                                           values={["vid_proizvod", "kolicina_proizvod", "status_proizvod"]}
                                           types={["text", "text", "dropdown"]}
                                           disabledButtons={!hasRoleClient}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           ddData={[null, null, this.props.lookupData.document_status]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           size={12}
                                           valueName={"proizvodi_sertifikacija"}
                                           template={animalniProizvodiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_5', event)}
                                          controlInput={this.state["control_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_LOKACIJA}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5"/></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_LOKACIJA_OPIS1}
                                                secondLabelInput={ALL.POU_LOKACIJA_OPIS2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRESELUVANJE}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.preseluvanje}
                                                onChangeAction={(event) => this.handleChange('preseluvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_6_1', event)}
                                          controlInput={this.state["control_6_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.preseluvanje.match("true") &&
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid container justify='space-around'>
                                <GridTable obj={this.state.glavna_lokacija}
                                           labeli={[ALL.LOKACIJA_PCELARNIK, ALL.BROJ_PCELNI_SEMEJSTVA, ALL.POU_STATUS]}
                                           values={["lokacija", "br_semejstva", "status"]}
                                           types={["text", "text", "dropdown"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           valueName={"glavna_lokacija"}
                                           size={12}
                                           ddData={[null, null, this.props.lookupData.document_status]}
                                           template={glavnaLokacijaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid container justify='space-around'>
                                <GridTable obj={this.state.glavna_lokacija_povrsini}
                                           labeli={[ALL.POU_POVRSINA_VEGETACIJA, ALL.POU_VKUPNA_POVRSINA, ALL.POU_IZVOR_NEKTAR, ALL.POU_RIZIK_ZAGADUVANJE]}
                                           values={["povrsina_vegetacija", "vkupna_povrsina", "izvor_nektar_medlika", "rizik_zagaduvanje"]}
                                           types={["text", "text", "text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           valueName={"glavna_lokacija_povrsini"}
                                           size={12}
                                           ddData={[null, null, null, null]}
                                           template={glavnaLokacijaPovrsiniTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_VODA_RASTOJANIE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={ALL.POU_VODA_RASTOJANIE}
                                                valueInput={this.state.voda_rastojanie}
                                                onChangeAction={(event) => this.handleChange('voda_rastojanie', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_HIGIENSKI_KVALITET}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                multilineInput={true}
                                                placeholderInput={ALL.POU_HIGIENSKI_KVALITET}
                                                valueInput={this.state.voda_higienski_kvalitet}
                                                onChangeAction={(event) => this.handleChange('voda_higienski_kvalitet', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_6_2', event)}
                                          controlInput={this.state["control_6_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                }
                {this.state.preseluvanje.match("true") &&
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_LOKACIJA_MIGRACIJA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                multilineInput={true}
                                                placeholderInput={ALL.POU_LOKACIJA_MIGRACIJA}
                                                valueInput={this.state.lokacija_migracija}
                                                onChangeAction={(event) => this.handleChange('lokacija_migracija', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_LOKACIJA_PERIOD}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                valueInput={this.state.lokacija_migracija_period}
                                                onChangeAction={(event) => this.handleChange('lokacija_migracija_period', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NAD_VISINA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={ALL.POU_NAD_VISINA}
                                                valueInput={this.state.lokacija_migracija_nadmorska_visina}
                                                onChangeAction={(event) => this.handleChange('lokacija_migracija_nadmorska_visina', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_GODISNI_VRNEZI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={ALL.POU_GODISNI_VRNEZI}
                                                valueInput={this.state.lokacija_migracija_godishni_vrnezi}
                                                onChangeAction={(event) => this.handleChange('lokacija_migracija_godishni_vrnezi', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid container justify='space-around'>
                                <GridTable obj={this.state.lokacija_migracija_povrsini}
                                           labeli={[ALL.POU_POVRSINA_VEGETACIJA, ALL.POU_VKUPNA_POVRSINA, ALL.POU_IZVOR_NEKTAR, ALL.POU_RIZIK_ZAGADUVANJE]}
                                           values={["povrsina_vegetacija", "vkupna_povrsina", "izvor_nektar_medlika", "rizik_zagaduvanje"]}
                                           types={["text", "text", "text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           valueName={"lokacija_migracija_povrsini"}
                                           size={12}
                                           ddData={[null, null, null, null]}
                                           template={glavnaLokacijaPovrsiniTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_6_3', event)}
                                          controlInput={this.state["control_6_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_MAPA_PCELARSTVO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.mapa_lokacii}
                                                onChangeAction={(event) => this.handleChange('mapa_lokacii', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'
                              style={{border: "1px solid black", display: 'flex', justifyContent: 'center'}}>
                            <UploadInput uploadFile={() => this.props.uploadFile()}
                                         fileUploadId={"mapa_upload_id"}
                                         disabledButtons={!hasRoleClient}
                                         fileId={this.state.mapa_upload_id}
                                         onDeleteAction={() => this.deleteFile("mapa_upload_id")}
                                         onChangeAction={() => this.uploadFileAndSetState("mapa_upload_id")}
                                         uploadLabel={"Додади мапа!"} downloadLabel={"Превземи мапа!"}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_6_4', event)}
                                          controlInput={this.state["control_6_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">6.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_NESOODVETNI_LOKACII}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.nesoodvetni_lokacii_opis}
                                                onChangeAction={(event) => this.handleChange('nesoodvetni_lokacii_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_6_5', event)}
                                          controlInput={this.state["control_6_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POTEKLO_PCELI_PREOD}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5"/></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POTEKLO_PCELI_PREOD_OPIS_1}
                                                secondLabelInput={ALL.POU_POTEKLO_PCELI_PREOD_OPIS_2}
                                                thirdLabelInput={ALL.POU_POTEKLO_PCELI_PREOD_OPIS_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_POTEKLO_OPIS}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.poteklo_semejstva}
                                                onChangeAction={(event) => this.handleChange('poteklo_semejstva', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_1', event)}
                                          controlInput={this.state["control_7_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PREOD_DETALI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.preod_semejstva_opis}
                                                onChangeAction={(event) => this.handleChange('preod_semejstva_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_2', event)}
                                          controlInput={this.state["control_7_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.preod_semejstva}
                                           labeli={[ALL.POU_IDENTIFIKACIJA, ALL.POU_PREOD_OD, ALL.POU_PREOD_DO, ALL.POU_POSLEDNA_NABAVKA, ALL.POU_BR_KOLONII]}
                                           values={["identifikacija", "zapocnuva", "zavrsuva", "posledna_nabavka", "br_nabaveni_kolonii"]}
                                           types={["textarea", "textarea", "textarea", "textarea", "textarea"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null, null, null]}
                                           valueName={"preod_semejstva"}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           size={12}
                                           template={preodSemejstvaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_3', event)}
                                          controlInput={this.state["control_7_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ORG_POTEKLO_NESOODVETNOST}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.nesoodvetnosti_organsko_poteklo}
                                                onChangeAction={(event) => this.handleChange('nesoodvetnosti_organsko_poteklo', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_4', event)}
                                          controlInput={this.state["control_7_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ZAMENA_VOSOK}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={zamenaVosokData}
                                                valueInput={this.state.zamena_vosok_preod}
                                                onChangeAction={(event) => this.handleChange('zamena_vosok_preod', event)}
                            />
                        </Grid>
                        {this.state.zamena_vosok_preod.match("false") &&
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ZAMENA_VOSOK_NE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                valueInput={this.state.zamena_vosok_preod_ne}
                                                onChangeAction={(event) => this.handleChange('zamena_vosok_preod_ne', event)}
                            />
                        </Grid>
                        }
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_5', event)}
                                          controlInput={this.state["control_7_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.zamena_vosok_preod.match("true") &&
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.6</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ZAMENA_VOSOK_DA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.zamena_vosok_preod_procedura}
                                                onChangeAction={(event) => this.handleChange('zamena_vosok_preod_procedura', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_6', event)}
                                          controlInput={this.state["control_7_6"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.7</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ZAMENA_VOSOK_DOC}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.zamena_vosok_preod_dokumentacija}
                                                onChangeAction={(event) => this.handleChange('zamena_vosok_preod_dokumentacija', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_7', event)}
                                          controlInput={this.state["control_7_7"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.8</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_MESANJE_VOSOK}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.organizacija_mesanje_vosok}
                                                onChangeAction={(event) => this.handleChange('organizacija_mesanje_vosok', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_8', event)}
                                          controlInput={this.state["control_7_8"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">7.9</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_VOSOK_NESOGLASNOST}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.vosok_nesoglasnost}
                                                onChangeAction={(event) => this.handleChange('vosok_nesoglasnost', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_7_9', event)}
                                          controlInput={this.state["control_7_9"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_MATERIJAL}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid> <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                        <CustomLabeledInput labelInput={ALL.POU_MATERIJAL_OPIS}
                                            size={12}
                                            firstElementSize={12}
                        />
                    </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_MATERIJAL_KOSNICI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.kosnici_materija}
                                                onChangeAction={(event) => this.handleChange('kosnici_materija', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_8_1', event)}
                                          controlInput={this.state["control_8_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KOSNICI_BOENJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.kosnici_boenje}
                                                onChangeAction={(event) => this.handleChange('kosnici_boenje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_8_2', event)}
                                          controlInput={this.state["control_8_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_RAMKI_MATERIJAL}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.ramki_materijal}
                                                onChangeAction={(event) => this.handleChange('ramki_materijal', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_8_3', event)}
                                          controlInput={this.state["control_8_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_CISTENJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.cistenje}
                                                onChangeAction={(event) => this.handleChange('cistenje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_8_4', event)}
                                          controlInput={this.state["control_8_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">8.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_MATERIJALI_PODOBRUVANJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.materijal_podobruvanje}
                                                onChangeAction={(event) => this.handleChange('materijal_podobruvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_8_5', event)}
                                          controlInput={this.state["control_8_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">9</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container  justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ISHRANA}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid> <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                        <CustomLabeledInput labelInput={ALL.POU_MED_ZA_PCELI_OPIS}
                                            size={12}
                                            firstElementSize={12}
                        />
                    </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">9.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_MED_ZA_PCELI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.med_za_pceli}
                                                onChangeAction={(event) => this.handleChange('med_za_pceli', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_9_1', event)}
                                          controlInput={this.state["control_9_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">9.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SEZONA_BEZ_CVETANJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sezona_bez_cvetanje}
                                                onChangeAction={(event) => this.handleChange('sezona_bez_cvetanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_9_2', event)}
                                          controlInput={this.state["control_9_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_UPRAVUVANJE_PCELI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_UBIVAJE_PCELI_OPIS}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_UBIVAJE_PCELI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                valueInput={this.state.ubivanje_pceli}
                                                onChangeAction={(event) => this.handleChange('ubivanje_pceli', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_10_1', event)}
                                          controlInput={this.state["control_10_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SECENJE_KRILA}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                valueInput={this.state.secenje_krila}
                                                onChangeAction={(event) => this.handleChange('secenje_krila', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_10_2', event)}
                                          controlInput={this.state["control_10_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_UBIVANJE_TRUTOVI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                valueInput={this.state.ubivanje_trutovi}
                                                onChangeAction={(event) => this.handleChange('ubivanje_trutovi', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_10_3', event)}
                                          controlInput={this.state["control_10_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_UPOTREBA_REPELENTI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.upotreba_repelenti}
                                                onChangeAction={(event) => this.handleChange('upotreba_repelenti', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_10_4', event)}
                                          controlInput={this.state["control_10_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">10.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KOLONII_PODOBRUVANJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.upravuvanje_kolonii_podobruvanje}
                                                onChangeAction={(event) => this.handleChange('upravuvanje_kolonii_podobruvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_10_5', event)}
                                          controlInput={this.state["control_10_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/*12*/}
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_BOLESTI}
                                                size={12}
                            />
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PREVENTIVA_OPIS}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PREVENTIVA_OPIS_P}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.prevencija_bolesti}
                                                onChangeAction={(event) => this.handleChange('prevencija_bolesti', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_1', event)}
                                          controlInput={this.state["control_11_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_BOLESTI_OPIS_P}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.bolest_opis}
                                                onChangeAction={(event) => this.handleChange('bolest_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_2', event)}
                                          controlInput={this.state["control_11_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_BOLESTI_SUPSTANCI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.supstanci_bolesti}
                                                onChangeAction={(event) => this.handleChange('supstanci_bolesti', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_3', event)}
                                          controlInput={this.state["control_11_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZOLACIJA_KOLONII}
                                                inputType={"checkbox"}
                                                disabled={!hasRoleClient}
                                                fullWidthCheckbox={true}
                                                firstElementSize={true}
                                                size={12}
                                                options={izolacijaKoloniiLookup}
                                                valueInput={this.state.izolacija_tretirani_kolonii}
                                                onChangeAction={(event) => this.handleChange('izolacija_tretirani_kolonii', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZOLACIJA_KOLONII_OPIS}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izolacija_tretirani_kolonii_opis}
                                                onChangeAction={(event) => this.handleChange('izolacija_tretirani_kolonii_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_4', event)}
                                          controlInput={this.state["control_11_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_TESTIRANJE_VOSOK}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.testiranje_vodok_med}
                                                onChangeAction={(event) => this.handleChange('testiranje_vodok_med', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_5', event)}
                                          controlInput={this.state["control_11_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.testiranje_vodok_med.match("true") &&
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.6</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_TESTIRANJE_VOSOK_DA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.testiranje_vodok_med_procedura}
                                                onChangeAction={(event) => this.handleChange('testiranje_vodok_med_procedura', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_TESTIRANJE_VOSOK_REZULTATI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.testiranje_vodok_med_rezultati}
                                                onChangeAction={(event) => this.handleChange('testiranje_vodok_med_rezultati', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'
                              style={{border: "1px solid black", display: 'flex', justifyContent: 'center'}}>
                            <UploadInput uploadFile={() => this.props.uploadFile()}
                                         fileUploadId={"rezultat_upload_id"}
                                         disabledButtons={!hasRoleClient}
                                         fileId={this.state.rezultat_upload_id}
                                         onChangeAction={() => this.uploadFileAndSetState("rezultat_upload_id")}
                                         onDeleteAction={() => this.deleteFile("rezultat_upload_id")}
                                         uploadLabel={"Додади резултат!"} downloadLabel={"Превземи резултат!"}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_6', event)}
                                          controlInput={this.state["control_11_6"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                }
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">11.7</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_BOLESTI_PREVENCIJA_P}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.preventiva_bolesti_podobruvanje}
                                                onChangeAction={(event) => this.handleChange('preventiva_bolesti_podobruvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_11_7', event)}
                                          controlInput={this.state["control_11_7"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">12</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PRINNOSI_SOBIRANJE}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}/>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid style={{width: trpe}} className={classes.purpleColoredItemGrid}>
                                </Grid>
                                <Grid style={{width: size}} className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_PRINOSI_MINATA_GODINA} </Typography>
                                </Grid>
                                <Grid style={{width: size}} className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_PRINOSI_OVAA_GODINA} </Typography>
                                </Grid>
                                <GridTable obj={this.state.prinosi}
                                           labeli={[ALL.POU_PROIZVOD, ALL.POU_VKUPNO_BRUTO_PRINOSI, ALL.POU_VKUPNO_NETO_PRINOSI, ALL.POU_BRUTO_PO_SEMEJSTVO, ALL.POU_VKUPNO_BRUTO_PRINOSI, ALL.POU_VKUPNO_NETO_PRINOSI, ALL.POU_BRUTO_PO_SEMEJSTVO]}
                                           values={["proizvod", "vkupno_bruto_lani", "vkupno_neto_lani", "bruto_po_semejstvo", "vkupno_bruto_godinava", "vkupno_neto_godinava", "bruto_po_semejstvo_godinava"]}
                                           types={["text", "text", "text", "text", "text", "text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null, null, null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"prinosi"}
                                           size={12}
                                           template={prinosiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRINOS_KOMENTAR}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.prinos_komentar}
                                                onChangeAction={(event) => this.handleChange('prinos_komentar', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_12', event)}
                                          controlInput={this.state["control_12"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POSTBERBENO_RAKUVANJE}
                                                size={12}
                            />
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POSTBERBENO_RAKUVANJE_P}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_POSTBERBENO_RAKUVANJE_OPIS_P}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.postberbeno_rakuvanje_opis}
                                           labeli={[ALL.POU_POSTAPKA_CEKOR, ALL.POU_IZVEDUVA, ALL.POU_ODGOVOREN]}
                                           values={["postapka", "kade_izveduvanje", "odgovoren"]}
                                           types={["textarea", "textarea", "textarea"]}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"postberbeno_rakuvanje_opis"}
                                           size={12}
                                           template={postberbenoRakuvanjeOpisTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3}
                          className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_13_1', event)}
                                          controlInput={this.state["control_13_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PODDOGOVARACI_POSTBERBENO_PCELARSTVO}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                options={yesno}
                                                valueInput={this.state.poddogovaraci}
                                                onChangeAction={(event) => this.handleChange('poddogovaraci', event)}
                            />
                        </Grid>
                        {this.state.poddogovaraci.match("true") &&
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PODDOGOVARACI_POSTBERBENO_OPIS}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.poddogovaraci_opis}
                                                onChangeAction={(event) => this.handleChange('poddogovaraci_opis', event)}
                            />
                        </Grid>
                        }
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_13_2', event)}
                                          controlInput={this.state["control_13_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZBEGNUVANJE_ZABRANETI_SUPSTANCI_PCELARSTVO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izbegnuvanje_zabraneti_supstanci}
                                                onChangeAction={(event) => this.handleChange('izbegnuvanje_zabraneti_supstanci', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_13_3', event)}
                                          controlInput={this.state["control_13_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_IZBEGNUVANJE_MESHANJE_PROIZVODI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.izbegnuvanje_meshanje_proizvodi}
                                                onChangeAction={(event) => this.handleChange('izbegnuvanje_meshanje_proizvodi', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_13_4', event)}
                                          controlInput={this.state["control_13_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">13.5</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_KOREKTIVNI_MERKI_OPIS_PCELARSTVO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.korektivni_merki_opis}
                                                onChangeAction={(event) => this.handleChange('korektivni_merki_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_13_5', event)}
                                          controlInput={this.state["control_13_5"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">14</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ETIKETIRANJE}
                                                size={12}
                            />
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ETIKETIRANJE_OPIS}
                                                size={12}
                                                firstElementSize={12}

                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">14.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ETIKETIRANJE_PCELARSTVO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.organski_proizvodi_ambalazi}
                                                onChangeAction={(event) => this.handleChange('organski_proizvodi_ambalazi', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_14_1', event)}
                                          controlInput={this.state["control_14_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">14.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRIMEROK_ETIKETA_PCELARSTVO}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.etiketa}
                                                onChangeAction={(event) => this.handleChange('etiketa', event)}
                            />
                        </Grid>
                        <Grid container justify='space-around'
                              style={{border: "1px solid black", display: 'flex', justifyContent: 'center'}}>
                            <UploadInput uploadFile={() => this.props.uploadFile()}
                                         fileUploadId={"etiketa_upload_id"}
                                         disabledButtons={!hasRoleClient}
                                         fileId={this.state.etiketa_upload_id}
                                         onDeleteAction={() => this.deleteFile("etiketa_upload_id")}
                                         onChangeAction={() => this.uploadFileAndSetState("etiketa_upload_id")}
                                         uploadLabel={"Додади етикета!"} downloadLabel={"Превземи етикета!"}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_14_2', event)}
                                          controlInput={this.state["control_14_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">14.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ETIKETA_KOREKCIJA}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.etiketa_korekcija}
                                                onChangeAction={(event) => this.handleChange('etiketa_korekcija', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_14_3', event)}
                                          controlInput={this.state["control_14_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">14.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_PRAVILA_LOGO_ZNAK}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.logo_znak_opis}
                                                onChangeAction={(event) => this.handleChange('logo_znak_opis', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_14_4', event)}
                                          controlInput={this.state["control_14_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15</Typography></Grid>
                    </Grid>
                    <Grid container xs={11}>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_SLEDLIVOST}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.1</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_AZURIRANI_ZAPISI_OPIS}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                options={daNeDelumnoData}
                                                valueInput={this.state.azurirani_zapisi}
                                                onChangeAction={(event) => this.handleChange('azurirani_zapisi', event)}
                            />
                            <CustomLabeledInput labelInput={ALL.POU_PCELARSKI_DNEVNICI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.pcelarski_dnevnik}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('pcelarski_dnevnik', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_TRETMANI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.zapisi_veterinarni_tretmani}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('zapisi_veterinarni_tretmani', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_SEMEJSTVA}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.zapisi_kolicini}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('zapisi_kolicini', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_FAKTURI_P}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.fakturi}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('fakturi', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_DNEVNIK_PAKUVANJE}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.dnevnik_pakuvanje}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('dnevnik_pakuvanje', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_EVIDENCIJA_PRODAZBA_PCELARSTVO}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.evidencija_prodazba}
                                                options={daNeDelumnoData}
                                                size={12}
                                                onChangeAction={(event) => this.handleChange('evidencija_prodazba', event)}/>
                            <CustomLabeledInput labelInput={ALL.POU_DRUGI}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.drugi_zapisi}
                                                onChangeAction={(event) => this.handleChange('drugi_zapisi', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_15_1', event)}
                                          controlInput={this.state["control_15_1"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.2</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_ZALBI_POPLAKI}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                valueInput={this.state.zapisi_zalbi_poplaki}
                                                options={yesno}
                                                size={12}
                                                vertical={true}
                                                onChangeAction={(event) => this.handleChange('zapisi_zalbi_poplaki', event)}/>
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput
                                labelInput={ALL.POU_ZAPISI_ZALBI_POPLAKI_NE + "\n" + ALL.POU_ZAPISI_ZALBI_POPLAKI_DA}
                                size={12}
                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.primeni_zalbi}
                                           labeli={[ALL.POU_PRIMENI_ZALBI, ALL.POU_PREVZEMENI_KOREKTIVNI_AKT]}
                                           values={["zalba", "korektivni_aktivnosti"]}
                                           types={["text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"primeni_zalbi"}
                                           size={12}
                                           cellWidth={12}
                                           template={primeniZalbiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_15_2', event)}
                                          controlInput={this.state["control_15_2"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.3</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SLEDLIVOST}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sledlivost}
                                                onChangeAction={(event) => this.handleChange('sledlivost', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_15_3', event)}
                                          controlInput={this.state["control_15_3"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={1} className={classes.numeratedItemGrid}>
                        <Grid item xs={12}><Typography variant="h5">15.4</Typography></Grid>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput labelInput={ALL.POU_SLEDLIVOST_PODOBRUVANJE}
                                                inputType={"text"}
                                                disabled={!hasRoleClient}
                                                size={12}
                                                vertical={true}
                                                placeholderInput={" "}
                                                multilineInput={true}
                                                valueInput={this.state.sledlivost_podobruvanje}
                                                onChangeAction={(event) => this.handleChange('sledlivost_podobruvanje', event)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={3} className={classes.kontrolorItemGrid}>
                        <Grid container justify='space-around'>
                            <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                          onChangeAction={(event) => this.handleChange('control_15_4', event)}
                                          controlInput={this.state["control_15_4"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>


                <br/>
                <br/>
                <br/>

                <Typography variant="h5">{ALL.POU_ZADOLZITELNI_ANEKSI}</Typography>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_1}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_1}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_4}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_4}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_5}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_5}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_FOTO}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_6}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_POTVRDA}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_CENTRIFUGA}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_OPREMA}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={5}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_ANEKS_EVIDENCIJA}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={6}>
                        <Grid container>
                            <CustomLabeledInput size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <Typography variant="h5">{ALL.POU_DRUGI_ANEKSI}</Typography>
                <Grid container className={classes.container}>
                    <GridTable obj={this.state.aneksi}
                               labeli={[ALL.POU_BROJ, ALL.POU_ANEKS]}
                               values={["broj", "aneks"]}
                               types={["text", "upload"]}
                               disabled={[!hasRoleClient, !hasRoleClient]}
                               disabledButtons={!hasRoleClient}
                               ddData={[null, null]}
                               uploadFile={(name) => this.props.uploadFile(name)}
                               columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                               valueName={"aneksi"}
                               size={11}
                               template={aneksiTemplate}
                               onChangeAction={(name, value) => this.handleObj(name, value)}
                    />
                </Grid>
                <br/>
                <Typography variant="h5">{ALL.POU_IZJAVA_KLIENT}</Typography>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_SOGLASNOST}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_1}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_2}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.container}>
                    <Grid container xs={11}>
                        <Grid container>
                            <CustomLabeledInput labelInput={ALL.POU_IZJAVA_3}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>
                    <Grid container xs={12}>
                        <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                            <Grid item xs={12}><Typography variant="h5">16</Typography></Grid>
                        </Grid>
                        <Grid container xs={11} justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput
                                labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI_OPIS}
                                size={12}
                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid style={{width: this.calculateHeaderSize(7, 4.6)}}
                                      className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"} variant="h6"> {ALL.POU_POP_FARMA} </Typography>
                                </Grid>
                                <Grid style={{width: this.calculateHeaderSize(7, 2.4)}}
                                      className={classes.blueColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                </Grid>
                                <GridTable obj={this.state.nesoodvetnosti_farma}
                                           labeli={[ALL.POU_NESOODVETNOST, ALL.POU_KOREKTIVNA_AKTIVNOST,
                                               ALL.POU_ROK_IMPLEMENTACIJA,
                                               ALL.POU_NESOODVETNOSTI_IDENTIFIKUVANI, ALL.POU_ROK_ADEK,
                                               ALL.POU_KOREKT_AKT_ADEK, ALL.POU_SERTIFIKATPOSLE_KOREKCIJA]}
                                           controlIndexes={[]}
                                           colWidth={[1.9, 1.9, 0.8, 0.6, 0.6, 0.6, 0.6]}
                                           values={["nesoodvetnost", "korektivna_aktivnost", "rok_implementacija",
                                               "tocno_identifikuvani", "adekvatni", "adekvaten_rok", "sertifikat_posle_korekcija"]}
                                           types={["textarea", "textarea", "textarea", "dropdown", "dropdown", "dropdown", "dropdown"]}
                                           ddData={[null, null, null, yesnomaybe, yesnomaybe, yesnomaybe, yesnomaybe]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           size={12}
                                           valueName={"nesoodvetnosti_farma"}
                                           disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                           template={nesoodvetnostiFarmaTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>

                    <Grid container xs={12}>
                        <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                            <Grid item xs={12}><Typography variant="h5">17</Typography></Grid>
                        </Grid>
                        <Grid container xs={11} justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_DOP_NESOODVETNOSTI}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <Grid alignContent={"flex-start"} container>
                                <Grid style={{width: this.calculateHeaderSize(7, 2.4)}}
                                      className={classes.blueColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                </Grid>
                                <Grid style={{width: this.calculateHeaderSize(7, 2.8)}}
                                      className={classes.purpleColoredItemGrid}>
                                    <Typography align={"center"} variant="h6"> {ALL.POU_POP_FARMA} </Typography>
                                </Grid>
                                <Grid style={{width: this.calculateHeaderSize(7, 1.8)}}
                                      className={classes.blueColoredItemGrid}>
                                    <Typography align={"center"}
                                                variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                </Grid>
                                <GridTable obj={this.state.dopolnitelni_nesoodvetnosti}
                                           labeli={[ALL.POU_BROJ_POU, ALL.POU_NESOODVETNOST, ALL.POU_KOREKT_AKT_DEF,
                                               ALL.POU_ROK, ALL.POU_KOREKT_AKT_ADEK,
                                               ALL.POU_ROK_ADEK, ALL.POU_SERTIF_POSLE_KOREK]}
                                           controlIndexes={[]}
                                           colWidth={[0.5, 1.9, 1.9, 0.9, 0.6, 0.6, 0.6]}
                                           values={["broj", "nesoodvetnosti", "korektivni_aktivnosti_definicija", "rok",
                                               "adekvateni_korektivni_aktivnosti", "adekvaten_rok", "sertifikat_posle_korekcija"]}
                                           types={["textarea", "textarea", "textarea", "textarea", "dropdown", "dropdown", "dropdown"]}
                                           ddData={[null, null, null, null, yesnomaybe, yesnomaybe, yesnomaybe]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"dopolnitelni_nesoodvetnosti"}
                                           size={12}
                                           disabled={[!hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleClient, !hasRoleClient, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                           template={dopolnitelniNesoodvetnostiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Grid container className={classes.container}>

                    <Grid container xs={12}>
                        <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                            <Grid item xs={12}><Typography variant="h5">18</Typography></Grid>
                        </Grid>
                        <Grid container xs={11} justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ZAKLUCOK_KONTROLOR}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                        <Grid container justify='space-around'>
                            <CustomLabeledInput size={12}
                                                inputType={"checkbox"}
                                                vertical={true}
                                                fullWidthCheckbox={true}
                                                valueInput={this.state.zaklucok}
                                                options={zakluchok}
                                                disabled={!hasRoleControlAdmin}
                                                onChangeAction={(event) => this.multiselectHandleChange('zaklucok', event)}/>
                        </Grid>
                    </Grid>
                </Grid>

                <br/>
                <br/>
                <br/>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText}
                                    variant="h5">{ALL.POU_DATA_POTPIS_F_K}</Typography>
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Typography className={classes.centeredText}
                                    variant="h5">{ALL.POU_DATA_POTPIS_KONT}</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={4}>
                        <CustomLabeledInput inputType={"text"}
                                            disabled={!hasRoleClient}
                                            size={12}
                                            placeholderInput={" "}
                                            valueInput={this.state.data_farma}
                                            onChangeAction={(event) => this.handleChange('data_farma', event)}
                        />
                    </Grid>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <CustomLabeledInput inputType={"text"}
                                            size={12}
                                            placeholderInput={" "}
                                            valueInput={this.state.data_kontrolor}
                                            disabled={!hasRoleControlAdmin}
                                            onChangeAction={(event) => this.handleChange('data_kontrolor', event)}
                        />
                    </Grid>
                </Grid>
                <div style={{height: "80px"}}/>

                {(createMode || editMode) && !canSave &&
                < Button
                    classes={{
                        root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    variant="contained" size={"large"}
                    aria-label="create"
                    onClick={() => this.requiredFieldsMessage()}>
                    {createMode ? "Креирај ПОУ пчеларство" : "Зачувај ПОУ пчеларство"}
                </Button>
                }
                {(createMode || editMode) && canSave &&
                < Button
                    classes={{
                        root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                        label: classes.label, // class name, e.g. `classes-nesting-label-x`
                    }}
                    variant="contained" size={"large"}
                    aria-label="create"
                    onClick={() => this.props.onSubmit(this.state)}>
                    {createMode ? "Креирај ПОУ пчеларство" : "Зачувај ПОУ пчеларство"}
                </Button>
                }
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}


PouPcelarstvo.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool
    }
;

PouPcelarstvo = reduxForm(
    {
        form: 'PouPcelarstvo',
        enableReinitialize: true,
        hasRole: true,
    }
)(PouPcelarstvo)

export default withStyles(globalStyles)(PouPcelarstvo);