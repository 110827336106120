export const newSpecPcelarstvoData =
    {
        "creatorId": "",
        "createdAt": "",
        "broj_sertifikat": "",
        "pouId": "",
        "pouName": "",
        "kapaciteti": [
            {
                "lokacija": "",
                "br_pcelni_semejstva": "",
                "status": ""
            }
        ],
        "proizvodstvo": [
            {
                "proizvod": "",
                "kolicina": "",
                "status": ""
            }
        ]
    }
