export const newApplicationData =
    {
        "ime_kompanija": "",
        "kontakt_lice": "",
        "ulica_broj": "",
        "opstina": "",
        "mesto": "",
        "telefon": "",
        "praven_status": "",
        "email": "",
        "vid_operacija": [],
        "vid_operacija_drugo": "",
        "rst_lokacija_farma": "",
        "rst_vk_hektari": "",
        "glavna_kultura": [
            {
                "kultura": "",
                "hektari": ""
            }
        ],
        "kultura_rotacija": [
            {
                "kultura": "",
                "hektari": ""
            }
        ],
        "vid_prerabotka": "",
        "lokacija_prerabotuvackiot_obj": "",
        "broj_preraboteni_proizvodi": "",
        "lista_prerabotka": [
            {
                "surovina": "",
                "finalen_proizvod": ""
            }
        ],
        "region_sobiranje": "",
        "povrsina_sobiranje": "",
        "broj_sobiraci": "",
        "broj_otkupniPunktovi": "",
        "samoniknati_vidovi": [
            {
                "samoniknat_vid": "",
                "kolicini": ""
            }
        ],
        "sto_lokacija_farma": "",
        "zivotni": [
            {
                "vid": "",
                "broj": ""
            }
        ],
        "lokacija_pcelarnik1": "",
        "broj_pcelniSemejstva1": "",
        "lokacija_pcelarnik2": "",
        "broj_pcelniSemejstva2": "",
        "lokacija_pcelarnik3": "",
        "broj_pcelniSemejstva3": "",
        "lokacija_pcelarnik4": "",
        "broj_pcelniSemejstva4": "",
        "migratorni_lokacii": "",
        "trgovija": [],
        "zakon_zem_rm": [],
        "tip_kopija": [],
        "pregovaraci_vkluceni": "",
        "pregovaraci_vkluceni_desc": "",
        "izjava1": [],
        "izjava2_check": [],
        "izjava2_desc": "",
        "izjava3_check": [],
        "izjava3_desc": "",
        "izjava4_check": [],
        "izjava4_desc": "",
        "izjava5": [],
        "vazni_informacii": "",
        "datum": "",
        "kontrola1": "",
        "kontrola2": "",
        "kontrola3": "",
        "kontrola4": "",
        "kontrola5_desc": "",
        "datum_kontrola": ""
    }