import React from 'react';
import PropTypes from 'prop-types';
import PouPcelarstvo from './PouPcelarstvo';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Notification from '../../../common/Notification';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {cleanJsonBody} from "../../../redux/helpers";
import {getPouPcelarstvo} from "../../../redux/pouPcelarstvo/reducer";
import {createPouPcelarstvoRequest, fetchNewPouPcelarstvo} from "../../../redux/pouPcelarstvo/fetch";
import {getUploadedFile} from "../../../redux/fileSystem/reducer";
import {uploadFileRequest} from "../../../redux/fileSystem/fetch";
import {getUser} from "../../../redux/user/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";
import {getLookupMap} from "../../../redux/lookup/reducer";

export class CreatePouPcelarstvo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.savePouPcelarstvo = this.savePouPcelarstvo.bind(this);
        this.uploadFilePcelarstvo = this.uploadFilePcelarstvo.bind(this);
        this.redirectToPouPcelarstvoList = this.redirectToPouPcelarstvoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchNewPouPcelarstvo, fetchLookups} = this.props;
        fetchNewPouPcelarstvo();
        fetchLookups();
    }

    savePouPcelarstvo(values) {
        /*  Create and call updatePouPcelarstvo action */
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)), true);
        this.props.createPouPcelarstvoRequest(newValues)
        this.showNotification("Успешно креирано ПОУ пчеларство!");
        setTimeout(() => this.redirectToPouPcelarstvoList(), 1000); //execute greet after 2000 milliseconds (2 seconds)
    }

    uploadFilePcelarstvo(file) {
        // this.props.uploadFileRequest(file)
        // this.showNotification("Успешно прикачен фајл");
        const myInput = document.getElementById(file);
        return uploadFileRequest(myInput).then(res => {
            this.showNotification("Успешно прикачен фајл!");
            return res
        })
    }

    redirectToPouPcelarstvoList() {
        this.props.history.push(`/PouPcelarstvoList`);
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        return (
            <div>
                <Card>
                    <CardContent>
                        <h1>Креирај ПОУ пчеларство</h1>
                    </CardContent>
                    {this.props.pouPcelarstvo &&
                    <PouPcelarstvo
                        pouPcelarstvo={this.props.pouPcelarstvo.pouPcelarstvo}
                        createMode
                        uploadFile={this.uploadFilePcelarstvo}
                        onSubmit={this.savePouPcelarstvo}
                        onCancel={this.redirectToPouPcelarstvoList}
                        user={this.props.user}
                        lookupData={this.props.lookupData}
                    />}
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        pouPcelarstvo: getPouPcelarstvo(state),
        uploadedFileId: getUploadedFile(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchNewPouPcelarstvo,
        createPouPcelarstvoRequest,
        uploadFileRequest,
        fetchLookups
    }, dispatch)
}

CreatePouPcelarstvo.propTypes = {
    dispatch: PropTypes.func
};

CreatePouPcelarstvo.contextTypes = {
    router: PropTypes.object
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePouPcelarstvo);