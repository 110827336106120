import {
    CREATE_NEW_POU_STOCARSTVO,
    CREATE_POU_STOCARSTVO,
    DELETE_POU_STOCARSTVO,
    DUPLICATE_POU_STOCARSTVO,
    FETCH_ALL_POU_STOCARSTVO,
    FETCH_POU_STOCARSTVO,
    UPDATE_POU_STOCARSTVO
} from "../actionTypes";

const initialState = {
    pouStocarstvoList: [],
    pouStocarstvo: null
};

export function pouStocarstvoReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_POU_STOCARSTVO: {
            const pouNum = action.payload.pouStocarstvoList;
            return {
                ...state,
                pouStocarstvo: null,
                pouStocarstvoList: pouNum,
                pouNum: pouNum ? pouNum.length : 0
            };
        }
        case FETCH_POU_STOCARSTVO: {
            return {
                ...state,
                pouStocarstvo: action.payload
            };
        }
        case UPDATE_POU_STOCARSTVO: {
            return {
                ...state,
                pouStocarstvo: action.payload
            };
        }
        case CREATE_NEW_POU_STOCARSTVO: {
            return {
                ...state,
                pouStocarstvo: action.payload
            };
        }
        case CREATE_POU_STOCARSTVO: {
            return {
                ...state,
                pouStocarstvo: action.payload
            };
        }
        case DUPLICATE_POU_STOCARSTVO: {
            return {
                ...state,
                pouStocarstvo: action.payload
            };
        }
        case DELETE_POU_STOCARSTVO: {
            return {
                ...state,
                pouStocarstvo: action.payload
            };
        }
        default:
            return state;
    }
}

export const getPouStocarstvoList = state => state.pouStocarstvo.pouStocarstvoList;
export const getPouNum = state => state.pouStocarstvo.pouNum;
export const getPouStocarstvo = state => state.pouStocarstvo.pouStocarstvo;