export const newPouStocarstvoData =
    {
        "header_kompanija": "",
        "header_datum_popol": "",
        "header_odgovoren_kompanija": "",
        "header_kontrolor": "",
        "header_datum_kontrola": "",
        "plan_kontrola": [
            {
                "data": "",
                "aktivnost": "",
                "zaedno_so": "",
                "posebni_incidenti": ""
            }
        ],
        "naodi_kontrola": "",
        "zemeni_primeroci": "false",
        "zemeni_primeroci_da": "",
        "ime_farma": "",
        "sopstvenik": "",
        "odgovorno_lice": "",
        "ulica_broj_fax": "",
        "grad_postenski_kod": "",
        "telefon": "",
        "mobilen": "",
        "mail": "",
        "imatel": "",
        "zakon_organsko_prv_pat": "false",
        "zakon_organsko_nePrvaPat_sertificirano": "",
        "zakon_organsko_prethodno_sertificirano": "",
        "zakon_organsko_drugi": "",
        "tip_kopija": [],
        "objasnuvanja_standard": "",
        "promena_standard": "",
        "sertifikacija_osporena": "false",
        "sertifikacija_osporena_opis": "",
        "minatogodishni_korektivni_aktivnosti": "",
        "korektivni_aktivnosti": [
            {
                "aktivnost": "",
                "implementacija": ""
            }
        ],
        "animalni_proizvodi_sertifikacija": [
            {
                "zivotni": "",
                "broj_kolicina": "",
                "status": ""
            }
        ],
        "nadmorska_visina_farma": "",
        "godishni_vrnezi_farma": "",
        "opis_farma": [
            {
                "zemjiste": "",
                "hektari_organsko": "",
                "hektari_preod": "",
                "hektari_konvencionalno": ""
            }
        ],
        "skica_objekti": "",
        "skica_upload_id": "",
        "br_zivotni_vid": [
            {
                "vid": "",
                "br_organsko": "",
                "br_preod": "",
                "br_konvencionalno": ""
            }
        ],
        "poteklo_zivotni_preod": [
            {
                "vid": "",
                "br_zivotni": "",
                "broj": "",
                "lokacija": "",
            }
        ],
        "ishrana_opis": "",
        "hranlivi_materii_opis": "",
        "mladi_cicaci_opis": "",
        "trevopasni_period": "",
        "kabasta_hrana_obezbeduvanje": "",
        "aditivi": "",
        "aditivi_opis": "",
        "alopatski_lekarstva_praksa": "",
        "preparati_preventirvno": "",
        "sistem_higiena_opis": "",
        "sistem_razmnozuvanje": "",
        "operacii_zivotni_praktiki": "",
        "kastracija": "",
        "vrzuvanje_zivotni": "",
        "podovi_shtali": "",
        "kg_ziva_mera": "",
        "prekumerna_ispasha": "",
        "popravanje_upravuvanje_gjubre": "",
        "prinosi": [
            {
                "proizvod": "",
                "vkupno_bruto_lani": "",
                "vkupno_neto_lani": "",
                "bruto_po_zivotno_lani": "",
                "vkupno_bruto_godinava": "",
                "vkupno_neto_godinava": "",
                "bruto_po_zivotno_godinava": ""
            }
        ],
        "prinos_komentar": "",
        "postberbeno_rakuvanje": "",
        "postberbeno_rakuvanje_opis": [
            {
                "postapka": "",
                "kade_izveduvanje": "",
                "odgovoren": ""
            }
        ],
        "poddogovaraci": "",
        "poddogovaraci_opis": "",
        "izbegnuvanje_zabraneti_supstanci": "",
        "izbegnuvanje_meshanje_proizvodi": "",
        "izbegnuvanje_stres": "",
        "etiketa": "",
        "logo_znak_opis": "",
        "azurirani_zapisi": "true",
        "dnevnik": "false",
        "zapisi_kolicini_den": "",
        "fakturi": "",
        "dnevnik_pakuvanje": "",
        "evidencija_prodazba": "",
        "drugi": "",
        "zapisi_zalbi_poplaki": "",
        "primeni_zalbi": [
            {
                "zalba": "",
                "korektivni_aktivnosti": ""
            }
        ],
        "sledlivost": "",
        "aneksi": [
            {
                "broj": "",
                "aneks": ""
            }
        ],
        "nesoodvetnosti_farma": [
            {
                "nesoodvetnost": "",
                "korektivna_aktivnost": "",
                "rok_implementacija": "",
                "tocno_identifikuvani": "",
                "adekvatni": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }
        ],
        "dopolnitelni_nesoodvetnosti": [
            {
                "korektivni_aktivnosti_definicija": "",
                "rok": "",
                "broj": "",
                "nesoodvetnosti": "",
                "adekvateni_korektivni_aktivnosti": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }
        ],
        "zaklucok": [],
        "data_farma": "",
        "data_kontrolor": ""
    }