import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {cleanJsonSpecifikacija} from "../../../redux/helpers";
import Notification from "../../../common/Notification";
import {getSpecRastitelno} from "../../../redux/specRastitelno/reducer";
import {getUser} from "../../../redux/user/reducer";
import {bindActionCreators, compose} from "redux";
import {createSpecRastitelnoRequest, fetchNewSpecRastitelno} from "../../../redux/specRastitelno/fetch";
import {connect} from "react-redux";
import SpecRastitelno from "./SpecRastitelno";
import {fetchLookups} from "../../../redux/lookup/fetch";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";
import {CreateSpecStocarstvo} from "../stocarstvo/CreateSpecStocarstvo";

export class CreateSpecRastitelno extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.saveSpecRastitelno = this.saveSpecRastitelno.bind(this);
        this.redirectToSpecRastitelnoList = this.redirectToSpecRastitelnoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchNewSpecRastitelno, fetchLookups} = this.props;
        fetchNewSpecRastitelno();
        fetchLookups();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error === false) {
            this.showNotification("Успешно креирана спецификација!");
            setTimeout(() => this.redirectToSpecRastitelnoList(), 1000); //execute greet after 2000 milliseconds (2 seconds)
        } else if (nextProps.error === true) {
            this.showNotification("Грешка при креирање на спецификацијата!", false)
        }
    }

    saveSpecRastitelno(values) {
        /*  Create and call updateSpecRastitelno action */
        values.creatorId = this.props.user.id
        let newValues = cleanJsonSpecifikacija(JSON.parse(JSON.stringify(values)), true);
        this.props.createSpecRastitelnoRequest(newValues)
        this.showNotification("Успешно креирана спецификација!");
        setTimeout(() => this.redirectToSpecRastitelnoList(), 1000); //execute greet after 2000 milliseconds (2 seconds)
    }

    redirectToSpecRastitelnoList() {
        this.props.history.push(`/SpecRastitelnoList`);
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        const {classes} = this.props;
        return (
            <div>
                <Card style={{boxShadow: "none", border: 0}}>
                    <CardContent className={classes.noPrint}>
                        <h1>Креирај спецификација растително</h1>
                    </CardContent>
                    {this.props.specRastitelno &&
                    <SpecRastitelno
                        specRastitelno={this.props.specRastitelno.specRastitelno}
                        createMode
                        onSubmit={this.saveSpecRastitelno}
                        onCancel={this.redirectToSpecRastitelnoList}
                        user={this.props.user}
                        lookupData={this.props.lookupData}
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        specRastitelno: getSpecRastitelno(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchNewSpecRastitelno, createSpecRastitelnoRequest, fetchLookups}, dispatch)
}

CreateSpecRastitelno.propTypes = {
    dispatch: PropTypes.func
};

CreateSpecRastitelno.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(CreateSpecRastitelno);
