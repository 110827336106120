import React from 'react';
import PropTypes from 'prop-types';
import Application from './Application';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Notification from '../../common/Notification';
import {getApplication} from "../../redux/application/reducer";
import {bindActionCreators, compose} from "redux";
import {createApplicationRequest, fetchNewApplication} from "../../redux/application/fetch";
import {connect} from "react-redux";
import {cleanJsonBody} from "../../redux/helpers";
import {getUser} from "../../redux/user/reducer";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../styles";
export class CreateApplication extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.saveApplication = this.saveApplication.bind(this);
        this.redirectToApplicationList = this.redirectToApplicationList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchNewApplication} = this.props;
        fetchNewApplication();
    }

    saveApplication(values) {
        /*  Create and call updateApplication action */
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)));
        this.props.createApplicationRequest(newValues)
        this.showNotification("Успешно креирана Апликација");
        setTimeout(() => this.redirectToApplicationList(), 1000); //execute greet after 2000 milliseconds (2 seconds)
    }

    redirectToApplicationList() {
        this.props.history.push(`/ApplicationList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardContent className={classes.noPrint}>
                        <h1>Креирај апликација</h1>
                    </CardContent>
                    {this.props.application &&
                    <Application
                        application={this.props.application.application}
                        createMode
                        onSubmit={this.saveApplication}
                        onCancel={this.redirectToApplicationList}
                        user={this.props.user}
                    />}
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        application: getApplication(state),
        user: getUser(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchNewApplication, createApplicationRequest}, dispatch)
}

CreateApplication.propTypes = {
    // actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

CreateApplication.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(CreateApplication);