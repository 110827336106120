import {
    createNewPouStocarstvoControlAction,
    createPouStocarstvoControlAction,
    deletePouStocarstvoControlAction,
    fetchPouStocarstvoControlByIdAction,
    updatePouStocarstvoControlAction
} from "./actions";
import {apiURL, authHeader, requestOptions} from "../helpers";
import {newPouStocarstvoControlData} from "./newPouStocarstvoControlData";


export function fetchPouStocarstvoControlById(pouStocarstvoControlId) {
    return dispatch => {
        fetch(`${apiURL}/kontrolorStocarstvo/${pouStocarstvoControlId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again

                dispatch(fetchPouStocarstvoControlByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewPouStocarstvoControl() {
    const newPouStocarstvoControl = newPouStocarstvoControlData
    return dispatch => {
        dispatch(createNewPouStocarstvoControlAction(newPouStocarstvoControl))
        return newPouStocarstvoControl
    }
}

export function updatePouStocarstvoControlRequest(pouStocarstvoControl, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...pouStocarstvoControl})
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorStocarstvo/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updatePouStocarstvoControlAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function createPouStocarstvoControlRequest(pouStocarstvoControl) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...pouStocarstvoControl})
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorStocarstvo/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(createPouStocarstvoControlAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deletePouStocarstvoControlRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorStocarstvo/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deletePouStocarstvoControlAction());
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}