export const seminja =
    [
        {
            id: "1", ime: "Сопствени органски"
        },
        {
            id: "2", ime: "Купени органски"
        },
        {
            id: "3", ime: "Конвенционални нетретирани"
        },
        {
            id: "4", ime: "Конвенционални третирани"
        }
    ]
