import React from 'react';
import propTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Notification from '../../common/Notification';
import Button from '@material-ui/core/Button';
import ConfirmDelete from '../../common/ConfirmDelete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import format from 'string-format';
import globalStyles from "../styles";
import {Fab, Grid, Input, Typography} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import {getLookups, getlukpsNum} from "../../redux/lookup/reducer";
import {
    createLookupRequest,
    deleteLookupRequest,
    fetchLookupByType,
    fetchLookups,
    updateLookupRequest
} from "../../redux/lookup/fetch";
import * as ALL from "../../common/staticLabels";
import {ADD, SEARCH} from "../../common/staticLabels";
import CustomLabeledInput from "../../components/customLabeledInput";
import {ddLookupTypesData} from "../../mockData/ddLookupTypesData";
import {cleanJsonBody} from "../../redux/helpers";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18
    },
    body: {
        fontSize: 16,
    },

}))(TableCell);

const CustomTableSmallCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18,
        width: 20
    },
    body: {
        fontSize: 16,
        width: 20
    },

}))(TableCell);

export class LookupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteRecord: false,
            id: '',
            pgNo: 1,
            pageSize: 10,
            notify: false,
            message: '',
            error: false,
            lookupFilter: '',
            lookupFilterName: 'шифрарник',
            editModeId: '',
            editModeIme: '',
            type: '',
            templateType: '',
            templateIme: '',

        }
        this.deleteLookup = this.deleteLookup.bind(this);
        this.deleteAfterConfirmation = this.deleteAfterConfirmation.bind(this);
        this.editLookup = this.editLookup.bind(this);
        this.addLookup = this.addLookup.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.handleDDChange = this.handleDDChange.bind(this)
        this.handleDDAdd = this.handleDDAdd.bind(this)
    }

    componentWillMount() {
        const {fetchLookups} = this.props;
        fetchLookups();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.lukpsNum !== nextProps.lukpsNum) {
            this.setState({lookups: nextProps.lookups})
        }
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    addLookup() {
        this.props.history.push(`/CreateLookup`);
    }


    searchLookupByType() {
        let filterField = ""
        const {fetchLookupByType} = this.props;
        if (this.state.lookupFilter !== undefined && this.state.lookupFilter !== null) {
            filterField = this.state.lookupFilter
        }
        fetchLookupByType(filterField);

    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value});
    };

    handleDDChange(name, event, value) {
        event.persist()
        this.setState({[name]: value})
    };

    handleAdd(name, event) {
        event.persist()
        this.setState({})
        this.setState({[name]: event.target.value});
    }

    handleDDAdd(name, event, value) {
        event.persist()
        this.setState({[name]: value});

    };

    saveLookup(type, ime) {
        /*  Create and call updateApplication action */
        // values.ime = this.state.editModeIme;

        const {createLookupRequest} = this.props
        createLookupRequest({ime: ime, type: type})
        this.showNotification("Успешно креиран шифрарник");
        this.setState({lookupFilter: "", templateType: type, templateIme: ''});
    }

    deleteLookup(id, type) {
        this.setState({deleteRecord: true, id: id, type: type});
    }

    deleteAfterConfirmation(deleteConfirmed) {
        this.setState({deleteRecord: false});
        if (deleteConfirmed) {
            const {deleteLookupRequest} = this.props
            deleteLookupRequest(this.state.id, this.state.type)
            this.showNotification("Успешно избришан шифрарник!");
        }
        this.setState({id: ''});
    }

    editLookup(values) {
        values.ime = this.state.editModeIme;
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)));
        const {updateLookupRequest} = this.props
        updateLookupRequest(newValues, values.id)
        this.showNotification("Успешно зачувано!");
        this.enableEditMode('');
        this.setState({editModeId: '', editModeIme: ''})

    }

    enableEditMode(value) {
        this.setState({editModeId: value.id})
    };

    render() {
        const {classes, lookups} = this.props;
        const {notify, message, error} = this.state;

        function getImeById(id) {
            const foundItem = ddLookupTypesData.find(item => item.id === id);
            return foundItem ? foundItem.ime : null;
        }

        return (
            <div>
                {/*<PageBase title="Шифрарници" navigation="">*/}
                <Card>
                    <CardContent>
                        <h1>Шифрарници</h1>
                    </CardContent>
                    <Grid container xs={12} className={classes.container}>
                        <Grid container xs={5} className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.LOOKUP_PREBARAJ}
                                                size={12}
                            />
                        </Grid>
                        <Grid container xs={7} className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.LOOKUP_DODADI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className={classes.container}>
                        <Grid container xs={5} style={{border: "2px solid black"}}>
                            <Grid container xs={6}>
                                <CustomLabeledInput inputType={"dropdown"}
                                                    size={12}
                                                    noBorder={true}
                                                    options={ddLookupTypesData}
                                                    valueInput={this.state.lookupFilter}
                                                    onChangeAction={(event, newValue) => this.handleDDChange("lookupFilter", event, newValue)}
                                />
                            </Grid>
                            <Grid container xs={6} style={{marginBottom: 5, marginTop: 5}}>
                                <Fab
                                    justify="center"
                                    variant="extended"
                                    color="primary"
                                    aria-label="search" className={classes.thinButton}
                                    disabled={this.state.lookupFilter === ""}
                                    onClick={() => this.searchLookupByType()}
                                > <SearchIcon/> {SEARCH + " " + this.state.lookupFilterName}</Fab>
                            </Grid>
                        </Grid>
                        <Grid container xs={7} style={{border: "2px solid black"}}>
                            <Grid container xs={4}>
                                <Grid xs={2}>
                                    <Typography display="block" variant="h6"
                                                className={classes.typographyLineHeight}>Име:</Typography>
                                </Grid>
                                <Grid xs={10}>
                                    <Input placeholder={""}
                                           className={classes.paddedFullWidthInput}
                                           value={this.state.templateIme}
                                           margin={"none"}
                                           onChange={(event) => this.handleAdd("templateIme", event)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={5}>
                                <Grid xs={2}>
                                    <Typography display="block" variant="h6"
                                                className={classes.typographyLineHeight}>Тип:</Typography>
                                </Grid>
                                <Grid xs={10}>
                                    <CustomLabeledInput inputType={"dropdown"}
                                                        size={12}
                                                        noBorder={true}
                                                        options={ddLookupTypesData}
                                                        valueInput={this.state.templateType}
                                                        onChangeAction={(event, newValue) => this.handleDDAdd("templateType", event, newValue)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={3}>
                                <Grid xs={12} style={{marginBottom: 5, marginTop: 5}}>
                                    <Fab
                                        justify="center"
                                        variant="extended"
                                        color="primary"
                                        aria-label="add" className={classes.thinButton}
                                        onClick={() => this.saveLookup(this.state.templateType, this.state.templateIme)}
                                        disabled={this.state.templateIme === "" || this.state.templateType === ""}
                                    >{ADD + " " + this.state.lookupFilterName}</Fab>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/*<CustomTableCell>Број</CustomTableCell>*/}
                                <CustomTableCell>Име</CustomTableCell>
                                <CustomTableCell>Тип</CustomTableCell>
                                <CustomTableSmallCell>Измени/Зачувај</CustomTableSmallCell>
                                <CustomTableSmallCell>Избриши</CustomTableSmallCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lookups && lookups.map(Lookup => {
                                return (
                                    <TableRow key={Lookup.id} className={classes.row}>
                                        {/*<CustomTableCell>{Lookup.id}</CustomTableCell>*/}
                                        <CustomTableCell>
                                            <CustomLabeledInput inputType={"text"}
                                                                size={6}
                                                                noBorder={true}
                                                                disabled={Lookup.id !== this.state.editModeId}
                                                                valueInput={Lookup.ime}
                                                                onChangeAction={(event) => this.handleChange("editModeIme", event)}
                                            />
                                        </CustomTableCell>
                                        <CustomTableCell>{getImeById(Lookup.type)}</CustomTableCell>
                                        {Lookup.id !== this.state.editModeId && <CustomTableCell>
                                            <Button style={globalStyles.editButton} variant="contained" size={"small"}
                                                    aria-label="edit"
                                                    onClick={() => this.enableEditMode(Lookup)}
                                            >
                                                <EditIcon/>
                                            </Button>
                                        </CustomTableCell>}
                                        {Lookup.id === this.state.editModeId && <CustomTableCell>
                                            <Button style={globalStyles.saveButton} variant="contained" size={"small"}
                                                    aria-label="save"
                                                    onClick={() => this.editLookup(Lookup)}
                                            >
                                                <SaveIcon/>
                                            </Button>
                                        </CustomTableCell>}
                                        <CustomTableCell>
                                            <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                    aria-label="delete"
                                                    onClick={() => this.deleteLookup(Lookup.id, Lookup.type)}>
                                                <DeleteIcon/>
                                            </Button>
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <ConfirmDelete resourceHeader="Избриши шифрарник?"
                                   resourceOpis={format("Дали сте сигурни дека сакате да го ибришете шифрарникот'{}'?", this.state.id)}
                                   onModalClose={this.deleteAfterConfirmation}
                                   openDeleteDialog={this.state.deleteRecord}/>

                    <Notification
                        notify={notify}
                        message={message}
                        error={error}
                        closed={this.handleNotificationClosed}
                    />
                </Card>
            </div>
        );
    }
}

LookupList.propTypes = {
    classes: propTypes.object.isRequired,
};

const mapStateToProps = state => ({
    lookups: getLookups(state),
    lukpsNum: getlukpsNum(state),
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchLookups,
        fetchLookupByType,
        createLookupRequest,
        updateLookupRequest,
        deleteLookupRequest
    }, dispatch)
}

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(LookupList)
)