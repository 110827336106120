import {
    createNewSpecStocarstvoAction,
    createSpecStocarstvoAction,
    deleteSpecStocarstvoAction,
    duplicateSpecStocarstvoAction,
    failedCreateSpecStocarstvoAction,
    fetchSpecStocarstvoByIdAction,
    fetchSpecStocarstvoListAction,
    updateSpecStocarstvoAction
} from "./actions";
import {apiURL, authHeader, cleanJsonBody, requestOptions} from "../helpers";
import {newSpecStocarstvoData} from "./newSpecStocarstvoData";

export function fetchSpecStocarstvoList() {
    return dispatch => {
        fetch(`${apiURL}/specStocarstvo/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchSpecStocarstvoListAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchSpecStocarstvoById(specStocarstvoId) {
    return dispatch => {
        fetch(`${apiURL}/specStocarstvo/${specStocarstvoId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchSpecStocarstvoByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewSpecStocarstvo() {
    const newSpecStocarstvo = newSpecStocarstvoData
    return dispatch => {
        dispatch(createNewSpecStocarstvoAction(newSpecStocarstvo))
        return newSpecStocarstvo
    }
}

export function updateSpecStocarstvoRequest(specStocarstvo, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...specStocarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/specStocarstvo/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updateSpecStocarstvoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function duplicateSpecStocarstvoRequest(specStocarstvoId) {

    return dispatch => {
        fetch(`${apiURL}/specStocarstvo/${specStocarstvoId}`, requestOptions())
            .then(res => res.json()).then(res => {
            let newValues = cleanJsonBody(JSON.parse(JSON.stringify(res)), true);
            const postRequestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...newValues})

            };
            fetch(`${apiURL}/specStocarstvo/`, postRequestOptions)
                .then(res => res.json())
                .then(res => {
                    if (!res.id) {
                        throw(res.error);
                    } else {
                        // const specStocarstvoControl = newSpecStocarstvoControlData;
                        // specStocarstvoControl.controlId = res.id;
                        // dispatch(createSpecStocarstvoControlRequest(specStocarstvoControl));
                    }
                    dispatch(duplicateSpecStocarstvoAction(res));
                    return res;
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }
}

export function createSpecStocarstvoRequest(specStocarstvo) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...specStocarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/specStocarstvo/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    dispatch(failedCreateSpecStocarstvoAction(res));
                }
                if (res.specStocarstvo) {
                    dispatch(createSpecStocarstvoAction(res));
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deleteSpecStocarstvoRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/specStocarstvo/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deleteSpecStocarstvoAction()).then(dispatch(fetchSpecStocarstvoList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}