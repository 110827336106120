import {
    CHANGE_PASSWORD,
    CREATE_NEW_USER,
    CREATE_USER,
    DELETE_USER,
    FAILED_CHANGE_PASSWORD,
    FAILED_CREATE_USER,
    FAILED_FORGOT_PASSWORD,
    FAILED_LOGIN,
    FAILED_REGISTER,
    FAILED_UPDATE_USER,
    FETCH_ALL_USERS,
    FETCH_REGISTER_USER,
    FETCH_USER,
    FORGOT_PASSWORD,
    LOGIN,
    LOGOUT,
    REGISTER_USER,
    UPDATE_USER
} from "../actionTypes";

export const loginAction = (payload) => ({
    type: LOGIN,
    payload: {
        user: payload,
    },
})


export const logoutAction = () => ({
    type: LOGOUT,
    payload: {
        user: null,
    },
})

export const failedLoginAction = (payload) => ({
    type: FAILED_LOGIN,
    payload: {
        loginError: true,
        message: payload.details
    },
})

export const fetchUserListAction = (payload) => ({
    type: FETCH_ALL_USERS,
    payload: {
        userList: payload,
    },
})

export const fetchUserByIdAction = (payload) => ({
    type: FETCH_USER,
    payload: {
        selectedUser: payload,
    },
})
export const createNewUserAction = (payload) => ({
    type: CREATE_NEW_USER,
    payload: {
        selectedUser: payload,
    },
})

export const createUserAction = (payload) => ({
    type: CREATE_USER,
    payload: {
        selectedUser: payload,
        error: false
    },
})

export const failedCreateUserAction = (payload) => ({
    type: FAILED_CREATE_USER,
    payload: {
        selectedUser: payload,
        error: true
    },
})

export const fetchRegisterUserAction = (payload) => ({
    type: FETCH_REGISTER_USER,
    payload: {
        selectedUser: payload
    },
})

export const registerUserAction = (payload) => ({
    type: REGISTER_USER,
    payload: {
        selectedUser: payload,
        error: false
    },
})

export const failedRegisterUserAction = (payload) => ({
    type: FAILED_REGISTER,
    payload: {
        selectedUser: payload,
        error: true
    },
})

export const forgotPasswordAction = () => ({
    type: FORGOT_PASSWORD,
    payload: {
        error: false
    },

})

export const failedForgotPasswordAction = () => ({
    type: FAILED_FORGOT_PASSWORD,
    payload: {
        error: true
    },
})

export const updateUserAction = (payload) => ({
    type: UPDATE_USER,
    payload: {
        selectedUser: payload,
        error: false
    },
})

export const failedUpdateUserAction = (payload) => ({
    type: FAILED_UPDATE_USER,
    payload: {
        selectedUser: payload,
        error: true
    },
})

export const changePasswordAction = (payload) => ({
    type: CHANGE_PASSWORD,
    payload: {
        selectedUser: payload,
        error: false
    },
})

export const failedChangePasswordAction = (payload) => ({
    type: FAILED_CHANGE_PASSWORD,
    payload: {
        changePassError: true,
        message: payload.details
    },
})

export const deleteUserAction = () => ({
    type: DELETE_USER,
    payload: {
        selectedUser: null,
        userNum: 0
    },
})