import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Notification from "../../../common/Notification";
import {getSpecStocarstvo} from "../../../redux/specStocarstvo/reducer";
import {getUser} from "../../../redux/user/reducer";
import {bindActionCreators, compose} from "redux";
import {createSpecStocarstvoRequest, fetchNewSpecStocarstvo} from "../../../redux/specStocarstvo/fetch";
import {connect} from "react-redux";
import SpecStocarstvo from "./SpecStocarstvo";
import {cleanJsonSpecifikacija} from "../../../redux/helpers";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";
import {EditSpecStocarstvo} from "./EditSpecStocarstvo";

export class CreateSpecStocarstvo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.saveSpecStocarstvo = this.saveSpecStocarstvo.bind(this);
        this.redirectToSpecStocarstvoList = this.redirectToSpecStocarstvoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchNewSpecStocarstvo, fetchLookups} = this.props;
        fetchNewSpecStocarstvo();
        fetchLookups();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error === false) {
            this.showNotification("Успешно креирана спецификација!");
            setTimeout(() => this.redirectToSpecStocarstvoList(), 1000); //execute greet after 2000 milliseconds (2 seconds)
        } else if (nextProps.error === true) {
            this.showNotification("Грешка при креирање на спецификацијата!", false)
        }
    }

    saveSpecStocarstvo(values) {
        /*  Create and call updateSpecStocarstvo action */
        values.creatorId = this.props.user.id
        let newValues = cleanJsonSpecifikacija(JSON.parse(JSON.stringify(values)));
        this.props.createSpecStocarstvoRequest(newValues)
        this.showNotification("Успешно креирана спецификација!");
        setTimeout(() => this.redirectToSpecStocarstvoList(), 1000); //execute greet after 2000 milliseconds (2 seconds)
    }

    redirectToSpecStocarstvoList() {
        this.props.history.push(`/SpecStocarstvoList`);
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Card style={{boxShadow: "none", border: 0}}>
                    <CardContent className={classes.noPrint}>
                        <h1>Креирај спецификација сточарство</h1>
                    </CardContent>
                    {this.props.specStocarstvo &&
                        <SpecStocarstvo
                            specStocarstvo={this.props.specStocarstvo.specStocarstvo}
                            createMode
                            onSubmit={this.saveSpecStocarstvo}
                            onCancel={this.redirectToSpecStocarstvoList}
                            user={this.props.user}
                            lookupData={this.props.lookupData}
                        />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        specStocarstvo: getSpecStocarstvo(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchNewSpecStocarstvo, createSpecStocarstvoRequest, fetchLookups}, dispatch)
}

CreateSpecStocarstvo.propTypes = {
    dispatch: PropTypes.func
};

CreateSpecStocarstvo.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(CreateSpecStocarstvo);
