export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const FETCH_ALL_APPLICATIONS = 'FETCH_ALL_APPLICATIONS';
export const FETCH_APPLICATION = 'FETCH_APPLICATION';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_NEW_APPLICATION = 'CREATE_NEW_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';

export const FETCH_ALL_POU_RASTITELNO = 'FETCH_ALL_POU_RASTITELNO';
export const FETCH_POU_RASTITELNO = 'FETCH_POU_RASTITELNO';
export const CREATE_POU_RASTITELNO = 'CREATE_POU_RASTITELNO';
export const DUPLICATE_POU_RASTITELNO = 'DUPLICATE_POU_RASTITELNO';
export const CREATE_NEW_POU_RASTITELNO = 'CREATE_NEW_POU_RASTITELNO';
export const UPDATE_POU_RASTITELNO = 'UPDATE_POU_RASTITELNO';
export const DELETE_POU_RASTITELNO = 'DELETE_POU_RASTITELNO';

export const FETCH_POU_RASTITELNO_CONTROL = 'FETCH_POU_RASTITELNO_CONTROL';
export const CREATE_POU_RASTITELNO_CONTROL = 'CREATE_POU_RASTITELNO_CONTROL';
export const CREATE_NEW_POU_RASTITELNO_CONTROL = 'CREATE_NEW_POU_RASTITELNO_CONTROL';
export const UPDATE_POU_RASTITELNO_CONTROL = 'UPDATE_POU_RASTITELNO_CONTROL';
export const DELETE_POU_RASTITELNO_CONTROL = 'DELETE_POU_RASTITELNO_CONTROL';
export const CLEAR_POU_RASTITELNO_CONTROL = 'CLEAR_POU_RASTITELNO_CONTROL';

export const FETCH_ALL_POU_STOCARSTVO = 'FETCH_ALL_POU_STOCARSTVO';
export const FETCH_POU_STOCARSTVO = 'FETCH_POU_STOCARSTVO';
export const CREATE_POU_STOCARSTVO = 'CREATE_POU_STOCARSTVO';
export const DUPLICATE_POU_STOCARSTVO = 'DUPLICATE_POU_STOCARSTVO';
export const CREATE_NEW_POU_STOCARSTVO = 'CREATE_NEW_POU_STOCARSTVO';
export const UPDATE_POU_STOCARSTVO = 'UPDATE_POU_STOCARSTVO';
export const DELETE_POU_STOCARSTVO = 'DELETE_POU_STOCARSTVO';

export const FETCH_POU_STOCARSTVO_CONTROL = 'FETCH_POU_STOCARSTVO_CONTROL';
export const CREATE_POU_STOCARSTVO_CONTROL = 'CREATE_POU_STOCARSTVO_CONTROL';
export const CREATE_NEW_POU_STOCARSTVO_CONTROL = 'CREATE_NEW_POU_STOCARSTVO_CONTROL';
export const UPDATE_POU_STOCARSTVO_CONTROL = 'UPDATE_POU_STOCARSTVO_CONTROL';
export const DELETE_POU_STOCARSTVO_CONTROL = 'DELETE_POU_STOCARSTVO_CONTROL';
export const CLEAR_POU_STOCARSTVO_CONTROL = 'CLEAR_POU_STOCARSTVO_CONTROL';

export const FETCH_ALL_POU_PCELARSTVO = 'FETCH_ALL_POU_PCELARSTVO';
export const FETCH_POU_PCELARSTVO = 'FETCH_POU_PCELARSTVO';
export const CREATE_POU_PCELARSTVO = 'CREATE_POU_PCELARSTVO';
export const CREATE_NEW_POU_PCELARSTVO = 'CREATE_NEW_POU_PCELARSTVO';
export const UPDATE_POU_PCELARSTVO = 'UPDATE_POU_PCELARSTVO';
export const DUPLICATE_POU_PCELARSTVO = 'DUPLICATE_POU_PCELARSTVO';
export const DELETE_POU_PCELARSTVO = 'DELETE_POU_PCELARSTVO';

export const FETCH_POU_PCELARSTVO_CONTROL = 'FETCH_POU_PCELARSTVO_CONTROL';
export const CREATE_POU_PCELARSTVO_CONTROL = 'CREATE_POU_PCELARSTVO_CONTROL';
export const CREATE_NEW_POU_PCELARSTVO_CONTROL = 'CREATE_NEW_POU_PCELARSTVO_CONTROL';
export const UPDATE_POU_PCELARSTVO_CONTROL = 'UPDATE_POU_PCELARSTVO_CONTROL';
export const DELETE_POU_PCELARSTVO_CONTROL = 'DELETE_POU_PCELARSTVO_CONTROL';
export const CLEAR_POU_PCELARSTVO_CONTROL = 'CLEAR_POU_PCELARSTVO_CONTROL';

export const FETCH_ALL_LOOKUPS = 'FETCH_ALL_LOOKUPS';
export const FETCH_LOOKUP = 'FETCH_LOOKUP';
export const CREATE_LOOKUP = 'CREATE_LOOKUP';
export const CREATE_NEW_LOOKUP = 'CREATE_NEW_LOOKUP';
export const UPDATE_LOOKUP = 'UPDATE_LOOKUP';
export const DELETE_LOOKUP = 'DELETE_LOOKUP';
export const FETCH_LOOKUP_BY_TYPE = 'FETCH_LOOKUP_BY_TYPE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FAILED_UPDATE_USER = 'FAILED_UPDATE_USER';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_USER = 'CREATE_USER';
export const FAILED_CREATE_USER = 'FAILED_CREATE_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const FETCH_REGISTER_USER = 'FETCH_REGISTER_USER';
export const FAILED_REGISTER = 'FAILED_REGISTER';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FAILED_CHANGE_PASSWORD = 'FAILED_CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FAILED_FORGOT_PASSWORD = 'FAILED_FORGOT_PASSWORD';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';


export const FETCH_ALL_SPEC_RASTITELNO = 'FETCH_ALL_SPEC_RASTITELNO';
export const FETCH_SPEC_RASTITELNO = 'FETCH_SPEC_RASTITELNO';
export const CREATE_SPEC_RASTITELNO = 'CREATE_SPEC_RASTITELNO';
export const FAILED_CREATE_SPEC_RASTITELNO = 'FAILED_CREATE_SPEC_RASTITELNO';
export const DUPLICATE_SPEC_RASTITELNO = 'DUPLICATE_SPEC_RASTITELNO';
export const CREATE_NEW_SPEC_RASTITELNO = 'CREATE_NEW_SPEC_RASTITELNO';
export const UPDATE_SPEC_RASTITELNO = 'UPDATE_SPEC_RASTITELNO';
export const DELETE_SPEC_RASTITELNO = 'DELETE_SPEC_RASTITELNO';

export const FETCH_ALL_SPEC_STOCARSTVO = 'FETCH_ALL_SPEC_STOCARSTVO';
export const FETCH_SPEC_STOCARSTVO = 'FETCH_SPEC_STOCARSTVO';
export const CREATE_SPEC_STOCARSTVO = 'CREATE_SPEC_STOCARSTVO';
export const FAILED_CREATE_SPEC_STOCARSTVO = 'FAILED_CREATE_SPEC_STOCARSTVO';
export const DUPLICATE_SPEC_STOCARSTVO = 'DUPLICATE_SPEC_STOCARSTVO';
export const CREATE_NEW_SPEC_STOCARSTVO = 'CREATE_NEW_SPEC_STOCARSTVO';
export const UPDATE_SPEC_STOCARSTVO = 'UPDATE_SPEC_STOCARSTVO';
export const DELETE_SPEC_STOCARSTVO = 'DELETE_SPEC_STOCARSTVO';

export const FETCH_ALL_SPEC_PCELARSTVO = 'FETCH_ALL_SPEC_PCELARSTVO';
export const FETCH_SPEC_PCELARSTVO = 'FETCH_SPEC_PCELARSTVO';
export const CREATE_SPEC_PCELARSTVO = 'CREATE_SPEC_PCELARSTVO';
export const FAILED_CREATE_SPEC_PCELARSTVO = 'FAILED_CREATE_SPEC_PCELARSTVO';
export const DUPLICATE_SPEC_PCELARSTVO = 'DUPLICATE_SPEC_PCELARSTVO';
export const CREATE_NEW_SPEC_PCELARSTVO = 'CREATE_NEW_SPEC_PCELARSTVO';
export const UPDATE_SPEC_PCELARSTVO = 'UPDATE_SPEC_PCELARSTVO';
export const DELETE_SPEC_PCELARSTVO = 'DELETE_SPEC_PCELARSTVO';