import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import {NavLink} from 'react-router-dom';
import {Assignment} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {getUser} from "../redux/user/reducer";
import {connect} from "react-redux";

const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main
        },
        primary: {
            color: theme.palette.common.white
        }, icon: {
            color: theme.palette.common.white
        },
    },
    submenuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main
        },
        primary: {
            color: theme.palette.common.white
        }, icon: {
            color: theme.palette.common.white
        },
        marginLeft: "10px"
    }
});

class Menu extends React.Component {

    isUserAdmin() {
        if (this.props.user && this.props.user.user.authorities) {
            return this.props.user.user.authorities.some(u => u.authority === "USER_ADMIN")
        }
        return false;
    }

    isLookupAdmin() {
        if (this.props.user && this.props.user.user.authorities) {
            return this.props.user.user.authorities.some(u => u.authority === "LOOKUP_ADMIN")
        }
        return false;
    }

    render() {
        const isUserAdmin = this.isUserAdmin()
        const isLookupAdmin = this.isLookupAdmin()
        const {classes} = this.props;
        return (
            <Paper>
                <MenuList>
                    {/*<NavLink to="/LookupList" style={{textDecoration: 'none'}}>*/}
                    {/*    <MenuItem className={classes.menuItem}>*/}
                    {/*        <ListItemIcon className={classes.icon}>*/}
                    {/*            <Assignment/>*/}
                    {/*        </ListItemIcon>*/}
                    {/*        <ListItemText classes={{primary: classes.primary}} inset primary="Шифрарници"/>*/}
                    {/*    </MenuItem>*/}
                    {/*</NavLink>*/}
                    {/*<Divider/>*/}
                    {/*<NavLink hidden={!isNenad} to="/CreateApplication" style={{textDecoration: 'none'}}>*/}
                    {/*    <MenuItem className={classes.menuItem}>*/}
                    {/*        <ListItemIcon className={classes.icon}>*/}
                    {/*            <AddIcon/>*/}
                    {/*        </ListItemIcon>*/}
                    {/*        <ListItemText classes={{primary: classes.primary}} inset primary="Aпликација"/>*/}
                    {/*    </MenuItem>*/}
                    {/*</NavLink>*/}
                    <NavLink to="/ApplicationList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Апликации"/>
                        </MenuItem>
                    </NavLink>

                    <Divider/>


                    <h4 style={{textAlign: "center"}}>ПОУ</h4>
                    <NavLink to="/PouRastitelnoList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Растително"/>
                        </MenuItem>
                    </NavLink>
                    <NavLink to="/PouStocarstvoList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Сточарство"/>
                        </MenuItem>
                    </NavLink>
                    <NavLink to="/PouPcelarstvoList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Пчеларство"/>
                        </MenuItem>
                    </NavLink>
                    <Divider/>

                    <h4 style={{textAlign: "center"}}>Спецификации</h4>
                    <NavLink to="/SpecRastitelnoList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Растително"/>
                        </MenuItem>
                    </NavLink>
                    <NavLink to="/SpecStocarstvoList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Сточарство"/>
                        </MenuItem>
                    </NavLink>
                    <NavLink to="/SpecPcelarstvoList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Пчеларство"/>
                        </MenuItem>
                    </NavLink>


                    <Divider/>
                    <h4 style={{textAlign: "center"}}>Корисници</h4>
                    <NavLink hidden={!isUserAdmin} to="/UserList" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Листа корисници"/>
                        </MenuItem>
                    </NavLink>
                    <NavLink to="/EditOwnUser" style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Промени лозинка"/>
                        </MenuItem>
                    </NavLink>
                    <Divider/>
                    <NavLink to="/LookupList" hidden={!isLookupAdmin} style={{textDecoration: 'none'}}>
                        <MenuItem className={classes.menuItem}>
                            <ListItemIcon className={classes.icon}>
                                <Assignment/>
                            </ListItemIcon>
                            <ListItemText classes={{primary: classes.primary}} inset primary="Шифрарници"/>
                        </MenuItem>
                    </NavLink>
                </MenuList>
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    user: getUser(state),
})

export default withStyles(styles)(
    connect(mapStateToProps)(Menu)
);

