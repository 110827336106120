import {
    CREATE_NEW_POU_STOCARSTVO,
    CREATE_POU_STOCARSTVO,
    DELETE_POU_STOCARSTVO,
    DUPLICATE_POU_STOCARSTVO,
    FETCH_ALL_POU_STOCARSTVO,
    FETCH_POU_STOCARSTVO,
    UPDATE_POU_STOCARSTVO
} from "../actionTypes";


export const fetchPouStocarstvoListAction = (payload) => ({
    type: FETCH_ALL_POU_STOCARSTVO,
    payload: {
        pouStocarstvoList: payload,
    },
})

export const fetchPouStocarstvoByIdAction = (payload) => ({
    type: FETCH_POU_STOCARSTVO,
    payload: {
        pouStocarstvo: payload,
    },
})
export const createNewPouStocarstvoAction = (payload) => ({
    type: CREATE_NEW_POU_STOCARSTVO,
    payload: {
        pouStocarstvo: payload,
    },
})

export const createPouStocarstvoAction = (payload) => ({
    type: CREATE_POU_STOCARSTVO,
    payload: {
        pouStocarstvo: payload,
    },
})

export const duplicatePouStocarstvoAction = (payload) => ({
    type: DUPLICATE_POU_STOCARSTVO,
    payload: {
        pouStocarstvo: payload,
    },
})
export const updatePouStocarstvoAction = (payload) => ({
    type: UPDATE_POU_STOCARSTVO,
    payload: {
        pouStocarstvo: payload,
    },
})
export const deletePouStocarstvoAction = () => ({
    type: DELETE_POU_STOCARSTVO,
    payload: {
        pouStocarstvo: null,
        pouNum: 0
    },
})