import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Notification from "../common/Notification";
import PropTypes from "prop-types";
import CustomLabeledInput from "../components/customLabeledInput";
import * as ALL from "../common/staticLabels";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {forgotPasswordRequest} from "../redux/user/fetch";
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "./styles";
import {getError} from "../redux/user/reducer";

export class ForgotPassword extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.state = {notify: false, message: '', error: false};
    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value})
    };

    forgotPassword(values) {
        this.props.forgotPasswordRequest(values.username)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error === false) {
            this.showNotification("Вашата нова лозинка е испратена на корисничката адреса! Проверете ја Вашата е-адреса!", true);
            setTimeout(() => this.props.history.push("/"), 1000); //execute greet after 2000 milliseconds (2 seconds)
        }
        if (nextProps.error === true) {
            this.showNotification("Внесената корисничка адреса не постои, обидете се повторно!", false)
        }
    }

    redirectToLogin() {
        this.props.history.push(`/login`);
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Card>
                    <CardContent>
                        <h1>Промена на заборавена лозинка</h1>
                        <h2>Внесете ја е-адресата на вашата корисничка сметка. На вашата е-адреса ќе ја добиете новата
                            лозинка. </h2>
                    </CardContent>
                    <Grid container className={classes.container}>

                        <CustomLabeledInput labelInput={ALL.USERNAME}
                                            inputType={"text"}
                                            size={10}
                                            multilineInput={true}
                                            valueInput={this.state.username}
                                            onChangeAction={(event) => this.handleChange('username', event)}
                        />
                        < Button
                            classes={{
                                root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                            variant="contained"
                            size={"large"}
                            aria-label="create"
                            onClick={() => this.forgotPassword(this.state)}>
                            Испрати
                        </Button>
                    </Grid>
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        )
            ;
    }
}

const mapStateToProps = state => ({
    error: getError(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({forgotPasswordRequest}, dispatch)
}

ForgotPassword.propTypes = {
    dispatch: PropTypes.func
};

ForgotPassword.contextTypes = {
    router: PropTypes.object
};

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
)
