import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './pages/Header';
import LeftDrawer from './pages/LeftDrawer'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getLoggedIn, getUser} from "./redux/user/reducer";
import {withRouter} from "react-router-dom";
import {fetchLogin, logoutRequest} from "./redux/user/fetch";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "rgb(132,212,149)",
            main: "rgb(132,212,149)",
            dark: "rgb(132,212,149)",
        },
        secondary: {
            light: "rgb(104,193,183)",
            main: "rgb(104,193,183)",
            dark: "rgb(104,193,183)",
        },
        delete: {
            light: "rgb(236,135,109)",
            main: "rgb(236,135,109)",
            dark: "rgb(236,135,109)",
        },
    },
});

class App extends Component {
    constructor(props) {
        super(props);
        this.handleChangeRequestNavDrawer = this.handleChangeRequestNavDrawer.bind(this)
        this.logout = this.logout.bind(this)
        this.state = {navDrawerOpen: false};
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.width !== nextProps.width) {
            this.setState({navDrawerOpen: nextProps.width === 'xl' || nextProps.width === 'lg'});
        }
        if (nextProps.loggedIn !== true) {
            this.setState({navDrawerOpen: false})
        }
    }

    handleChangeRequestNavDrawer() {
        this.setState({
            navDrawerOpen: !this.state.navDrawerOpen
        });
    }

    logout() {
        this.setState({
            navDrawerOpen: false
        });
        const {logoutRequest} = this.props
        logoutRequest()
    }


    render() {
        let {navDrawerOpen} = this.state;
        const paddingLeftDrawerOpen = 280;

        const styles = {
            container: {
                margin: '80px 20px 20px 15px',
                paddingLeft: navDrawerOpen && this.props.width !== 'sm' ? paddingLeftDrawerOpen : 0
            }
        };
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <div>
                    <Header navDrawerOpen={this.state.navDrawerOpen}
                            logout={this.logout}
                            paddingLeft={styles.container.paddingLeft}
                            handleChangeRequestNavDrawer={this.handleChangeRequestNavDrawer}/>

                    <LeftDrawer navDrawerOpen={this.props.loggedIn && navDrawerOpen}/>
                    <div style={styles.container}>
                        {this.props.children}
                    </div>
                </div>

            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    user: getUser(state),
    loggedIn: getLoggedIn(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchLogin, logoutRequest}, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));