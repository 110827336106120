import {
    CREATE_LOOKUP,
    CREATE_NEW_LOOKUP,
    DELETE_LOOKUP,
    FETCH_ALL_LOOKUPS,
    FETCH_LOOKUP,
    FETCH_LOOKUP_BY_TYPE,
    UPDATE_LOOKUP
} from "../actionTypes";

const initialState = {
    lookups: [],
    lookup: null,
};

export function lookupReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_LOOKUPS: {
            const lukps = action.payload.lookups
            return {
                ...state,
                lookup: null,
                lookups: lukps,
                lukpsNum: lukps ? lukps.length : 0
            };
        }
        case FETCH_LOOKUP_BY_TYPE: {
            const lukps = action.payload.lookup
            return {
                ...state,
                lookup: null,
                lookups: lukps,
                lukpsNum: lukps ? lukps.length : 0
            };
        }
        case FETCH_LOOKUP: {
            return {
                ...state,
                lookup: action.payload
            };
        }
        case UPDATE_LOOKUP: {
            return {
                ...state,
                lookup: action.payload
            };
        }
        case CREATE_NEW_LOOKUP: {
            return {
                ...state,
                lookup: action.payload
            };
        }
        case CREATE_LOOKUP: {
            return {
                ...state,
                lookup: action.payload
            };
        }
        case DELETE_LOOKUP: {
            return {
                ...state,
                lookup: action.payload
            };
        }
        default:
            return state;
    }
}

export const getLookups = state => state.lookup.lookups;
export const getlukpsNum = state => state.lookup.lukpsNum;
export const getLookup = state => state.lookup.lookup;

export const getLookupMap = state => {
    const typeMap = {};

    for (const object of state.lookup.lookups) {
        const {type, ...otherValues} = object;

        if (typeMap[type]) {
            typeMap[type].push(otherValues);
        } else {
            typeMap[type] = [otherValues];
        }
    }

    return Object.keys(typeMap).length > 0 ? typeMap : undefined;
}