export const newSpecStocarstvoData =
    {
        "creatorId": "",
        "createdAt": "",
        "broj_sertifikat": "",
        "pouId": "",
        "pouName": "",
        "kapaciteti": [
            {
                "odgleduvaliste": "",
                "rbo": "",
                "vid_zivotno": "",
                "broj_zivotni": "",
                "status": ""
            }
        ],
        "proizvodstvo": [
            {
                "proizvod": "",
                "kolicina": "",
                "status": ""
            }
        ]
    }