import {
    CREATE_NEW_SPEC_STOCARSTVO,
    CREATE_SPEC_STOCARSTVO,
    DELETE_SPEC_STOCARSTVO,
    DUPLICATE_SPEC_STOCARSTVO,
    FAILED_CREATE_SPEC_STOCARSTVO,
    FETCH_ALL_SPEC_STOCARSTVO,
    FETCH_SPEC_STOCARSTVO,
    UPDATE_SPEC_STOCARSTVO
} from "../actionTypes";

export const fetchSpecStocarstvoListAction = (payload) => ({
    type: FETCH_ALL_SPEC_STOCARSTVO,
    payload: {
        specStocarstvoList: payload,
    },
})

export const fetchSpecStocarstvoByIdAction = (payload) => ({
    type: FETCH_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: payload,
    },
})
export const createNewSpecStocarstvoAction = (payload) => ({
    type: CREATE_NEW_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: payload,
    },
})

export const createSpecStocarstvoAction = (payload) => ({
    type: CREATE_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: payload,
        error: false
    },
})

export const failedCreateSpecStocarstvoAction = (payload) => ({
    type: FAILED_CREATE_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: payload,
        error: true
    },
})

export const duplicateSpecStocarstvoAction = (payload) => ({
    type: DUPLICATE_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: payload,
    },
})

export const updateSpecStocarstvoAction = (payload) => ({
    type: UPDATE_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: payload,
    },
})
export const deleteSpecStocarstvoAction = () => ({
    type: DELETE_SPEC_STOCARSTVO,
    payload: {
        specStocarstvo: null,
        specNum: 0
    },
})