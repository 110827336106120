import {
    CREATE_APPLICATION,
    CREATE_NEW_APPLICATION,
    DELETE_APPLICATION,
    FETCH_ALL_APPLICATIONS,
    FETCH_APPLICATION,
    UPDATE_APPLICATION
} from "../actionTypes";

const initialState = {
    applications: [],
    application: null,
};

export function applicationReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_APPLICATIONS: {
            const apps = action.payload.applications
            return {
                ...state,
                application: null,
                applications: apps,
                appNum: apps ? apps.length : 0
            };
        }
        case FETCH_APPLICATION: {
            return {
                ...state,
                application: action.payload
            };
        }
        case UPDATE_APPLICATION: {
            return {
                ...state,
                application: action.payload
            };
        }
        case CREATE_NEW_APPLICATION: {
            return {
                ...state,
                application: action.payload
            };
        }
        case CREATE_APPLICATION: {
            return {
                ...state,
                application: action.payload
            };
        }
        case DELETE_APPLICATION: {
            return {
                ...state,
                application: action.payload
            };
        }
        default:
            return state;
    }
}

export const getApplications = state => state.application.applications;
export const getAppNum = state => state.application.appNum;
export const getApplication = state => state.application.application;
