import {
    CLEAR_POU_RASTITELNO_CONTROL,
    CREATE_NEW_POU_RASTITELNO_CONTROL,
    CREATE_POU_RASTITELNO_CONTROL,
    DELETE_POU_RASTITELNO_CONTROL,
    FETCH_POU_RASTITELNO_CONTROL,
    UPDATE_POU_RASTITELNO_CONTROL
} from "../actionTypes";

const initialState = {
    pouRastitelnoControl: null
};

export function pouRastitelnoControlReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_POU_RASTITELNO_CONTROL: {
            return {
                ...state,
                pouRastitelnoControl: action.payload
            };
        }
        case UPDATE_POU_RASTITELNO_CONTROL: {
            return {
                ...state,
                pouRastitelnoControl: action.payload
            };
        }
        case CREATE_NEW_POU_RASTITELNO_CONTROL: {
            return {
                ...state,
                pouRastitelnoControl: action.payload
            };
        }
        case CREATE_POU_RASTITELNO_CONTROL: {
            return {
                ...state,
                pouRastitelnoControl: action.payload
            };
        }
        case DELETE_POU_RASTITELNO_CONTROL: {
            return {
                ...state,
                pouRastitelnoControl: action.payload
            };
        }
        case CLEAR_POU_RASTITELNO_CONTROL: {
            return {
                ...state,
                pouRastitelnoControl: null
            };
        }
        default:
            return state;
    }
}

export const getPouRastitelnoControl = state => state.pouRastitelnoControl.pouRastitelnoControl;