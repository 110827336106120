export const prerabotkaData =
    [
        {
            id: "1", ime: "јаболко"
        },
        {
            id: "2", ime: "круша"
        },
        {
            id: "3", ime: "слива"
        },
        {
            id: "4", ime: "праска"
        },
        {
            id: "5", ime: "вишна"
        },
        {
            id: "6", ime: "кајсија"
        },
        {
            id: "7", ime: "цреша"
        },
        {
            id: "8", ime: "јагода на отворено"
        },
        {
            id: "9", ime: "малина"
        },
        {
            id: "10", ime: "капина"
        },
        {
            id: "11", ime: "боровинка"
        },
        {
            id: "12", ime: "дуња"
        },
        {
            id: "13", ime: "мушмула"
        },
        {
            id: "14", ime: "актинија"
        },
        {
            id: "15", ime: "маслинка"
        },
        {
            id: "16", ime: "калинка"
        },
        {
            id: "17", ime: "јапонско јаболко"
        },
        {
            id: "18", ime: "смоква"
        },
        {
            id: "19", ime: "аронија"
        },
        {
            id: "20", ime: "шипинка"
        },
        {
            id: "21", ime: "рибизла"
        },
    ]