export const zakluchok =
    [
        {
            id: "1",
            ime: "Нема несоодветност. Ја препорачувам компанијата за сертификација."
        },
        {
            id: "2",
            ime: "Несоодветноси, кои ќе се корегираат по издавање на сертификат, при што може да влијаат на промена на статусот"
        },
        {
            id: "3",
            ime: "Погоре споменатите несоодветности треба да се корегираат пред издавање на сертификат."
        },
        {
            id: "4",
            ime: "Треба да се изврши нова контрола, за да се потврдат имплементациите на потребните корективни активности. Сертификатот ќе се издаде само после тоа."
        },
        {
            id: "5",
            ime: "Операторот засега не е за сертифицирање."
        }
    ]