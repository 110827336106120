import {
    createNewSpecRastitelnoAction,
    createSpecRastitelnoAction,
    deleteSpecRastitelnoAction,
    duplicateSpecRastitelnoAction,
    failedCreateSpecRastitelnoAction,
    fetchSpecRastitelnoByIdAction,
    fetchSpecRastitelnoListAction,
    updateSpecRastitelnoAction
} from "./actions";
import {apiURL, authHeader, cleanJsonBody, requestOptions} from "../helpers";
import {newSpecRastitelnoData} from "./newSpecRastitelnoData";

export function fetchSpecRastitelnoList() {
    return dispatch => {
        fetch(`${apiURL}/specRastitelno/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchSpecRastitelnoListAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchSpecRastitelnoById(specRastitelnoId) {
    return dispatch => {
        fetch(`${apiURL}/specRastitelno/${specRastitelnoId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchSpecRastitelnoByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewSpecRastitelno() {
    const newSpecRastitelno = newSpecRastitelnoData
    return dispatch => {
        dispatch(createNewSpecRastitelnoAction(newSpecRastitelno))
        return newSpecRastitelno
    }
}

export function updateSpecRastitelnoRequest(specRastitelno, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...specRastitelno})
    };

    return dispatch => {
        fetch(`${apiURL}/specRastitelno/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updateSpecRastitelnoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function duplicateSpecRastitelnoRequest(specRastitelnoId) {

    return dispatch => {
        fetch(`${apiURL}/specRastitelno/${specRastitelnoId}`, requestOptions())
            .then(res => res.json()).then(res => {
            let newValues = cleanJsonBody(JSON.parse(JSON.stringify(res)), true);
            const postRequestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...newValues})

            };
            fetch(`${apiURL}/specRastitelno/`, postRequestOptions)
                .then(res => res.json())
                .then(res => {
                    if (!res.id) {
                        throw(res.error);
                    } else {
                        // const specRastitelnoControl = newSpecRastitelnoControlData;
                        // specRastitelnoControl.controlId = res.id;
                        // dispatch(createSpecRastitelnoControlRequest(specRastitelnoControl));
                    }
                    dispatch(duplicateSpecRastitelnoAction(res));
                    return res;
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }
}

export function createSpecRastitelnoRequest(specRastitelno) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...specRastitelno})
    };

    return dispatch => {
        fetch(`${apiURL}/specRastitelno/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    dispatch(failedCreateSpecRastitelnoAction(res));
                }
                if (res.specStocarstvo) {
                    dispatch(createSpecRastitelnoAction(res));
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deleteSpecRastitelnoRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/specRastitelno/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deleteSpecRastitelnoAction()).then(dispatch(fetchSpecRastitelnoList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}