import {Checkbox, FormGroup, Grid, Input, Typography} from "@material-ui/core";
import React, {Component} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../pages/styles";

class CustomLabelCheckboxInput extends Component {

    render() {
        const {classes} = this.props;
        const {labelDesc, valueDesc, valueCheck, changeActionDesc, changeActionCheck, options} = this.props

        return (
            <Grid container xs={12} style={{border: "1px solid black"}}>
                {labelDesc &&
                <Typography variant="h6" className={classes.typographyLineHeight}>{labelDesc}</Typography>}
                {labelDesc &&
                <Input placeholder={""}
                       value={valueDesc}
                       margin={"none"}
                       defaultValue={""}
                       className={classes.paddedInput}
                       onChange={changeActionDesc}
                       multiline={true}
                />
                }
                <FormGroup>
                    {
                        options.map((item) => (
                                < FormControlLabel
                                    style={{justifyContent: 'flex-end', marginLeft: '0px'}}
                                    control={
                                        <Checkbox
                                            checked={valueCheck ? valueCheck.includes(item.ime) : false}
                                            onChange={changeActionCheck}
                                            value={item.ime}
                                        />
                                    }
                                    labelPlacement="start"
                                    label={<Typography className={classes.paddedTypography}> {item.ime}</Typography>}

                                />
                            )
                        )}
                </FormGroup>
            </Grid>
        )
    }
}

export default withStyles(globalStyles)(CustomLabelCheckboxInput);