export const newPouStocarstvoControlData =
    {
        "controlId": "",
        "control_1": "",
        "control_2_1": "",
        "control_3_1": "",
        "control_3_2": "",
        "control_3_3": "",
        "control_3_4": "",
        "control_3_5": "",
        "control_4": "",
        "control_4_1": "",
        "control_5": "",
        "control_6": "",
        "control_7_1": "",
        "control_7_2": "",
        "control_8": "",

        "control_9_1": "",

        "control_10_1": "",
        "control_10_2": "",
        "control_10_3": "",
        "control_10_4": "",
        "control_10_5": "",
        "control_10_6": "",

        "control_11_1": "",
        "control_11_2": "",
        "control_11_3": "",
        "control_11_4": "",

        "control_12_1": "",
        "control_12_2": "",
        "control_12_3": "",
        "control_12_4": "",
        "control_12_5": "",
        "control_12_6": "",

        "control_13_1": "",
        "control_13_2": "",
        "control_13_3": "",

        "control_14": "",

        "control_15_1": "",
        "control_15_2": "",
        "control_15_3": "",
        "control_15_4": "",
        "control_15_5": "",
        "control_15_6": "",

        "control_16_1": "",
        "control_16_2": "",

        "control_17_1": "",
        "control_17_2": "",
        "control_17_3": "",
    }

