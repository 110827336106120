export const daNeDelumnoData =
    [
        {
            id: "da", ime: "Да"
        },
        {
            id: "ne", ime: "Не"
        },
        {
            id: "delumno", ime: "Делумно"
        },
        {
            id: "neRelevantno", ime: "Не релевантно"
        }
    ]