import {
    createNewPouRastitelnoAction,
    createPouRastitelnoAction,
    deletePouRastitelnoAction,
    duplicatePouRastitelnoAction,
    fetchPouRastitelnoByIdAction,
    fetchPouRastitelnoListAction,
    updatePouRastitelnoAction
} from "./actions";
import {apiURL, authHeader, cleanJsonBody, requestOptions} from "../helpers";
import {newPouRastitelnoData} from "./newPouRastitelnoData";
import {newPouRastitelnoControlData} from "../pouRastitelnoControl/newPouRastitelnoControlData";
import {createPouRastitelnoControlRequest} from "../pouRastitelnoControl/fetch";
import {clearPouRastitelnoControlAction} from "../pouRastitelnoControl/actions";

export function fetchPouRastitelnoList() {
    return dispatch => {
        fetch(`${apiURL}/pouRastitelno/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                let i = 0;
                res.map(item => {
                    i = i + 1;
                    item.ime = i + ") " + item.ime_farma + " - " + item.sopstvenik + " - " + item.header_datum_popol;
                    item.id = item.id;
                    return item;
                })
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchPouRastitelnoListAction(res));
                dispatch(clearPouRastitelnoControlAction());
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchPouRastitelnoById(pouRastitelnoId) {
    return dispatch => {
        fetch(`${apiURL}/pouRastitelno/${pouRastitelnoId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchPouRastitelnoByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewPouRastitelno() {
    const newPouRastitelno = newPouRastitelnoData
    return dispatch => {
        dispatch(createNewPouRastitelnoAction(newPouRastitelno))
        return newPouRastitelno
    }
}

export function updatePouRastitelnoRequest(pouRastitelno, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...pouRastitelno})
    };

    return dispatch => {
        fetch(`${apiURL}/pouRastitelno/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updatePouRastitelnoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function duplicatePouRastitelnoRequest(pouRastitelnoId) {

    return dispatch => {
        fetch(`${apiURL}/pouRastitelno/${pouRastitelnoId}`, requestOptions())
            .then(res => res.json()).then(res => {
            let newValues = cleanJsonBody(JSON.parse(JSON.stringify(res)), true, true);
            const postRequestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...newValues})

            };
            fetch(`${apiURL}/pouRastitelno/`, postRequestOptions)
                .then(res => res.json())
                .then(res => {
                    if (!res.id) {
                        throw(res.error);
                    } else {
                        fetch(`${apiURL}/kontrolorRastitelno/${pouRastitelnoId}`, requestOptions())
                            .then(res => res.json())
                            .then(conRes => {
                                if (conRes.error) {
                                    throw(conRes.error);
                                } else {
                                    const pouRastitelnoControl = conRes;
                                    pouRastitelnoControl.controlId = res.id;
                                    const postRequestOptions = {
                                        method: 'POST',
                                        headers: authHeader(),
                                        body: JSON.stringify({...pouRastitelnoControl})
                                    };
                                    fetch(`${apiURL}/kontrolorRastitelno/`, postRequestOptions)
                                        .then(res => res.json())
                                        .then(res => {
                                            if (res.error) {
                                                throw(res.error);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }
                            })
                    }
                    dispatch(duplicatePouRastitelnoAction(res));
                    return res;
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }
}

export function createPouRastitelnoRequest(pouRastitelno) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...pouRastitelno})
    };

    return dispatch => {
        fetch(`${apiURL}/pouRastitelno/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    throw(res.error);
                } else {
                    const pouRastitelnoControl = newPouRastitelnoControlData;
                    pouRastitelnoControl.controlId = res.id;
                    dispatch(createPouRastitelnoControlRequest(pouRastitelnoControl));
                }
                dispatch(createPouRastitelnoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deletePouRastitelnoRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/pouRastitelno/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deletePouRastitelnoAction()).then(dispatch(fetchPouRastitelnoList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}