import React from 'react';
import PropTypes from 'prop-types';
import SpecPcelarstvo from './SpecPcelarstvo';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Notification from "../../../common/Notification";
import {cleanJsonSpecifikacija} from "../../../redux/helpers";
import {getUser} from "../../../redux/user/reducer";
import {bindActionCreators, compose} from "redux";
import {getSpecPcelarstvo} from "../../../redux/specPcelarstvo/reducer";
import {fetchSpecPcelarstvoById, updateSpecPcelarstvoRequest} from "../../../redux/specPcelarstvo/fetch";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";


export class EditSpecPcelarstvo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.updateSpecPcelarstvo = this.updateSpecPcelarstvo.bind(this);
        this.redirectToSpecPcelarstvoList = this.redirectToSpecPcelarstvoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentDidMount() {
        const {fetchSpecPcelarstvoById, fetchLookups} = this.props;
        fetchSpecPcelarstvoById(this.props.match.params.id);
        fetchLookups();
    }

    updateSpecPcelarstvo(values) {
        /*  Create and call updateSpecification action */
        const id = values.id
        let newValues = cleanJsonSpecifikacija(JSON.parse(JSON.stringify(values)), true);
        this.props.updateSpecPcelarstvoRequest(newValues, id)
        this.showNotification("Успешно зачувано");
    }

    redirectToSpecPcelarstvoList() {
        this.props.history.push(`/SpecPcelarstvoList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Card style={{boxShadow: "none", border: 0}}>
                    <CardContent className={classes.noPrint}>
                        <h1>Измени спецификација пчеларство</h1>
                    </CardContent>
                    {this.props.id && this.props.specPcelarstvo &&
                        <SpecPcelarstvo
                            pouId={this.props.specPcelarstvo.specPcelarstvo.id}
                            specPcelarstvo={this.props.specPcelarstvo.specPcelarstvo}
                            onSubmit={this.updateSpecPcelarstvo}
                            onCancel={this.redirectToSpecPcelarstvoList}
                            editMode
                            lookupData={this.props.lookupData}
                        />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        specPcelarstvo: getSpecPcelarstvo(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchSpecPcelarstvoById,
        updateSpecPcelarstvoRequest,
        fetchLookups
    }, dispatch)
}

EditSpecPcelarstvo.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditSpecPcelarstvo.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(EditSpecPcelarstvo);
