import {
    CLEAR_POU_STOCARSTVO_CONTROL,
    CREATE_NEW_POU_STOCARSTVO_CONTROL,
    CREATE_POU_STOCARSTVO_CONTROL,
    DELETE_POU_STOCARSTVO_CONTROL,
    FETCH_POU_STOCARSTVO_CONTROL,
    UPDATE_POU_STOCARSTVO_CONTROL
} from "../actionTypes";

const initialState = {
    pouStocarstvoControl: null
};

export function pouStocarstvoControlReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_POU_STOCARSTVO_CONTROL: {
            return {
                ...state,
                pouStocarstvoControl: action.payload
            };
        }
        case UPDATE_POU_STOCARSTVO_CONTROL: {
            return {
                ...state,
                pouStocarstvoControl: action.payload
            };
        }
        case CREATE_NEW_POU_STOCARSTVO_CONTROL: {
            return {
                ...state,
                pouStocarstvoControl: action.payload
            };
        }
        case CREATE_POU_STOCARSTVO_CONTROL: {
            return {
                ...state,
                pouStocarstvoControl: action.payload
            };
        }
        case DELETE_POU_STOCARSTVO_CONTROL: {
            return {
                ...state,
                pouStocarstvoControl: action.payload
            };
        }
        case CLEAR_POU_STOCARSTVO_CONTROL: {
            return {
                ...state,
                pouStocarstvoControl: null
            };
        }
        default:
            return state;
    }
}

export const getPouStocarstvoControl = state => state.pouStocarstvoControl.pouStocarstvoControl;