export const authorities =
    [
        {
            id: "CLIENT", ime: "CLIENT"
        },
        {
            id: "USER_ADMIN", ime: "USER_ADMIN"
        },
        {
            id: "LOOKUP_ADMIN", ime: "LOOKUP_ADMIN"
        },
        {
            id: "CONTROL_ADMIN", ime: "CONTROL_ADMIN"
        }
    ]