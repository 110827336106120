export const newPouPcelarstvoControlData =
    {
        "controlId": "",
        "control_1": "",
        "control_2_1": "",
        "control_2_2": "",
        "control_2_3": "",
        "control_2_4": "",
        "control_3_1": "",
        "control_3_2": "",
        "control_3_3": "",
        "control_3_4": "",
        "control_3_5": "",
        "control_4": "",
        "control_4_1": "",
        "control_5": "",
        "control_76": "",
        "control_6_1": "",
        "control_6_2": "",
        "control_6_3": "",
        "control_6_4": "",
        "control_6_5": "",
        "control_7_1": "",
        "control_7_2": "",
        "control_7_3": "",
        "control_7_4": "",
        "control_7_5": "",
        "control_7_6": "",
        "control_7_7": "",
        "control_7_8": "",
        "control_7_9": "",
        "control_8": "",
        "control_8_1": "",
        "control_8_2": "",
        "control_8_3": "",
        "control_8_4": "",
        "control_8_5": "",
        "control_9_1": "",
        "control_9_2": "",
        "control_10_1": "",
        "control_10_2": "",
        "control_10_3": "",
        "control_10_4": "",
        "control_10_5": "",
        "control_11_1": "",
        "control_11_2": "",
        "control_11_3": "",
        "control_11_4": "",
        "control_11_5": "",
        "control_11_6": "",
        "control_11_7": "",
        "control_12": "",
        "control_12_1": "",
        "control_12_2": "",
        "control_12_3": "",
        "control_13_1": "",
        "control_13_2": "",
        "control_13_3": "",
        "control_13_4": "",
        "control_13_5": "",
        "control_14": "",
        "control_14_1": "",
        "control_14_2": "",
        "control_14_3": "",
        "control_14_4": "",
        "control_15_1": "",
        "control_15_2": "",
        "control_15_3": "",
        "control_15_4": "",
        "control_16": "",
        "control_17": "",
        "control_18": ""
    }