import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SpecStocarstvo from "./SpecStocarstvo";
import Notification from "../../../common/Notification";
import {cleanJsonSpecifikacija} from "../../../redux/helpers";
import {getUser} from "../../../redux/user/reducer";
import {getSpecStocarstvo} from "../../../redux/specStocarstvo/reducer";
import {bindActionCreators, compose} from "redux";
import {fetchSpecStocarstvoById, updateSpecStocarstvoRequest} from "../../../redux/specStocarstvo/fetch";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";


export class EditSpecStocarstvo extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.updateSpecStocarstvo = this.updateSpecStocarstvo.bind(this);
        this.redirectToSpecStocarstvoList = this.redirectToSpecStocarstvoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentDidMount() {
        const {fetchSpecStocarstvoById,fetchLookups} = this.props;
        fetchSpecStocarstvoById(this.props.match.params.id);
        fetchLookups();
    }

    updateSpecStocarstvo(values) {
        /*  Create and call updateSpecification action */
        const id = values.id
        let newValues = cleanJsonSpecifikacija(JSON.parse(JSON.stringify(values)), true);
        this.props.updateSpecStocarstvoRequest(newValues, id)
        this.showNotification("Успешно зачувано");
    }

    redirectToSpecStocarstvoList() {
        this.props.history.push(`/SpecStocarstvoList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Card style={{boxShadow: "none", border: 0}}>
                    <CardContent className={classes.noPrint}>
                        <h1>Измени спецификација сточарство</h1>
                    </CardContent>
                    {this.props.id && this.props.specStocarstvo &&
                    <SpecStocarstvo
                        pouId={this.props.specStocarstvo.specStocarstvo.id}
                        specStocarstvo={this.props.specStocarstvo.specStocarstvo}
                        onSubmit={this.updateSpecStocarstvo}
                        onCancel={this.redirectToSpecStocarstvoList}
                        editMode
                        lookupData={this.props.lookupData}
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        specStocarstvo: getSpecStocarstvo(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchSpecStocarstvoById,
        updateSpecStocarstvoRequest,
        fetchLookups
    }, dispatch)
}

EditSpecStocarstvo.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditSpecStocarstvo.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(EditSpecStocarstvo);
