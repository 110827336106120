import {
    createNewSpecPcelarstvoAction,
    createSpecPcelarstvoAction,
    deleteSpecPcelarstvoAction,
    duplicateSpecPcelarstvoAction,
    failedCreateSpecPcelarstvoAction,
    fetchSpecPcelarstvoByIdAction,
    fetchSpecPcelarstvoListAction,
    updateSpecPcelarstvoAction
} from "./actions";
import {apiURL, authHeader, cleanJsonBody, requestOptions} from "../helpers";
import {newSpecPcelarstvoData} from "./newSpecPcelarstvoData";

export function fetchSpecPcelarstvoList() {
    return dispatch => {
        fetch(`${apiURL}/specPcelarstvo/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchSpecPcelarstvoListAction(res));

                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchSpecPcelarstvoById(specPcelarstvoId) {
    return dispatch => {
        fetch(`${apiURL}/specPcelarstvo/${specPcelarstvoId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchSpecPcelarstvoByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewSpecPcelarstvo() {
    const newSpecPcelarstvo = newSpecPcelarstvoData
    return dispatch => {
        dispatch(createNewSpecPcelarstvoAction(newSpecPcelarstvo))
        return newSpecPcelarstvo
    }
}

export function updateSpecPcelarstvoRequest(specPcelarstvo, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...specPcelarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/specPcelarstvo/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updateSpecPcelarstvoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function duplicateSpecPcelarstvoRequest(specPcelarstvoId) {

    return dispatch => {
        fetch(`${apiURL}/specPcelarstvo/${specPcelarstvoId}`, requestOptions())
            .then(res => res.json()).then(res => {
            let newValues = cleanJsonBody(JSON.parse(JSON.stringify(res)), true);
            const postRequestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...newValues})

            };
            fetch(`${apiURL}/specPcelarstvo/`, postRequestOptions)
                .then(res => res.json())
                .then(res => {
                    if (!res.id) {
                        throw(res.error);
                    } else {
                        // const specPcelarstvoControl = newSpecPcelarstvoControlData;
                        // specPcelarstvoControl.controlId = res.id;
                        // dispatch(createSpecPcelarstvoControlRequest(specPcelarstvoControl));
                    }
                    dispatch(duplicateSpecPcelarstvoAction(res));
                    return res;
                })
                .catch(error => {
                    console.log(error)
                })
        })
    }
}

export function createSpecPcelarstvoRequest(specPcelarstvo) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...specPcelarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/specPcelarstvo/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    dispatch(failedCreateSpecPcelarstvoAction(res));
                }
                if (res.specStocarstvo) {
                    dispatch(createSpecPcelarstvoAction(res));
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deleteSpecPcelarstvoRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/specPcelarstvo/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deleteSpecPcelarstvoAction()).then(dispatch(fetchSpecPcelarstvoList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}