export const kulturaData =
    [
        {id: "1", ime: "јаболко"},
        {id: "2", ime: "круша"},
        {id: "3", ime: "слива"},
        {id: "4", ime: "праска"},
        {id: "5", ime: "вишна"},
        {id: "6", ime: "кајсија"},
        {id: "7", ime: "цреша"},
        {id: "8", ime: "јагода на отворено"},
        {id: "9", ime: "малина"},
        {id: "10", ime: "капина"},
        {id: "11", ime: "боровинка"},
        {id: "12", ime: "дуња"},
        {id: "13", ime: "мушмула"},
        {id: "14", ime: "актинија"},
        {id: "15", ime: "маслинка"},
        {id: "16", ime: "калинка"},
        {id: "17", ime: "јапонско јаболко"},
        {id: "18", ime: "смоква"},
        {id: "19", ime: "аронија"},
        {id: "20", ime: "шипинка"},
        {id: "21", ime: "пченица"},
        {id: "22", ime: "јачмен"},
        {id: "23", ime: "пченка"},
        {id: "24", ime: "`рж"},
        {id: "25", ime: "овес"},
        {id: "26", ime: "ориз"},
        {id: "27", ime: "тритикале"},
        {id: "28", ime: "хељда"},
        {id: "29", ime: "сирак"},
        {id: "30", ime: "просо"},
        {id: "31", ime: "хмељ"},
        {id: "32", ime: "лен"},
        {id: "33", ime: "леќа"},
        {id: "34", ime: "кикирики"},
        {id: "35", ime: "соја"},
        {id: "36", ime: "сончоглед"},
        {id: "37", ime: "маслодајна репка"},
        {id: "38", ime: "добиточна репка"},
        {id: "39", ime: "афион"},
        {id: "40", ime: "шеќерна репка"},
        {id: "41", ime: "памук"},
        {id: "42", ime: "граор"},
        {id: "43", ime: "еспарзета"},
        {id: "44", ime: "добиточен грашок и наут"},
        {id: "45", ime: "луцерка домати"},
        {id: "46", ime: "пипер"},
        {id: "47", ime: "диња"},
        {id: "48", ime: "тиква"},
        {id: "49", ime: "грав"},
        {id: "50", ime: "боранија"},
        {id: "51", ime: "грашок"},
        {id: "52", ime: "компир"},
        {id: "53", ime: "краставици"},
        {id: "54", ime: "корнишони"},
        {id: "55", ime: "зелка"},
        {id: "56", ime: "кељ"},
        {id: "57", ime: "лубеница"},
        {id: "58", ime: "кромид"},
        {id: "59", ime: "лук"},
        {id: "60", ime: "праз"},
        {id: "61", ime: "тиквички"},
        {id: "62", ime: "зелена салата"},
        {id: "63", ime: "брокула"},
        {id: "64", ime: "спанаќ"},
        {id: "65", ime: "карфиол"},
        {id: "66", ime: "модар патлиџан"},
        {id: "67", ime: "морков"},
        {id: "68", ime: "целер"},
        {id: "69", ime: "цвекло"},
        {id: "70", ime: "пашканат"},
        {id: "71", ime: "аспарагус"},
        {id: "72", ime: "ротквици"},
        {id: "73", ime: "Valeriana officinalis – мачкина трева (валеријанa)"},
        {id: "74", ime: "Inula helenium L. – оман (голем корен, волско око)"},
        {id: "75", ime: "Ocimum basilicum L – босилек"},
        {id: "76", ime: "Trigonella foenumgraecum L – грчко семе (пискавица, сминдух)"},
        {id: "77", ime: "Angelica archangelica L. - ангелика"},
        {id: "78", ime: "Digitalis lanata Ehrh. – волнест напрсток (бесник)"},
        {id: "79", ime: "Chamomilla recutita L. /Matricaria chamomilla - камилица"},
        {id: "80", ime: "Centaurea cyanus L. – синчец"},
        {id: "81", ime: "Silybum marianum L. – (сикавица, дива артичока, бадељ)"},
        {id: "82", ime: "Petroselinum crispum Mill. - магдонос"},
        {id: "83", ime: "Calendula officialis L. - невен"},
        {id: "84", ime: "Achilea millefolium L. – ајдучка трева"},
        {id: "85", ime: "Echinacea purpurea ( angust iflia ) – сончев шешир"},
        {id: "86", ime: "Plangato lanceolata L. – теснолистен тегавец"},
        {id: "87", ime: "Hissopus officinalis L. - изоп"},
        {id: "88", ime: "Arnica montana - арника"},
        {id: "89", ime: "Urtica dioica L. - коприва"},
        {id: "90", ime: "Gent iana lutea L. - линцура"},
        {id: "91", ime: "Verbascum phlomolides и Verbascum densiflorum – лопен"},
        {id: "92", ime: "Malva silvestris L. – црн слез (див слез, голем слез)"},
        {id: "93", ime: "Alt haea officinalis L.– бел слез"},
        {id: "94", ime: "Saturea hortensis (montana) L.– чубрица"},
        {id: "95", ime: "Anetum graveolens L. - копар"},
        {id: "96", ime: "Artemisia dracunculus– естрагон"},
        {id: "97", ime: "Coriandrum sat ivum L. – кориандер"},
        {id: "98", ime: "Sinapsis alba L. – бел синап"},
        {id: "99", ime: "Artemisia absint hium L. – пелин (бел пелин, питом пелин)"},
        {id: "100", ime: "Cuminum cyminum L. - кумин"},
        {id: "101", ime: "Carum carvi L -ким"},
        {id: "102", ime: "Laurus nobilis L.- ловор"},
        {id: "103", ime: "Stevia Rebaudiana L. – стевија"},
        {id: "104", ime: "Lavandula angust ifolia L. - лаванда"},
        {id: "105", ime: "Melissa officinalis L. – маточина (пчелина трeва, лимон трева)"},
        {id: "106", ime: "Mentha piperitae L - нане"},
        {id: "107", ime: "Salvia officinalis L. - жалфија"},
        {id: "108", ime: "Hipericum perforatum L. - кантарион"},
        {id: "109", ime: "Majorana hortensis (Оriganum majorana ) L. – мајоран"},
        {id: "110", ime: "Thymus vilgaris L. – тимјан"},
        {id: "111", ime: "Thymus serpyllum L.-мајчина душичка"},
        {id: "112", ime: "Rosmarinus officinalis L.-рузмарин"},
        {id: "113", ime: "Оriganum vulgare - оригано"},
        {id: "114", ime: "Helichrusum italicum - смиље"},
        {id: "115", ime: "Galeopsistetrahit L. – конопица"},
        {id: "116", ime: "Trifolium repens L. – бела детелина"},
        {id: "117", ime: "Siderit is scardika – планински чај"},
        {id: "118", ime: "Siderit is raeseri – планински чај"},
        {id: "119", ime: "лозови насади"},
        {id: "120", ime: "рибизла"},
        {id: "121", ime: "гоџи бери"},
        {id: "122", ime: "бадем"},
        {id: "123", ime: "лешник"},
        {id: "124", ime: "ореви"},
        {id: "125", ime: "питоми"},
        {id: "126", ime: "костени"}
    ]


