export const ddRizikData =
    [
        {
            id: "golem", ime: "Голем"
        },
        {
            id: "sreden", ime: "Среден"
        },
        {
            id: "nema", ime: "Нема"
        }
    ]