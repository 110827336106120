import {apiURL, getFileRequestOptions, postFileRequestOptions} from "../helpers";


export function uploadFileRequest(file) {
    const data = new FormData()
    data.append('file', file.files[0])
    data.append('user', 'filesys')
    return fetch(`${apiURL}/file/upload`, postFileRequestOptions(data))
        .then(res => res.text())
        .then(res => {
            if (res.error) {
                throw(res.error);
            }
            //this sets the received values to the state, so we don't need to call this api again
            return res;
        })
        .catch(error => {
            console.log(error)
        })
}


export function downloadFileRequest(fileId) {
    let filename = ""
    return fetch(`${apiURL}/file/metadata/${fileId}`, getFileRequestOptions()).then(res => res.json()).then(res => {
        filename = res.filename
        fetch(`${apiURL}/file/download/${fileId}`, getFileRequestOptions())
            .then(response => {
                    return response.blob();
                }
            )
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            });
    })
}

export function fileMetadataRequest(fileId) {
    let filename = ""
    fetch(`${apiURL}/file/metadata/${fileId}`, getFileRequestOptions()).then(res => res.json()).then(res => {
        filename = res.filename
    })
    return filename
}

