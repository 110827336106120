import {
    createNewPouStocarstvoAction,
    createPouStocarstvoAction,
    deletePouStocarstvoAction,
    duplicatePouStocarstvoAction,
    fetchPouStocarstvoByIdAction,
    fetchPouStocarstvoListAction,
    updatePouStocarstvoAction
} from "./actions";
import {apiURL, authHeader, cleanJsonBody, requestOptions} from "../helpers";
import {newPouStocarstvoData} from "./newPouStocarstvoData";
import {newPouStocarstvoControlData} from "../pouStocarstvoControl/newPouStocarstvoControlData";
import {createPouStocarstvoControlRequest} from "../pouStocarstvoControl/fetch";
import {clearPouStocarstvoControlAction} from "../pouStocarstvoControl/actions";


export function fetchPouStocarstvoList() {
    return dispatch => {
        fetch(`${apiURL}/pouStocarstvo/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                let i = 0;
                res.map(item => {
                    i = i + 1;
                    item.ime = i + ") " + item.ime_farma + " - " + item.sopstvenik + " - " + item.header_datum_popol;
                    item.id = item.id;
                    return item;
                })
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchPouStocarstvoListAction(res));
                dispatch(clearPouStocarstvoControlAction());

                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}


export function fetchPouStocarstvoById(pouStocarstvoId) {
    return dispatch => {
        fetch(`${apiURL}/pouStocarstvo/${pouStocarstvoId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again

                dispatch(fetchPouStocarstvoByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewPouStocarstvo() {
    const newPouStocarstvo = newPouStocarstvoData
    return dispatch => {
        dispatch(createNewPouStocarstvoAction(newPouStocarstvo))
        return newPouStocarstvo
    }
}

export function updatePouStocarstvoRequest(pouStocarstvo, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...pouStocarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/pouStocarstvo/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updatePouStocarstvoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function duplicatePouStocarstvoRequest(pouStocarstvoId) {

    return dispatch => {
        fetch(`${apiURL}/pouStocarstvo/${pouStocarstvoId}`, requestOptions())
            .then(res => res.json()).then(res => {
            let newValues = cleanJsonBody(JSON.parse(JSON.stringify(res)), true);
            const postRequestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...newValues})

            };
            fetch(`${apiURL}/pouStocarstvo/`, postRequestOptions)
                .then(res => res.json())
                .then(res => {
                    if (!res.id) {
                        throw(res.error);
                    } else {
                        fetch(`${apiURL}/kontrolorStocarstvo/${pouStocarstvoId}`, requestOptions())
                            .then(res => res.json())
                            .then(conRes => {
                                if (conRes.error) {
                                    throw(conRes.error);
                                } else {
                                    const pouStocarstvoControl = conRes;
                                    pouStocarstvoControl.controlId = res.id;
                                    const postRequestOptions = {
                                        method: 'POST',
                                        headers: authHeader(),
                                        body: JSON.stringify({...pouStocarstvoControl})
                                    };
                                    fetch(`${apiURL}/kontrolorStocarstvo/`, postRequestOptions)
                                        .then(res => res.json())
                                        .then(res => {
                                            if (res.error) {
                                                throw(res.error);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                }
                            })
                    }
                    dispatch(duplicatePouStocarstvoAction(res));
                    return res;
                })
                .catch(error => {
                    console.log(error)
                })
        })

    }
}

export function createPouStocarstvoRequest(pouStocarstvo) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...pouStocarstvo})
    };

    return dispatch => {
        fetch(`${apiURL}/pouStocarstvo/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    throw(res.error);
                } else {
                    const pouStocarstvoControl = newPouStocarstvoControlData;
                    pouStocarstvoControl.controlId = res.id;
                    dispatch(createPouStocarstvoControlRequest(pouStocarstvoControl));
                }
                dispatch(createPouStocarstvoAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deletePouStocarstvoRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/pouStocarstvo/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deletePouStocarstvoAction()).then(dispatch(fetchPouStocarstvoList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}