export const tipKopija =
    [
        {
            id: "1", ime: "Да, имам печатен материјал"
        },
        {
            id: "2", ime: "Да, имам пристап до интернет"
        },
        {
            id: "3", ime: "Не, немам познавања"
        }
    ]