import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import {Grid, Typography} from '@material-ui/core';
import * as ALL from "../../../common/staticLabels";
import GridTable from "../../../components/gridTable";
import globalStyles from "../../styles";
import Button from "@material-ui/core/Button";
import CustomLabeledInput from "../../../components/customLabeledInput";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    deletePouStocarstvoRequest,
    fetchPouStocarstvoById,
    fetchPouStocarstvoList
} from "../../../redux/pouStocarstvo/fetch";
import {getPouStocarstvo, getPouStocarstvoList} from "../../../redux/pouStocarstvo/reducer";
import {getPouNum} from "../../../redux/pouPcelarstvo/reducer";
import Notification from "../../../common/Notification";
import PrintIcon from "@material-ui/icons/Print";

class SpecStocarstvo extends Component {
    constructor(props) {
        super(props);
        this.updateObject = this.updateObject.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleObj = this.handleObj.bind(this)
        this.handleDDChange = this.handleDDChange.bind(this)
        this.updateSpecDataFromPout = this.updateSpecDataFromPout.bind(this)
        this.handleAddKapacitetiWithParams = this.handleAddKapacitetiWithParams.bind(this)
        this.handleAddProizvodstvoWithParams = this.handleAddProizvodstvoWithParams.bind(this)
        this.handlePrint = this.handlePrint.bind(this)
        this.addLeadingZeroes = this.addLeadingZeroes.bind(this);

        this.state = {
            navDrawerOpen: false,
            ...this.props.pouId,
            ...this.props.specStocarstvo,
            notify: false,
            message: '',
            error: false
        };
    }

    componentWillMount() {
        const {fetchPouStocarstvoList} = this.props;
        fetchPouStocarstvoList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((!this.state.pouStocarstvo && nextProps.pouStocarstvo) || (this.state.pouStocarstvo && this.state.pouStocarstvo.id !== nextProps.pouStocarstvo.id)) {
            this.updateSpecDataFromPout(nextProps.pouStocarstvo);
            this.setState({["pouName"]: nextProps.pouStocarstvo.pouStocarstvo.ime_farma})
        }
    }
    handlePrint() {
        // Create a <style> element
        var style = document.createElement('style');

        // Define the @page rule for A4 portrait
        style.textContent = '@page { size: A4 portrait; }';

        // Append the <style> element to the <head>
        document.head.appendChild(style);

        // Apply any additional print-specific styles here

        // Trigger the print dialog
        window.print();

        // Remove the dynamically added <style> element to avoid affecting future prints
        document.head.removeChild(style);
    }

    handleDDChange(name, event, value) {
        event.persist()
        this.setState({[name]: value})
        this.props.fetchPouStocarstvoById(value)
    };

    updateSpecDataFromPout(pou) {
        const kapaticetiInput = pou.pouStocarstvo.poteklo_zivotni_preod; //tabela 9.1
        const proizvodstvoInput = pou.pouStocarstvo.animalni_proizvodi_sertifikacija; //tabela 5
        let listKapaciteti = []
        let listProizvodstvo = []
        if (kapaticetiInput) {
            kapaticetiInput.forEach(p => this.handleAddKapacitetiWithParams(listKapaciteti, p.lokacija, p.broj, p.vid, p.br_zivotni))
        }
        if (proizvodstvoInput) {
            proizvodstvoInput.forEach(p => this.handleAddProizvodstvoWithParams(listProizvodstvo, p.zivotni, p.broj_kolicina, p.status))
        }
    }

    handleAddKapacitetiWithParams(listKapaciteti, odgleduvalisteI, rboI, vid_zivotnoI, broj_zivotniI) {
        const myObj = {
            odgleduvaliste: odgleduvalisteI,
            rbo: rboI,
            vid_zivotno: vid_zivotnoI,
            broj_zivotni: broj_zivotniI,
        };
        listKapaciteti.push(myObj)
        const random = Math.random() + Math.random();
        this.setState({'kapaciteti': listKapaciteti, 'kapacitetNum': random + listKapaciteti.length});
    }


    handleAddProizvodstvoWithParams(listProizvodstvo, proizvodI, broj_kolicinaI, statusI) {
        const myObj = {
            proizvod: proizvodI,
            kolicina: broj_kolicinaI,
            status: statusI
        };
        listProizvodstvo.push(myObj)
        const random = Math.random() + Math.random();
        this.setState({'proizvodstvo': listProizvodstvo, 'proizvodstvoNum': random + listProizvodstvo.length});
    }

    checkCanSave() {
        return true
    }

    handleAdd(type, template) {
        let list = this.state[type]
        list.push(template)
        this.setState({[type]: list});
    }

    handleRemove(type) {
        let list = this.state[type]
        if (list.length > 1) {
            list.pop()
        }
        this.setState({[type]: list});
    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value})
    };

    handleObj(name, value) {
        this.setState({[name]: value})
    };

    updateObject(obj, keys, value) {
        let key = keys.shift();
        if (keys.length > 0) {

            let tmp = this.updateObject(obj[key], keys, value);
            return {...obj, [key]: tmp};
        } else {
            return {...obj, [key]: value};
        }
    }

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    addLeadingZeroes(num) {
        const number = num ? num : 0
        const numberString = number.toString()
        const desiredLength = 4;

        if (numberString.length >= desiredLength) {
            // If the number already has 4 or more digits, return it as is
            return numberString;
        } else {
            // Add leading zeroes to make it a 4-digit number
            const numberOfZeroesToAdd = desiredLength - numberString.length;
            const leadingZeroes = '0'.repeat(numberOfZeroesToAdd);
            return leadingZeroes + numberString;
        }
    }

    render() {
        const {classes, createMode, editMode, pouStocarstvoList} = this.props;
        const {notify, message, error} = this.state;
        const canSave = this.checkCanSave()
        const kapacitetiTemplate = {
            "odgleduvaliste": "",
            "rbo": "",
            "vid_zivotno": "",
            "broj_zivotni": "",
            "status": ""
        }
        const proizvodstvoTemplate = {
            "proizvod": "",
            "kolicina": "",
            "status": ""
        }

        return (
            <div className={classes.mainContainer}>
                <Grid container spacing={16} className={classes.noPrint}>
                    <Typography align={"center"} variant="h6">{ALL.SPEC_IZBOR_POU}</Typography>
                    {pouStocarstvoList &&
                    <Grid item xs={12}>

                        <CustomLabeledInput
                            inputType={"dropdown"}
                            options={pouStocarstvoList}
                            valueInput={this.state.pouId}
                            onChangeAction={(event, newValue) => this.handleDDChange('pouId', event, newValue)}/>
                    </Grid>
                    }
                </Grid>
                {this.state.pouId && this.props.lookupData !== undefined &&
                <div>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <div className={classes.noPrint}>
                                <CustomLabeledInput labelInput={"Внеси број на спецификација "}
                                                    inputType={"text"}
                                                    size={5}
                                                    noBorder={true}
                                                    placeholderInput={" "}
                                                    valueInput={this.addLeadingZeroes(this.state.broj_spec)}
                                                    onChangeAction={(event) => this.handleChange('broj_spec', event)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"}
                                        variant="h4">{ALL.SPECIFICATION_TITLE_MK} {this.addLeadingZeroes(this.state.broj_spec)}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography align={"center"} variant="h6"
                                        className={classes.h6LineHeight}>{ALL.SPECIFICATION_DESC_MK}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"}
                                        variant="h4">{ALL.SPECIFICATION_TITLE_ENG}{this.addLeadingZeroes(this.state.broj_spec)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"} variant="h6"
                                        className={classes.h6LineHeight}>{ALL.SPECIFICATION_DESC_ENG}</Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.divider}/>
                    <Grid container xs={12} className={classes.container}>
                        <Grid alignContent={"flex-start"} container className={classes.paddedRightContainer} xs={12}>
                            <Grid xs={12} className={classes.lessHeightControlColoredItemGrid}>
                                <Typography
                                    align={"center"}
                                    variant="h5">{ALL.STOCARSTVO_PROIZVODNI_KAPACITETI}</Typography>
                            </Grid>
                            <GridTable obj={this.state.kapaciteti}
                                       key={this.state.kapacitetNum}
                                       largerCellOutputFont={true}
                                       labeli={[ALL.LOKACIJA_ODGLEDUVALISTE, ALL.REGISTRACISKI_BR_ODGLEDUVALISTE, ALL.SPEC_VID_ZIVOTNO, ALL.SPEC_BROJ_ZIVOTNI, ALL.SPECIFIKACIJA_STATUS]}
                                       values={["odgleduvaliste", "rbo", "vid_zivotno", "broj_zivotni", "status"]}
                                       types={["textarea", "textarea", "dropdown", "textarea", "dropdown"]}
                                       ddData={[null, null, this.props.lookupData.zivotno, null, this.props.lookupData.document_status]}
                                       removeRow={true}
                                       colWidth={[1,1,1,1]}
                                       columnHeaderClass={'lessHeightPurpleControlColoredItemGrid'}
                                       valueName={"kapaciteti"}
                                       onChangeAction={(name, value) => this.handleObj(name, value)}
                                       size={12}
                                       template={kapacitetiTemplate}
                            />
                        </Grid>
                        {/*<Grid alignContent={"flex-start"} container  xs={6}>*/}
                        {/*    <Grid xs={12} className={classes.lessHeightControlColoredItemGrid}>*/}
                        {/*        <Typography align={"center"} variant="h5">{ALL.PROIZVODSTVO}</Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <GridTable obj={this.state.proizvodstvo}*/}
                        {/*               key={this.state.proizvodstvoNum}*/}
                        {/*               largerCellOutputFont={true}*/}
                        {/*               labeli={[ALL.SPEC_PROIZVOD, ALL.SPEC_KOLICINI, ALL.SPECIFIKACIJA_STATUS]}*/}
                        {/*               values={["proizvod", "kolicina", "status"]}*/}
                        {/*               types={["dropdown", "textarea", "dropdown"]}*/}
                        {/*               ddData={[this.props.lookupData.zivotno, null, this.props.lookupData.document_status]}*/}
                        {/*               removeRow={true}*/}
                        {/*               colWidth={[1,1,1]}*/}
                        {/*               columnHeaderClass={'lessHeightPurpleControlColoredItemGrid'}*/}
                        {/*               valueName={"proizvodstvo"}*/}
                        {/*               onChangeAction={(name, value) => this.handleObj(name, value)}*/}
                        {/*               size={12}*/}
                        {/*               template={proizvodstvoTemplate}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                    </Grid>
                    <div className={classes.divider}/>
                    <div className={classes.divider}/>
                    <div className={classes.divider}/>
                    <div className={classes.divider}/>

                    <Grid item xs={12}>
                        <Typography align={"left"} variant="h8"
                                    className={classes.h6LineHeight}>{ALL.SPECIFICATION_FOOTER}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={"left"} variant="h8"
                                    className={classes.h6LineHeight}>{ALL.SPECIFICATION_FOOTER_1}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={"left"} variant="h8"
                                    className={classes.h6LineHeight}>{ALL.SPECIFICATION_FOOTER_2}</Typography>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={9}/>
                        <Grid item xs={3}>
                            <Typography className={classes.centeredText}
                                        variant="h5">{ALL.IZRABOTIL}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={9}/>
                        <Grid item xs={3}>
                            <div className={classes.divSignature}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={9}/>
                        <Grid item xs={3}>
                            <Typography className={classes.centeredText}
                                        variant="h5">{ALL.PROCERT_EN}</Typography>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    {(createMode || editMode) && !canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.requiredFieldsMessage()}>
                        {createMode ? "Креирај спецификација" : "Зачувај спецификација"}
                    </Button>
                    }
                    {(createMode || editMode) && canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.props.onSubmit(this.state)}>
                        {createMode ? "Креирај спецификација" : "Зачувај спецификација"}
                    </Button>
                    }
                    {/*{*/}
                    {/*    (createMode || editMode) && canSave &&*/}
                    {/*    < Button*/}
                    {/*        classes={{*/}
                    {/*            root: classes.printFormStickyButton, // class name, e.g. `classes-nesting-root-x`*/}
                    {/*            label: classes.label, // class name, e.g. `classes-nesting-label-x`*/}
                    {/*        }}*/}
                    {/*        variant="contained" size={"small"}*/}
                    {/*        aria-label="print"*/}
                    {/*        onClick={() => this.handlePrint()}>*/}
                    {/*        {<PrintIcon/>}*/}

                    {/*    </Button>*/}
                    {/*}*/}
                </div>
                }
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

SpecStocarstvo.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    };

SpecStocarstvo = reduxForm(
    {
        form: 'SpecStocarstvo',
        enableReinitialize: true,
        hasRole: true,
    }
)(SpecStocarstvo)


function mapStateToProps(state) {
    return {
        pouStocarstvoList: getPouStocarstvoList(state),
        pouStocarstvo: getPouStocarstvo(state),
        pouNum: getPouNum(state)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPouStocarstvoList,
        fetchPouStocarstvoById,
        deletePouStocarstvoRequest
    }, dispatch)
}

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(SpecStocarstvo)
)
