import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import User from "./user/User";
import Notification from "../common/Notification";
import {cleanJsonUserCreate} from "../redux/helpers";
import {bindActionCreators} from "redux";
import {fetchRegisteredUser, registerUserRequest} from "../redux/user/fetch";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getError, getSelectedUser} from "../redux/user/reducer";

export class RegisterUser extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.registerUser = this.registerUser.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.state = {notify: false, message: '', error: this.props.error};
    }

    componentWillMount() {
        const {fetchRegisteredUser} = this.props;
        fetchRegisteredUser();
    }

    registerUser(values) {
        let newValues = cleanJsonUserCreate(JSON.parse(JSON.stringify(values)));
        this.props.registerUserRequest(newValues)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error === true && nextProps.selectedUser.selectedUser.details && nextProps.selectedUser.selectedUser.details[0] === "Username exists!") {
            this.showNotification("Корисничкото име веќе е регистрирано, обидете се повторно!", false)
        } else if (nextProps.error === true && nextProps.selectedUser.selectedUser.details && nextProps.selectedUser.selectedUser.details[0] === "Passwords don't match!") {
            this.showNotification("Лозинките не се совпаѓаат, обидете се повторно!", false)
        } else if (nextProps.error === true && nextProps.selectedUser.selectedUser.details) {
            this.showNotification("Корисничкото име мора да биде во формат на е-адреса!", false)
        } else if (nextProps.error === false) {
            this.showNotification("Вашата регистрација е успешна! Продолжете со најава!");
            setTimeout(() => this.props.history.push("/"), 1000); //execute greet after 2000 milliseconds (2 seconds)
        }
    }

    redirectToLogin() {
        this.props.history.push(`/login`);
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;

        return (
            <div>
                <Card>
                    <CardContent>
                        <h1>Регистрирајте се</h1>
                    </CardContent>
                    {this.props.selectedUser &&
                    <User
                        selectedUser={this.props.selectedUser}
                        registerMode
                        onSubmit={this.registerUser}
                        onCancel={this.redirectToLogin}
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        selectedUser: getSelectedUser(state),
        error: getError(state)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchRegisteredUser, registerUserRequest}, dispatch)
}

RegisterUser.propTypes = {
    dispatch: PropTypes.func
};

RegisterUser.contextTypes = {
    router: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);