import {
    CLEAR_POU_RASTITELNO_CONTROL,
    CREATE_NEW_POU_RASTITELNO_CONTROL,
    CREATE_POU_RASTITELNO_CONTROL,
    DELETE_POU_RASTITELNO_CONTROL,
    FETCH_POU_RASTITELNO_CONTROL,
    UPDATE_POU_RASTITELNO_CONTROL
} from "../actionTypes";

export const fetchPouRastitelnoControlByIdAction = (payload) => ({
    type: FETCH_POU_RASTITELNO_CONTROL,
    payload: {
        pouRastitelnoControl: payload,
    },
})
export const createNewPouRastitelnoControlAction = (payload) => ({
    type: CREATE_NEW_POU_RASTITELNO_CONTROL,
    payload: {
        pouRastitelnoControl: payload,
    },
})

export const createPouRastitelnoControlAction = (payload) => ({
    type: CREATE_POU_RASTITELNO_CONTROL,
    payload: {
        pouRastitelnoControl: payload,
    },
})
export const updatePouRastitelnoControlAction = (payload) => ({
    type: UPDATE_POU_RASTITELNO_CONTROL,
    payload: {
        pouRastitelnoControl: payload,
    },
})
export const clearPouRastitelnoControlAction = () => ({
    type: CLEAR_POU_RASTITELNO_CONTROL,
    payload: {
        pouRastitelnoControl: null,
    },
})
export const deletePouRastitelnoControlAction = () => ({
    type: DELETE_POU_RASTITELNO_CONTROL,
    payload: {
        pouRastitelnoControl: null,
    },
})