import React from 'react';
import PropTypes from 'prop-types';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Notification from "../../common/Notification";
import {bindActionCreators} from "redux";
import {getSelectedUser} from "../../redux/user/reducer";
import {createUserRequest, fetchNewUser} from "../../redux/user/fetch";
import {connect} from "react-redux";
import User from "./User";
import {cleanJsonUserCreate} from "../../redux/helpers";

export class CreateUser extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.saveUser = this.saveUser.bind(this);
        this.redirectToUserList = this.redirectToUserList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchNewUser} = this.props;
        fetchNewUser();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error === false) {
            this.showNotification("Успешно креиран корисник!");
            setTimeout(() => this.props.history.push("/"), 1000); //execute greet after 2000 milliseconds (2 seconds)
        } else if (nextProps.error === true && nextProps.selectedUser.selectedUser.details[0] === "Username exists!") {
            this.showNotification("Корисничкото име веќе е регистрирано, обидете се повторно!", false)
        } else if (nextProps.error === true && nextProps.selectedUser.selectedUser.details[0] === "Passwords don't match!") {
            this.showNotification("Лозинките не се совпаѓаат, обидете се повторно!", false)
        }
    }

    saveUser(values) {
        let newValues = cleanJsonUserCreate(JSON.parse(JSON.stringify(values)));
        this.props.createUserRequest(newValues)
    }

    redirectToUserList() {
        this.props.history.push(`/UserList`);
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false})
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message, error} = this.state;
        return (
            <div>
                <Card>
                    <CardContent>
                        <h1>Креирај корисник</h1>
                    </CardContent>
                    {this.props.selectedUser &&
                    <User
                        selectedUser={this.props.selectedUser}
                        createMode
                        onSubmit={this.saveUser}
                        onCancel={this.redirectToUserList}
                    />}
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        selectedUser: getSelectedUser(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchNewUser, createUserRequest}, dispatch)
}

CreateUser.propTypes = {
    dispatch: PropTypes.func
};

CreateUser.contextTypes = {
    router: PropTypes.object
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);