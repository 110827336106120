import {UPLOAD_FILE} from "../actionTypes";

const initialState = {
    fileSystem: null
};

export function fileSystemReducer(state = initialState, action) {
    switch (action.type) {
        case UPLOAD_FILE: {
            return {
                ...state,
                fileSystem: action.payload
            };
        }
        default:
            return state;
    }
}

export const getUploadedFile = state => state.fileSystem.fileSystem;
