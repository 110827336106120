import {
    CREATE_NEW_SPEC_PCELARSTVO,
    CREATE_SPEC_PCELARSTVO,
    DELETE_SPEC_PCELARSTVO,
    DUPLICATE_SPEC_PCELARSTVO,
    FAILED_CREATE_SPEC_PCELARSTVO,
    FETCH_ALL_SPEC_PCELARSTVO,
    FETCH_SPEC_PCELARSTVO,
    UPDATE_SPEC_PCELARSTVO
} from "../actionTypes";


export const fetchSpecPcelarstvoListAction = (payload) => ({
    type: FETCH_ALL_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvoList: payload,
    },
})

export const fetchSpecPcelarstvoByIdAction = (payload) => ({
    type: FETCH_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: payload,
    },
})
export const createNewSpecPcelarstvoAction = (payload) => ({
    type: CREATE_NEW_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: payload,
    },
})

export const createSpecPcelarstvoAction = (payload) => ({
    type: CREATE_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: payload,
        error: false
    },
})

export const failedCreateSpecPcelarstvoAction = (payload) => ({
    type: FAILED_CREATE_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: payload,
        error: true
    },
})

export const duplicateSpecPcelarstvoAction = (payload) => ({
    type: DUPLICATE_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: payload,
    },
})

export const updateSpecPcelarstvoAction = (payload) => ({
    type: UPDATE_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: payload,
    },
})
export const deleteSpecPcelarstvoAction = () => ({
    type: DELETE_SPEC_PCELARSTVO,
    payload: {
        specPcelarstvo: null,
        specNum: 0
    },
})