import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {fetchUserById, updateUserRequest} from "../../redux/user/fetch";
import {getSelectedUser} from "../../redux/user/reducer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Notification from "../../common/Notification";
import {cleanJsonUser} from "../../redux/helpers";
import User from "./User";
import {reset} from "redux-form";

export class EditUser extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateUser = this.updateUser.bind(this);
        this.redirectToUserList = this.redirectToUserList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchUserById} = this.props;
        fetchUserById(this.props.match.params.id);
    }

    redirectToUserList() {
        this.props.history.push(`/UserList`);
        this.props.dispatch(reset('User'));
    }

    updateUser(values) {
        /*  Create and call updateUser action */
        const id = values.id
        let newValues = cleanJsonUser(JSON.parse(JSON.stringify(values)), true);
        this.props.updateUserRequest(newValues, id)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.error === false) {
            this.showNotification("Успешно зачувано");
            setTimeout(() => this.props.history.push("/"), 1000); //execute greet after 2000 milliseconds (2 seconds)
        }
        if (nextProps.error === true) {
            this.showNotification("Грешка при менување на корисникот, обидете се повторно!", false)
        }
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    render() {
        const {notify, message} = this.state;
        return (
            <div>
                <Card>
                    <CardContent>
                        <h1>Измени корисник</h1>
                    </CardContent>
                    {this.props.id && this.props.selectedUser &&
                    <User
                        selectedUser={this.props.selectedUser.selectedUser}
                        selectedUserId={this.props.selectedUser.selectedUser.id}
                        onSubmit={this.updateUser}
                        onCancel={this.redirectToUserList}
                        editMode
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={false}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        selectedUser: getSelectedUser(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchUserById,
        updateUserRequest
    }, dispatch)
}


EditUser.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditUser.contextTypes = {
    router: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
