import React from 'react';
import propTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Notification from '../../common/Notification';
import Button from '@material-ui/core/Button';
import ConfirmDelete from '../../common/ConfirmDelete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add'
import format from 'string-format';
import PageBase from '../PageBase';
import globalStyles from "../styles";
import {Fab} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import {getApplications, getAppNum} from "../../redux/application/reducer";
import {deleteApplicationRequest, fetchApplications} from "../../redux/application/fetch";
import {getUser} from "../../redux/user/reducer";

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18
    },
    body: {
        fontSize: 16,
    },

}))(TableCell);

const CustomTableSmallCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: 18,
        width: 20
    },
    body: {
        fontSize: 16,
        width: 20
    },

}))(TableCell);

const styles = theme => ({
    row: {
        '&:nth-of-Status(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        margin: theme.spacing.unit,
    }
});


export class ApplicationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            deleteRecord: false,
            id: '',
            pgNo: 1,
            pageSize: 10,
            notify: false,
            message: '',
            error: false
        }
        this.deleteApplication = this.deleteApplication.bind(this);
        this.deleteAfterConfirmation = this.deleteAfterConfirmation.bind(this);
        this.EditApplication = this.EditApplication.bind(this);
        this.addApplication = this.addApplication.bind(this);
        this.isOwner = this.isOwner.bind(this);
    }

    isOwner(owner) {
        if (this.props.user && this.props.user.user.authorities) {
            return this.props.user.user.fullName === owner
        }
        return false;
    }

    isControlAdmin() {
        if (this.props.user && this.props.user.user.authorities) {
            return this.props.user.user.authorities.some(u => u.authority === "CONTROL_ADMIN")
        }
        return false;
    }

    componentWillMount() {
        const {fetchApplications} = this.props;
        fetchApplications();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.appNum !== nextProps.appNum) {
            this.setState({applications: nextProps.applications})
        }
    }

    deleteApplication(id) {
        this.setState({deleteRecord: true, id: id});
    }

    EditApplication(id) {
        this.props.history.push(`/Application/` + id);
    }

    deleteAfterConfirmation(deleteConfirmed) {
        this.setState({deleteRecord: false});
        if (deleteConfirmed) {

            const {deleteApplicationRequest} = this.props
            deleteApplicationRequest(this.state.id)
            this.showNotification("Успешно избришана Апликација");
        }
        this.setState({id: ''});
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    addApplication() {
        this.props.history.push(`/CreateApplication`);
    }

    render() {
        const isControlAdmin = this.isControlAdmin()
        const {classes, applications} = this.props;
        const {notify, message, error} = this.state;
        return (
            <PageBase title="Апликации" navigation="Procert / Апликации">
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add" className={classes.button}
                    onClick={this.addApplication}
                >
                    <AddIcon/>
                    Додади Апликација
                </Fab>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Име на компанија</CustomTableCell>
                            <CustomTableCell>Датум</CustomTableCell>
                            <CustomTableCell>Контакт</CustomTableCell>
                            <CustomTableCell>Статус</CustomTableCell>
                            <CustomTableSmallCell>Измени</CustomTableSmallCell>
                            <CustomTableSmallCell>Избриши</CustomTableSmallCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applications && applications.map(Application => {
                            if (isControlAdmin || this.isOwner(Application.ime_kompanija)) {

                                return (
                                    <TableRow key={Application.Broj} className={classes.row}>
                                        <CustomTableCell>{Application.ime_kompanija}</CustomTableCell>
                                        <CustomTableCell>{Application.datum}</CustomTableCell>
                                        <CustomTableCell>{Application.kontakt_lice}</CustomTableCell>
                                        <CustomTableCell>{Application.status}</CustomTableCell>
                                        <CustomTableCell>
                                            <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                    aria-label="edit"
                                                    onClick={() => this.EditApplication(Application.id)}>
                                                <EditIcon/>
                                            </Button>
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            <Button style={globalStyles.deleteButton} variant="contained" size={"small"}
                                                    aria-label="delete"
                                                    onClick={() => this.deleteApplication(Application.id)}>
                                                <DeleteIcon/>
                                            </Button>
                                        </CustomTableCell>
                                    </TableRow>

                                );
                            }
                        })}
                    </TableBody>
                </Table>
                <ConfirmDelete resourceHeader="Избриши апликација?"
                               resourceOpis={format("Дали сте сигурни дека сакате да ја ибришете апликацијата'{}'?", this.state.id)}
                               onModalClose={this.deleteAfterConfirmation}
                               openDeleteDialog={this.state.deleteRecord}/>

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </PageBase>
        );
    }
}

ApplicationList.propTypes = {
    classes: propTypes.object.isRequired,
};

const mapStateToProps = state => ({
    applications: getApplications(state),
    appNum: getAppNum(state),
    user: getUser(state),
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchApplications, deleteApplicationRequest}, dispatch)
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ApplicationList)
)