export const vidOperacija =
    [
        {
            id: "1", ime: "Растително производство"
        },
        {
            id: "2", ime: "Преработка"
        },
        {
            id: "3", ime: "Сточарско производство"
        },
        {
            id: "4", ime: "Трговија"
        },
        {
            id: "5", ime: "Собирање самоникнати видови"
        },
        {
            id: "6", ime: "Пчеларство"
        }
    ]