export const yesnomaybe =
    [
        {
            id: " ", ime: " "
        },
        {
            id: "true", ime: "Да"
        },
        {
            id: "false", ime: "Не"
        }
    ]