import {apiURL, authHeader, noAuthHeader, requestOptions} from "../helpers";
import {
    changePasswordAction,
    createNewUserAction,
    createUserAction,
    deleteUserAction,
    failedChangePasswordAction,
    failedCreateUserAction,
    failedForgotPasswordAction,
    failedLoginAction,
    failedRegisterUserAction,
    failedUpdateUserAction,
    fetchRegisterUserAction,
    fetchUserByIdAction,
    fetchUserListAction,
    forgotPasswordAction,
    loginAction,
    logoutAction,
    registerUserAction,
    updateUserAction
} from "./action";
import {newUserData} from "./newUserData";
import {POGRRESNI_PODATOCI_LOGIN, SISTEMSKA_GRESKA} from "../../common/staticLabels";

export function fetchLogin(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username: username, password: password})
    };

    return dispatch => {
        fetch(`${apiURL}/public/login`, requestOptions)
            .then(res => {
                if (res.status === 401) {
                    const m = {
                        details: [
                            POGRRESNI_PODATOCI_LOGIN
                        ]
                    }
                    dispatch(failedLoginAction(m));
                } else if (res.status !== 200) {
                    const m = {
                        details: [
                            SISTEMSKA_GRESKA
                        ]
                    }
                }
                return res.json();
            })
            .then(res => {
                if (!res.user) {
                    console.log(res)
                }
                if (res.user) {
                    sessionStorage.setItem('user', JSON.stringify(res));
                    dispatch(loginAction(res));
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function logoutRequest() {
    return dispatch => {
        sessionStorage.removeItem("user")
        dispatch(logoutAction())
    }
}

export function fetchUserList() {
    return dispatch => {
        fetch(`${apiURL}/admin/user/all`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(fetchUserListAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchUserById(userId) {
    return dispatch => {
        fetch(`${apiURL}/admin/user/${userId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again

                dispatch(fetchUserByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewUser() {
    const newUser = newUserData
    return dispatch => {
        dispatch(createNewUserAction(newUser))
        return newUser
    }
}

export function fetchRegisteredUser() {
    const newUser = newUserData
    return dispatch => {
        dispatch(fetchRegisterUserAction(newUser))
        return newUser
    }
}

export function createUserRequest(user) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...user})
    };

    return dispatch => {
        fetch(`${apiURL}/admin/user/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    dispatch(failedCreateUserAction(res))
                }
                if (res.user) {
                    dispatch(createUserAction(res)).then(dispatch(fetchUserList()))
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function registerUserRequest(user) {
    const postRequestOptions = {
        method: 'POST',
        headers: noAuthHeader(),
        body: JSON.stringify({...user})
    };

    return dispatch => {
        fetch(`${apiURL}/registration/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    dispatch(failedRegisterUserAction(res))
                }
                if (res.username) {
                    dispatch(registerUserAction(res))
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function forgotPasswordRequest(username) {
    const putRequestOptions = {
        method: 'PUT',
        headers: noAuthHeader()
    };

    return dispatch => {
        fetch(`${apiURL}/forgotPassword/${username}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.message === 'Not found exception') {
                    dispatch(failedForgotPasswordAction())
                } else {
                    dispatch(forgotPasswordAction())
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function updateUserRequest(user, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...user})
    };

    return dispatch => {
        fetch(`${apiURL}/admin/user/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (!res.id) {
                    dispatch(failedUpdateUserAction(res));
                }
                if (res.username) {
                    dispatch(updateUserAction(res));
                }
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function changePasswordRequest(user, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...user})
    };

    return dispatch => {
        fetch(`${apiURL}/admin/user/changePassword/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                    if (!res.selectedUser) {
                        dispatch(failedChangePasswordAction(res));
                    }
                    if (res.selectedUser) {
                        dispatch(changePasswordAction(res));
                    }
                    return res;
                }
            )
            .catch(error => {
                console.log(error)
            })
    }
}

export function deleteUserRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/admin/user/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deleteUserAction()).then(dispatch(fetchUserList()))
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}