export const newPouPcelarstvoData =
    {
        "header_kompanija": "",
        "header_datum_popol": "",
        "header_odgovoren_kompanija": "",
        "header_kontrolor": "",
        "header_datum_kontrola": "",
        "plan_kontrola": [
            {
                "data": "",
                "aktivnost": "",
                "zaedno_so": "",
                "posebni_incidenti": ""
            }
        ],
        "naodi_kontrola": "",
        "azuriranje_pou": "",
        "zemeni_primeroci_kontrola": "",
        "ime_farma": "",
        "sopstvenik": "",
        "odgovorno_lice": "",
        "ulica_broj_fax": "",
        "grad_postenski_kod": "",
        "zemja": "",
        "telefon": "",
        "faks": "",
        "mobilen": "",
        "mail": "",
        "prerabotuvac": "true",
        "prerabotuvac_opis": "",
        "izvoznik": "",
        "imatel": "",
        "zakon_organsko_ovaa_godina": "",
        "zakon_organsko_prv_pat": "false",
        "zakon_organsko_nePrvaPat_sertificirano": "",
        "zakon_organsko_prethodno_sertificirano": "",
        "zakon_organsko_drugi": "",
        "tip_kopija": [],
        "objasnuvanja_standard": "",
        "promena_standard": "",
        "sertifikacija_osporena": "false",
        "sertifikacija_osporena_opis": "",
        "minatogodishni_korektivni_aktivnosti": "",
        "korektivni_aktivnosti": [
            {
                "aktivnost": "",
                "implementacija": ""
            }
        ],
        "proizvodi_sertifikacija": [
            {
                "vid_proizvod": "",
                "kolicina_proizvod": "",
                "status_proizvod": ""
            }
        ],
        "preseluvanje": "true",
        "glavna_lokacija": [
            {
                "lokacija": "",
                "br_semejstva": "",
                "status": ""
            }
        ],
        "glavna_lokacija_povrsini": [
            {
                "povrsina_vegetacija": "",
                "vkupna_povrsina": "",
                "izvor_nektar_medlika": "",
                "rizik_zagaduvanje": ""
            }
        ],
        "voda_rastojanie": "",
        "voda_higienski_kvalitet": "",
        "lokacija_migracija": "",
        "lokacija_migracija_period": "",
        "lokacija_migracija_nadmorska_visina": "",
        "lokacija_migracija_godishni_vrnezi": "",
        "lokacija_migracija_povrsini": [
            {
                "povrsina_vegetacija": "",
                "vkupna_povrsina": "",
                "izvor_nektar_medlika": "",
                "rizik_zagaduvanje": ""
            }
        ],
        "mapa_lokacii": "",
        "mapa_upload_id": "",
        "nesoodvetni_lokacii_opis": "",
        "poteklo_semejstva": "",
        "preod_semejstva_opis": "",
        "preod_semejstva": [
            {
                "identifikacija": "",
                "zapocnuva": "",
                "zavrsuva": "",
                "posledna_nabavka": "",
                "br_nabaveni_kolonii": ""
            }
        ],
        "nesoodvetnosti_organsko_poteklo": "",
        "zamena_vosok_preod": "true",
        "zamena_vosok_preod_ne": "",
        "zamena_vosok_preod_procedura": "",
        "zamena_vosok_preod_dokumentacija": "",
        "organizacija_mesanje_vosok": "",
        "vosok_nesoglasnost": "",
        "kosnici_materija": "",
        "kosnici_boenje": "",
        "ramki_materijal": "",
        "cistenje": "",
        "materijal_podobruvanje": "",
        "med_za_pceli": "",
        "sezona_bez_cvetanje": "",
        "nedozvoleno_ishrana": "true",
        "nedozvoleno_ishrana_da": "",
        "nedozvoleno_ishrana_drugo": "",
        "ishrana": "",
        "ishrana_podobruvanje": "",
        "ubivanje_pceli": "",
        "secenje_krila": "",
        "ubivanje_trutovi": "",
        "upotreba_repelenti": "",
        "upravuvanje_kolonii_podobruvanje": "",
        "prevencija_bolesti": "",
        "bolest_opis": "",
        "supstanci_bolesti": "",
        "izolacija_tretirani_kolonii": "",
        "izolacija_tretirani_kolonii_opis": "",
        "testiranje_vodok_med": "true",
        "testiranje_vodok_med_procedura": "",
        "testiranje_vodok_med_rezultati": "",
        "rezultat_upload_id": "",
        "preventiva_bolesti_podobruvanje": "",
        "prinosi": [
            {
                "proizvod": "",
                "vkupno_bruto_lani": "",
                "vkupno_neto_lani": "",
                "bruto_po_semejstvo": "",
                "vkupno_bruto_godinava": "",
                "vkupno_neto_godinava": "",
                "bruto_po_semejstvo_godinava": ""
            }
        ],
        "prinos_komentar": "",
        "alatki_sobiranje": "",
        "higienski_uslovi": "",
        "higienski_uslovi_podobruvanje": "",
        "postberbeno_rakuvanje_opis": [
            {
                "postapka": "",
                "kade_izveduvanje": "",
                "odgovoren": ""
            }
        ],
        "poddogovaraci": "true",
        "poddogovaraci_opis": "",
        "izbegnuvanje_zabraneti_supstanci": "",
        "izbegnuvanje_meshanje_proizvodi": "",
        "korektivni_merki_opis": "",
        "organski_proizvodi_ambalazi": "",
        "etiketa": "",
        "etiketa_upload_id": "",
        "etiketa_primerok": "",
        "etiketa_korekcija": "",
        "logo_znak_opis": "",
        "azurirani_zapisi": "",
        "pcelarski_dnevnik": "",
        "zapisi_veterinarni_tretmani": "",
        "zapisi_kolicini": "",
        "fakturi": "",
        "dnevnik_pakuvanje": "",
        "evidencija_prodazba": "",
        "drugi_zapisi": "",
        "zapisi_zalbi_poplaki": "",
        "primeni_zalbi": [
            {
                "zalba": "",
                "korektivni_aktivnosti": ""
            }
        ],
        "sledlivost": "",
        "sledlivost_podobruvanje": "",
        "aneksi": [
            {
                "broj": "",
                "aneks": ""
            }
        ],
        "nesoodvetnosti_farma": [
            {
                "nesoodvetnost": "",
                "korektivna_aktivnost": "",
                "rok_implementacija": "",
                "tocno_identifikuvani": "",
                "adekvatni": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }
        ],
        "dopolnitelni_nesoodvetnosti": [
            {
                "korektivni_aktivnosti_definicija": "",
                "rok": "",
                "broj": "",
                "nesoodvetnosti": "",
                "adekvateni_korektivni_aktivnosti": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }
        ],
        "zaklucok": [],
        "data_farma": "",
        "data_kontrolor": ""
    }