export function authHeader() {
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.authToken) {
        return {'Authorization': 'Bearer ' + user.authToken, 'Content-Type': 'application/json'};
    } else {
        return {};
    }
}

export function noAuthHeader() {
    return {'Content-Type': 'application/json'};
}

export function fileAuthHeader() {
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.authToken) {
        return {'Authorization': 'Bearer ' + user.authToken};
    } else {
        return {};
    }
}

export function requestOptions() {
    return {
        method: 'GET',
        headers: authHeader()
    }
}

export function postFileRequestOptions(file) {
    return {
        method: 'POST',
        headers: fileAuthHeader(),
        body: file
    }
}

export function getFileRequestOptions() {
    return {
        method: 'GET',
        headers: fileAuthHeader(),
    }
}

export const itemsToDelete = ["navDrawerOpen", "id", "creatorId", "createdAtStart", "createdAtEnd", "creator", "createdAt", "status"]
export const itemsToDeleteUser = ["navDrawerOpen", "id", "username", "error", "message", "notify", "selectedUser"]
export const itemsToDeleteUserCreate = ["navDrawerOpen", "id", "error", "message", "notify", "selectedUser"]
export const itemsToDeleteChangePassword = ["navDrawerOpen", "id", "username", "fullName", "open", "authorities", "error", "message", "notify", "changePassError"]
export const itemsToDeleteSpecifikacija = ["navDrawerOpen", "kapacitetNum", "proizvodstvoNum", "error", "message", "notify"]
export const itemsToDeleteKontrolor = [""]

function formatTodayDate() {
    const today = new Date(); // Get today's date

    // Extract day, month, and year components
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
    const year = today.getFullYear();

    return `${day}.${month}.${year}`;
}

function modifyOpisFarma(json) {
    json.opis_farma.forEach(element => {
        element.pred_3_godini = element.pred_2_godini;
        element.pred_2_godini = element.pred_1_godini;
        element.pred_1_godini = element.ovaa_godina;
        element.ovaa_godina = "";
    });
    return json;
}

export function cleanJsonBody(values, deleteControl = false, duplicate = false) {
    itemsToDelete.forEach(item => delete values[item])
    if (deleteControl) {
        Object.keys(values).forEach(key => {
            if (key.startsWith("control_"))
                delete values[key]
        })
    }
    if (values.opis_farma && duplicate) {
        values = modifyOpisFarma(values);
        values.header_datum_popol = formatTodayDate();
        values.header_datum_kontrola = "";
    }

    return values
}

export function cleanJsonControl(values) {
    Object.keys(values).forEach(key => {
        if (!key.startsWith("control_"))
            delete values[key]
    })
    return values
}

export function cleanJsonUser(values) {
    itemsToDeleteUser.forEach(item => delete values[item])
    return values
}

export function cleanJsonUserCreate(values) {
    itemsToDeleteUserCreate.forEach(item => delete values[item])
    return values
}

export function cleanJsonChangePassword(values) {
    itemsToDeleteChangePassword.forEach(item => delete values[item])
    return values
}

export function cleanJsonSpecifikacija(values) {
    itemsToDeleteSpecifikacija.forEach(item => delete values[item])
    return values
}

// export const apiURL = "http://94.156.46.105:8088/procert-be/api"
export const apiURL = "http://45.32.157.255:55000/procert-be/api"
// export const apiURL = "http://procert.mk:8088/procert-be/api"
// export const apiURL = "http://localhost:8089/api"