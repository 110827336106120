import {
    CREATE_NEW_SPEC_PCELARSTVO,
    CREATE_SPEC_PCELARSTVO,
    DELETE_SPEC_PCELARSTVO,
    FAILED_CREATE_SPEC_PCELARSTVO,
    FETCH_ALL_SPEC_PCELARSTVO,
    FETCH_SPEC_PCELARSTVO,
    UPDATE_SPEC_PCELARSTVO
} from "../actionTypes";

const initialState = {
    specPcelarstvoList: [],
    specPcelarstvo: null,
    error: false
};

export function specPcelarstvoReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_SPEC_PCELARSTVO: {
            const specNum = action.payload.specPcelarstvoList
            return {
                ...state,
                specPcelarstvo: null,
                specPcelarstvoList: specNum,
                specNum: specNum ? specNum.length : 0
            };
        }
        case FETCH_SPEC_PCELARSTVO: {
            return {
                ...state,
                specPcelarstvo: action.payload
            };
        }
        case UPDATE_SPEC_PCELARSTVO: {
            return {
                ...state,
                specPcelarstvo: action.payload
            };
        }
        case CREATE_NEW_SPEC_PCELARSTVO: {
            return {
                ...state,
                specPcelarstvo: action.payload
            };
        }
        case CREATE_SPEC_PCELARSTVO: {
            return {
                ...state,
                specPcelarstvo: action.payload
            };
        }
        case FAILED_CREATE_SPEC_PCELARSTVO: {
            return {
                ...state,
                specPcelarstvo: action.payload,
                error: true
            };
        }
        case DELETE_SPEC_PCELARSTVO: {
            return {
                ...state,
                specPcelarstvo: action.payload
            };
        }
        default:
            return state;
    }
}

export const getSpecPcelarstvoList = state => state.specPcelarstvo.specPcelarstvoList;
export const getSpecNum = state => state.specPcelarstvo.specNum;
export const getSpecPcelarstvo = state => state.specPcelarstvo.specPcelarstvo;