import {Checkbox, Fab, FormGroup, Grid, Input, Typography} from "@material-ui/core";
import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import styles from "../pages/styles";
import {ADD, REMOVE} from '../common/staticLabels'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import UploadInput from "./uploadInput";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from '@material-ui/icons/Delete';

class GridTable extends Component {
    constructor(props) {
        super(props);
        this.state = {obj: this.props.obj};
        this.handleValueChange = this.handleValueChange.bind(this)
        this.handleValueBlur = this.handleValueBlur.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.uploadFileAndSetState = this.uploadFileAndSetState.bind(this)
        this.handleFileIdChange = this.handleFileIdChange.bind(this)
        this.isBlue = this.isBlue.bind(this)
        this.getOptionResult = this.getOptionResult.bind(this)

    }

    getOptionResult(option, index, value, ddData, item, alternative) {
        const result = option && option.ime ? option : ddData[index].filter(obj => {
            return obj.id === item[value];
        })[0];
        return result ? result.ime : alternative;
    }

    isBlue(index) {
        const {controlIndexes} = this.props
        if (controlIndexes !== undefined && controlIndexes.includes(index)) {
            return "rgb(180,223,251)"
        } else {
            return "rgb(255,255,255)"
        }
    }

    setValue(val) {
        this.setState({valueIn: val});
    }

    handleValueChange(name, index, event) {
        event.persist()
        let object = JSON.parse(JSON.stringify(this.state.obj))
        object[index][name] = event.target.value
        this.setState({obj: object})

    };

    handleValueBlur() {
        this.props.onChangeAction(this.props.valueName, this.state.obj)
    }

    handleValueChangeOnce(name, index, event, newValue) {
        event.persist()
        let object = JSON.parse(JSON.stringify(this.state.obj))
        if (newValue !== undefined && newValue !== null) {
            object[index][name] = newValue.id

        } else {
            object[index][name] = null
        }
        this.setState({obj: object});
        this.props.onChangeAction(this.props.valueName, object)
    };

    uploadFileAndSetState(fileUploadId, name, index) {
        this.props.uploadFile(fileUploadId).then(res => {
            this.handleFileIdChange(name, index, res)
        })
    }

    handleFileIdChange(name, index, value) {
        let object = JSON.parse(JSON.stringify(this.state.obj))
        object[index][name] = value
        this.props.onChangeAction(this.props.valueName, object)
        this.setState({obj: object})

    };

    multiselectHandleChange(name, indexOf, event) {
        event.persist()
        const val = event.target.value
        const arr = this.state.obj[indexOf][name]
        const index = this.state.obj[indexOf][name].indexOf(val);
        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.push(val)
        }
        let object = JSON.parse(JSON.stringify(this.state.obj))
        object[indexOf][name] = arr
        this.setState({obj: object})
    };

    handleAdd() {
        let object = JSON.parse(JSON.stringify(this.state.obj))
        object.push(this.props.template)
        this.setState({obj: object})

    }
    handleRemoveForIndex(index) {
        this.setState(
            prevState => {
                const object = [...prevState.obj];
                if (index >= 0 && index < object.length) {
                    object.splice(index, 1);
                }
                return { obj: object };
            },
            () => {
                this.handleValueBlur();
            }
        );
    }
    handleRemove() {
        let object = JSON.parse(JSON.stringify(this.state.obj));
        if (object.length > 1) {
            object.pop();
        }
        this.setState({obj: object}, () => {
            this.handleValueBlur();
        });
    }

    caluclateColumnWidth(unit, col, index) {
        if (col && col[index]) {
            return col[index] * unit + "%"
        } else
            return unit + "%"
    }

    render() {
        const {
            classes,
            labeli,
            columnHeaderClass,
            largerCellOutputFont,
            values,
            types,
            ddData,
            size,
            multilineInput,
            colWidth,
            disabled,
            disabledButtons,
            removeRow,
        } = this.props
        const colUnitWidth = 100 / labeli.length
        const labelStyle = labeli.length > 4 ? "body2" : "h6"
        return (
            <Grid container alignContent={"flex-start"} xs={size || 6}>
                {labeli.map((item, index) => (
                    <Grid className={columnHeaderClass ? classes[columnHeaderClass] : classes.purpleColoredItemGrid}
                          item style={{
                        width: this.caluclateColumnWidth(colUnitWidth, colWidth, index),
                        backgroundColor: this.isBlue(index,)
                    }}>
                        <Typography align={"center"} variant={labelStyle} style={{color: "black"}}
                                    className={largerCellOutputFont ? classes.typographyLineHeightPrint : classes.compactTypographyLineHeightPrint}>{item}</Typography>
                    </Grid>
                ))
                }

                {values && this.state.obj.map((item, itemIndex) => (
                    <Grid container xs={12} style={{border: 0}}>
                        {values.map((value, index) => (
                            <Grid item
                                  style={{
                                      border: "1px solid black",
                                      minHeight: "30px",
                                      width: this.caluclateColumnWidth(colUnitWidth, colWidth, index),
                                      backgroundColor: this.isBlue(index),
                                      alignItems: "center",
                                      display: "flex"
                                  }}>
                                {(types[index] === "text" || types[index] === "number" || types[index] === "textarea") &&
                                <React.Fragment>
                                    <Input className={classes.lessPaddedFullWidthInputNoPrint}
                                           disabled={disabled === undefined ? false : disabled[index]}
                                           type={types[index]}
                                           multiline={multilineInput || types[index] === "textarea"}
                                           rows={1}
                                           rowsMax={10}
                                           onChange={(event) => this.handleValueChange(value, itemIndex, event)}
                                           onBlur={this.handleValueBlur}
                                           value={this.state.obj[itemIndex][value]}/>
                                    <Typography align={"center"} variant={labelStyle} style={{color: "black"}}
                                                className={largerCellOutputFont ? classes.typographyPrint : classes.compactTypographyPrint}>{this.state.obj[itemIndex][value]}</Typography>
                                </React.Fragment>}
                                {types[index] === "dropdown" &&
                                <React.Fragment>
                                    <Autocomplete
                                        id="multi-filter"
                                        className={classes.lessPaddedFullWidthInputNoPrint}
                                        options={ddData[index]}
                                        value={item[value]}
                                        disabled={disabled === undefined ? false : disabled[index]}
                                        onChange={(event, newValue) => this.handleValueChangeOnce(value, itemIndex, event, newValue)}
                                        getOptionLabel={(option) => this.getOptionResult(option, index, value, ddData, item, "")}
                                        renderInput={(params) => (
                                            <div
                                                className={classes.lessPaddedFullWidthInput}
                                                ref={params.InputProps.ref}
                                            >
                                                <input
                                                    className={classes.noOutline}
                                                    style={{
                                                        height: "32px",
                                                        background: "none",
                                                        border: 0,
                                                        width: "100%",
                                                        "&:hover": {outline: "none"}
                                                    }}
                                                    type="search"
                                                    aria-label="Search"
                                                    {...params.inputProps}
                                                />
                                            </div>
                                        )}
                                    />
                                    <Typography align={"center"} variant={labelStyle} style={{color: "black"}}
                                                className={largerCellOutputFont ? classes.typographyPrint : classes.compactTypographyPrint}>{this.getOptionResult(null, index, value, ddData, item, "  ")}</Typography>
                                </React.Fragment>
                                }
                                {types[index] === "checkbox" &&
                                <FormGroup row>
                                    {
                                        ddData[index].map((item) => (
                                                < FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{width: "20%"}}
                                                            checked={this.state.obj[itemIndex][value].includes(item.ime)}
                                                            onChange={(event) => this.multiselectHandleChange(value, itemIndex, event)}
                                                            value={item.ime}
                                                            disabled={disabled === undefined ? false : disabled[index]}
                                                        />
                                                    }
                                                    label={item.ime}
                                                    labelPlacement="top"
                                                />
                                            )
                                        )}
                                </FormGroup>
                                }
                                {
                                    types[index] === "upload" &&
                                    <UploadInput uploadFile={() => this.props.uploadFile()}
                                                 fileUploadId={"file_upload_" + itemIndex + "_" + value}
                                                 disabledButtons={disabled === undefined ? false : disabled[index]}
                                                 fileId={this.state.obj[itemIndex][value]}
                                                 onChangeAction={() => this.uploadFileAndSetState("file_upload_" + itemIndex + "_" + value, value, itemIndex)}
                                                 uploadLabel={"Додади " + labeli[values.length - 1]}
                                                 downloadLabel={"Превземи " + labeli[values.length - 1]}
                                    />
                                }
                                {
                                    removeRow === true && index === values.length - 1 &&
                                    <Grid item style={{
                                        borderLeft: "1px solid black",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%"
                                    }}
                                          className={classes.noPrint}>
                                        <Fab
                                            justify="center"
                                            variant="extended"
                                            aria-label="add"
                                            className={classes.thinButton}
                                            onClick={() => this.handleRemoveForIndex(itemIndex)}
                                            disabled={disabledButtons === undefined ? false : disabledButtons}
                                        >
                                            <DeleteIcon/>
                                        </Fab>
                                    </Grid>

                                }
                            </Grid>
                        ))
                        }
                    </Grid>
                ))
                }

                <Grid item xs={12} style={{border: "1px solid black",borderTop: "none", display: 'flex', justifyContent: 'center'}}
                      className={classes.noPrint}>
                    <Fab
                        justify="center"
                        variant="extended"
                        color="primary"
                        aria-label="add" className={classes.thinButton}
                        onClick={this.handleAdd}
                        disabled={disabledButtons === undefined ? false : disabledButtons}
                    >{ADD}</Fab>
                    {!removeRow &&
                    <Fab
                        justify="center"
                        variant="extended"
                        aria-label="add" className={classes.thinButton}
                        onClick={this.handleRemove}
                        disabled={disabledButtons === undefined ? false : disabledButtons}
                    >{REMOVE}</Fab>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(GridTable);