import {
    CREATE_NEW_POU_PCELARSTVO,
    CREATE_POU_PCELARSTVO,
    DELETE_POU_PCELARSTVO,
    DUPLICATE_POU_PCELARSTVO,
    FETCH_ALL_POU_PCELARSTVO,
    FETCH_POU_PCELARSTVO,
    UPDATE_POU_PCELARSTVO
} from "../actionTypes";


export const fetchPouPcelarstvoListAction = (payload) => ({
    type: FETCH_ALL_POU_PCELARSTVO,
    payload: {
        pouPcelarstvoList: payload,
    },
})

export const fetchPouPcelarstvoByIdAction = (payload) => ({
    type: FETCH_POU_PCELARSTVO,
    payload: {
        pouPcelarstvo: payload,
    },
})
export const createNewPouPcelarstvoAction = (payload) => ({
    type: CREATE_NEW_POU_PCELARSTVO,
    payload: {
        pouPcelarstvo: payload,
    },
})

export const createPouPcelarstvoAction = (payload) => ({
    type: CREATE_POU_PCELARSTVO,
    payload: {
        pouPcelarstvo: payload,
    },
})
export const updatePouPcelarstvoAction = (payload) => ({
    type: UPDATE_POU_PCELARSTVO,
    payload: {
        pouPcelarstvo: payload,
    },
})

export const duplicatePouPcelarstvoAction = (payload) => ({
    type: DUPLICATE_POU_PCELARSTVO,
    payload: {
        pouPcelarstvo: payload,
    },
})

export const deletePouPcelarstvoAction = () => ({
    type: DELETE_POU_PCELARSTVO,
    payload: {
        pouPcelarstvo: null,
        pouNum: 0
    },
})