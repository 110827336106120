import React from 'react';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Notification from "../../common/Notification";
import {getChangePassError, getChangePassMessage, getUser} from "../../redux/user/reducer";
import {bindActionCreators} from "redux";
import {changePasswordRequest} from "../../redux/user/fetch";
import CustomLabeledInput from "../../components/customLabeledInput";
import * as ALL from "../../common/staticLabels";
import {cleanJsonChangePassword} from "../../redux/helpers";
import PropTypes from "prop-types";
import globalStyles from "../styles";

export class EditOwnUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            notify: false,
            message: '',
            error: false,
            ...this.props.user.user,
            changePassError: this.props.changePassError,
            changePassMessage: this.props.changePassMessage
        }
        this.checkCanSave = this.checkCanSave.bind(this)
        this.requiredFieldsMessage = this.requiredFieldsMessage.bind(this)
        this.showNotification = this.showNotification.bind(this)
    }

    checkCanSave() {
        return (!!this.state.currentPassword && !!this.state.newPassword && !!this.state.confirmPassword)
    }

    requiredFieldsMessage() {
        this.showNotification("Полињата се задолжителни!");
    }

    changePassword(values) {
        const id = values.id
        let newValues = cleanJsonChangePassword(JSON.parse(JSON.stringify(values)), true);
        this.props.changePasswordRequest(newValues, id)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.changePassError === false) {
            this.showNotification("Успешно зачувано");
            setTimeout(() => this.redirectToEditOwnUser(), 1000); //execute greet after 2000 milliseconds (2 seconds)
        }
        if (nextProps.changePassError === true) {
            this.showNotification(nextProps.changePassMessage.join(', '), false)
        }
    }

    redirectToEditOwnUser() {
        window.location.reload()
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleChange(name, event) {
        event.persist()
        this.handleObj(name, event.target.value)
    };

    handleObj(name, value) {
        let object = JSON.parse(JSON.stringify(value))
        this.setState({[name]: object})

    };

    render() {
        const {classes} = this.props;
        const {notify, message, error} = this.state;
        const canSave = this.checkCanSave()

        return (
            <div style={{padding: 20, margin: 50, border: "1px solid black"}}>
                <Grid container className={classes.container}>
                    <CustomLabeledInput labelInput={ALL.USERNAME}
                                        inputType={"text"}
                                        size={12}
                                        disabled={true}
                                        valueInput={this.state.username}
                                        onChangeAction={(event) => this.handleChange('username', event)}
                    />

                    <CustomLabeledInput labelInput={ALL.FULLNAME}
                                        inputType={"text"}
                                        size={12}
                                        disabled={true}
                                        valueInput={this.state.fullName}
                                        onChangeAction={(event) => this.handleChange('fullName', event)}
                    />

                    <CustomLabeledInput labelInput={ALL.CURRENT_PASSWORD}
                                        inputType={"password"}
                                        size={12}
                                        valueInput={this.state.currentPassword}
                                        onChangeAction={(event) => this.handleChange('currentPassword', event)}
                    />
                    <CustomLabeledInput labelInput={ALL.NEW_PASSWORD}
                                        inputType={"password"}
                                        size={12}
                                        valueInput={this.state.newPassword}
                                        onChangeAction={(event) => this.handleChange('newPassword', event)}
                    />
                    <CustomLabeledInput labelInput={ALL.CONFIRM_PASSWORD}
                                        inputType={"password"}
                                        size={12}
                                        valueInput={this.state.confirmPassword}
                                        onChangeAction={(event) => this.handleChange('confirmPassword', event)}
                    />
                </Grid>

                {
                    (!canSave) &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.requiredFieldsMessage()}>
                        Промени лозинка
                    </Button>
                }
                {
                    (canSave) &&
                    <Button
                        classes={{
                            root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.changePassword(this.state)}>
                        Промени лозинка
                    </Button>
                }
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

EditOwnUser.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    }
;

const mapStateToProps = state => ({
    user: getUser(state),
    changePassError: getChangePassError(state),
    changePassMessage: getChangePassMessage(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({changePasswordRequest}, dispatch)
}

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(EditOwnUser)
)