export const newPouRastitelnoData =
    {
        "header_kompanija": "",
        "header_datum_popol": "",
        "header_odgovoren_kompanija": "",
        "header_kontrolor": "",
        "header_datum_kontrola": "",
        "plan_kontrola": [
            {
                "data": "",
                "aktivnost": "",
                "zaedno_so": "",
                "posebni_incidenti": ""
            }
        ],
        "zemeni_primeroci_kontrola": "",
        "ime_farma": "",
        "sopstvenik": "",
        "odgovorno_lice": "",
        "ulica_broj_fax": "",
        "grad_postenski_kod": "",
        "mail": "",
        "telefon": "",
        "prerabotuvac": "",
        "prerabotuvac_opis": "",
        "imatel": "",
        "podogovarachi": "",
        "tip_kopija": [],
        "sertifikacija_osporena": "",
        "minatogodisni_korektivni": "",
        "minatogodisni_korektivni_akt": [
            {
                "aktivnost": "",
                "implementacija": ""
            }
        ],
        "povrsini_kulturi_sertifikacija": [
            {
                "kultura": "",
                "povrshina": "",
                "status": "",
            }
        ],
        "nadmorska_visina_farma": "",
        "godishni_vrnezi_farma": "",
        "pochvi_farma": "",
        "pochvena_erozija": "",
        "zagadena_voda": "",
        "spaluvanje_materijali": "",
        "povrshini_neiskoristeni": "",
        "povrshini_neiskoristeni_opis": "",
        "neorganski_smet": "",
        "opis_farma": [
            {
                "parcela": "",
                "povrshina": "",
                "posledna_upotreba_gjubrivo": "",
                "posledna_upotreba_pesticidi": "",
                "konvencionalno": "",
                "pred_3_godini": "",
                "pred_2_godini": "",
                "pred_1_godini": "",
                "ovaa_godina": ""
            }
        ],
        "mapa_parceli": "",
        "mapa_upload_id": "",
        "stocharstvo": "",
        "stocharstvo_zivotni": [
            {
                "vid": "",
                "broj": "",
            }
        ],
        "period_preod_novi_parceli": "",
        "period_preod_novi_parceli_ne": "",
        "priznavanje_upravuvanje_zemja": "",
        "dokaz_upravuvanje_minato": "",
        "paralelno_proizvodstvo": "",
        "paralelno_proizvodstvo_data": [
            {
                "kultura": "",
                "povrsina": "",
            }
        ],
        "seminja_saden_materijal": [
            {
                "kultura": "",
                "seminja": [],
            }
        ],
        "plodored_ne_primenlivo": "",
        "plodored_opis": "",
        "legumenozi_opis": "",
        "gjubrenje_opis": "",
        "gjubrenje": [{
            "gjubrivo": "",
            "poteklo_sopstveno": "",
            "poteklo_kupeno": "",
            "kolichini": ""
        }],

        "kontrola_bolesti_impl": "",
        "kontrola_bolesti_proizvodi": [{
            "kultura": "",
            "shtetnik": "",
            "proizvod": "",
            "doza": "",
            "aktivna_materija": "",
            "bez_preparat": []
        }],
        "pesticidi_sosedni_farmi": "",
        "pesticidi_sosedni_farmi_granici": [{
            "povrshina": "",
            "granici": "",
            "sosedna_konven_kultura": "",
            "intenzitet_prskanje": "",
            "oprema_prskanje": "",
            "rastojanie": "",
            "vegetacija": "",
            "rizik": ""
        }],
        "ispituvanje_rezidui": "",
        "ispituvanje_rezidui_opis": "",
        "navodnuvanje_zagaduvanje": "",
        "navodnuvanje_zagaduvanje_opis": "",
        "berba_prinosi": [{
            "kultura": "",
            "vkupno_bruto_lani": "",
            "bruto_ha_lani": "",
            "vkupno_bruto_godinava": "",
            "bruto_ha_godinava": "",
            "period_berba": ""
        }],
        "prihod_proizvod": [{
            "kultura": "",
            "zaliha_lani": "",
            "vkupno_neto_prinos": "",
            "prodadeno_organsko": "",
            "prodadeno_organsko_preod": "",
            "prodadeno_konvencionalno": "",
            "momentalna_zaliha": ""
        }],
        "post_berbeno_rakuvanje": [],
        "post_berbeno_rakuvanje_opis": [{
            "postapka": "",
            "mesto_izveduvanje": "",
            "odgovoren": "",
            "nachin_odeluvanje": ""
        }],
        "poddogovarachi_post_berbeno": "",
        "skaldiranje": "",
        "skaldiranje_opis": "",
        "etiketa": "",
        "etiketa_file": "",
        "logo_znak": "",
        "logo_znak_opis": "",
        "azurirani_zapisi": "",
        "dnevnik": "",
        "fakturi": "",
        "dnevnik_pakuvanje": "",
        "evidencija_prodazba": "",
        "drugi": "",
        "drugi_opis": "",
        "zapisi_zalbi_poplaki": "",
        "zapisi_zalbi_poplaki_ne": "",
        "primeni_zalbi": [
            {
                "zalba": "",
                "korektivni_aktivnosti": ""
            }
        ],
        "aneksi": [
            {
                "broj": "",
                "aneks": ""
            }
        ],
        "nesoodvetnosti_farma": [
            {
                "nesoodvetnost": "",
                "korektivna_aktivnost": "",
                "rok_implementacija": "",
                "tocno_identifikuvani": "",
                "adekvatni": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }
        ],
        "dopolnitelni_nesoodvetnosti": [
            {
                "korektivni_aktivnosti_definicija": "",
                "rok": "",
                "broj": "",
                "nesoodvetnosti": "",
                "adekvateni_korektivni_aktivnosti": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": ""
            }
        ],
        "zaklucok": [],
        "data_farma": null,
        "data_kontrolor": null
    }