import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import {Grid, Typography} from '@material-ui/core';
import * as ALL from "../../../common/staticLabels";
import GridTable from "../../../components/gridTable";
import {yesno} from "../../../mockData/ddDaNeData";
import globalStyles from "../../styles";
import Button from "@material-ui/core/Button";
import CustomLabeledInput from "../../../components/customLabeledInput";
import {getPouNum, getPouRastitelno, getPouRastitelnoList} from "../../../redux/pouRastitelno/reducer";
import {bindActionCreators} from "redux";
import {
    deletePouRastitelnoRequest,
    fetchPouRastitelnoById,
    fetchPouRastitelnoList
} from "../../../redux/pouRastitelno/fetch";
import {connect} from "react-redux";
import Notification from "../../../common/Notification";

class SpecRastitelno extends Component {
    constructor(props) {
        super(props);
        this.updateObject = this.updateObject.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleObj = this.handleObj.bind(this)
        this.handleDDChange = this.handleDDChange.bind(this)
        this.updateSpecDataFromPout = this.updateSpecDataFromPout.bind(this)
        this.handleAddKapacitetiWithParams = this.handleAddKapacitetiWithParams.bind(this)
        this.handleAddProizvodstvoWithParams = this.handleAddProizvodstvoWithParams.bind(this)
        this.handlePrint = this.handlePrint.bind(this)
        this.addLeadingZeroes = this.addLeadingZeroes.bind(this);

        this.state = {
            navDrawerOpen: false,
            ...this.props.pouId,
            ...this.props.specRastitelno,
            notify: false,
            message: '',
            error: false
        };
    }


    componentWillMount() {
        const {fetchPouRastitelnoList} = this.props;
        fetchPouRastitelnoList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((!this.state.pouRastitelno && nextProps.pouRastitelno) || (this.state.pouRastitelno && this.state.pouRastitelno.id !== nextProps.pouRastitelno.id)) {
            this.updateSpecDataFromPout(nextProps.pouRastitelno);
            this.setState({["pouName"]: nextProps.pouRastitelno.pouRastitelno.ime_farma})
        }
    }

    handlePrint() {
        // Create a <style> element
        var style = document.createElement('style');

        // Define the @page rule for A4 portrait
        style.textContent = '@page { size: A4 portrait; }';

        // Append the <style> element to the <head>
        document.head.appendChild(style);

        // Apply any additional print-specific styles here

        // Trigger the print dialog
        window.print();

        // Remove the dynamically added <style> element to avoid affecting future prints
        document.head.removeChild(style);
    }

    handleDDChange(name, event, value) {
        event.persist()
        this.setState({[name]: value})
        this.props.fetchPouRastitelnoById(value)

    };

    updateSpecDataFromPout(pou) {
        const kapaticetiInput = pou.pouRastitelno.opis_farma; //tabela 9.1
        const proizvodstvoInput = pou.pouRastitelno.povrsini_kulturi_sertifikacija; // tabela 5
        let listKapaciteti = []
        let listProizvodstvo = []
        if (kapaticetiInput) {
            kapaticetiInput.forEach(p => this.handleAddKapacitetiWithParams(listKapaciteti, p.parcela, p.povrshina,))
        }
        if (proizvodstvoInput) {
            proizvodstvoInput.forEach(p => this.handleAddProizvodstvoWithParams(listProizvodstvo, p.kultura, p.povrshina, p.status))
        }
    }

    checkCanSave() {
        return true
    }

    handleAdd(type, template) {
        let list = this.state[type]
        list.push(template)
        return {...list, [type]: list};
    }

    handleAddKapacitetiWithParams(listKapaciteti, parcelaI, povrsinaI) {
        const myObj = {
            parcela: parcelaI,
            povrsina: povrsinaI

        };
        listKapaciteti.push(myObj)
        const random = Math.random() + Math.random();
        this.setState({'kapaciteti': listKapaciteti, 'kapacitetNum': random + listKapaciteti.length});
    }

    handleAddProizvodstvoWithParams(listProizvodstvo, kulturaI, povrsinaI, statusI) {
        const myObj = {
            kultura: kulturaI,
            povrsina: povrsinaI,
            status: statusI,
        };
        listProizvodstvo.push(myObj)
        const random = Math.random() + Math.random();
        this.setState({'proizvodstvo': listProizvodstvo, 'proizvodstvoNum': random + listProizvodstvo.length});
    }

    handleRemove(type) {
        let list = this.state[type]
        if (list.length > 1) {
            list.pop()
        }
        this.setState({[type]: list});
    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value})
    };

    handleObj(name, value) {
        this.setState({[name]: value})
    };

    updateObject(obj, keys, value) {
        let key = keys.shift();
        if (keys.length > 0) {

            let tmp = this.updateObject(obj[key], keys, value);
            return {...obj, [key]: tmp};
        } else {
            return {...obj, [key]: value};
        }
    }

    addLeadingZeroes(num) {
        const number = num ? num : 0
        const numberString = number.toString()
        const desiredLength = 4;

        if (numberString.length >= desiredLength) {
            // If the number already has 4 or more digits, return it as is
            return numberString;
        } else {
            // Add leading zeroes to make it a 4-digit number
            const numberOfZeroesToAdd = desiredLength - numberString.length;
            const leadingZeroes = '0'.repeat(numberOfZeroesToAdd);
            return leadingZeroes + numberString;
        }
    }

    render() {
        const {classes, createMode, editMode, pouRastitelnoList} = this.props;
        const {notify, message, error} = this.state;
        const canSave = this.checkCanSave()
        const proizvodniKapacitetiTemplate = {
            "parcela": "",
            "povrsina": "",
            "organsko": "",
            "preod": ""
        }
        const proizvodstvoTemplate = {
            "kultura": "",
            "povrsina": "",
            "status": ""
        }

        return (

            <div className={classes.mainContainer}>
                <Grid container spacing={16} className={classes.noPrint}>
                    <Typography align={"center"} variant="h6">{ALL.SPEC_IZBOR_POU}</Typography>
                    {pouRastitelnoList &&
                    <Grid item xs={12}>

                        <CustomLabeledInput
                            inputType={"dropdown"}
                            options={pouRastitelnoList}
                            valueInput={this.state.pouId}
                            onChangeAction={(event, newValue) => this.handleDDChange('pouId', event, newValue)}/>
                    </Grid>
                    }
                </Grid>

                {this.state.pouId && this.props.lookupData !== undefined &&
                <div>
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <div className={classes.noPrint}>
                                <CustomLabeledInput labelInput={"Внеси број на спецификација "}
                                                    inputType={"text"}
                                                    size={5}
                                                    noBorder={true}
                                                    placeholderInput={" "}
                                                    valueInput={this.addLeadingZeroes(this.state.broj_spec)}
                                                    onChangeAction={(event) => this.handleChange('broj_spec', event)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"}
                                        variant="h4">{ALL.SPECIFICATION_TITLE_MK} {this.addLeadingZeroes(this.state.broj_spec)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"} variant="h6"
                                        className={classes.h6LineHeight}>{ALL.SPECIFICATION_DESC_MK}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"}
                                        variant="h4">{ALL.SPECIFICATION_TITLE_ENG} {this.addLeadingZeroes(this.state.broj_spec)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align={"center"} variant="h6"
                                        className={classes.h6LineHeight}>{ALL.SPECIFICATION_DESC_ENG}</Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.divider}/>
                    <Grid container xs={12} className={classes.container}>
                        {/*<Grid alignContent={"flex-start"} container className={classes.paddedRightContainer} xs={6}>*/}
                        {/*    <Grid xs={12} className={classes.lessHeightControlColoredItemGrid}>*/}
                        {/*        <Typography*/}
                        {/*            align={"center"}*/}
                        {/*            variant="h5">{ALL.RASTITELNO_PROIZVODNI_KAPACITETI}</Typography>*/}
                        {/*    </Grid>*/}
                        {/*    <GridTable obj={this.state.kapaciteti}*/}
                        {/*               key={this.state.kapacitetNum}*/}
                        {/*               largerCellOutputFont={true}*/}
                        {/*               labeli={[ALL.PARCELA, ALL.POVRSHINA, ALL.ORGANSKO, ALL.VO_PREOD]}*/}
                        {/*               values={["parcela", "povrsina", "organsko", "preod"]}*/}
                        {/*               types={["textarea", "textarea", "dropdown", "dropdown"]}*/}
                        {/*               ddData={[null, null, yesno, yesno]}*/}
                        {/*               columnHeaderClass={'lessHeightPurpleControlColoredItemGrid'}*/}
                        {/*               valueName={"kapaciteti"}*/}
                        {/*               removeRow={true}*/}
                        {/*               colWidth={[1,1,1,1]}*/}
                        {/*               onChangeAction={(name, value) => this.handleObj(name, value)}*/}
                        {/*               size={12}*/}
                        {/*               template={proizvodniKapacitetiTemplate}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid alignContent={"flex-start"} container className={classes.paddedLeftContainer} xs={12}>
                            <Grid xs={12} className={classes.lessHeightControlColoredItemGrid}>
                                <Typography align={"center"} variant="h5">{ALL.PROIZVODSTVO}</Typography>
                            </Grid>
                            <GridTable obj={this.state.proizvodstvo}
                                       key={this.state.proizvodstvoNum}
                                       largerCellOutputFont={true}
                                       labeli={[ALL.KULTURA, ALL.POVRSHINA, ALL.SPECIFIKACIJA_STATUS]}
                                       values={["kultura", "povrsina", "status"]}
                                       types={["dropdown", "text", "dropdown"]}
                                       ddData={[this.props.lookupData.kultura, null, this.props.lookupData.document_status]}
                                       removeRow={true}
                                       colWidth={[1,1,1]}
                                       columnHeaderClass={'lessHeightPurpleControlColoredItemGrid'}
                                       valueName={"proizvodstvo"}
                                       onChangeAction={(name, value) => this.handleObj(name, value)}
                                       size={12}
                                       template={proizvodstvoTemplate}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.divider}/>
                    <div className={classes.divider}/>
                    <div className={classes.divider}/>
                    <div className={classes.divider}/>

                    <Grid item xs={12}>
                        <Typography align={"left"} variant="h8"
                                    className={classes.h6LineHeight}>{ALL.SPECIFICATION_FOOTER}</Typography>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={9}/>
                        <Grid item xs={3}>
                            <Typography className={classes.centeredText}
                                        variant="h5">{ALL.IZRABOTIL}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={9}/>
                        <Grid item xs={3}>
                            <div className={classes.divSignature}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={9}/>
                        <Grid item xs={3}>
                            <Typography className={classes.centeredText}
                                        variant="h5">{ALL.PROCERT_EN}</Typography>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    {(createMode || editMode) && !canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.requiredFieldsMessage()}>
                        {createMode ? "Креирај спецификација" : "Зачувај спецификација"}
                    </Button>
                    }
                    {(createMode || editMode) && canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.props.onSubmit(this.state)}>
                        {createMode ? "Креирај спецификација" : "Зачувај спецификација"}
                    </Button>
                    }
                    {/*{*/}
                    {/*    (createMode || editMode) && canSave &&*/}
                    {/*    < Button*/}
                    {/*        classes={{*/}
                    {/*            root: classes.printFormStickyButton, // class name, e.g. `classes-nesting-root-x`*/}
                    {/*            label: classes.label, // class name, e.g. `classes-nesting-label-x`*/}
                    {/*        }}*/}
                    {/*        variant="contained" size={"small"}*/}
                    {/*        aria-label="print"*/}
                    {/*        onClick={() => this.handlePrint()}>*/}
                    {/*        {<PrintIcon/>}*/}

                    {/*    </Button>*/}
                    {/*}*/}
                </div>
                }
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

SpecRastitelno.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    };

SpecRastitelno = reduxForm(
    {
        form: 'SpecRastitelno',
        enableReinitialize: true,
        hasRole: true,
    }
)(SpecRastitelno)

function mapStateToProps(state) {
    return {
        pouRastitelnoList: getPouRastitelnoList(state),
        pouRastitelno: getPouRastitelno(state),
        pouNum: getPouNum(state)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPouRastitelnoList,
        fetchPouRastitelnoById,
        deletePouRastitelnoRequest
    }, dispatch)
}

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(SpecRastitelno)
)
