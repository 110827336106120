import {
    createNewPouPcelarstvoControlAction,
    createPouPcelarstvoControlAction,
    deletePouPcelarstvoControlAction,
    fetchPouPcelarstvoControlByIdAction,
    updatePouPcelarstvoControlAction
} from "./actions";
import {apiURL, authHeader, requestOptions} from "../helpers";
import {newPouPcelarstvoControlData} from "./newPouPcelarstvoControlData";


export function fetchPouPcelarstvoControlById(pouPcelarstvoControlId) {
    return dispatch => {
        fetch(`${apiURL}/kontrolorPcelarstvo/${pouPcelarstvoControlId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again

                dispatch(fetchPouPcelarstvoControlByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewPouPcelarstvoControl() {
    const newPouPcelarstvoControl = newPouPcelarstvoControlData
    return dispatch => {
        dispatch(createNewPouPcelarstvoControlAction(newPouPcelarstvoControl))
        return newPouPcelarstvoControl
    }
}

export function updatePouPcelarstvoControlRequest(pouPcelarstvoControl, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...pouPcelarstvoControl})
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorPcelarstvo/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updatePouPcelarstvoControlAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function createPouPcelarstvoControlRequest(pouPcelarstvoControl) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...pouPcelarstvoControl})
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorPcelarstvo/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(createPouPcelarstvoControlAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deletePouPcelarstvoControlRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorPcelarstvo/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deletePouPcelarstvoControlAction());
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}