import {Checkbox, FormGroup, Grid, Input, Radio, RadioGroup, Typography} from "@material-ui/core";
import React, {Component} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../pages/styles";

class CustomLabeledInput extends Component {

    constructor(props) {
        super(props);
        this.state = {valueIn: this.props.valueInput};
    }

    setValue(val) {
        this.setState({valueIn: val});
    }

    setEventValue(event, val) {
        if (val && val.id) {
            this.setState({valueIn: val.id});
            this.props.onChangeAction(event, val.id)
        } else {
            this.setState({valueIn: null});
            this.props.onChangeAction(event, null)
        }

    }

    render() {
        const {
            classes,
            labelInput,
            secondLabelInput,
            thirdLabelInput,
            placeholderInput,
            inputType,
            valueInput,
            onChangeAction,
            options,
            size,
            firstElementSize,
            disabled,
            customClassName,
            multilineInput,
            fullWidthCheckbox,
            vertical,
            requiredInput,
            noBorder
        } = this.props
        const firstElement = vertical ? 12 : firstElementSize ? firstElementSize : 6
        const secondElement = vertical ? 12 : 12 - firstElement
        const checkboxWidthStyle = fullWidthCheckbox ? {width: "100%"} : {}
        const borderStyle = noBorder ? {border: "0px"} : {border: "2px solid black"}
        return (
            <Grid container xs={size || 6} className={customClassName} style={borderStyle}>
                {labelInput &&
                <Grid item xs={firstElement}>
                    <Typography display="block" variant="h6"
                                className={classes.typographyLineHeight}>{labelInput}</Typography>
                    {secondLabelInput &&
                    <Typography variant="h6" className={classes.typographyLineHeight}>{secondLabelInput}</Typography>}
                    {thirdLabelInput &&
                    <Typography variant="h6" className={classes.typographyLineHeight}>{thirdLabelInput}</Typography>}
                </Grid>
                }
                <Grid item xs={labelInput ? secondElement : 12}>
                    {inputType === "text" && <Input placeholder={placeholderInput || labelInput}
                                                    className={classes.paddedFullWidthInput}
                                                    value={this.state.valueIn}
                                                    margin={"none"}
                                                    onChange={event => this.setValue(event.target.value)}
                                                    onBlur={onChangeAction}
                                                    disabled={disabled}
                                                    multiline={multilineInput}
                                                    rows={1}
                                                    rowsMax={10}
                                                    required={requiredInput}
                    />}
                    {inputType === "number" && <Input placeholder={placeholderInput || labelInput}
                                                      className={classes.paddedFullWidthInput}
                                                      value={this.state.valueIn}
                                                      margin={"none"}
                                                      onChange={event => this.setValue(event.target.value)}
                                                      onBlur={onChangeAction}
                                                      disabled={disabled}
                                                      rows={1}
                                                      type="number"
                    />}
                    {inputType === "password" && <Input placeholder={placeholderInput || labelInput}
                                                        className={classes.paddedFullWidthInput}
                                                        value={this.state.valueIn}
                                                        margin={"none"}
                                                        onChange={event => this.setValue(event.target.value)}
                                                        onBlur={onChangeAction}
                                                        disabled={disabled}
                                                        rows={1}
                                                        type="password"
                    />}
                    {inputType === "dropdown" &&
                    <Autocomplete
                        id="multi-filter"
                        className="t-search"
                        options={options}
                        value={this.state.valueIn}
                        onChange={(event, newValue) => this.setEventValue(event, newValue)}
                        getOptionLabel={(option) => {
                            const result = option && option.ime ? option : options.filter(obj => {
                                return obj.id === this.state.valueIn
                            })[0]
                            return result ? result.ime : " "
                        }
                        }
                        renderInput={(params) => (
                            <div
                                className={classes.paddedFullWidthInput}
                                ref={params.InputProps.ref}
                            >
                                <input
                                    className={classes.noOutline}
                                    style={{
                                        height: "32px",
                                        background: "none",
                                        border: 0,
                                        width: "100%",
                                        "&:hover": {outline: "none"}
                                    }}
                                    type="search"
                                    placeholder={labelInput}
                                    aria-label="Search"
                                    {...params.inputProps}
                                />
                            </div>
                        )}
                    />
                        // <Select value={valueInput}
                        //         className={classes.paddedFullWidthInput}
                        //         onChange={onChangeAction}
                        // >
                        //     {options.map((item) => (
                        //         <MenuItem name={item.ime} value={item.id}>
                        //             <em> {item.ime}</em>
                        //         </MenuItem>
                        //     ))}
                        // </Select>

                    }

                    {inputType === "radio" &&
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        className={classes.paddedRadio}
                        value={valueInput}
                        row
                        onChange={onChangeAction}
                    >
                        {options.map((item) => (
                            <FormControlLabel
                                value={item.id}
                                control={<Radio color="primary"/>}
                                label={item.ime}
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroup>
                    }
                    {inputType === "checkbox" &&
                    <FormGroup row>
                        {
                            options.map((item) => (
                                    < FormControlLabel
                                        style={checkboxWidthStyle}
                                        control={
                                            <Checkbox
                                                checked={valueInput ? valueInput.includes(item.ime) : false}
                                                onChange={onChangeAction}
                                                value={item.ime}
                                                disabled={disabled}
                                            />
                                        }
                                        label={item.ime}
                                    />
                                )
                            )}
                    </FormGroup>
                    }
                </Grid>
            </Grid>
        )
    }

}

export default withStyles(globalStyles)(CustomLabeledInput);