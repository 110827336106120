import {
    CREATE_NEW_POU_PCELARSTVO,
    CREATE_POU_PCELARSTVO,
    DELETE_POU_PCELARSTVO,
    FETCH_ALL_POU_PCELARSTVO,
    FETCH_POU_PCELARSTVO,
    UPDATE_POU_PCELARSTVO
} from "../actionTypes";

const initialState = {
    pouPcelarstvoList: [],
    pouPcelarstvo: null
};

export function pouPcelarstvoReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_POU_PCELARSTVO: {
            const pouNum = action.payload.pouPcelarstvoList;
            return {
                ...state,
                pouPcelarstvo: null,
                pouPcelarstvoList: pouNum,
                pouNum: pouNum ? pouNum.length : 0
            };
        }
        case FETCH_POU_PCELARSTVO: {
            return {
                ...state,
                pouPcelarstvo: action.payload
            };
        }
        case UPDATE_POU_PCELARSTVO: {
            return {
                ...state,
                pouPcelarstvo: action.payload
            };
        }
        case CREATE_NEW_POU_PCELARSTVO: {
            return {
                ...state,
                pouPcelarstvo: action.payload
            };
        }
        case CREATE_POU_PCELARSTVO: {
            return {
                ...state,
                pouPcelarstvo: action.payload
            };
        }
        case DELETE_POU_PCELARSTVO: {
            return {
                ...state,
                pouPcelarstvo: action.payload
            };
        }
        default:
            return state;
    }
}

export const getPouPcelarstvoList = state => state.pouPcelarstvo.pouPcelarstvoList;
export const getPouNum = state => state.pouPcelarstvo.pouNum;
export const getPouPcelarstvo = state => state.pouPcelarstvo.pouPcelarstvo;