export const daNePonekogasData =
    [
        {
            id: "da", ime: "Да"
        },
        {
            id: "ne", ime: "Не"
        },
        {
            id: "ponekogas", ime: "Понекогаш"
        }
    ]