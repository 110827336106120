import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {bindActionCreators, compose} from "redux";
import PouRastitelno from "./PouRastitelno";
import {getPouRastitelno} from "../../../redux/pouRastitelno/reducer";
import {fetchPouRastitelnoById, updatePouRastitelnoRequest} from "../../../redux/pouRastitelno/fetch";
import {
    fetchPouRastitelnoControlById,
    updatePouRastitelnoControlRequest
} from "../../../redux/pouRastitelnoControl/fetch";
import Notification from "../../../common/Notification";
import {cleanJsonBody, cleanJsonControl} from "../../../redux/helpers";
import {getPouRastitelnoControl} from "../../../redux/pouRastitelnoControl/reducer";
import {getUploadedFile} from "../../../redux/fileSystem/reducer";
import {uploadFileRequest} from "../../../redux/fileSystem/fetch";
import {getUser} from "../../../redux/user/reducer";
import {getLookupMap} from "../../../redux/lookup/reducer";
import {fetchLookups} from "../../../redux/lookup/fetch";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../../styles";


export class EditPouRastitelno extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.updatePouRastitelno = this.updatePouRastitelno.bind(this);
        this.uploadFileRastitelno = this.uploadFileRastitelno.bind(this);
        this.redirectToPouRastitelnoList = this.redirectToPouRastitelnoList.bind(this);
        this.state = {notify: false, message: '', error: false};
    }

    componentWillMount() {
        const {fetchPouRastitelnoById, fetchPouRastitelnoControlById, fetchLookups} = this.props;
        fetchPouRastitelnoById(this.props.match.params.id);
        fetchPouRastitelnoControlById(this.props.match.params.id);
        fetchLookups();
    }

    updatePouRastitelno(values) {
        /*  Create and call updatePouRastitelno action */
        const id = values.id
        let newValues = cleanJsonBody(JSON.parse(JSON.stringify(values)), true);
        let newValuesControl = cleanJsonControl(JSON.parse(JSON.stringify(values)));
        this.props.updatePouRastitelnoRequest(newValues, id)
        this.props.updatePouRastitelnoControlRequest(newValuesControl, id)
        this.showNotification("Успешно зачувано!");
    }

    redirectToPouRastitelnoList() {
        this.props.dispatch(reset('PouRastitelno'));
        this.props.history.push(`/PouRastitelnoList`);
    }

    showNotification = (msg, err) => {
        if (err)
            this.setState({notify: true, message: msg, error: true});
        else
            this.setState({notify: true, message: msg, error: false});
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    uploadFileRastitelno(file_upload_id) {
        const myInput = document.getElementById(file_upload_id);
        return uploadFileRequest(myInput).then(res => {
            this.showNotification("Успешно прикачен фајл!");
            return res
        })
    }

    render() {
        const {notify, message} = this.state;
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardContent className={classes.noPrint}>
                        <h1>Измени ПОУ растително</h1>
                    </CardContent>
                    {this.props.id && this.props.pouRastitelno &&
                    this.props.pouRastitelnoControl &&
                    this.props.pouRastitelnoControl.controlId === this.props.pouRastitelno.id &&
                    <PouRastitelno
                        pouRastitelno={this.props.pouRastitelno.pouRastitelno}
                        pouRastitelnoControl={this.props.pouRastitelnoControl.pouRastitelnoControl}
                        pouRastitelnoId={this.props.pouRastitelno.pouRastitelno.id}
                        uploadedFileId={this.props.fileSystem ? this.props.fileSystem.fileSystem : null}
                        uploadFile={this.uploadFileRastitelno}
                        onSubmit={this.updatePouRastitelno}
                        onCancel={this.redirectToPouRastitelnoList}
                        editMode
                        user={this.props.user}
                        lookupData={this.props.lookupData}
                    />
                    }
                </Card>

                <Notification
                    notify={notify}
                    message={message}
                    error={false}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    return {
        id: id,
        pouRastitelno: getPouRastitelno(state),
        pouRastitelnoControl: getPouRastitelnoControl(state),
        fileSystem: getUploadedFile(state),
        user: getUser(state),
        lookupData: getLookupMap(state)
    };
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPouRastitelnoById,
        updatePouRastitelnoRequest,
        fetchPouRastitelnoControlById,
        updatePouRastitelnoControlRequest,
        fetchLookups
    }, dispatch)
}


EditPouRastitelno.propTypes = {
    actions: PropTypes.object.isRequired,
    dispatch: PropTypes.func
};

EditPouRastitelno.contextTypes = {
    router: PropTypes.object
};
export default compose(
    withStyles(globalStyles),
    connect(mapStateToProps, mapDispatchToProps)
)(EditPouRastitelno);
