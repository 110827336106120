import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import globalStyles from './styles';
import {Fab, Grid, TextField, Typography} from "@material-ui/core";
import {ExitToApp} from "@material-ui/icons";
import {getLoggedIn, getLoginError, getLoginErrorMessage, getUser} from "../redux/user/reducer";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import Notification from "../common/Notification";
import {fetchLogin} from "../redux/user/fetch";
import {NavLink} from "react-router-dom";

class LoginScreen extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.login = this.login.bind(this)
        this.checkCanSave = this.checkCanSave.bind(this)
        this.redirectToUserList = this.redirectToUserList.bind(this)
        this.requiredFieldsMessage = this.requiredFieldsMessage.bind(this)
        this.state = {
            notify: false,
            message: '',
            loginError: this.props.loginError,
            loginErrorMessage: this.props.loginErrorMessage,
            username: "",
            password: ""
        };
    }

    checkCanSave() {
        return (!!this.state.username && !!this.state.password)
    }

    requiredFieldsMessage() {
        this.showNotification("Полињата се задолжителни!");
    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value})
    };

    login() {
        const {fetchLogin} = this.props
        fetchLogin(this.state.username, this.state.password)
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 5000);
    };

    handleNotificationClosed = () => {
        this.setState({
            notify: false
        });
    };

    handleKeypress = (e) => {
        if (e.key === 'Enter') {
            this.login();
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.loggedIn === true) {
            this.showNotification("Успешна најава!")
            setTimeout(() => this.props.history.push("/"), 1000); //execute greet after 2000 milliseconds (2 seconds)
        }
        if (nextProps.loginError === true) {
            this.showNotification(nextProps.loginErrorMessage.join(', '), false)

        }
    }

    redirectToUserList() {
        this.props.history.push("/")
    }

    render() {
        const {navigation} = this.props;
        const {notify, message, error} = this.state;
        const {classes} = this.props;
        const canSave = this.checkCanSave()

        return (
            <div>
                <span style={globalStyles.navigation}>{navigation}</span>

                <Grid container justify="center" xs={12}>
                    <Grid item xs={4}>
                        <Paper style={globalStyles.paper}>
                            <Grid className={classes.paddedGrid} align="center" spacing={2} container xs={12}>
                                <Grid item className={classes.paddedGrid} xs={12}>
                                    <Typography variant="h5">{"Ве молиме најавете се"}</Typography>
                                </Grid>
                                <Grid className={classes.paddedGrid} item xs={6}>
                                    <TextField
                                        id="standard-name"
                                        label="Корисничко Име"
                                        className={classes.loginTextField}
                                        value={this.state.username}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={this.props.error}
                                        onKeyPress={(event) => this.handleKeypress(event)}
                                        onChange={(event) => this.handleChange('username', event)}/>

                                </Grid>
                                <Grid className={classes.paddedGrid} item xs={6}>
                                    <TextField
                                        id="standard-name"
                                        label="Лозинка"
                                        variant="outlined"
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classes.loginTextField}
                                        value={this.state.password}
                                        margin="normal"
                                        error={this.props.error}
                                        onKeyPress={(event) => this.handleKeypress(event)}
                                        onChange={(event) => this.handleChange('password', event)}/>
                                </Grid>

                                {
                                    (!canSave) &&
                                    <Grid className={classes.paddedGrid} item xs={12}>
                                        <Fab
                                            variant="extended"
                                            color="primary"
                                            style={{width: 200}}
                                            aria-label="add" className={classes.button}
                                            onClick={() => this.requiredFieldsMessage()}
                                        >
                                            <ExitToApp/>
                                            Најава
                                        </Fab>
                                    </Grid>
                                }
                                {
                                    (canSave) &&
                                    <Grid className={classes.paddedGrid} item xs={12}>
                                        <Fab
                                            variant="extended"
                                            color="primary"
                                            style={{width: 200}}
                                            aria-label="add" className={classes.button}
                                            onClick={() => this.login()}
                                        >
                                            <ExitToApp/>
                                            Најава
                                        </Fab>
                                    </Grid>
                                }
                                <Grid className={classes.paddedGrid} item xs={12}>
                                    Не сте регистрирани?
                                    <NavLink to="/RegisterUser"> Регистрирајте се </NavLink>
                                </Grid>
                                <Grid className={classes.paddedGrid} item xs={12}>
                                    <NavLink to="/ForgotPassword"> Ја заборавивте лозинката? </NavLink>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <div style={globalStyles.clear}/>
                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: getUser(state),
    loggedIn: getLoggedIn(state),
    loginError: getLoginError(state),
    loginErrorMessage: getLoginErrorMessage(state)
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchLogin}, dispatch)
}

export default withStyles(globalStyles)(
    connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
)
