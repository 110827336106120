import {
    CREATE_NEW_USER,
    CREATE_USER,
    DELETE_USER,
    FAILED_CHANGE_PASSWORD,
    FAILED_CREATE_USER,
    FAILED_FORGOT_PASSWORD,
    FAILED_LOGIN,
    FAILED_REGISTER,
    FAILED_UPDATE_USER,
    FETCH_ALL_USERS,
    FETCH_REGISTER_USER,
    FETCH_USER,
    FORGOT_PASSWORD,
    LOGIN,
    LOGOUT,
    REGISTER_USER,
    UPDATE_USER,
} from "../actionTypes";

let user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user ? {
    user: user,
    userList: [],
    selectedUser: null,
    loggedIn: true,
    error: false,
    loginError: false,
    changePassError: false
} : {userList: [], selectedUser: null};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                loggedIn: true,
                error: false,
                user: action.payload.user
            };
        case LOGOUT:
            return {
                loggedIn: false,
                error: false,
                user: null
            };
        case FAILED_LOGIN:
            return {
                loggedIn: false,
                user: null,
                loginError: action.payload.loginError,
                loginErrorMessage: action.payload.message
            };
        case FETCH_ALL_USERS: {
            const userNum = action.payload.userList;
            return {
                ...state,
                selectedUser: null,
                userList: userNum,
                userNum: userNum ? userNum.length : 0
            }
        }
        case FETCH_USER: {
            return {
                ...state,
                selectedUser: action.payload
            };
        }
        case UPDATE_USER: {
            return {
                ...state,
                selectedUser: action.payload,
                error: false
            };
        }
        case FAILED_UPDATE_USER: {
            return {
                ...state,
                selectedUser: action.payload,
                error: true
            };
        }
        case CREATE_NEW_USER: {
            return {
                ...state,
                selectedUser: action.payload
            };
        }
        case CREATE_USER: {
            return {
                ...state,
                selectedUser: action.payload
            };
        }
        case FAILED_CREATE_USER: {
            return {
                ...state,
                selectedUser: action.payload,
                error: true
            };
        }
        case REGISTER_USER: {
            return {
                ...state,
                selectedUser: action.payload,
                error: false
            };
        }
        case FETCH_REGISTER_USER: {
            return {
                ...state,
                selectedUser: action.payload
            };
        }
        case FAILED_REGISTER: {
            return {
                ...state,
                selectedUser: action.payload,
                error: true
            };
        }
        case FORGOT_PASSWORD: {
            return {
                ...state
            };
        }
        case FAILED_FORGOT_PASSWORD: {
            return {
                error: true,
            };
        }
        case DELETE_USER: {
            return {
                ...state,
                selectedUser: action.payload
            };
        }
        case FAILED_CHANGE_PASSWORD: {
            return {
                ...state,
                changePassError: action.payload.changePassError,
                changePassMessage: action.payload.message
            };
        }
        default:
            return state
    }
}

export const getUser = state => state.login.user;
export const getLoginError = state => state.login.loginError;
export const getLoginErrorMessage = state => state.login.loginErrorMessage;
export const getChangePassError = state => state.login.changePassError;
export const getChangePassMessage = state => state.login.changePassMessage;
export const getLoggedIn = state => state.login.loggedIn;
export const getError = state => state.login.error;

export const getSelectedUser = state => state.login.selectedUser;
export const getUserNum = state => state.login.userNum;
export const getUserList = state => state.login.userList;