export const newPouRastitelnoControlData =
    {
        "controlId": "",
        "control_1": "",
        "control_2_1": "",
        "control_2_2": "",
        "control_2_3": "",
        "control_2_4": "",
        "control_3_1": "",
        "control_3_2": "",
        "control_4_1": "",
        "control_4_2": "",
        "control_5": "",
        "control_6": "",
        "control_8_1": "",
        "control_8_2": "",
        "control_8_3": "",
        "control_8_4": "",
        "control_8_7": "",
        "control_9_1": "",
        "control_9_2": "",
        "control_9_3": "",
        "control_10_1": "",
        "control_10_2": "",
        "control_10_3": "",
        "control_10_4": "",
        "control_11": "",
        "control_12_2": "",
        "control_13_1": "",
        "control_13_2": "",
        "control_14_1": "",
        "control_15": "",
        "control_15_1": "",
        "control_16_1": "",
        "control_16_2": "",
        "control_17_1": "",
        "control_17_2": "",
        "control_18": "",
        "control_18_1": "",
        "control_18_2": "",
        "control_19_1": "",
        "control_19_2": "",
        "control_20_1": "",
        "control_20_2": "",
        "control_20_3": "",
        "control_20_4": "",
        "control_21_1": "",
        "control_21_2": "",
        "control_22_1": "",
        "control_22_2": "",
        "control_22_3": "",
        "control_22_4": ""
    }
