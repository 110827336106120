import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import globalStyles from './styles.js'
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import {getError, getLoggedIn, getUser} from "../redux/user/reducer";
import {connect} from "react-redux";
import {ExitToApp} from "@material-ui/icons";

class Header extends React.Component {

    render() {
        const {navDrawerOpen, handleChangeRequestNavDrawer, logout, classes, loggedIn, user} = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static" style={{paddingLeft: this.props.paddingLeft}} className={classes.appBar}>
                    <Toolbar variant="dense">
                        {loggedIn &&

                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu"
                                    onClick={handleChangeRequestNavDrawer}>
                            {navDrawerOpen ? <ChevronLeftIcon/> : <MenuIcon/>}

                        </IconButton>
                        }

                        <Typography variant="title" className={classes.root} color="inherit">
                            Procert
                        </Typography>
                        {loggedIn &&
                        <Typography variant="title" className={classes.fullName} color="inherit">
                            {user.user.fullName}
                        </Typography>}
                        {loggedIn &&
                        <IconButton className={classes.menuButton} color="inherit" aria-label="menu"
                                    onClick={logout}>
                            <ExitToApp/>
                        </IconButton>
                        }
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    user: getUser(state),
    loggedIn: getLoggedIn(state),
    error: getError(state)
})

export default withStyles(globalStyles)(
    connect(mapStateToProps, null)(Header)
)