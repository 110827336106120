import {
    CREATE_NEW_SPEC_RASTITELNO,
    CREATE_SPEC_RASTITELNO,
    DELETE_SPEC_RASTITELNO,
    DUPLICATE_SPEC_RASTITELNO,
    FAILED_CREATE_SPEC_RASTITELNO,
    FETCH_ALL_SPEC_RASTITELNO,
    FETCH_SPEC_RASTITELNO,
    UPDATE_SPEC_RASTITELNO
} from "../actionTypes";


export const fetchSpecRastitelnoListAction = (payload) => ({
    type: FETCH_ALL_SPEC_RASTITELNO,
    payload: {
        specRastitelnoList: payload,
    },
})

export const fetchSpecRastitelnoByIdAction = (payload) => ({
    type: FETCH_SPEC_RASTITELNO,
    payload: {
        specRastitelno: payload,
    },
})
export const createNewSpecRastitelnoAction = (payload) => ({
    type: CREATE_NEW_SPEC_RASTITELNO,
    payload: {
        specRastitelno: payload,
    },
})

export const createSpecRastitelnoAction = (payload) => ({
    type: CREATE_SPEC_RASTITELNO,
    payload: {
        specRastitelno: payload,
        error: false
    },
})

export const failedCreateSpecRastitelnoAction = (payload) => ({
    type: FAILED_CREATE_SPEC_RASTITELNO,
    payload: {
        specRastitelno: payload,
        error: true
    },
})

export const duplicateSpecRastitelnoAction = (payload) => ({
    type: DUPLICATE_SPEC_RASTITELNO,
    payload: {
        specRastitelno: payload,
    },
})

export const updateSpecRastitelnoAction = (payload) => ({
    type: UPDATE_SPEC_RASTITELNO,
    payload: {
        specRastitelno: payload,
    },
})
export const deleteSpecRastitelnoAction = () => ({
    type: DELETE_SPEC_RASTITELNO,
    payload: {
        specRastitelno: null,
        specNum: 0
    },
})