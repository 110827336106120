import {Grid, TextField} from "@material-ui/core";
import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core/styles";
import globalStyles from "../pages/styles";

class ControlInput extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {valueIn: this.props.controlInput};
    }

    setValue(val) {
        this.setState({valueIn: val});
    }

    render() {
        const {classes, customClassName, editable, onChangeAction} = this.props

        return (
            <Grid alignContent={"flex-start"} container xs={12} className={customClassName}
                  style={{border: "1px solid black", height: "100%"}}>
                <Grid container xs={12} className={customClassName}
                      style={{border: "1px solid black", display: 'flex', flex: 1, padding: "5px"}}>
                    <TextField
                        disabled={!editable}
                        type={"text"}
                        multiline={true}
                        rows={3}
                        rowsMax={50}
                        onBlur={onChangeAction}
                        onChange={event => this.setValue(event.target.value)}
                        className={classes.controlPaddedFullWidthInput}
                        value={this.state.valueIn}
                    />

                </Grid>
            </Grid>
        )
    }
}

export default withStyles(globalStyles)(ControlInput);