import {
    CREATE_NEW_POU_RASTITELNO,
    CREATE_POU_RASTITELNO,
    DELETE_POU_RASTITELNO,
    DUPLICATE_POU_RASTITELNO,
    FETCH_ALL_POU_RASTITELNO,
    FETCH_POU_RASTITELNO,
    UPDATE_POU_RASTITELNO
} from "../actionTypes";


export const fetchPouRastitelnoListAction = (payload) => ({
    type: FETCH_ALL_POU_RASTITELNO,
    payload: {
        pouRastitelnoList: payload,
    },
})

export const fetchPouRastitelnoByIdAction = (payload) => ({
    type: FETCH_POU_RASTITELNO,
    payload: {
        pouRastitelno: payload,
    },
})
export const createNewPouRastitelnoAction = (payload) => ({
    type: CREATE_NEW_POU_RASTITELNO,
    payload: {
        pouRastitelno: payload,
    },
})

export const createPouRastitelnoAction = (payload) => ({
    type: CREATE_POU_RASTITELNO,
    payload: {
        pouRastitelno: payload,
    },
})

export const duplicatePouRastitelnoAction = (payload) => ({
    type: DUPLICATE_POU_RASTITELNO,
    payload: {
        pouRastitelno: payload,
    },
})

export const updatePouRastitelnoAction = (payload) => ({
    type: UPDATE_POU_RASTITELNO,
    payload: {
        pouRastitelno: payload,
    },
})
export const deletePouRastitelnoAction = () => ({
    type: DELETE_POU_RASTITELNO,
    payload: {
        pouRastitelno: null,
        pouNum: 0
    },
})