import {
    CLEAR_POU_STOCARSTVO_CONTROL,
    CREATE_NEW_POU_STOCARSTVO_CONTROL,
    CREATE_POU_STOCARSTVO_CONTROL,
    DELETE_POU_STOCARSTVO_CONTROL,
    FETCH_POU_STOCARSTVO_CONTROL,
    UPDATE_POU_STOCARSTVO_CONTROL
} from "../actionTypes";

export const fetchPouStocarstvoControlByIdAction = (payload) => ({
    type: FETCH_POU_STOCARSTVO_CONTROL,
    payload: {
        pouStocarstvoControl: payload,
    },
})
export const createNewPouStocarstvoControlAction = (payload) => ({
    type: CREATE_NEW_POU_STOCARSTVO_CONTROL,
    payload: {
        pouStocarstvoControl: payload,
    },
})

export const createPouStocarstvoControlAction = (payload) => ({
    type: CREATE_POU_STOCARSTVO_CONTROL,
    payload: {
        pouStocarstvoControl: payload,
    },
})
export const updatePouStocarstvoControlAction = (payload) => ({
    type: UPDATE_POU_STOCARSTVO_CONTROL,
    payload: {
        pouStocarstvoControl: payload,
    },
})
export const clearPouStocarstvoControlAction = () => ({
    type: CLEAR_POU_STOCARSTVO_CONTROL,
    payload: {
        pouStocarstvoControl: null,
    },
})
export const deletePouStocarstvoControlAction = () => ({
    type: DELETE_POU_STOCARSTVO_CONTROL,
    payload: {
        pouStocarstvoControl: null,
    },
})