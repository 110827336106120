import {
    createNewPouRastitelnoControlAction,
    createPouRastitelnoControlAction,
    deletePouRastitelnoControlAction,
    fetchPouRastitelnoControlByIdAction,
    updatePouRastitelnoControlAction
} from "./actions";
import {apiURL, authHeader, requestOptions} from "../helpers";
import {newPouRastitelnoControlData} from "./newPouRastitelnoControlData";


export function fetchPouRastitelnoControlById(pouRastitelnoControlId) {
    return dispatch => {
        fetch(`${apiURL}/kontrolorRastitelno/${pouRastitelnoControlId}`, requestOptions())
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again

                dispatch(fetchPouRastitelnoControlByIdAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function fetchNewPouRastitelnoControl() {
    const newPouRastitelnoControl = newPouRastitelnoControlData
    return dispatch => {
        dispatch(createNewPouRastitelnoControlAction(newPouRastitelnoControl))
        return newPouRastitelnoControl
    }
}

export function updatePouRastitelnoControlRequest(pouRastitelnoControl, id) {
    const putRequestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({...pouRastitelnoControl})
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorRastitelno/${id}`, putRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                //this sets the received values to the state, so we don't need to call this api again
                dispatch(updatePouRastitelnoControlAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function createPouRastitelnoControlRequest(pouRastitelnoControl) {
    const postRequestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...pouRastitelnoControl})
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorRastitelno/`, postRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(createPouRastitelnoControlAction(res));
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export function deletePouRastitelnoControlRequest(id) {
    const deleteRequestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return dispatch => {
        fetch(`${apiURL}/kontrolorRastitelno/${id}`, deleteRequestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw(res.error);
                }
                dispatch(deletePouRastitelnoControlAction());
                return res;
            })
            .catch(error => {
                console.log(error)
            })
    }
}