import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {reduxForm} from 'redux-form'
import * as ALL from '../../../common/staticLabels'
import {Grid, Typography} from '@material-ui/core';
import globalStyles from "../../styles";
import GridTable from "../../../components/gridTable";
import CustomLabeledInput from "../../../components/customLabeledInput";
import {yesno} from "../../../mockData/ddDaNeData";
import {seminja} from "../../../mockData/seminjaLookup";
import ControlInput from "../../../components/controlInput";
import {daNeDelumnoData} from "../../../mockData/ddDaNeDelumnoData";
import {zakluchok} from "../../../mockData/zakluchokLookup";
import {postberbeno} from "../../../mockData/postberbenoLookup";
import {korektivniAktivnostiData} from "../../../mockData/ddKorektivniAktivnostiData";
import {prazenChecbox} from "../../../mockData/prazenCheckboxLookup";
import {ddRizikData} from "../../../mockData/ddRizikData";
import Button from "@material-ui/core/Button";
import Notification from "../../../common/Notification";
import {yesnomaybe} from "../../../mockData/ddDaNePraznoData";
import UploadInput from "../../../components/uploadInput";
import PrintIcon from "@material-ui/icons/Print";

class PouRastitelno extends Component {

    constructor(props) {
        super(props);
        this.addKultura = this.addKultura.bind(this)
        this.updateObject = this.updateObject.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.checkCanSave = this.checkCanSave.bind(this)
        this.requiredFieldsMessage = this.requiredFieldsMessage.bind(this)
        this.showNotification = this.showNotification.bind(this)
        this.uploadFileAndSetState = this.uploadFileAndSetState.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.hasRole = this.hasRole.bind(this)
        this.handlePrint = this.handlePrint.bind(this)
        this.state = {
            navDrawerOpen: false, ...this.props.pouRastitelno, ...this.props.pouRastitelnoControl,
            notify: false,
            message: '',
            error: false
        };
    }

    handlePrint = () => {
        window.print();
    }

    checkCanSave() {
        return !!this.state.telefon && !!this.state.ime_farma && !!this.state.sopstvenik
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((this.props.pouRastitelno.id && this.props.pouRastitelno.id !== nextProps.pouRastitelno.id) ||
            (this.props.pouRastitelnoControl && this.props.pouRastitelnoControl.controlId && this.props.pouRastitelnoControl.controlId !== nextProps.pouRastitelnoControl.controlId)) {
            window.location.reload();
        }
    }

    addKultura() {
        this.setState({
            navDrawerOpen: !this.state.navDrawerOpen
        });
    }

    handleChange(name, event) {
        event.persist()
        this.setState({[name]: event.target.value})
    };

    handleObj(name, value) {
        this.setState({[name]: value})
    };

    updateObject(obj, keys, value) {
        let key = keys.shift();
        if (keys.length > 0) {

            let tmp = this.updateObject(obj[key], keys, value);
            return {...obj, [key]: tmp};
        } else {
            return {...obj, [key]: value};
        }
    }

    multiselectHandleChange(name, event) {
        event.persist()
        const val = event.target.value
        let object = JSON.parse(JSON.stringify(this.state[name]))
        const index = object.indexOf(val);
        if (index > -1) {
            object.splice(index, 1);
        } else {
            object.push(val)
        }
        this.setState({[name]: object})
    };

    calculateHeaderSize(fraction, unit) {
        return 100 / fraction * unit + "%"
    }

    requiredFieldsMessage() {
        this.showNotification("Полињата во точка 1 се задолжителни");
    }

    showNotification = (msg, err) => {
        if (err) {
            this.setState({notify: true, message: msg, error: true});
        } else {
            this.setState({notify: true, message: msg, error: false});
        }
        setTimeout(() => this.setState({notify: false, message: "", error: false}), 1000);
    };

    uploadFileAndSetState(fileUploadId) {
        this.props.uploadFile(fileUploadId).then(res => {
            this.handleObj("fileUploadId", res)
            this.setState({[fileUploadId]: res})
            this.showNotification("Успешно прикачен фајл");
        })
    }

    deleteFile(fileUploadId) {
        this.setState({[fileUploadId]: ""})
        this.showNotification("Успешно избришан фајл");
    }

    hasRole(role) {
        var userAuthorities = this.props.user && this.props.user.user && this.props.user.user.authorities;
        var modifierUserAuthorities = this.props.pouRastitelno && this.props.pouRastitelno.modifierUser && this.props.pouRastitelno.modifierUser.authorities;
        if (role === "CONTROL_ADMIN" && !this.props.pouRastitelnoControl) {
            return false;
        }
        if (modifierUserAuthorities) {
            if (userAuthorities && userAuthorities.length < 2 && userAuthorities.length < modifierUserAuthorities.length) {
                return false;
            }
        }

        return userAuthorities && userAuthorities.some(function (u) {
            return u.authority === role;
        }) || false;
    }

    render() {
        const {classes, createMode, editMode} = this.props;
        const {notify, message, error} = this.state;
        const hasRoleControlAdmin = this.hasRole("CONTROL_ADMIN");
        const hasRoleClient = this.hasRole("CLIENT");
        const canSave = this.checkCanSave()


        const kulturiSertifikacijaTemplate = {
            "kultura": "",
            "povrshina": "",
            "status": "",
        }

        const korektivniAktivnostiTemplate = {
            "aktivnost": "",
            "implementacija": ""
        }

        const opisFarmaTemplate = {
            "parcela": "",
            "povrshina": "",
            "status": "",
            "posledna_upotreba_gjubrivo": "",
            "posledna_upotreba_pesticidi": "",
            "konvencionalno": "",
            "pred_3_godini": "",
            "pred_2_godini": "",
            "pred_1_godini": "",
            "ovaa_godina": ""
        }
        const seminjaSadenMaterijalTemplate = {
            "kultura": "",
            "seminja": []
        }
        const pesticidiSosedniFarmiGraniciTemplate = {
            "povrshina": "",
            "granici": "",
            "sosedna_konven_kultura": "",
            "intenzitet_prskanje": "",
            "oprema_prskanje": "",
            "rastojanie": "",
            "vegetacija": "",
            "rizik": ""
        }
        const prihodProizvodTemplate = {
            "kultura": "",
            "zaliha_lani": "",
            "vkupno_neto_prinos": "",
            "prodadeno_organsko": "",
            "prodadeno_organsko_preod": "",
            "prodadeno_konvencionalno": "",
            "momentalna_zaliha": ""
        }
        const berbaPrinosiTemplate = {
            "kultura": "",
            "vkupno_bruto_lani": "",
            "bruto_ha_lani": "",
            "vkupno_bruto_godinava": "",
            "bruto_ha_godinava": "",
            "period_berba": ""
        }
        const postBerbenoRakuvanjeOpisTemplate = {
            "postapka": "",
            "mesto_izveduvanje": "",
            "odgovoren": "",
            "nachin_odeluvanje": ""
        }
        const gjubrenjeTemplate = {
            "gjubrivo": "",
            "poteklo_sopstveno": "",
            "poteklo_kupeno": "",
            "kolichini": ""
        }
        const kontrolaBolestiProizvodiTemplate = {
            "kultura": "",
            "shtetnik": "",
            "proizvod": "",
            "doza": "",
            "aktivna_materija": "",
            "bez_preparat": []
        }
        const planKontrolaTemplate = {
            "data": "",
            "aktivnost": "",
            "zaedno_so": "",
            "posebni_incidenti": ""
        }
        const aneksiTemplate = {
            "broj": "",
            "aneks": ""
        }

        const primeniZalbiTemplate = {
            "zalba": "",
            "korektivni_aktivnosti": ""
        }

        const stocarstvoZivotniTemplate = {
            "vid": "",
            "broj": "",
        }

        const paralelnoProizvodstvoTemplate = {
            "kultura": "",
            "povrsina": "",
        }

        const nesoodvetnostiFarmaTemplate =
            {
                "nesoodvetnost": "",
                "korektivna_aktivnost": "",
                "rok_implementacija": "",
                "tocno_identifikuvani": "",
                "adekvatni": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": "",
            }

        const dopolnitelniNesoodvetnostiTemplate =
            {
                "korektivni_aktivnosti_definicija": "",
                "rok": "",
                "broj": "",
                "nesoodvetnosti": "",
                "adekvateni_korektivni_aktivnosti": "",
                "adekvaten_rok": "",
                "sertifikat_posle_korekcija": "",
            }

        let columnWidth6 = ""
        let columnWidth2od6 = ""
        columnWidth6 = (100 / 6) + "%"
        columnWidth2od6 = (100 / 6) * 2 + "%"


        return (
            (this.state.id !== undefined || this.props.createMode) && this.props.lookupData !== undefined &&
            <div>
                <div id="printDiv">
                    <Grid container style={{border: "1px solid black"}} xs={12}>
                        <Grid item xs={2} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_PROCERT}</Typography></Grid>
                        <Grid item xs={2} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center',
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_FORM_RASTITELNO}</Typography></Grid>
                        <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={3}>
                            <Grid item xs={12} style={{
                                border: "1px solid black",
                                display: 'flex',
                                justifyContent: 'center',
                                height: "50%"
                            }}><Typography
                                className={classes.verticalTextCenter}>{ALL.POU_HEADER_PREV_VERSION}</Typography></Grid>
                            <Grid item xs={12} style={{
                                border: "1px solid black",
                                display: 'flex',
                                justifyContent: 'center',
                                height: "50%"
                            }}><Typography
                                className={classes.verticalTextCenter}>{ALL.POU_HEADER_VERSION}</Typography></Grid>
                        </Grid>
                        <Grid alignContent={"flex-start"} style={{border: "1px solid black"}} xs={3}>
                            <Grid item xs={12} style={{
                                border: "1px solid black",
                                display: 'flex',
                                justifyContent: 'center',
                                height: "50%"
                            }}><Typography
                                className={classes.verticalTextCenter}>{ALL.POU_HEADER_IZGOTVIL}</Typography></Grid>
                            <Grid item xs={12} style={{
                                border: "1px solid black",
                                display: 'flex',
                                justifyContent: 'center',
                                height: "50%"
                            }}><Typography
                                className={classes.verticalTextCenter}>{ALL.POU_HEADER_PROVERIL_POTVRDIL}</Typography></Grid>
                        </Grid>
                        <Grid item xs={2} style={{
                            border: "1px solid black",
                            display: 'flex',
                            justifyContent: 'center'
                        }}><Typography
                            className={classes.verticalTextCenter}>{ALL.POU_HEADER_SUBTITLE}</Typography></Grid>
                    </Grid>
                    <Grid container style={{border: "1px solid black"}} xs={12}>
                        <Grid container item xs={12}>

                            <CustomLabeledInput labelInput={ALL.POU_HEADER_KOMPANIJA}
                                                inputType={"text"}
                                                size={3}
                                                vertical={true}
                                                valueInput={this.state.header_kompanija}
                                                onChangeAction={(event) => this.handleChange('header_kompanija', event)}
                            />
                            <CustomLabeledInput labelInput={ALL.POU_HEADER_DATUM_POPOL}
                                                inputType={"text"}
                                                size={2}
                                                vertical={true}
                                                valueInput={this.state.header_datum_popol}
                                                onChangeAction={(event) => this.handleChange('header_datum_popol', event)}
                            />
                            <CustomLabeledInput labelInput={ALL.POU_HEADER_ODGOVOREN_KOMPANIJA}
                                                inputType={"text"}
                                                size={2}
                                                vertical={true}
                                                valueInput={this.state.header_odgovoren_kompanija}
                                                onChangeAction={(event) => this.handleChange('header_odgovoren_kompanija', event)}
                            />
                            <CustomLabeledInput labelInput={ALL.POU_HEADER_KONTROLOR}
                                                inputType={"text"}
                                                size={3}
                                                vertical={true}
                                                valueInput={this.state.header_kontrolor}
                                                onChangeAction={(event) => this.handleChange('header_kontrolor', event)}
                            />
                            <CustomLabeledInput labelInput={ALL.POU_HEADER_DATUM_KONTROLA}
                                                inputType={"text"}
                                                size={2}
                                                vertical={true}
                                                valueInput={this.state.header_datum_kontrola}
                                                onChangeAction={(event) => this.handleChange('header_datum_kontrola', event)}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <Typography variant="h5">{ALL.POU}</Typography>
                            <Typography variant="h7">{ALL.RASTITELNO_PROIZVODSTVO}</Typography>
                            <Grid container xs={12} className={classes.coloredItemGrid1}>
                                <Grid item xs={12}><Typography>{ALL.POU_DESC}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <Grid xs={12} className={classes.heightControlBlueItemGrid}>
                                        <Typography variant="h5">{ALL.POU_PLAN_KONTROLA}</Typography>
                                    </Grid>
                                    <GridTable obj={this.state.plan_kontrola}
                                               labeli={[ALL.DATA, ALL.POU_AKTIVNOST, ALL.POU_ZAEDNO_SO, ALL.POU_POSEBNI_INCIDENTI]}
                                               values={["data", "aktivnost", "zaedno_so", "posebni_incidenti"]}
                                               valueName={"plan_kontrola"}
                                               types={["textarea", "textarea", "textarea", "textarea"]}
                                               ddData={[null, null, null, null]}
                                               columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                               size={12}
                                               disabled={[!hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                               disabledButtons={!hasRoleControlAdmin}
                                               template={planKontrolaTemplate}
                                               onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRIMEROCI}
                                                    inputType={"text"}
                                                    disabled={!hasRoleControlAdmin}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.zemeni_primeroci_kontrola}
                                                    onChangeAction={(event) => this.handleChange('zemeni_primeroci_kontrola', event)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Grid container xs={12} className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}/>
                        <Grid container className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_KONTROLA_UPATSTVO}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_ADRESA}
                                                    size={12}
                                />
                            </Grid>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_IME_NA_FARMATA}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    multilineInput={true}
                                                    valueInput={this.state.ime_farma}
                                                    onChangeAction={(event) => this.handleChange('ime_farma', event)}
                                />

                                <CustomLabeledInput labelInput={ALL.POU_SOPSTVENIK}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    multilineInput={true}
                                                    valueInput={this.state.sopstvenik}
                                                    onChangeAction={(event) => this.handleChange('sopstvenik', event)}
                                />

                                <CustomLabeledInput labelInput={ALL.POU_ODGOVORNO_LICE}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.odgovorno_lice}
                                                    size={12}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('odgovorno_lice', event)}
                                />

                                <CustomLabeledInput labelInput={ALL.POU_ULICA_BROJ_FAX}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    multilineInput={true}
                                                    valueInput={this.state.ulica_broj_fax}
                                                    onChangeAction={(event) => this.handleChange('ulica_broj_fax', event)}
                                />

                                <CustomLabeledInput labelInput={ALL.POU_GRAD_KOD}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    multilineInput={true}
                                                    valueInput={this.state.grad_postenski_kod}
                                                    onChangeAction={(event) => this.handleChange('grad_postenski_kod', event)}
                                />

                                <CustomLabeledInput labelInput={ALL.EMAIL}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    multilineInput={true}
                                                    valueInput={this.state.mail}
                                                    onChangeAction={(event) => this.handleChange('mail', event)}
                                />

                                <CustomLabeledInput labelInput={ALL.POU_TELEFON}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.telefon}
                                                    onChangeAction={(event) => this.handleChange('telefon', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_1', event)}
                                              controlInput={this.state["control_1"]}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">2</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PARTNERI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">2.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRERABOTUVAC_R}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.prerabotuvac}
                                                    onChangeAction={(event) => this.handleChange('prerabotuvac', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_2_1', event)}
                                              controlInput={this.state["control_2_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        this.state.prerabotuvac === "true" &&
                        <Grid container className={classes.container}>
                            <Grid container className={classes.numeratedItemGrid}>
                                <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                    variant="h5">2.2</Typography></Grid>
                            </Grid>
                            <Grid container xs={8}>
                                <Grid container justify='space-around'>
                                    <CustomLabeledInput labelInput={ALL.POU_PRERABOTUVAC_DA}
                                                        inputType={"text"}
                                                        disabled={!hasRoleClient}
                                                        size={12}
                                                        vertical={true}
                                                        placeholderInput={" "}
                                                        multilineInput={true}
                                                        valueInput={this.state.prerabotuvac_opis}
                                                        onChangeAction={(event) => this.handleChange('prerabotuvac_opis', event)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.kontrolorItemGrid}>
                                <Grid container justify='space-around'>
                                    <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                                  onChangeAction={(event) => this.handleChange('control_2_2', event)}
                                                  controlInput={this.state["control_2_2"]}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    }
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">2.3</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_VKLUCENI_PODOGOVARACI}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.podogovarachi}
                                                    onChangeAction={(event) => this.handleChange('podogovarachi', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_2_3', event)}
                                              controlInput={this.state["control_2_3"]}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">2.4</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_IMATEL}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.imatel}
                                                    onChangeAction={(event) => this.handleChange('imatel', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_2_4', event)}
                                              controlInput={this.state["control_2_4"]}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">3</Typography></Grid>
                        </Grid>

                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_OPSEG_SERTIFIKACIJA}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">3.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_KOPIJA}
                                                    size={12}
                                                    inputType={"checkbox"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.tip_kopija}
                                                    options={this.props.lookupData.tip_kopija}
                                                    onChangeAction={(event) => this.multiselectHandleChange('tip_kopija', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_3_1', event)}
                                              controlInput={this.state["control_3_1"]}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">3.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_OSPORENA_ORGANSKA_SERTIFIKACIJA}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.sertifikacija_osporena}
                                                    onChangeAction={(event) => this.handleChange('sertifikacija_osporena', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_3_2', event)}
                                              controlInput={this.state["control_3_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">4</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_MIN_KOR_AKT}
                                                firstElementSize={12}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">4.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_MIN_KOR}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={korektivniAktivnostiData}
                                                    valueInput={this.state.minatogodisni_korektivni}
                                                    onChangeAction={(event) => this.handleChange('minatogodisni_korektivni', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_4_1', event)}
                                              controlInput={this.state["control_4_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">4.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.minatogodisni_korektivni_akt}
                                        labeli={[ALL.POU_KOREKT_AKT, ALL.POU_IMPLEMENTACIJA]}
                                        values={["aktivnost", "implementacija"]}
                                        valueName={"minatogodisni_korektivni_akt"}
                                        types={["textarea", "textarea"]}
                                        disabled={[!hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        ddData={[null, null]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={korektivniAktivnostiTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_4_2', event)}
                                              controlInput={this.state["control_4_2"]}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">5</Typography></Grid>
                        </Grid>

                        <Grid container xs={8}>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_POVRSINI_KULTURI_ZA_SERTIFIKACIJA}
                                                    firstElementSize={12}
                                                    size={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.povrsini_kulturi_sertifikacija}
                                        labeli={[ALL.POU_POVRSHINA, ALL.POU_KULTURA, ALL.POU_STATUS]}
                                        values={["povrshina", "kultura", "status"]}
                                        valueName={"povrsini_kulturi_sertifikacija"}
                                        types={["text", "dropdown", "dropdown"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        ddData={[null, this.props.lookupData.kultura, this.props.lookupData.document_status]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={kulturiSertifikacijaTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_5', event)}
                                              controlInput={this.state["control_5"]}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">6</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_PODATOCI_ZA_OPKRUZUVANJETO}
                                                    size={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_NADMORSKA_VISINA}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    placeholderInput={" "}
                                                    valueInput={this.state.nadmorska_visina_farma}
                                                    onChangeAction={(event) => this.handleChange('nadmorska_visina_farma', event)}
                                />
                            </Grid>

                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_GODISNI_VRNEZI}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    placeholderInput={" "}
                                                    valueInput={this.state.godishni_vrnezi_farma}
                                                    onChangeAction={(event) => this.handleChange('godishni_vrnezi_farma', event)}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_POCVI}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    placeholderInput={" "}
                                                    valueInput={this.state.pochvi_farma}
                                                    onChangeAction={(event) => this.handleChange('pochvi_farma', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_6', event)}
                                              controlInput={this.state["control_6"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">8</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput size={12} labelInput={ALL.POU_UPRAVUVANJE_OKOLINA}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">8.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_EROZJA}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.pochvena_erozija}
                                                    onChangeAction={(event) => this.handleChange('pochvena_erozija', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_8_1', event)}
                                              controlInput={this.state["control_8_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">8.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_ZAGADUVANJE}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.zagadena_voda}
                                                    onChangeAction={(event) => this.handleChange('zagadena_voda', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_8_2', event)}
                                              controlInput={this.state["control_8_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">8.3</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_SPALUVANJE_OSTATOCI}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.spaluvanje_materijali}
                                                    onChangeAction={(event) => this.handleChange('spaluvanje_materijali', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_8_3', event)}
                                              controlInput={this.state["control_8_3"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">8.4</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_SUMSKI_POVRSINI}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={yesno}
                                                    valueInput={this.state.povrshini_neiskoristeni}
                                                    onChangeAction={(event) => this.handleChange('povrshini_neiskoristeni', event)}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_OPIS}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    size={12}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.povrshini_neiskoristeni_opis}
                                                    onChangeAction={(event) => this.handleChange('povrshini_neiskoristeni_opis', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_8_4', event)}
                                              controlInput={this.state["control_8_4"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">8.7</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_NEORGANSKI_SMET}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    options={daNeDelumnoData}
                                                    valueInput={this.state.neorganski_smet}
                                                    onChangeAction={(event) => this.handleChange('neorganski_smet', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_8_7', event)}
                                              controlInput={this.state["control_8_7"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">9</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_OPIS_FARMA} size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">9.1</Typography>
                            </Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_POVRSINI_KULTURI}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.opis_farma}
                                        labeli={[ALL.POU_PARCELA_BROJ_NAZIV, ALL.POU_POVRSHINA, ALL.POU_STATUS, ALL.POU_PESTICIDI, ALL.POU_KONVEN_PROIZVODSTVO, ALL.POU_3_GOD, ALL.POU_2_GOD, ALL.POU_1_GOD, ALL.POU_OVAA_GODINA]}
                                        values={["parcela", "povrshina", "status", "posledna_upotreba_pesticidi", "konvencionalno", "pred_3_godini", "pred_2_godini", "pred_1_godini", "ovaa_godina"]}
                                        types={["textarea", "text", "dropdown", "text", "dropdown", "textarea", "textarea", "textarea", "textarea"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        valueName={"opis_farma"}
                                        ddData={[null, null, this.props.lookupData.document_status, null, yesno, null, null, null, null]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        colWidth={[1.3, 0.9, 1.4, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9]}
                                        size={12}
                                        template={opisFarmaTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_9_1', event)}
                                              controlInput={this.state["control_9_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">9.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_MAPA}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.mapa_parceli}
                                                    onChangeAction={(event) => this.handleChange('mapa_parceli', event)}
                                />
                            </Grid>
                            <Grid container justify='space-around'
                                  style={{border: "1px solid black", display: 'flex', justifyContent: 'center'}}>
                                <UploadInput uploadFile={() => this.props.uploadFile()}
                                             fileUploadId={"mapa_upload_id"}
                                             disabledButtons={!hasRoleClient}
                                             fileId={this.state.mapa_upload_id}
                                             onChangeAction={() => this.uploadFileAndSetState("mapa_upload_id")}
                                             onDeleteAction={() => this.deleteFile("mapa_upload_id")}
                                             uploadLabel={"Додади мапа!"} downloadLabel={"Превземи мапа!"}/>

                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_9_2', event)}
                                              controlInput={this.state["control_9_2"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">9.3</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <CustomLabeledInput labelInput={ALL.POU_IMA_STOCARSTVO}
                                                size={12}
                                                inputType={"radio"}
                                                disabled={!hasRoleClient}
                                                vertical={true}
                                                valueInput={this.state.stocharstvo}
                                                options={yesno}
                                                onChangeAction={(event) => this.handleChange('stocharstvo', event)}/>
                            {this.state.stocharstvo === "true" &&
                            <Grid alignContent={"flex-start"} container>
                                <GridTable
                                    obj={this.state.stocharstvo_zivotni}
                                    labeli={[ALL.VID_ZIVOTNI, ALL.BROJ_ZIVOTNI]}
                                    values={["vid", "broj"]}
                                    disabled={[!hasRoleClient, !hasRoleClient]}
                                    disabledButtons={!hasRoleClient}
                                    valueName={"stocharstvo_zivotni"}
                                    types={["dropdown", "text"]}
                                    ddData={[this.props.lookupData.zivotno, null]}
                                    columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                    size={12}
                                    template={stocarstvoZivotniTemplate}
                                    onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                            }
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_9_3', event)}
                                              controlInput={this.state["control_9_3"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">10</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PREOD}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">10.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PERIOD_PREOD}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    valueInput={this.state.period_preod_novi_parceli}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('period_preod_novi_parceli', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_10_1', event)}
                                              controlInput={this.state["control_10_1"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">10.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PERIOD_PREOD_NE}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    valueInput={this.state.period_preod_novi_parceli_ne}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('period_preod_novi_parceli_ne', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_10_2', event)}
                                              controlInput={this.state["control_10_2"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">10.3</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRIZNAVANJE_UPRAVUVANJE}
                                                    size={12}
                                                    vertical={true}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.priznavanje_upravuvanje_zemja}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('priznavanje_upravuvanje_zemja', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_10_3', event)}
                                              controlInput={this.state["control_10_3"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">10.4</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRIZNAVANJE_UPRAVUVANJE_DA}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    valueInput={this.state.dokaz_upravuvanje_minato}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('dokaz_upravuvanje_minato', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_10_4', event)}
                                              controlInput={this.state["control_10_4"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">11</Typography></Grid>
                        </Grid>

                        <Grid container xs={8}>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_PARALELNO_MESANO_PROIZ_R}
                                                    size={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PARALELNO_MESANO_PROIZ}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.paralelno_proizvodstvo}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('paralelno_proizvodstvo', event)}/>
                            </Grid>
                            {this.state.paralelno_proizvodstvo === "true" &&
                            <Grid alignContent={"flex-start"} container>
                                <GridTable
                                    obj={this.state.paralelno_proizvodstvo_data}
                                    labeli={[ALL.KULTURA, ALL.POVRSHINA]}
                                    values={["kultura", "povrsina"]}
                                    valueName={"paralelno_proizvodstvo_data"}
                                    types={["dropdown", "text"]}
                                    disabled={[!hasRoleClient, !hasRoleClient]}
                                    disabledButtons={!hasRoleClient}
                                    ddData={[this.props.lookupData.kultura, null]}
                                    columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                    size={12}
                                    template={paralelnoProizvodstvoTemplate}
                                    onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>
                            }
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_11', event)}
                                              controlInput={this.state["control_11"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">12</Typography></Grid>
                        </Grid>

                        <Grid container xs={8}>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_SEMINJA_SADEN_M}
                                                    size={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.seminja_saden_materijal}
                                        labeli={[ALL.KULTURA, ALL.POU_SEMINJA]}
                                        values={["kultura", "seminja"]}
                                        types={["text", "checkbox"]}
                                        disabled={[!hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        ddData={[null, seminja]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={seminjaSadenMaterijalTemplate}
                                        valueName={"seminja_saden_materijal"}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_12_2', event)}
                                              controlInput={this.state["control_12_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">13</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PLODORED}
                                                size={12}
                            />
                            <CustomLabeledInput labelInput={ALL.POU_PLODORED_OPIS}
                                                size={12}
                                                firstElementSize={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">13.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PLODORED_NE_PRIMENLIVO}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.plodored_ne_primenlivo}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('plodored_ne_primenlivo', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_13_1', event)}
                                              controlInput={this.state["control_13_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">13.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PLODORED_OPISANO}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.plodored_opis}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('plodored_opis', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_13_2', event)}
                                              controlInput={this.state["control_13_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">14</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_LEGUMENOZI_POKRIVNI_K}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">14.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_LEGUMENOZI_OPIS}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    valueInput={this.state.legumenozi_opis}
                                                    onChangeAction={(event) => this.handleChange('legumenozi_opis', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_14_1', event)}
                                              controlInput={this.state["control_14_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">15</Typography></Grid>
                        </Grid>

                        <Grid container xs={8}>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_GJUBRENJE}
                                                    size={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_GJUBRENJE_OPIS}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    valueInput={this.state.gjubrenje_opis}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('gjubrenje_opis', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_15', event)}
                                              controlInput={this.state["control_15"]}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">15.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.gjubrenje}
                                        labeli={[ALL.POU_STATUS, ALL.POU_SOPSTVENO, ALL.POU_KUPENO, ALL.POU_KORISTENA_KOLICINA]}
                                        values={["gjubrivo", "poteklo_sopstveno", "poteklo_kupeno", "kolichini"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        valueName={"gjubrenje"}
                                        types={["text", "text", "text", "text"]}
                                        ddData={[null, null, null, null]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={gjubrenjeTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_15_1', event)}
                                              controlInput={this.state["control_15_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">16</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_KONTROLA_BOLESTI_STETNICI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">16.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_POU_KONTROLA_BOLESTI_STETNICI_OPIS}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    valueInput={this.state.kontrola_bolesti_impl}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('kontrola_bolesti_impl', event)}

                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_16_1', event)}
                                              controlInput={this.state["control_16_1"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">16.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_IMPLEMENTACIJA_PREVENTIVNI_MERKI}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.kontrola_bolesti_proizvodi}
                                        labeli={[ALL.KULTURA, ALL.POU_STETNIK_BOLEST, ALL.POU_UPOTREBEN_PROIZVOD, ALL.POU_DOZA, ALL.POU_AKTIVNA_MATERIJA, ALL.POU_NEMA_UPOT_PREP]}
                                        values={["kultura", "shtetnik", "proizvod", "doza", "aktivna_materija", "bez_preparat"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        valueName={"kontrola_bolesti_proizvodi"}
                                        types={["text", "text", "text", "text", "text", "checkbox"]}
                                        ddData={[null, null, null, null, null, prazenChecbox]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={kontrolaBolestiProizvodiTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_16_2', event)}
                                              controlInput={this.state["control_16_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">17</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_PESTICIDI_SOSEDNI_FARMI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">17.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_GRANICI_NANESUVANJE_PESTICIDI}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.pesticidi_sosedni_farmi_granici}
                                        labeli={[ALL.POU_POVRSINA, ALL.POU_GRANICI, ALL.POU_SOSEDNA_KONVEC_K, ALL.POU_INTENZITET_PRSKANJE, ALL.POU_OPREMA_PRSKANJE, ALL.POU_RASTOJANIE, ALL.POU_VEGETACIJA_G, ALL.POU_RIZIK]}
                                        values={["povrshina", "granici", "sosedna_konven_kultura", "intenzitet_prskanje", "oprema_prskanje", "rastojanie", "vegetacija", "rizik"]}
                                        types={["text", "text", "text", "text", "text", "text", "dropdown", "dropdown"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        valueName={"pesticidi_sosedni_farmi_granici"}
                                        ddData={[null, null, null, null, null, null, yesno, ddRizikData]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={pesticidiSosedniFarmiGraniciTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_17_1', event)}
                                              controlInput={this.state["control_17_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">17.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_REZIDUI_PEST}
                                                    size={12}
                                                    vertical={true}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.ispituvanje_rezidui}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('ispituvanje_rezidui', event)}/>
                            </Grid>
                            {
                                this.state.ispituvanje_rezidui === "true" &&
                                <Grid container justify='space-around'>
                                    <CustomLabeledInput labelInput={ALL.POU_REZIDUI_PEST_DA}
                                                        inputType={"text"}
                                                        disabled={!hasRoleClient}
                                                        size={12}
                                                        placeholderInput={" "}
                                                        multilineInput={true}
                                                        vertical={true}
                                                        valueInput={this.state.ispituvanje_rezidui_opis}
                                                        onChangeAction={(event) => this.handleChange('ispituvanje_rezidui_opis', event)}/>

                                </Grid>
                            }
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_17_2', event)}
                                              controlInput={this.state["control_17_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">18</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_VODA_ZAGADUVANJE}
                                                firstElementSize={12}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">18.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_VODA_ZAGADUVANJE_IZVOR}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.navodnuvanje_zagaduvanje}
                                                    options={yesno}
                                                    vertical={true}
                                                    onChangeAction={(event) => this.handleChange('navodnuvanje_zagaduvanje', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_18_1', event)}
                                              controlInput={this.state["control_18_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">18.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_VODA_KVALITET}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    vertical={true}
                                                    valueinput={this.state.navodnuvanje_zagaduvanje_opis}
                                                    onChangeAction={(event) => this.handleChange('navodnuvanje_zagaduvanje_opis', event)}/>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_18_2', event)}
                                              controlInput={this.state["control_18_2"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">19</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_BERBA_PRINOSI}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">19.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <Grid style={{width: columnWidth6}} className={classes.purpleColoredItemGrid}>
                                    </Grid>
                                    <Grid style={{width: columnWidth2od6}}
                                          className={classes.purpleColoredItemGrid}>
                                        <Typography align={"center"}
                                                    variant="h6"> {ALL.POU_PRINOS_MINATA_GODINA} </Typography>
                                    </Grid>
                                    <Grid style={{width: columnWidth2od6}}
                                          className={classes.purpleColoredItemGrid}>
                                        <Typography align={"center"}
                                                    variant="h6"> {ALL.POU_OCEKUVAN_PRINOS} </Typography>
                                    </Grid>
                                    <Grid style={{width: columnWidth6}} className={classes.purpleColoredItemGrid}>
                                    </Grid>
                                    <GridTable
                                        obj={this.state.berba_prinosi}
                                        labeli={[ALL.POU_KULTURA, ALL.POU_VKUPNO_BRUTO, ALL.POU_BRUTO_HA, ALL.POU_VKUPNO_BRUTO, ALL.POU_BRUTO_HA, ALL.POU_PERIOD_BERBA]}
                                        values={["kultura", "vkupno_bruto_lani", "bruto_ha_lani", "vkupno_bruto_godinava", "bruto_ha_godinava", "period_berba"]}
                                        types={["text", "text", "text", "text", "text", "text"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        valueName={"berba_prinosi"}
                                        ddData={[null, null, null, null, null, null]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={berbaPrinosiTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_19_1', event)}
                                              controlInput={this.state["control_19_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">19.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_SUMA_PROIZ_SERT}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.prihod_proizvod}
                                        labeli={[ALL.POU_KULTURA, ALL.POU_ZALIHA_MIN_GOD, ALL.POU_VKUPNO_NETO, ALL.POU_PRODADENO_ORG, ALL.POU_PODADENO_ORG_PREOD, ALL.POU_PRODADENO_KONVENV, ALL.POU_MOM_ZALIHA]}
                                        values={["kultura", "zaliha_lani", "vkupno_neto_prinos", "prodadeno_organsko", "prodadeno_organsko_preod", "prodadeno_konvencionalno", "momentalna_zaliha"]}
                                        types={["text", "text", "text", "text", "text", "text", "text"]}
                                        disabledButtons={!hasRoleClient}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        valueName={"prihod_proizvod"}
                                        ddData={[null, null, null, null, null, null, null]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={prihodProizvodTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_19_2', event)}
                                              controlInput={this.state["control_19_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">20</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_POSTBERBENO_RAKUVANJE}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">20.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput size={12}
                                                    inputType={"checkbox"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.post_berbeno_rakuvanje}
                                                    options={postberbeno}
                                                    onChangeAction={(event) => this.multiselectHandleChange('post_berbeno_rakuvanje', event)}/>
                            </Grid>

                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_20_1', event)}
                                              controlInput={this.state["control_20_1"]}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">20.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_BERB_RAK_POINAKU}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <GridTable
                                        obj={this.state.post_berbeno_rakuvanje_opis}
                                        labeli={[ALL.POU_POSTAPKA, ALL.POU_IZVEDUVA, ALL.POU_ODGOVOREN, ALL.POU_ODDELUVANJE_NESERT]}
                                        values={["postapka", "mesto_izveduvanje", "odgovoren", "nachin_odeluvanje"]}
                                        types={["textarea", "textarea", "textarea", "textarea"]}
                                        disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleClient]}
                                        disabledButtons={!hasRoleClient}
                                        valueName={"post_berbeno_rakuvanje_opis"}
                                        ddData={[null, null, null, null]}
                                        columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                        size={12}
                                        template={postBerbenoRakuvanjeOpisTemplate}
                                        onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_20_2', event)}
                                              controlInput={this.state["control_20_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">20.3</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_DOZVOLENI_NAVEDENI_SUP}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.skaldiranje}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('skaldiranje', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput
                                    inputType={"text"}
                                    disabled={!hasRoleClient}
                                    size={12}
                                    vertical={true}
                                    valueInput={this.state.skaldiranje_opis}
                                    placeholderInput={" "}
                                    multilineInput={true}
                                    onChangeAction={(event) => this.handleChange('skaldiranje_opis', event)}

                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_20_3', event)}
                                              controlInput={this.state["control_20_3"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">21</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ETIKETIRANJE}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">21.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRIMEROK_ETIKETA}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.etiketa}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('etiketa', event)}/>
                            </Grid>
                            {this.state.etiketa === "true" &&
                            <Grid container justify='space-around'
                                  style={{border: "1px solid black", display: 'flex', justifyContent: 'center'}}>
                                <CustomLabeledInput labelInput={ALL.POU_PRIMEROK_ETIKETA_DA}
                                                    size={12}
                                                    vertical={true}/>
                                <UploadInput uploadFile={() => this.props.uploadFile()}
                                             fileUploadId={"etiketa_file"}
                                             disabledButtons={!hasRoleClient}
                                             fileId={this.state.etiketa_file}
                                             onDeleteAction={() => this.deleteFile("etiketa_file")}
                                             onChangeAction={() => this.uploadFileAndSetState("etiketa_file")}
                                             uploadLabel={"Додади етикета!"} downloadLabel={"Превземи етикета!"}/>
                            </Grid>
                            }
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_21_1', event)}
                                              controlInput={this.state["control_21_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">21.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_PRAVILA_LOGO_ZNAK}
                                                    inputType={"text"}
                                                    disabled={!hasRoleClient}
                                                    size={12}
                                                    vertical={true}
                                                    valueInput={this.state.logo_znak_opis}
                                                    placeholderInput={" "}
                                                    multilineInput={true}
                                                    onChangeAction={(event) => this.handleChange('logo_znak_opis', event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_21_2', event)}
                                              controlInput={this.state["control_21_2"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">22</Typography></Grid>
                        </Grid>
                        <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                            <CustomLabeledInput labelInput={ALL.POU_ZAPISI_SLEDLIVOST}
                                                size={12}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">22.1</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_DOBRI_ZAPISI}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.azurirani_zapisi}
                                                    options={daNeDelumnoData}
                                                    onChangeAction={(event) => this.handleChange('azurirani_zapisi', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_DNEV_FARMA}
                                                    size={12}
                                                    options={daNeDelumnoData}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.dnevnik}
                                                    onChangeAction={(event) => this.handleChange('dnevnik', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_FAKTURI_NABAVKI}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.fakturi}
                                                    options={daNeDelumnoData}
                                                    onChangeAction={(event) => this.handleChange('fakturi', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_DNEV_PAKUVANJE_PRERABOTKA}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.dnevnik_pakuvanje}
                                                    options={daNeDelumnoData}
                                                    vertical={true}
                                                    onChangeAction={(event) => this.handleChange('dnevnik_pakuvanje', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_EVIDEN_PRODAZBI}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    valueInput={this.state.evidencija_prodazba}
                                                    options={daNeDelumnoData}
                                                    vertical={true}
                                                    onChangeAction={(event) => this.handleChange('evidencija_prodazba', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_DRUGI}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.drugi}
                                                    options={daNeDelumnoData}
                                                    onChangeAction={(event) => this.handleChange('drugi', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput
                                    inputType={"text"}
                                    disabled={!hasRoleClient}
                                    size={12}
                                    placeholderInput={" "}
                                    multilineInput={true}
                                    vertical={true}
                                    valueInput={this.state.drugi_opis}
                                    onChangeAction={(event) => this.handleChange('drugi_opis', event)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_22_1', event)}
                                              controlInput={this.state["control_22_1"]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container className={classes.numeratedItemGrid}>
                            <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                variant="h5">22.2</Typography></Grid>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput labelInput={ALL.POU_CUVANJE_ZAPISI}
                                                    size={12}
                                                    inputType={"radio"}
                                                    disabled={!hasRoleClient}
                                                    vertical={true}
                                                    valueInput={this.state.zapisi_zalbi_poplaki}
                                                    options={yesno}
                                                    onChangeAction={(event) => this.handleChange('zapisi_zalbi_poplaki', event)}/>
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput
                                    labelInput={ALL.POU_ZAPISI_ZALBI_POPLAKI_NE + "\n" + ALL.POU_ZAPISI_ZALBI_POPLAKI_DA}
                                    size={12}
                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid alignContent={"flex-start"} container>
                                <GridTable obj={this.state.primeni_zalbi}
                                           labeli={[ALL.POU_PRIMENI_ZALBI, ALL.POU_PREVZEMENI_KOREKTIVNI_AKT]}
                                           values={["zalba", "korektivni_aktivnosti"]}
                                           types={["text", "text"]}
                                           disabled={[!hasRoleClient, !hasRoleClient]}
                                           disabledButtons={!hasRoleClient}
                                           ddData={[null, null, null]}
                                           columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                           valueName={"primeni_zalbi"}
                                           size={12}
                                           cellWidth={12}
                                           template={primeniZalbiTemplate}
                                           onChangeAction={(name, value) => this.handleObj(name, value)}
                                />
                            </Grid>

                        </Grid>
                        <Grid container className={classes.kontrolorItemGrid}>
                            <Grid container justify='space-around'>
                                <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                              onChangeAction={(event) => this.handleChange('control_22_2', event)}
                                              controlInput={this.state["control_22_2"]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid container className={classes.numeratedItemGrid}>
                                <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                    variant="h5">22.3</Typography></Grid>
                            </Grid>
                            <Grid container xs={8}>
                                <Grid container justify='space-around'>
                                    <CustomLabeledInput labelInput={ALL.POU_SLEDLIVOST}
                                                        inputType={"text"}
                                                        disabled={!hasRoleClient}
                                                        size={12}
                                                        vertical={true}
                                                        placeholderInput={" "}
                                                        multilineInput={true}
                                                        valueInput={this.state.sledlivost}
                                                        onChangeAction={(event) => this.handleChange('sledlivost', event)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.kontrolorItemGrid}>
                                <Grid container justify='space-around'>
                                    <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                                  onChangeAction={(event) => this.handleChange('control_22_3', event)}
                                                  controlInput={this.state["control_22_3"]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.container}>
                            <Grid container className={classes.numeratedItemGrid}>
                                <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                    variant="h5">22.4</Typography></Grid>
                            </Grid>
                            <Grid container xs={8}>
                                <Grid container justify='space-around'>
                                    <CustomLabeledInput labelInput={ALL.POU_SLEDLIVOST_PODOBRUVANJE}
                                                        inputType={"text"}
                                                        disabled={!hasRoleClient}
                                                        size={12}
                                                        vertical={true}
                                                        placeholderInput={" "}
                                                        multilineInput={true}
                                                        valueInput={this.state.sledlivost_podobruvanje}
                                                        onChangeAction={(event) => this.handleChange('sledlivost_podobruvanje', event)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.kontrolorItemGrid}>
                                <Grid container justify='space-around'>
                                    <ControlInput editable={this.hasRole("CONTROL_ADMIN")}
                                                  onChangeAction={(event) => this.handleChange('control_22_4', event)}
                                                  controlInput={this.state["control_22_4"]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br/>
                    <br/>
                    <br/>

                    <Typography variant="h5">{ALL.POU_ZADOLZITELNI_ANEKSI}</Typography>
                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_1}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_1}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_2}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_2}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_3}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_3}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_4}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_4}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_5}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_5}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_6}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={6}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_ANEKS_ZADOLZITELNO_6}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <Typography variant="h5">{ALL.POU_DRUGI_ANEKSI}</Typography>
                    <Grid container className={classes.container}>
                        <GridTable obj={this.state.aneksi}
                                   labeli={[ALL.POU_BROJ, ALL.POU_ANEKS]}
                                   values={["broj", "aneks"]}
                                   types={["text", "upload"]}
                                   disabled={[!hasRoleClient, !hasRoleClient]}
                                   disabledButtons={!hasRoleClient}
                                   ddData={[null, null]}
                                   uploadFile={(name) => this.props.uploadFile(name)}
                                   columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                   valueName={"aneksi"}
                                   size={12}
                                   template={aneksiTemplate}
                                   onChangeAction={(name, value) => this.handleObj(name, value)}
                        />
                    </Grid>
                    <br/>
                    <Typography variant="h5">{ALL.POU_IZJAVA_KLIENT}</Typography>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_IZJAVA_SOGLASNOST}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_IZJAVA_1}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_IZJAVA_2}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container>
                                <CustomLabeledInput labelInput={ALL.POU_IZJAVA_3}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <br/>
                    <br/>
                    <Grid container className={classes.container}>
                        <Grid container xs={12}>
                            <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                                <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                    variant="h5">23</Typography></Grid>
                            </Grid>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput
                                    labelInput={ALL.POU_SUMIRANJE_NESOODVETNOSTI_OPIS}
                                    size={12}
                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>
                                    <Grid style={{width: this.calculateHeaderSize(7, 4.6)}}
                                          className={classes.purpleColoredItemGrid}>
                                        <Typography align={"center"} variant="h6"> {ALL.POU_POP_FARMA} </Typography>
                                    </Grid>
                                    <Grid style={{width: this.calculateHeaderSize(7, 2.4)}}
                                          className={classes.blueColoredItemGrid}>
                                        <Typography align={"center"}
                                                    variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                    </Grid>
                                    <GridTable obj={this.state.nesoodvetnosti_farma}
                                               labeli={[ALL.POU_NESOODVETNOST, ALL.POU_KOREKTIVNA_AKTIVNOST,
                                                   ALL.POU_ROK_IMPLEMENTACIJA,
                                                   ALL.POU_NESOODVETNOSTI_IDENTIFIKUVANI, ALL.POU_ROK_ADEK,
                                                   ALL.POU_KOREKT_AKT_ADEK, ALL.POU_SERTIFIKATPOSLE_KOREKCIJA]}
                                               controlIndexes={[]}
                                               colWidth={[1.9, 1.9, 0.8, 0.6, 0.6, 0.6, 0.6]}
                                               values={["nesoodvetnost", "korektivna_aktivnost", "rok_implementacija",
                                                   "tocno_identifikuvani", "adekvatni", "adekvaten_rok", "sertifikat_posle_korekcija"]}
                                               types={["textarea", "textarea", "textarea", "dropdown", "dropdown", "dropdown", "dropdown"]}
                                               valueName={"nesoodvetnosti_farma"}
                                               ddData={[null, null, null, yesnomaybe, yesnomaybe, yesnomaybe, yesnomaybe]}
                                               columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                               size={12}
                                               disabled={[!hasRoleClient, !hasRoleClient, !hasRoleClient, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                               template={nesoodvetnostiFarmaTemplate}
                                               onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>

                    <Grid container className={classes.container}>

                        <Grid container xs={12}>
                            <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                                <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                    variant="h5">24</Typography></Grid>
                            </Grid>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_DOP_NESOODVETNOSTI}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <Grid alignContent={"flex-start"} container>

                                    <Grid style={{width: this.calculateHeaderSize(7, 2.4)}}
                                          className={classes.blueColoredItemGrid}>
                                        <Typography align={"center"}
                                                    variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                    </Grid>
                                    <Grid style={{width: this.calculateHeaderSize(7, 2.8)}}
                                          className={classes.purpleColoredItemGrid}>
                                        <Typography align={"center"} variant="h6"> {ALL.POU_POP_FARMA} </Typography>
                                    </Grid>
                                    <Grid style={{width: this.calculateHeaderSize(7, 1.8)}}
                                          className={classes.blueColoredItemGrid}>
                                        <Typography align={"center"}
                                                    variant="h6"> {ALL.POU_POP_KONTROLOR} </Typography>
                                    </Grid>
                                    <GridTable obj={this.state.dopolnitelni_nesoodvetnosti}
                                               labeli={[ALL.POU_BR, ALL.POU_NESOODVETNOST, ALL.POU_KOREKT_AKT_DEF,
                                                   ALL.POU_ROK, ALL.POU_KOREKT_AKT_ADEK,
                                                   ALL.POU_ROK_ADEK, ALL.POU_SERTIF_POSLE_KOREK]}
                                               controlIndexes={[]}
                                               colWidth={[0.5, 1.9, 1.9, 0.9, 0.6, 0.6, 0.6]}
                                               values={["broj", "nesoodvetnosti", "korektivni_aktivnosti_definicija", "rok",
                                                   "adekvateni_korektivni_aktivnosti", "adekvaten_rok", "sertifikat_posle_korekcija"]}
                                               valueName={"dopolnitelni_nesoodvetnosti"}
                                               types={["textarea", "textarea", "textarea", "textarea", "dropdown", "dropdown", "dropdown"]}
                                               ddData={[null, null, null, null, yesnomaybe, yesnomaybe, yesnomaybe]}
                                               columnHeaderClass={'heightPurpleControlColoredItemGrid'}
                                               size={12}
                                               disabled={[!hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleClient, !hasRoleClient, !hasRoleControlAdmin, !hasRoleControlAdmin, !hasRoleControlAdmin]}
                                               template={dopolnitelniNesoodvetnostiTemplate}
                                               onChangeAction={(name, value) => this.handleObj(name, value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid container className={classes.container}>

                        <Grid container xs={12}>
                            <Grid container xs={1} className={classes.numeratedFreeItemGrid}>
                                <Grid item xs={12} className={classes.centeredItemGrid}><Typography
                                    variant="h5">25</Typography></Grid>
                            </Grid>
                            <Grid container justify='space-around' className={classes.orangeColoredItemGrid}>
                                <CustomLabeledInput labelInput={ALL.POU_ZAKLUCOK_KONTROLOR}
                                                    size={12}
                                                    firstElementSize={12}
                                />
                            </Grid>
                            <Grid container justify='space-around'>
                                <CustomLabeledInput size={12}
                                                    fullWidthCheckbox={true}
                                                    inputType={"checkbox"}
                                                    vertical={true}
                                                    valueInput={this.state.zaklucok}
                                                    options={zakluchok}
                                                    disabled={!hasRoleControlAdmin}
                                                    onChangeAction={(event) => this.multiselectHandleChange('zaklucok', event)}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br/>
                    <br/>
                    <br/>
                    <Grid container xs={12}>
                        <Grid item xs={1}/>
                        <Grid item xs={3}>
                            <Typography className={classes.centeredText}
                                        variant="h5">{ALL.POU_DATA_POTPIS_F_K}</Typography>
                        </Grid>
                        <Grid item xs={5}/>
                        <Grid item xs={3}>
                            <Typography className={classes.centeredText}
                                        variant="h5">{ALL.POU_DATA_POTPIS_KONT}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={4}>
                            <CustomLabeledInput inputType={"text"}
                                                size={12}
                                                placeholderInput={" "}
                                                valueInput={this.state.data_farma}
                                                onChangeAction={(event) => this.handleChange('data_farma', event)}
                            />
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={4}>
                            <CustomLabeledInput inputType={"text"}
                                                size={12}
                                                disabled={!hasRoleControlAdmin}
                                                placeholderInput={" "}
                                                valueInput={this.state.data_kontrolor}
                                                onChangeAction={(event) => this.handleChange('data_kontrolor', event)}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{height: "80px"}}/>
                {/*{this.props.editMode &&*/
                }
                {/*< Button style={globalStyles.neutralButton} variant="contained" size={"medium"}*/
                }
                {/*         aria-label="update"*/
                }
                {/*         onClick={() => this.props.onSubmit(this.state)}>*/
                }
                {/*    Зачувај ПОУ растително*/
                }
                {/*    <EditIcon/>*/
                }
                {/*</Button>*/
                }
                {/*}*/
                }
                {
                    (createMode || editMode) && !canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButtonDisabled, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.requiredFieldsMessage()}>
                        {createMode ? "Креирај ПОУ растително" : "Зачувај ПОУ растително"}
                    </Button>
                }
                {
                    (createMode || editMode) && canSave &&
                    < Button
                        classes={{
                            root: classes.addFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"large"}
                        aria-label="create"
                        onClick={() => this.props.onSubmit(this.state)}>
                        {createMode ? "Креирај ПОУ растително" : "Зачувај ПОУ растително"}
                    </Button>
                }
                {
                    (createMode || editMode) && canSave &&
                    < Button
                        classes={{
                            root: classes.printFormStickyButton, // class name, e.g. `classes-nesting-root-x`
                            label: classes.label, // class name, e.g. `classes-nesting-label-x`
                        }}
                        variant="contained" size={"small"}
                        aria-label="print"
                        onClick={() => this.handlePrint()}>
                        {<PrintIcon/>}

                    </Button>
                }

                <Notification
                    notify={notify}
                    message={message}
                    error={error}
                    closed={this.handleNotificationClosed}
                />
            </div>
        );
    }
}


PouRastitelno.propTypes =
    {
        classes: PropTypes.object.isRequired,
        hasRole: PropTypes.bool,
    }
;

PouRastitelno = reduxForm(
    {
        form: 'PouRastitelno',
        enableReinitialize: true,
        hasRole: true,
    }
)(PouRastitelno)

export default withStyles(globalStyles)(PouRastitelno);